import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import colors from 'src/theme/colors';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import MaterialInput from 'src/components/form/MaterialInput';
import { InputLabel, Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { createFrameworkLibrary, updateFrameworkLibrary } from 'src/api/frameworklibraries';
import MaterialDatePicker from 'src/components/form/MaterialDatePicker';
import { isValid } from 'date-fns';
import { queryCache, useMutation } from 'react-query';
import { Framework, QKeys } from 'src/api/types';

const StyledModal = styled(Modal)`
  width: 950px;
  min-height: 200px;
  margin: 8px;
  padding: 18px 0px 20px 0px;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .grid {
    display: grid;
    grid-gap: 8px;

    &--2 {
      grid-template-columns: 1fr 1fr;
    }
    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .error {
    color: ${colors.error};
    font-size: 14px;
    text-align: center;
  }
`;

const ModalHeaderText = styled(Poppins)`
  padding-left: 40px;
`;

const frameworkValidationSchema = Yup.object({
  name: Yup.string().required('Required'),
  shortname: Yup.string().nullable().max(255, 'Max 255 characters'),
  frameworkLibraryId: Yup.string().nullable().max(255, 'Max 255 characters'),
  external_id: Yup.string().nullable().max(255, 'Max 255 characters'),
  version: Yup.string().nullable().max(255, 'Max 255 characters'),
  author: Yup.string().nullable().max(255, 'Max 255 characters'),
  description: Yup.string().nullable().max(2048, 'Max 2048 characters'),
  publish_date: Yup.string()
    .nullable()
    .test('publish_date', 'Invalid Date', (date) => {
      if (typeof date === 'string') return isValid(new Date(date));
      return false;
    }),
});

interface Props {
  onClose: () => void;
  frameworkData?: Framework;
}

export const FrameworkLibraryModal = ({ frameworkData, onClose }: Props) => {
  const [responseError, setResponseError] = useState('');

  const onSuccess = () => {
    queryCache.invalidateQueries(QKeys.Frameworks);
    onClose();
  };

  const onError = (err: any) => setResponseError(err.message || 'Server Error Encountered');

  const [create] = useMutation(createFrameworkLibrary, {
    onSuccess,
    onError,
  });

  const [update] = useMutation(updateFrameworkLibrary, {
    onSuccess,
    onError,
  });

  const handleClose = () => {
    onClose();
  };

  const isUpdate = Boolean(frameworkData);

  const initialValues = frameworkData
    ? frameworkData
    : {
        name: '',
        shortname: '',
        description: '',
        external_id: '',
        frameworkLibraryId: '',
        version: '',
        author: '',
        publish_date: '',
      };

  const {
    values,
    errors,
    isSubmitting,
    handleSubmit,
    handleChange,
    handleBlur,
    setFieldValue,
    touched,
    isValid: formikIsValid,
  } = useFormik({
    initialValues,
    validationSchema: frameworkValidationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      if (isUpdate) {
        // @ts-ignore
        await update(values);
      } else {
        // @ts-ignore
        await create(values);
      }
    },
  });

  return (
    <StyledModal onRequestClose={handleClose} isOpen>
      <ModalHeaderText className="m-title" px={28}>
        Framework Library
      </ModalHeaderText>
      <Spacer $px={5} />
      <div className="h-padding">
        <InputLabel>NAME</InputLabel>
        <MaterialInput
          name="name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.name}
          placeholder="Framework library name"
          css="width: 100%;"
          data-cy="framework-name-input"
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
      </div>
      <Spacer $px={15} />
      <div className="h-padding">
        <InputLabel>SHORT NAME</InputLabel>
        <MaterialInput
          name="shortname"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.shortname}
          placeholder="Framework library shortname"
          css="width: 100%;"
          data-cy="framework-shortname-input"
          error={touched.shortname && Boolean(errors.shortname)}
          helperText={touched.shortname && errors.shortname}
        />
      </div>
      <Spacer $px={15} />
      <div className="h-padding">
        <InputLabel>FRAMEWORK LIBRARY ID</InputLabel>
        <MaterialInput
          name="frameworkLibraryId"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.frameworkLibraryId}
          placeholder="Framework library id"
          css="width: 100%;"
          data-cy="framework-libraryid-input"
          error={touched.frameworkLibraryId && Boolean(errors.frameworkLibraryId)}
          helperText={touched.frameworkLibraryId && errors.frameworkLibraryId}
        />
      </div>
      <Spacer $px={15} />
      <div className="h-padding">
        <InputLabel>EXTERNAL IDENTIFIER</InputLabel>
        <MaterialInput
          name="external_id"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.external_id}
          placeholder="External library identifier"
          css="width: 100%;"
          data-cy="external-identifier-input"
          error={touched.external_id && Boolean(errors.external_id)}
          helperText={touched.external_id && errors.external_id}
        />
      </div>
      <Spacer $px={15} />
      <div className="h-padding">
        <InputLabel>VERSION</InputLabel>
        <MaterialInput
          name="version"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.version}
          placeholder="Framework library version"
          css="width: 100%;"
          data-cy="framework-version-input"
          error={touched.version && Boolean(errors.version)}
          helperText={touched.version && errors.version}
        />
      </div>
      <Spacer $px={15} />
      <div className="grid grid--2 h-padding">
        <div className="">
          <InputLabel>AUTHOR</InputLabel>
          <MaterialInput
            name="author"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.author}
            placeholder="Framework library author"
            css="width: 100%;"
            data-cy="framework-author-input"
            error={touched.author && Boolean(errors.author)}
            helperText={touched.author && errors.author}
          />
        </div>
        <div className="">
          <InputLabel>PUBLISH DATE</InputLabel>
          <MaterialDatePicker
            value={values.publish_date}
            onChange={(date) => setFieldValue('publish_date', date)}
            inputProps={{
              placeholder: 'Publish date',
              onBlur: handleBlur('publish_date'),
              fullWidth: true,
              error: touched.publish_date && Boolean(errors.publish_date),
              helperText: touched.publish_date && errors.publish_date,
              ['data-cy']: 'publish-date-input',
            }}
          />
        </div>
      </div>
      <Spacer $px={15} />
      <div className="h-padding">
        <div className="grid grid--12">
          <InputLabel>DESCRIPTION</InputLabel>
          <MaterialInput
            name="description"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description}
            multiline
            maxRows={4}
            minRows={2}
            placeholder="Framework library description"
            css="width: 100%;"
            data-cy="framework-description-input"
            error={touched.description && Boolean(errors.description)}
            helperText={touched.description && errors.description}
          />
        </div>
      </div>
      <Spacer $px={30} />
      <div className="h-padding">
        <div className="grid grid--12">
          <Button disabled={!formikIsValid || isSubmitting} onClick={() => handleSubmit()} primary css="width: 100%;">
            {!!isUpdate ? 'UPDATE' : 'CREATE'}
          </Button>
          {!!responseError && (
            <>
              <Spacer $px={15} />
              <div className="error">{responseError}</div>
            </>
          )}
        </div>
      </div>
      <Spacer $px={10} />
    </StyledModal>
  );
};
