import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { GTCheckBox, GTColumnSelectAnchor } from 'src/components/GenericTable';
import { formatDate } from 'src/utils/misc';
import { filter } from 'lodash';
import { TableUserItem } from 'src/common';
import { GTInternalIds } from 'src/components/GenericTable/types';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

interface AssessmentVersionOptional {
  id: string;
  editable?: 0 | 1;
  name: string;
  userName?: string;
  profilePhotoPath?: string | null;
  created_at?: string;
}

type UseColumns = (a: {
  data?: AssessmentVersionOptional[];
  onSelect: (a: string) => void;
  selectedVersion: string;
  tableId: TableIds;
}) => {
  columns: ColumnDef<AssessmentVersionOptional>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['name', 'userName', 'created_at'];

export const useColumns: UseColumns = ({ data = [], onSelect, selectedVersion, tableId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${tableId}-columns`, defaultColumns);
  const [checked, setChecked] = useState<string[]>([]);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<AssessmentVersionOptional>[] = [
      {
        id: GTInternalIds.checker,
        header: () => null,
        cell: ({ row }) => {
          const id = row.original.id;
          const isChecked = checked.includes(id);

          return (
            <GTCheckBox
              isChecked={selectedVersion === id}
              onClick={() => {
                onSelect(id);
              }}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'name',
        header: 'Version Name',
        accessorFn: (row) => row.name,
        cell: (info) => info.getValue(),

        sortUndefined: 'last',
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'userName',
        header: 'Published by',
        accessorFn: (row) => row.userName || null,
        cell: ({ row }) =>
          row.original.userName ? (
            <TableUserItem name={row.original.userName} profilePhotoPath={row.original.profilePhotoPath} />
          ) : null,
        sortingFn: sortingFns.text,
      },
      {
        id: 'created_at',
        header: 'Date',
        accessorFn: (row) => row.created_at || null,
        cell: ({ row }) => (row.original.id ? formatDate(row.original.created_at || '') : null),
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: () => null,
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, selectedVersion]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Version Name' },
        { key: 'userName', title: 'Published by' },
        { key: 'created_at', title: 'Date' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported };
};
