import React from 'react';
import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { motion } from 'framer-motion';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { Link } from 'react-router-dom';

const clipNext = (angle: number) => css`
  clip-path: polygon(calc(100% - ${angle}px) 0%, 100% 50%, calc(100% - ${angle}px) 100%, 0% 100%, 0 50%, 0% 0%);
`;

const clipStart = (angle: number) => css`
  clip-path: polygon(
    calc(100% - ${angle}px) 0%,
    100% 50%,
    calc(100% - ${angle}px) 100%,
    0% 100%,
    ${angle}px 50%,
    0% 0%
  );
`;

interface DivProps {
  $isStart?: boolean;
  $isNext?: boolean;
  $isClear?: boolean;
  $zIndex: number;
  $isContractMode?: boolean;
  $angle: number;
}

const Div = styled.div<DivProps>`
  height: 86px;
  display: flex;
  align-items: center;
  position: relative;
  background-color: ${colors.cultured};
  z-index: 2;
  cursor: pointer;
  overflow: hidden;
  flex-shrink: 0;
  padding-right: 40px !important;

  .nav-content {
    overflow: hidden;
    width: 105% !important;
  }

  &:hover {
    .title {
      text-decoration: underline;
    }
    ${({ $isClear }) =>
      !$isClear &&
      css`
        path {
          fill: white;
        }
        .title {
          color: white;
        }
      `}
  }

  ${({ $zIndex }) => css`
    z-index: ${$zIndex};
  `}

  ${({ $isStart, $angle }) =>
    $isStart &&
    css`
      ${clipNext($angle)};
      .nav-content {
        padding: 0 0px 0 40px;
      }
    `};

  ${({ $isNext, $angle }) =>
    $isNext &&
    css`
      margin-left: -${$angle}px;
      ${clipStart($angle)};

      .nav-content {
        padding: 0 0px 0 50px;
      }
    `};

  ${({ $isClear }) =>
    $isClear &&
    css`
      cursor: default;
      background-color: white;
      &::after {
        background: white !important;
      }
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 56px;
    padding-right: 20px !important;

    ${({ $isStart }) =>
      $isStart &&
      css`
        .nav-content {
          padding: 0 0px 0 20px;
        }
      `};

    ${({ $isNext }) =>
      $isNext &&
      css`
        .nav-content {
          padding: 0 0px 0 30px;
        }
      `};
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 40px;
  }
`;

const MotionDiv = motion(Div);

interface NavItemProps {
  titleComponent: React.ReactNode;
  isStart?: boolean;
  isNext?: boolean;
  onClick?: () => void;
  isClear?: boolean;
  zIndex: number;
  isContractMode?: boolean;
  width: number;
  path: string;
}

export const NavItem: React.FC<NavItemProps> = ({
  titleComponent,
  isStart,
  isNext,
  onClick,
  isClear,
  zIndex,
  isContractMode,
  width,
  path,
}) => {
  const isMd = useBreakpoints('md');
  const isSm = useBreakpoints('sm');

  return (
    <Link to={path}>
      <MotionDiv
        initial={{ width: isContractMode ? 170 : width }}
        animate={
          !isClear
            ? {
                width: isContractMode ? (width > 170 ? 170 : width) : width,
                background: `linear-gradient(106.11deg, ${colors.cultured} 0.01%, ${colors.cultured} 98.53%)`,
              }
            : {}
        }
        whileHover={
          !isClear
            ? {
                width: width,
                background: colors.gradient,
              }
            : {}
        }
        transition={{ type: 'tween', duration: 0.15 }}
        $isStart={isStart}
        $isNext={isNext}
        $isClear={isClear}
        onClick={onClick}
        $zIndex={zIndex}
        $isContractMode={isContractMode}
        $angle={isSm ? 15 : isMd ? 20 : 30}
      >
        <div className="nav-content">{titleComponent}</div>
      </MotionDiv>
    </Link>
  );
};
