import React, { useCallback, useEffect, useState } from 'react';

interface ScenarioContextInterface {
  id: string | undefined;
  isOpen: boolean;
  openModal: (id?: string | number) => void;
  closeModal: () => void;
}

const ScenarioContext = React.createContext<Partial<ScenarioContextInterface>>({});

const ScenarioProvider = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string | number | undefined>(undefined);
  const openModal = useCallback(
    (id?: string | number) => {
      if (id) {
        setId(id);
        setIsOpen(true);
      }
      setIsOpen(true);
    },
    [setIsOpen],
  );
  useEffect(() => {
    if (!isOpen) {
      setId(undefined);
    }
  }, [isOpen]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return (
    <ScenarioContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        id,
      }}
      {...props}
    />
  );
};

const useScenario = () => {
  const context = React.useContext(ScenarioContext);
  if (context === undefined) {
    throw new Error('useScenario must be used within a ScenarioProvider');
  }
  return context as ScenarioContextInterface;
};

export { ScenarioProvider, useScenario };
