import React, { useCallback, useEffect, useRef, useState } from 'react';

interface AssessmentContextInterface {
  id: string | undefined;
  isOpen: boolean;
  openModal: (id?: string | number) => void;
  closeModal: () => void;
  page: number;
  setPage: (page: number) => void;
}

const AssessmentContext = React.createContext<Partial<AssessmentContextInterface>>({});

const AssessmentProvider = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState<string | number | undefined>(undefined);
  const [page, setPage] = useState(1);

  const openModal = useCallback(
    (id?: string | number) => {
      if (id) {
        setId(id);
        setIsOpen(true);
      }
      setIsOpen(true);
    },
    [setIsOpen],
  );
  useEffect(() => {
    if (!isOpen) {
      setId(undefined);
    }
  }, [isOpen]);

  const closeModal = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);
  return (
    <AssessmentContext.Provider
      value={{
        isOpen,
        openModal,
        closeModal,
        id,
        page,
        setPage,
      }}
      {...props}
    />
  );
};

const useAssessment = () => {
  const context = React.useContext(AssessmentContext);
  if (context === undefined) {
    throw new Error('useAssessment must be used within a AssessmentProvider');
  }
  return context as AssessmentContextInterface;
};

export { AssessmentProvider, useAssessment };
