import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getAssessment, getAssessmentVersions } from 'src/api/assessment';
import Meta from 'src/components/Meta';
import { Divider, PageCard, PageChartSkeleton, PageContainer, Spacer } from 'src/common';
import ScenariosCompare from './comps/scenarios/ScenariosCompare';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { Charts } from './comps/charts';
import { AssessmentVersion, ProjectionVariant, QKeys } from 'src/api/types';
import useVersions from '../useVersions';
import { mergeScenarios } from 'src/pages/AssessmentPage/util';
import styled from 'styled-components';

const Card = styled(PageCard)`
  padding: 0;
  padding: 20px 0px 30px 0px;

  .paddings {
    padding: 0px 30px 0px 30px;
  }
`;

const CompareAssessment = () => {
  const { idA, idB } = useParams<{ idA: string; idB: string }>();
  const { versionAId, versionBId } = useVersions();
  const [versionA, setVersionA] = useState<AssessmentVersion | undefined>(undefined);
  const [versionB, setVersionB] = useState<AssessmentVersion | undefined>(undefined);
  const [tab, setTab] = useState<0 | 1 | 2>(0);

  const { data: dataAlive, isLoading: ildaLive } = useQuery(
    [QKeys.Assessment, { assessmentId: idA, version: versionAId, variant: ProjectionVariant.live }],
    getAssessment,
  );
  const { data: dataAbase, isLoading: ildaBase } = useQuery(
    [QKeys.Assessment, { assessmentId: idA, version: versionAId, variant: ProjectionVariant.base }],
    getAssessment,
  );
  const { data: dataAcomplete, isLoading: ildaComplete } = useQuery(
    [QKeys.Assessment, { assessmentId: idA, version: versionAId, variant: ProjectionVariant.complete }],
    getAssessment,
  );
  // B
  const { data: dataBlive, isLoading: ildbLive } = useQuery(
    [QKeys.Assessment, { assessmentId: idB, version: versionBId, variant: ProjectionVariant.live }],
    getAssessment,
  );
  const { data: dataBbase, isLoading: ildbBase } = useQuery(
    [QKeys.Assessment, { assessmentId: idB, version: versionBId, variant: ProjectionVariant.base }],
    getAssessment,
  );
  const { data: dataBcomplete, isLoading: ildbComplete } = useQuery(
    [QKeys.Assessment, { assessmentId: idB, version: versionBId, variant: ProjectionVariant.complete }],
    getAssessment,
  );

  const { data: versionsA, isLoading: ilva } = useQuery([QKeys.Versions, idA], () =>
    getAssessmentVersions(idA as string),
  );
  const { data: versionsB, isLoading: ilvb } = useQuery([QKeys.Versions, idB], () =>
    getAssessmentVersions(idB as string),
  );

  useEffect(() => {
    versionsA && setVersionA(versionsA.versions.find((v) => v.id === versionAId));
    versionsB && setVersionB(versionsB.versions.find((v) => v.id === versionBId));
  }, [versionAId, versionBId, versionsA, versionsB]);

  useGaEventPageView('Cydea | Compare Assessments');

  const isLoading = ildaLive || ildaBase || ildaComplete || ildbLive || ildbBase || ildbComplete || ilva || ilvb;

  if (isLoading || !dataAlive || !dataBlive || !dataAbase || !dataBbase || !dataAcomplete || !dataBcomplete)
    return (
      <PageContainer>
        <PageCard>
          <PageChartSkeleton />
        </PageCard>
      </PageContainer>
    );

  const mergedA = mergeScenarios({
    baseData: dataAlive?.scenarios || [],
    liveData: dataAbase?.scenarios || [],
    completeData: dataAcomplete?.scenarios || [],
  });

  const mergedB = mergeScenarios({
    baseData: dataBlive?.scenarios || [],
    liveData: dataBbase?.scenarios || [],
    completeData: dataBcomplete?.scenarios || [],
  });

  const showProjectedA = !!dataAbase?.scenarios
    .map((el) => el.implementationPlans?.map((el) => el.modifiers)?.flat())
    ?.flat().length;

  const showProjectedB = !!dataBbase?.scenarios
    .map((el) => el.implementationPlans?.map((el) => el.modifiers)?.flat())
    ?.flat().length;

  return (
    <>
      <Meta title={`Cydea | Compare Assessments`} feedbackScript />
      <PageContainer data-cy="compare-assessments-page">
        <Card>
          <Charts
            dataA={{ live: dataAlive, base: dataAbase, complete: dataAcomplete }}
            dataB={{ live: dataBlive, base: dataBbase, complete: dataBcomplete }}
            versionA={versionA}
            versionB={versionB}
            assessmentA={{
              seriesEnabled: tab === 0 || tab === 1,
              showProjected: showProjectedA,
            }}
            assessmentB={{
              seriesEnabled: tab === 0 || tab === 2,
              showProjected: showProjectedB,
            }}
          />

          <Spacer $px={20} />
          <Divider />
          <Spacer $px={30} />
          <div className="paddings">
            <ScenariosCompare
              tab={tab}
              setTab={setTab}
              // @ts-ignore
              dataA={{ ...dataAlive, scenarios: mergedA }}
              // @ts-ignore
              dataB={{ ...dataBlive, scenarios: mergedB }}
              versionA={versionA}
              versionB={versionB}
            />
          </div>
        </Card>
      </PageContainer>
    </>
  );
};

export default CompareAssessment;
