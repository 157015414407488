import React from 'react';
import { CLegend } from 'src/components/charts/comps';
import { LegendParams } from 'src/components/charts/comps/CLegend/types';
import useVersion from 'src/hooks/useVersion';
import colors from 'src/theme/colors';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface LegendComposedProps {
  isLegacyVersion: boolean;
  showAllProjected?: boolean;
}

export const LegendComposed: React.FC<LegendComposedProps> = ({ isLegacyVersion, showAllProjected }) => {
  const { version } = useVersion();

  const legends = !isLegacyVersion
    ? ([
        showAllProjected
          ? {
              id: 'leg-base-m',
              title: 'Unmodified Risk',
              type: 'line',
              color: colors.prussianBlue,
              tooltip: 'Represents risk without control modifiers applied',
              onClick: () =>
                mpEvent(MPEvents.GraphLegendToggle, {
                  value: 'Unmodified Risk',
                  tags: ['ASSESSMENT'],
                }),
            }
          : null,
        {
          id: 'leg-s1-m',
          title: 'Live Risk',
          type: 'line',
          color: colors.red,
          tooltip: showAllProjected ? 'Represents risk with control modifiers from live controls applied' : null,
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Live Risk',
              tags: ['ASSESSMENT'],
            }),
        },
        showAllProjected
          ? {
              id: 'leg-s2-m',
              title: 'Projected Risk',
              type: 'line',
              color: colors.brightBlue,
              tooltip: 'Represents risk with control modifiers from all controls applied',
              onClick: () =>
                mpEvent(MPEvents.GraphLegendToggle, {
                  value: 'Projected Risk',
                  tags: ['ASSESSMENT'],
                }),
            }
          : null,
        {
          title: 'Risk Tolerance',
          id: 'leg-rt',
          type: 'dash',
          color: colors.cflowerBlue,
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Risk Tolerance',
              tags: ['ASSESSMENT'],
            }),
        },
      ].filter(Boolean) as LegendParams[])
    : ([
        {
          title: 'Loss Exceedance',
          id: 'legend1',
          type: 'line',
          color: colors.gradientPart,
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Loss Exceedance',
              tags: ['ASSESSMENT'],
            }),
        },
        {
          title: 'Risk Tolerance',
          id: 'leg-rt',
          type: 'dash',
          color: colors.cflowerBlue,
          onClick: () =>
            mpEvent(MPEvents.GraphLegendToggle, {
              value: 'Risk Tolerance',
              tags: ['ASSESSMENT'],
            }),
        },
      ] as LegendParams[]);
  return <CLegend legends={legends} />;
};
