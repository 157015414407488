import React, { forwardRef } from 'react';
import classNames from 'classnames';
import styled, { css } from 'styled-components';
import { useSidebar } from 'src/state/sidebar';
import colors from 'src/theme/colors';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  display: none;
`;

const breakPoint = css<{ toggled?: boolean }>`
  position: fixed;
  left: -250px;
  &${Overlay} {
    left: -80px;
  }
  ${({ toggled }) =>
    toggled &&
    css`
      left: 0;
      ${Overlay} {
        display: block;
      }
    `}
`;

const Container = styled.div<{ collapsed?: boolean; toggled?: boolean }>`
  color: ${colors.cflowerBlue};
  height: 100vh;
  width: ${({ toggled }) => (!toggled ? '250px' : '100%')};
  min-width: 260px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  z-index: 9;

  &::before {
    content: '';
    position: relative;
    width: 50px;
  }

  ${({ collapsed }) =>
    collapsed &&
    css`
      width: 72px;
      min-width: 72px;
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: ${({ toggled }) => (!toggled ? '200px' : '100%')};
    min-width: 210px;
    ${({ collapsed }) =>
      collapsed &&
      css`
        width: 50px;
        min-width: 50px;
      `};
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: ${({ toggled }) => (!toggled ? '160px' : '100%')};
    min-width: 170px;

    position: fixed;
    top: 40px;
    height: calc(100% - 40px);

    ${({ collapsed }) =>
      collapsed
        ? css`
            width: 40px;
            min-width: 40px;
          `
        : css`
            box-shadow: 0px 0px 37px 0px rgba(7, 46, 91, 0.15);
          `};
  }

  &.xs {
    @media (max-width: 480px) {
      ${breakPoint}
    }
  }
  &.sm {
    @media (max-width: 576px) {
      ${breakPoint}
    }
  }
  &.md {
    @media (max-width: 768px) {
      ${breakPoint}
    }
  }
  &.lg {
    @media (max-width: 992px) {
      ${breakPoint}
    }
  }
  &.xl {
    @media (max-width: 1200px) {
      ${breakPoint}
    }
  }
`;

const Inner = styled.div`
  background: ${colors.white};
  height: 100%;
  position: relative;
  z-index: 101;
  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    opacity: 0.3;
    left: 0;
    top: 0;
    z-index: 100;
  }
`;

const Layout = styled.div`
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 9;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
`;

export interface Props {
  width?: string | number;
  image?: string;
  className?: string;
  children?: React.ReactNode;
  breakPoint?: 'xl' | 'lg' | 'md' | 'sm' | 'xs';
}

const Sidebar: React.ForwardRefRenderFunction<unknown, Props> = (
  { children, className, width, breakPoint, ...rest },
  ref,
) => {
  const sidebarRef = (ref as any) || React.createRef<HTMLDivElement>();
  const { collapsed, toggled, setToggled } = useSidebar();
  return (
    <Container
      {...rest}
      ref={sidebarRef}
      collapsed={collapsed}
      toggled={toggled}
      className={classNames('pro-sidebar', className, breakPoint, { collapsed, toggled })}
      style={{ width }}
    >
      <Inner>
        <Layout>{children}</Layout>
      </Inner>
      <Overlay
        onClick={() => setToggled(false)}
        onKeyPress={() => setToggled(false)}
        role="button"
        tabIndex={0}
        aria-label="overlay"
      />
    </Container>
  );
};

export default forwardRef<unknown, Props>(Sidebar);
