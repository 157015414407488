import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconUnmakeAdmin: React.FC<Props> = ({ isActive }) => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.89013 5.184L9.47713 2.5C6.47713 2.5 4.97713 1 4.97713 1C4.97713 1 3.47713 2.5 0.477129 2.5L0.0641285 5.184C-0.1081 6.30199 0.0685785 7.4459 0.570086 8.45983C1.07159 9.47376 1.87354 10.3084 2.86663 10.85L4.97713 12L7.08763 10.85C8.08072 10.3084 8.88266 9.47376 9.38417 8.45983C9.88568 7.4459 10.0624 6.30199 9.89013 5.184ZM7.33063 5.3535L4.33063 8.3535C4.23686 8.44724 4.10971 8.49989 3.97713 8.49989C3.84455 8.49989 3.71739 8.44724 3.62363 8.3535L2.62363 7.3535C2.57587 7.30738 2.53778 7.2522 2.51158 7.1912C2.48537 7.1302 2.47158 7.06459 2.471 6.9982C2.47043 6.93181 2.48308 6.86597 2.50822 6.80452C2.53336 6.74307 2.57048 6.68725 2.61743 6.6403C2.66438 6.59336 2.7202 6.55623 2.78165 6.53109C2.8431 6.50595 2.90894 6.4933 2.97533 6.49387C3.04172 6.49445 3.10733 6.50824 3.16833 6.53445C3.22933 6.56065 3.2845 6.59874 3.33063 6.6465L3.97713 7.293L6.62363 4.6465C6.71793 4.55542 6.84423 4.50502 6.97533 4.50616C7.10643 4.5073 7.23183 4.55989 7.32454 4.65259C7.41724 4.74529 7.46983 4.8707 7.47097 5.0018C7.47211 5.1329 7.42171 5.2592 7.33063 5.3535Z"
        fill={isActive ? colors.red : '#6A7590'}
      />
      <path
        d="M8.02344 6C8.02344 7.65685 6.68029 9 5.02344 9C3.36658 9 2.02344 7.65685 2.02344 6C2.02344 4.34315 3.36658 3 5.02344 3C6.68029 3 8.02344 4.34315 8.02344 6Z"
        fill={isActive ? colors.red : '#6A7590'}
      />
      <path d="M6.93544 5.576V6.88H3.31144V5.576H6.93544Z" fill="white" />
    </svg>
  );
};
