import {
  Assessment,
  AssessmentVersion,
  AxiosArgs,
  ProjectionVariant,
  SortType,
  ThenArgs,
  Variable,
  VariablePost,
} from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';
import { QueryKey } from 'react-query';

type CreateAssessment = (a: {
  name: string;
  description: string;
  review_date: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const createAssessment: CreateAssessment = (parameters) => {
  const path = '/api/public/api/assessments';
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateAssessment = (
  a: Partial<
    | Assessment
    | {
        id: string;
      }
  >,
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const updateAssessment: UpdateAssessment = ({ id, ...parameters }) => {
  const path = `/api/public/api/assessments/${id}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetAssessments = (
  key: QueryKey,
  parameters: { sortBy?: SortType },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment[]>>>>;

export const getAssessments: GetAssessments = (_, parameters) => {
  const path = `/api/public/api/assessments${encodeQueryParams(parameters)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetAssessment = (
  key: QueryKey,
  parameters: {
    assessmentId: string | number;
    version?: string;
    variant?: ProjectionVariant;
  },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const getAssessment: GetAssessment = (_, { assessmentId, ...params }) => {
  const path = `/api/public/api/assessments/${assessmentId}${encodeQueryParams({ ...params })}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type DeleteAssessment = (parameters: {
  id: string | number;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const deleteAssessment: DeleteAssessment = ({ id }) => {
  const path = `/api/public/api/assessments/${id}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type CopyAssessment = (params: {
  assessment: string;
  incidents: {
    include: boolean;
    makeNewLink: boolean;
  };
  risk_management_plan: {
    include: boolean;
    makeNewLink: boolean;
  };
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const copyAssessment: CopyAssessment = (params) => {
  const path = `/api/public/api/clone-assessment`;

  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
// ------------------------------------------------------------

type SaveVariables = (parameters: {
  assessmentId: string;
  variables: VariablePost[];
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Variable[]>>>>;

export const saveVariables: SaveVariables = ({ assessmentId, variables }) => {
  const path = `/api/public/api/assessments/variables/${assessmentId}`;

  return axios({
    method: 'POST',
    url: path,
    data: encodeBody({ entries: variables }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type GetAssessmentVersions = (
  a: string,
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<{ assessmentId: string; versions: AssessmentVersion[] }>>>>;

export const getAssessmentVersions: GetAssessmentVersions = (assessmentId) => {
  const path = `/api/public/api/assessments/${assessmentId}/versions`;

  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type PublishAssessmentVersion = (a: {
  assessmentId: string;
  name: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<any[]>>>>;

export const publishAssessmentVersion: PublishAssessmentVersion = ({ assessmentId, ...params }) => {
  const path = `/api/public/api/assessments/${assessmentId}/versions`;

  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type DuplicateAssessmentFromVersion = (params: {
  id: string;
  incidents: {
    include: boolean;
    makeNewLink: boolean;
  };
  risk_management_plan: {
    include: boolean;
    makeNewLink: boolean;
  };
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Assessment>>>>;

export const duplicateAssessmentFromVersion: DuplicateAssessmentFromVersion = (params) => {
  const path = `/api/public/api/assessments/versions/${params.id}`;

  return axios({
    method: 'POST',
    url: path,
    data: {
      incidents: {
        include: params.incidents.include,
        makeNewLink: params.incidents.makeNewLink,
      },
      risk_management_plan: {
        include: params.risk_management_plan.include,
        makeNewLink: params.risk_management_plan.makeNewLink,
      },
    },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
