// @ts-nocheck
import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, Text, View } from '@react-pdf/renderer';
import { ControlsTable, Footer, FooterStartComponent, Header, TitleLink } from '../../comps';
import { isEqual } from 'lodash';
import { Control } from 'src/api/types';

interface RmpSummaryProps {
  data: Control[];
  onDone: (pageNumber: number) => void;
}

export const RmpSummary: React.FC<RmpSummaryProps> = memo(
  ({ onDone, data }) => {
    return (
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <Header />
        <View
          render={(data) => {
            onDone(data.pageNumber);
          }}
        />
        <View style={pdfStyles.section}>
          <TitleLink title={'Risk Management Plan'} intLinkId={'rmp'} extLink={`risk-management`} />

          <View style={pdfStyles.spacer8} />
          <View style={pdfStyles.spacer4} />
          <Text style={pdfStyles.label}>CONTROLS</Text>
          <View style={pdfStyles.spacer8} />
          {data.length ? <ControlsTable data={data} /> : <Text style={pdfStyles.value}>-</Text>}
        </View>

        <Footer footerComponent={<FooterStartComponent title="Risk management plan / Controls" />} />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
