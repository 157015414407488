import React from 'react';

interface Props {
  isActive?: boolean;
}
export const Improovement: React.FC<Props> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="17"
      viewBox="0 0 23 17"
      fill={isActive ? 'url(#icon-gradient)' : '#6A7590'}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 0C1.9028 0 0 1.9028 0 4.25V12.75C0 15.0973 1.9028 17 4.25 17H18.4167C20.7639 17 22.6667 15.0973 22.6667 12.75V4.25C22.6667 1.9028 20.7639 0 18.4167 0H4.25ZM2.83333 12.75V11.3333H9.91667V14.1667H4.25C3.4676 14.1667 2.83333 13.5324 2.83333 12.75ZM12.75 14.1667H18.4167C19.1991 14.1667 19.8333 13.5324 19.8333 12.75V11.3333H12.75V14.1667ZM12.75 8.5H19.8333V5.66667H12.75V8.5ZM9.91667 5.66667H2.83333V8.5H9.91667V5.66667Z"
      />
      <defs>
        <linearGradient id="icon-gradient" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
