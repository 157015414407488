export enum Headers {
  Name = 'Name',
  ID = 'ID',
  Sources = 'Sources',
  Events = 'Events',
  Consequences = 'Consequences',
  RiskOwner = 'Risk Owner',
  RiskNarrative = 'Risk Narrative',
  FrequencyTimes = 'Frequency - Times',
  FrequencyUnit = 'Frequency - Years',
  FrequencyNote = 'Frequency - Note',
  ConsequenceLower = 'Consequence - Lower',
  ConsequenceUpper = 'Consequence - Upper',
  ConsequenceNote = 'Consequence - Note',
  IdentifiedDate = 'Identified Date (DD-MM-YYYY)',
  ReviewDate = 'Review Date (DD-MM-YYYY)',
  RiskManagementStrategy = 'Risk Management Strategy',
  RiskManagementNote = 'Risk Management Note',
}

export interface ScenarioCSV {
  [Headers.Name]?: string;
  [Headers.ID]?: string | null;
  [Headers.Sources]?: number | null;
  [Headers.Events]?: number | null;
  [Headers.Consequences]?: number | null;
  [Headers.RiskOwner]?: string | null;
  [Headers.RiskNarrative]?: string | null;
  [Headers.FrequencyTimes]?: string | null;
  [Headers.FrequencyUnit]?: string | null;
  [Headers.FrequencyNote]?: string | null;
  [Headers.ConsequenceLower]?: string | null;
  [Headers.ConsequenceUpper]?: string | null;
  [Headers.ConsequenceNote]?: string | null;
  [Headers.IdentifiedDate]?: string | null;
  [Headers.ReviewDate]?: string | null;
  [Headers.RiskManagementStrategy]?: string | null;
  [Headers.RiskManagementNote]?: string | null;
}

export interface ValidationErrorItem {
  rowIndex: number;
  errors: {
    path: string;
    message: string;
  }[];
}
