import React from 'react';

interface Props {
  isActive?: boolean;
}
export const AuditLog: React.FC<Props> = ({ isActive }) => {
  return (
    <svg width="20" height="19" viewBox="0 0 20 19" fill="#6A7590" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5.71333V17.7088C0 18.4065 0.565687 18.9702 1.26332 18.9702H13.8902C14.5878 18.9702 15.1522 18.4065 15.1522 17.7088V13.1544L12.5165 15.6829L7.57611 16.0492L8.3307 11.0281L15.1522 4.68617V1.29393C15.1522 0.596933 14.5878 0.03125 13.8902 0.03125H5.68208V5.71333H0ZM4.42067 0.03125V4.45065H0L4.42067 0.03125ZM18.321 8.59063L15.7376 5.82022L17.2158 4.44178C17.4708 4.20398 17.8703 4.21795 18.1081 4.47296L19.8304 6.3199C20.0682 6.5749 20.0542 6.97439 19.7992 7.21219L18.321 8.59063ZM17.3976 9.45175L11.8716 14.6048L8.81109 14.869L9.28818 11.8344L14.8141 6.68134L17.3976 9.45175Z"
        fill={isActive ? 'url(#icon-gradient)' : '#6A7590'}
      />
      <defs>
        <linearGradient id="icon-gradient" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
