import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Div = styled.div`
  /* border: 1px solid red; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex: 1;

  #children {
    /* border: 1px solid blue; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

interface IOverflowContainerProps {
  children: React.ReactNode;
  onOverflow: (a: boolean) => void;
}

export const OverflowContainer: React.FC<IOverflowContainerProps> = ({ children, onOverflow }) => {
  const parentRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const parentWidth = parentRef.current?.clientWidth;
    const childrenWidth = childrenRef.current?.clientWidth;

    if (parentWidth === childrenWidth) {
      onOverflow(true);
    } else {
      onOverflow(false);
    }
  }, []);

  return (
    <Div ref={parentRef}>
      <div id="children" ref={childrenRef}>
        {children}
      </div>
    </Div>
  );
};
