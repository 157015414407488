// @ts-nocheck
import { View } from '@react-pdf/renderer';
import React from 'react';
import { NoData } from './NoData';
import colors from 'src/theme/colors';

export const EmptyChart = () => {
  return (
    <View style={{ height: 160, alignItems: 'center', justifyContent: 'center', backgroundColor: colors.cultured }}>
      <NoData title="Insufficient data to display chart" />
    </View>
  );
};
