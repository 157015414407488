import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';

interface DivProps {
  $isActive?: boolean;
}

const Div = styled.div<DivProps>`
  width: 22px;
  min-height: 22px;
  background: ${({ $isActive }) => ($isActive ? colors.gradient : colors.cflowerBlue)};
  position: absolute;
  z-index: 10;
  /* top: 0; */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  .active-outer {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .active-inner {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: ${colors.gradient};
  }
  .inactive-inner {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: white;
  }
`;

interface PointProps {
  isActive?: boolean;
}

export const Point: React.FC<PointProps> = ({ isActive }) => {
  return (
    <Div $isActive={isActive}>
      {isActive ? (
        <>
          <div className="active-outer">
            <div className="active-inner" />
          </div>
        </>
      ) : (
        <>
          <div className="inactive-inner" />
        </>
      )}
    </Div>
  );
};
