import React from 'react';
import moment, { Moment } from 'moment';
import MaterialInput from '../MaterialInput';
import { DATE_FORMAT_PICKER } from 'src/config';
import { ReactComponent as CalendarIcon } from 'src/assets/calendar.svg';
import styled from 'styled-components';
import colors from 'src/theme/colors';
import { PickersDay } from '@mui/x-date-pickers';
import { ActionBar, Toolbar } from './comps';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import _ from 'lodash';
import { DatePicker } from '@mui/x-date-pickers';

const StyledDay = styled(PickersDay)`
  font-weight: 500 !important;
  &.Mui-selected {
    background: ${colors.gradient} !important;
  }

  .adornment {
    display: flex;
    &__value {
      width: 35px;
      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        background: ${colors.cultured};
      }
    }
  }

  &.MuiDateCalendar-root {
    border: 2px solid red !important;
  }
`;

interface InputProps extends React.ComponentProps<typeof MaterialInput> {
  ['data-cy']?: string;
}

interface MaterialDatePickerProps extends Omit<React.ComponentProps<typeof DatePicker>, 'value' | 'onChange'> {
  value: string | null | undefined;
  onChange: (date: string | null) => void;
  inputProps?: InputProps;
}

const MaterialDatePicker: React.FC<MaterialDatePickerProps> = ({ value, inputProps, onChange, ...rest }) => {
  const handleOnDateChange = (date: Moment | null) => {
    onChange(date ? date.toISOString() : null);
  };

  return (
    <DesktopDatePicker
      value={value ? moment(value) : null}
      onChange={handleOnDateChange}
      format={DATE_FORMAT_PICKER}
      slots={{
        textField: MaterialInput,
        //   <MaterialInput
        //     {...p}
        //     slotProps={{
        //       input: {
        //         endAdornment: (
        //           <div
        //             css={`
        //               display: flex;
        //             `}
        //           >
        //             {value && (
        //               <div
        //                 css={`
        //                   width: 35px;
        //                 `}
        //               >
        //                 <div
        //                   css={`
        //                     display: flex;
        //                     align-items: center;
        //                     justify-content: center;
        //                     position: absolute;
        //                     top: 0;
        //                     bottom: 0;
        //                     background: ${colors.cultured};
        //                   `}
        //                 >
        //                   <IconButton
        //                     onClick={(e) => {
        //                       e.stopPropagation();
        //                       onChange(null);
        //                     }}
        //                     size="small"
        //                     css={`
        //                       transform: translateX(-8px);
        //                     `}
        //                   >
        //                     <Clear />
        //                   </IconButton>
        //                 </div>
        //               </div>
        //             )}
        //             <InputAdornment position="end">
        //               <IconButton
        //                 onClick={() => setOpen(!open)}
        //                 sx={{
        //                   marginRight: 1,
        //                 }}
        //               >
        //                 <CalendarIcon />
        //               </IconButton>
        //             </InputAdornment>
        //           </div>
        //         ),
        //       },
        //     }}
        //   />
        // ),
        toolbar: Toolbar,
        actionBar: (p) => <ActionBar {...p} onPeriodSelect={handleOnDateChange} />,
        day: StyledDay,

        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        textField: {
          ...inputProps,
        },
        popper: {
          placement: 'auto',
          // sx: {
          //   '.MuiPickersLayout-root': {
          //     position: 'fixed',
          //     top: '0 !important',
          //     background: colors.white,
          //     margin: 'auto',
          //   },
          // },
        },
        openPickerButton: {
          sx: {
            marginRight: 1,
          },
        },
        toolbar: {
          toolbarFormat: DATE_FORMAT_PICKER,
        },
      }}
      {...rest}
    />
  );
};
export default MaterialDatePicker;
