import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { GradientText, NoData, PageContainer, PageControlSkeleton, Poppins, Spacer } from 'src/common';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import Button from 'src/components/form/Button';
import { ConfirmDeleteModal, LinkScenariosToControlModal, RiskManagementModal } from 'src/components/modals';
import { ReactComponent as LinkSvg } from 'src/assets/link.svg';
import { deleteControls, getControl } from 'src/api/controls';
import { ControllStatus, QKeys } from 'src/api/types';
import { getControlScenarios } from 'src/api/plan';
import { MetaSheet } from './comps';
import colors from 'src/theme/colors';
import GenericTable from 'src/components/GenericTable';
import { useColumns } from './useColumns';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import Audit from 'src/components/Audit';
import { useAuth } from 'src/state/auth';
import { Alert } from '@mui/material';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useVersion from 'src/hooks/useVersion';
import { TableIds } from 'src/api/types/misc';
import { Modifier } from 'src/components/Modifier';

const Div = styled(PageContainer)`
  .space-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .link-icon {
      margin-right: 10px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .space-between {
      .link-icon {
        margin-right: 6px;
      }
    }
  }
`;

const HDivider = styled.div`
  height: 1px;
  background: ${colors.stroke};
  width: 100%;
  position: absolute;
  left: 0;
`;

const ControlPage = () => {
  const { controlId } = useParams<{ controlId: string }>();
  const [isLinkScenarioModalOpen, setIsLinkScenarioModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { userFeatures } = useAuth();
  const { setLocationPaths } = useActionsApp();
  const { version } = useVersion();
  useGaEventPageView('Cydea | Risk Management Plan | Control');

  const navigate = useNavigate();

  const { data: control, isLoading: isControlLoading } = useQuery([QKeys.Control, controlId, { version }], () =>
    getControl(controlId as string, { version }),
  );

  const {
    data: controlScenarios = [],
    isLoading,
    error,
  } = useQuery([QKeys.ControlScenarios, controlId, { version }], () =>
    getControlScenarios(controlId as string, { version }),
  );

  useEffect(() => {
    if (control) {
      setLocationPaths({ rmpControl: { id: control.id, name: control.name } });
    }
    return () => {
      setLocationPaths({ rmpControl: undefined });
    };
  }, [control]);

  const { columns, GTColumnSelectAnchorExported } = useColumns({
    data: controlScenarios,
    controlId: controlId as string,
  });

  const [remove] = useMutation(deleteControls, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      navigate('/risk-management');
    },
  });

  const isPageLoading = isControlLoading || isLoading;

  return (
    <>
      <Meta title={`Cydea | Risk Management Plan | Control`} feedbackScript />
      {isLinkScenarioModalOpen && (
        <LinkScenariosToControlModal
          controlId={controlId as string}
          onClose={() => setIsLinkScenarioModalOpen(false)}
          subTitle={{
            title: 'CONTROL',
            value: control?.name || '',
          }}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDeleteModal
          onConfirm={async () => remove([controlId as string])}
          onClose={() => setIsDeleteModalOpen(false)}
        >
          {controlScenarios.length > 0 && (
            <Alert severity="warning">
              {controlScenarios.length > 1
                ? 'This control is linked to scenarios.'
                : 'This control is linked to a scenario.'}
            </Alert>
          )}
          {control?.status === ControllStatus.Live && <Alert severity="warning">This control is already live.</Alert>}
        </ConfirmDeleteModal>
      )}
      {isEditModalOpen && <RiskManagementModal onClose={() => setIsEditModalOpen(false)} initialValues={control} />}
      {control && (
        <Div data-cy="control-page">
          {!isPageLoading ? (
            <>
              <MetaSheet
                data={control}
                editable={{
                  onDeleteSelect: () => setIsDeleteModalOpen(true),
                  onEditSelect: () => setIsEditModalOpen(true),
                }}
              >
                <Spacer $px={20} />
                <HDivider />
                <Spacer $px={30} />
                <div className="space-between">
                  <GradientText>LINKED SCENARIOS</GradientText>
                  {!version && (
                    <Button
                      primary
                      onClick={() => {
                        setIsLinkScenarioModalOpen(true);
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Link scenarios',
                          tags: ['RMP', 'SCENARIO'],
                        });
                      }}
                      data-cy="link-scenarion-btn"
                    >
                      <LinkSvg className="link-icon" />
                      LINK SCENARIOS
                    </Button>
                  )}
                </div>
                <Spacer $px={30} />
                {!isLoading && (
                  <>
                    {controlScenarios.length ? (
                      <GenericTable
                        tableId={TableIds.controlScenarios}
                        data={controlScenarios}
                        columns={columns}
                        GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                        searchable={['name', 'source', 'event', 'consequence', 'expected_loss']}
                        expandContent={(el) => {
                          return (
                            <Modifier
                              control={el.control_data}
                              projectedScenario={el.scenario_data}
                              disabled={!!version}
                            />
                          );
                        }}
                      />
                    ) : (
                      <>
                        {error ? (
                          <Poppins color="error" px={14}>
                            {/* @ts-ignore */}
                            {error.message || 'Something went wrong'}
                          </Poppins>
                        ) : (
                          <NoData title="No scenarios linked" />
                        )}
                      </>
                    )}
                  </>
                )}
              </MetaSheet>
              {userFeatures.app.audit.report && userFeatures.app.audit.controls.report && (
                <Audit rootPath="controls" initQuery={{ control_id: controlId }} tableId={TableIds.auditControl} />
              )}
            </>
          ) : (
            <PageControlSkeleton />
          )}
        </Div>
      )}
    </>
  );
};

export default ControlPage;
