import React, { useState } from 'react';
import Button from 'src/components/form/Button';
import { AddRiskScenarioModal } from 'src/components/modals';
import { MPEvents } from 'src/utils/mixpanel/types';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';

interface AddRiskScenarioProps {
  assessmentId: string;
  assessmentName: string;
}

const AddRiskScenario: React.FC<AddRiskScenarioProps> = ({ assessmentId, assessmentName }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      {isOpen && (
        <AddRiskScenarioModal
          onClose={() => setIsOpen(false)}
          assessmentId={assessmentId}
          assessmentName={assessmentName}
        />
      )}
      <Button
        onClick={() => {
          setIsOpen(true);
          mpEvent(MPEvents.ButtonClick, {
            button: 'Add Risk Scenario',
            tags: ['SCENARIO'],
          });
        }}
        primary
        data-cy="add-scenario-button"
      >
        + ADD RISK SCENARIO
      </Button>
    </>
  );
};

export default AddRiskScenario;
