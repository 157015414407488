import React from 'react';
import { DataTitle } from 'src/common';
import { GenericSelect } from 'src/components/select/GenericSelect';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { Modifiers, ProjectedScenario } from 'src/api/types';
import { ReduceConsequence, ReduceFrequency } from './comps';
import { CloseIcon } from 'src/components/Modifier/comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 310px 1fr;

  .slot {
    &__top {
      margin-bottom: 4px;
      height: 20px;
      user-select: none;
    }
    &__content {
      position: relative;
      min-height: 52px;
      display: grid;
      &--action {
        grid-template-columns: repeat(auto-fit, minmax(30px, auto));
        align-items: center;
      }
    }
  }
`;

const getInitialData = (option: string) => {
  switch (option) {
    case 'TREAT_REDUCE_CONSEQUENCE':
      return { lower: 0, upper: 0, isPercentage: false };
    case 'TREAT_REDUCE_FREQUENCY':
      return { value: 0, isPercentage: false };
    default:
      return {};
  }
};

interface ActionProps {
  idx: number;
  projectedScenario: ProjectedScenario;
  onRemove: () => void;
  options: { label: string; value: 'TREAT_REDUCE_CONSEQUENCE' | 'TREAT_REDUCE_FREQUENCY' }[];
  disabled?: boolean;
  noResult?: boolean;
}

const Action: React.FC<ActionProps> = ({ idx, projectedScenario, onRemove, options, disabled, noResult }) => {
  const { values, setFieldValue } = useFormikContext<Modifiers>();

  const selectedTypes = values.treat.map((mod) => mod.type);

  const availableOptions = options.filter(
    (opt) => !selectedTypes.includes(opt.value) || values.treat[idx].type === opt.value,
  );

  return (
    <Div>
      <div className="slot">
        <div className="slot__top">
          <DataTitle
            $noMargin
            css={`
              margin-left: ${!disabled && !noResult ? 40 : 0}px;
            `}
          >
            ACTION
          </DataTitle>
        </div>
        <div className="slot__content slot__content--action">
          {!disabled && (
            <CloseIcon
              onClick={() => {
                onRemove();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Modifier:Remove',
                  tags: ['MODIFIERS'],
                });
              }}
            />
          )}
          <GenericSelect
            white
            options={availableOptions}
            onChange={(o) => {
              setFieldValue(`treat[${idx}].type`, o.value);
              setFieldValue(
                `treat[${idx}].data`,
                getInitialData(o.value as 'TREAT_REDUCE_CONSEQUENCE' | 'TREAT_REDUCE_FREQUENCY'),
              );
            }}
            value={values.treat[idx].type}
            disabled={disabled}
          />
        </div>
      </div>
      {values.treat[idx].type === 'TREAT_REDUCE_CONSEQUENCE' && (
        <ReduceConsequence
          idx={idx}
          projectedScenario={projectedScenario}
          onRemove={onRemove}
          disabled={disabled}
          noResult={noResult}
        />
      )}
      {values.treat[idx].type === 'TREAT_REDUCE_FREQUENCY' && (
        <ReduceFrequency
          idx={idx}
          projectedScenario={projectedScenario}
          onRemove={onRemove}
          disabled={disabled}
          noResult={noResult}
        />
      )}
    </Div>
  );
};

export default Action;
