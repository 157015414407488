import React, { useEffect } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, PageControlSkeleton } from 'src/common';
import { getScenarioTemplate } from 'src/api/scenarioTemplates';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { MetaSheetScenario } from './comps'; // Correct MetaSheet import
import { QKeys } from 'src/api/types';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';

const ScenarioTemplatePage = () => {
  const { scenarioTemplateId } = useParams<{ scenarioTemplateId: string }>();
  const { data: scenarioTemplate, isLoading } = useQuery([QKeys.ScenarioTemplates, scenarioTemplateId], () =>
    getScenarioTemplate(scenarioTemplateId as string),
  );

  const { setLocationPaths } = useActionsApp();

  useEffect(() => {
    if (scenarioTemplate) {
      setLocationPaths({
        scenarioTemplate: { id: scenarioTemplate.ux_id, name: scenarioTemplate.name },
      });
    }
    return () => {
      setLocationPaths({ scenarioTemplate: undefined });
    };
  }, [scenarioTemplate]);

  useGaEventPageView(`Cydea | Scenario | ${scenarioTemplate?.name}`);

  return (
    <>
      <Meta title={`Cydea | Scenario | ${scenarioTemplate?.name}`} feedbackScript />
      {scenarioTemplate && (
        <PageContainer>
          {!isLoading ? <MetaSheetScenario data={scenarioTemplate} /> : <PageControlSkeleton noTable />}
        </PageContainer>
      )}
    </>
  );
};

export default ScenarioTemplatePage;
