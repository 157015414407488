import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import colors from 'src/theme/colors';

const MaterialInput = styled(TextField).attrs((props) => ({
  variant: 'outlined',
  ...props,
}))`
  border-radius: 3px;
  outline: none;
  appearance: none;
  word-break: initial;
  font-family: 'Poppins', sans-serif;

  .MuiInputBase-multiline {
    padding: 0px !important;
  }
  & .MuiInputBase-root {
    padding: 0;
  }
  & .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: 1px solid ${colors.stroke};
  }
  & .MuiOutlinedInput-root:hover fieldset {
    border: 1px solid ${colors.stroke};
  }
  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${colors.stroke};
  }

  .Mui-error {
    font-family: 'Poppins', sans-serif;
  }
  .Mui-error.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${colors.error};
  }
  .Mui-error.MuiOutlinedInput-root:hover fieldset {
    border: 1px solid ${colors.error};
  }

  .Mui-disabled.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${colors.cultured};
  }
  .Mui-disabled.MuiOutlinedInput-root:hover fieldset {
    border: 1px solid ${colors.stroke};
  }
  .Mui-disabled.MuiOutlinedInput-root fieldset {
    border: 1px solid ${colors.stroke};
  }

  & input,
  textarea {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.prussianBlue};
    padding: 16px 30px;
    background: ${colors.cultured};
  }
  & input::placeholder,
  textarea::placeholder {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.cflowerBlue};
    opacity: 0.6;
  }

  & input:disabled,
  textarea:disabled {
    color: ${colors.cflowerBlue} !important;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    & input,
    textarea {
      font-size: 14px;
      padding: 10px 25px;
    }
    & input::placeholder,
    textarea::placeholder {
      font-size: 14px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    & input,
    textarea {
      font-size: 12px;
      padding: 8px 22px;
    }
    & input::placeholder,
    textarea::placeholder {
      font-size: 12px;
    }
  }
`;

export default MaterialInput;
