import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import { getToken } from 'src/utils/auth';

const uploadLink = createUploadLink({
  uri: `${process.env.BASE_URL}api/public/api/graphql`,
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      console.error('[GraphQL error]', { message, path, locations, code: extensions?.code });
    });
  }
  if (networkError) {
    console.error('[Network error]', networkError);
  }
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : undefined,
    },
  }));

  return forward(operation);
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, uploadLink]),
  cache: new InMemoryCache(),

  defaultOptions: {
    watchQuery: {
      errorPolicy: 'none', //default none
      fetchPolicy: 'cache-and-network', //default cache-and-network
    },
  },
});
