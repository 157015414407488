import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { workspaceInviteAccept, workspaceInviteReject } from 'src/api/workspace';
import { CountLabel, Spacer } from 'src/common';
import { RowInvite } from 'src/components/orgRows';
import { useAuth } from 'src/state/auth';
import styled from 'styled-components';

const Div = styled.div`
  .orgs-list {
    display: grid;
    grid-gap: 10px;
  }
`;

interface InvitesProps {
  onSuccess: () => void;
}

export const Invites: React.FC<InvitesProps> = ({ onSuccess }) => {
  const [loadingInviteIds, setLoadingInviteIds] = useState<string[]>([]);
  const { user } = useAuth();

  const [acceptInvite] = useMutation(workspaceInviteAccept, {
    onSuccess,
  });

  const [rejectInvite] = useMutation(workspaceInviteReject, {
    onSuccess,
  });

  const invites = user?.invites || [];

  return (
    <Div>
      {invites.length > 0 && (
        <>
          <Spacer $px={30} />
          <CountLabel label="invite" length={invites.length} />
          <Spacer $px={5} />
          <div className="orgs-list">
            {invites.map((el) => (
              <RowInvite
                key={el.id}
                id={el.id}
                name={el.name}
                img={null}
                onAccept={async () => {
                  setLoadingInviteIds((prev) => [...prev, el.id]);
                  await acceptInvite({
                    orgId: el.id,
                    requestId: el.invite.id,
                  });
                  setLoadingInviteIds((prev) => prev.filter((s) => s !== el.id));
                }}
                // onReject={async () => {
                //   setLoadingInviteIds((prev) => [...prev, el.id]);
                //   await rejectInvite({
                //     orgId: el.id,
                //     requestId: el.invite.id,
                //   });
                //   setLoadingInviteIds((prev) => prev.filter((s) => s !== el.id));
                // }}
                isLoading={loadingInviteIds.includes(el.id)}
              />
            ))}
          </div>
        </>
      )}
    </Div>
  );
};
