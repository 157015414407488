import _ from 'lodash';
import { Scenario } from 'src/api/types';

interface ModifiedValue<T> {
  value: T;
  hasChanges: boolean;
}

export type ModifiedScenario = {
  [Property in keyof Scenario]: ModifiedValue<Scenario[Property]>;
};

export interface ModifiedScenarioNamed extends Omit<ModifiedScenario, 'source' | 'consequence' | 'event'> {
  source: { value: string; hasChanges: boolean };
  consequence: { value: string; hasChanges: boolean };
  event: { value: string; hasChanges: boolean };
}

type ScenarioKey = keyof Scenario;

interface ComparisonResult {
  exactMatch: ModifiedScenario[];
  dataChanges: ModifiedScenario[];
  unique: ModifiedScenario[];
}

export interface ScenariosComparison {
  compareA: ComparisonResult;
  compareB: ComparisonResult;
}

const createTransformedScenario = (scenario: Scenario, hasChangesFlags: boolean[]): ModifiedScenario => {
  return Object.keys(scenario).reduce<ModifiedScenario>((acc, key, index) => {
    // @ts-ignore
    acc[key] = {
      value: scenario[key as ScenarioKey],
      hasChanges: hasChangesFlags[index],
    };
    return acc;
  }, {} as ModifiedScenario);
};

export const compareScenarios = (
  arrayA: Scenario[],
  arrayB: Scenario[],
  idKey = 'id' as keyof Scenario,
): ScenariosComparison => {
  const omitProperties = [
    'assessment_id',
    'created_at',
    'updated_at',
    'updated_by_id',
    'chart',
    'incidents',
    'id',
    'is_incomplete',
    'lower_formula',
    'upper_formula',
  ];

  const comparisonResult: ScenariosComparison = {
    compareA: { exactMatch: [], dataChanges: [], unique: [] },
    compareB: { exactMatch: [], dataChanges: [], unique: [] },
  };

  const prepareForComparison = (scenario: Scenario) => _.omit(scenario, omitProperties);

  arrayA.forEach((scenarioA) => {
    const scenarioB = arrayB.find((s) => s[idKey] === scenarioA[idKey]);
    const preparedA = prepareForComparison(scenarioA);
    if (scenarioB) {
      const preparedB = prepareForComparison(scenarioB);
      const isExactMatch = _.isEqual(preparedA, preparedB);
      if (isExactMatch) {
        comparisonResult.compareA.exactMatch.push(
          createTransformedScenario(scenarioA, new Array(Object.keys(scenarioA).length).fill(false)),
        );
        comparisonResult.compareB.exactMatch.push(
          createTransformedScenario(scenarioB, new Array(Object.keys(scenarioB).length).fill(false)),
        );
      } else {
        const changesFlags = Object.keys(scenarioA).map(
          (key) => !_.isEqual(scenarioA[key as ScenarioKey], scenarioB[key as ScenarioKey]),
        );
        comparisonResult.compareA.dataChanges.push(
          createTransformedScenario(scenarioA, new Array(Object.keys(scenarioA).length).fill(false)),
        );
        comparisonResult.compareB.dataChanges.push(createTransformedScenario(scenarioB, changesFlags));
      }
    } else {
      comparisonResult.compareA.unique.push(
        createTransformedScenario(scenarioA, new Array(Object.keys(scenarioA).length).fill(false)),
      );
    }
  });

  arrayB.forEach((scenarioB) => {
    if (!arrayA.some((s) => s[idKey] === scenarioB[idKey])) {
      comparisonResult.compareB.unique.push(
        createTransformedScenario(scenarioB, new Array(Object.keys(scenarioB).length).fill(false)),
      );
    }
  });

  return comparisonResult;
};
