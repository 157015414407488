import React, { useState } from 'react';

interface EventsFilters {
  assessments: {
    created: boolean;
    dueToStart: boolean;
    dueForReview: boolean;
    started: boolean;
    versionsPublished: boolean;
  };
  scenarios: {
    created: boolean;
    identified: boolean;
    addedToAssessment: boolean;
    lastEdited: boolean;
  };
  controls: {
    created: boolean;
    liveDates: boolean;
    dueForReview: boolean;
    lastEdited: boolean;
  };
  incidents: {
    occured: boolean;
  };
}
interface ActivityContextInterface {
  filters: {
    events: EventsFilters;
    assessments: string[];
    scenarios: string[];
    controls: string[];
    incidents: string[];
  };
  setEventsFilters: React.Dispatch<React.SetStateAction<EventsFilters>>;
  setAssessmentsFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setScenariosFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setControlsFilters: React.Dispatch<React.SetStateAction<string[]>>;
  setIncidentsFilters: React.Dispatch<React.SetStateAction<string[]>>;
}

const ActivityContext = React.createContext<Partial<ActivityContextInterface>>({});

const ActivityProvider = (props: any) => {
  const [eventsFilters, setEventsFilters] = useState<EventsFilters>({
    assessments: {
      created: true,
      dueToStart: true,
      dueForReview: false,
      started: true,
      versionsPublished: true,
    },
    scenarios: {
      created: true,
      identified: true,
      addedToAssessment: true,
      lastEdited: true,
    },
    controls: {
      created: true,
      liveDates: true,
      dueForReview: true,
      lastEdited: true,
    },
    incidents: {
      occured: true,
    },
  });
  const [assessmentsFilters, setAssessmentsFilters] = useState<string[]>([]);
  const [scenariosFilters, setScenariosFilters] = useState<string[]>([]);
  const [controlsFilters, setControlsFilters] = useState<string[]>([]);
  const [incidentsFilters, setIncidentsFilters] = useState<string[]>([]);

  return (
    <ActivityContext.Provider
      value={{
        filters: {
          events: eventsFilters,
          assessments: assessmentsFilters,
          scenarios: scenariosFilters,
          controls: controlsFilters,
          incidents: incidentsFilters,
        },
        setEventsFilters,
        setAssessmentsFilters,
        setScenariosFilters,
        setControlsFilters,
        setIncidentsFilters,
      }}
      {...props}
    />
  );
};

const useActivityState = () => {
  const context = React.useContext(ActivityContext);
  if (context === undefined) {
    throw new Error('useActivityState must be used within a ActivityProvider');
  }
  return context as ActivityContextInterface;
};

export { ActivityProvider, useActivityState };
