import React from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { riskEventsOptions, RiskEvents } from 'src/api/types';
import { ActionMeta, OptionTypeBase, Props, Theme } from 'react-select';
import { DefaultTheme } from 'styled-components';

interface EventsSelectProps extends Omit<Props<{ label: string; value: RiskEvents }>, 'value'> {
  theme?: Theme & DefaultTheme;
  value?: RiskEvents;
  onChange?: (value: { label: string; value: RiskEvents }, meta: ActionMeta<any>) => void;
}

const getValue = (opts: OptionTypeBase[], val?: RiskEvents) => {
  const data = opts.reduce((acc: OptionTypeBase[], curr: any) => {
    if (curr.options) {
      return [...acc, ...curr.options];
    } else {
      return [...acc, curr];
    }
  }, []);

  return data.find((o) => o.value === val) || null;
};

export const EventsSelect: React.FC<EventsSelectProps> = ({ value, ...other }) => {
  return (
    <>
      <CSelect
        value={getValue(riskEventsOptions, value)}
        placeholder="Select event"
        options={riskEventsOptions}
        inputId="events-select"
        {...other}
      />
      {other.error && other.helperText && <Error>{other.helperText}</Error>}
    </>
  );
};
