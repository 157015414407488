import React from 'react';
import styled from 'styled-components';
import { CheckItem } from './comps';

const Div = styled.div``;

interface ListSelectorProps {
  data: { id: string; label: string }[];
  selected: string[];
  onSelect: (id: string) => void;
  onSelectAll: (isAdd: boolean) => void;
}

export const ListSelector: React.FC<ListSelectorProps> = ({ data, selected, onSelect, onSelectAll }) => {
  const isAllSelected = data.length === selected.length;
  return (
    <Div className="afltr-container__contents__egrid-items">
      <CheckItem title="All" isChecked={isAllSelected} onClick={() => onSelectAll(!isAllSelected)} />
      {data.map((el) => (
        <CheckItem
          key={el.id}
          title={el.label}
          isChecked={selected.some((id) => id === el.id)}
          onClick={() => onSelect(el.id)}
        />
      ))}
    </Div>
  );
};
