import React from 'react';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import { Modal } from '../../comps';
import { useAuth } from 'src/state/auth';

const StyledModal = styled(Modal)`
  width: 540px;
  min-height: 200px;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .btn-container {
    display: flex;
    justify-content: center;
    button {
      min-width: 180px;
    }
  }
`;

interface TscsWarnModalProps {
  onClose: () => void;
  onOk: () => void;
}

export const TscsWarnModal: React.FC<TscsWarnModalProps> = ({ onClose, onOk }) => {
  const { activeOrganisation } = useAuth();
  return (
    <StyledModal onRequestClose={onClose} isOpen>
      <Poppins>
        {activeOrganisation?.name} has not accepted the platform terms and conditions. Please contact the organisation
        administrator.
      </Poppins>
      <Spacer $px={40} />
      <div className="btn-container">
        <Button primary onClick={onOk}>
          OKAY
        </Button>
      </div>
    </StyledModal>
  );
};
