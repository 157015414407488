import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import styled from 'styled-components';
import Meta from 'src/components/Meta';
import { PageContainer, Poppins } from 'src/common';
import { useGaEventPageView } from '../../utils/gaEvent';
import { Main, Logo, Image, RightContainer } from './comps/styles';

const Container = styled(PageContainer)`
  flex: 0.8;
  .auth-contents {
    max-width: 600px;
    margin: auto;
    display: flex;
    flex-direction: column;
    height: 100%;

    &__buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      max-width: 400px;
    }

    &__footer {
      display: flex;
      margin-top: auto;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex: 1;
  }
`;

const StyledLink = styled.a`
  color: rgb(158, 165, 183);
  &:visited {
    color: rgb(158, 165, 183);
  }
`;

const VerifyPage: React.FC = () => {
  const { loginWithRedirect } = useAuth0();

  useGaEventPageView('Cydea | Auth | Verify');

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta title={`Cydea | Verify`} />
      <Main id="main">
        <Container $noSideMargin>
          <div className="auth-contents">
            <Logo />
            <Poppins px={56} weight={600}>
              Better risk analysis
            </Poppins>
            <Poppins css="margin-top: 42px" weight={600}>
              Thanks for registering!
            </Poppins>
            <Poppins css="margin-top: 21px" color="cflowerBlue">
              Please check the email address you used to register for a verification request and follow the{' '}
              <strong>“Verify Your Account”</strong> button, or copy and paste the link into your browser.
            </Poppins>
            <Poppins css="margin-top: 21px" color="cflowerBlue">
              Once you’ve verified your email address you will be able to login.
            </Poppins>
            <Poppins css="margin-top: 21px" color="cflowerBlue">
              If you haven’t received or can’t find the email then please{' '}
              <span onClick={() => loginWithRedirect()}>
                <strong>reset your password</strong>
              </span>
              .
            </Poppins>

            <div className="auth-contents__footer">
              <Poppins px={14} color="cflowerBlue">
                © {new Date().getFullYear()} Cydea Ltd. Registered in England and Wales, 12204451. Cydea is a
                registered trademark. Read about your{' '}
                <StyledLink href="https://cydea.com/privacy/">privacy & how we use data</StyledLink>.
              </Poppins>
            </div>
          </div>
        </Container>
        <RightContainer>
          <Image />
        </RightContainer>
      </Main>
    </>
  );
};

export default VerifyPage;
