import React from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, Poppins, Spacer } from 'src/common';
import Activity from './comps/Activity';
import styled from 'styled-components';

const Div = styled(PageContainer)``;

const DashboardPage = () => {
  useGaEventPageView('Cydea | Timeline');

  return (
    <>
      <Meta title={`Cydea | Timeline`} feedbackScript />
      <Div>
        <Poppins px={32} weight={600}>
          Timeline
        </Poppins>
        <Spacer $px={30} />
        <Activity />
      </Div>
    </>
  );
};

export default DashboardPage;
