import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { CompileIconGray } from './icons';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface EPControlWentLiveProps {
  control: {
    id: string;
    name: string;
  };
  isToday?: boolean;
}

export const EPControlWentLive: React.FC<EPControlWentLiveProps> = ({ control, isToday }) => {
  const isPast = !isToday;

  return (
    <Base
      iconComponent={<CompileIconGray />}
      constentComponent={
        <Text $isPast={isPast}>
          Control{' '}
          <Link
            to={`/risk-management/control/${control.id}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['Timeline'],
                },
                destination: {
                  value: ['Control'],
                  params: {
                    id: control.id,
                  },
                },
                tags: ['TIMELINE', 'RMP'],
              })
            }
          >
            <TextLink $isPast={isPast}>{control.name}</TextLink>
          </Link>{' '}
          went live.
        </Text>
      }
    />
  );
};
