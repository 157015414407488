import { TreeNode } from 'primereact/treenode';
import { OrgTreeItem } from 'src/api/types';

const genNodes = (data: OrgTreeItem[], parentKey: string = ''): TreeNode[] =>
  data.map((item, index) => {
    const key = parentKey ? `${parentKey}-${index}` : `${index}`;

    const convertedItem: TreeNode = {
      key,
      data: {
        id: item.id,
        name: item.name,
        imgUrl: item.imgUrl,
        type: item.type,
        primary_contact: item.primary_contact,
        domain: item.domain,
      },
    };
    if (item.children) {
      convertedItem.children = genNodes(item.children, key);
    }
    return convertedItem;
  });

export default genNodes;
