import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page } from '@react-pdf/renderer';
import { Footer, Header, TableOfContants } from '../../comps';
import { isEqual } from 'lodash';
import { Toc } from '../../types';

interface AssessmentTocProps {
  contents: Toc[];
}

export const AssessmentToc: React.FC<AssessmentTocProps> = memo(
  ({ contents }) => {
    return (
      // @ts-ignore
      <Page size={'A4'} style={pdfStyles.page} id="toc">
        <Header />
        <TableOfContants contents={contents} />
        <Footer />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
