import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';
import { Poppins } from './Poppins';

export const GradientText = styled(Poppins)<{ $vertical?: boolean }>`
  background: ${({ $vertical }) => ($vertical ? colors.gradientVertical : colors.gradient)};
  -webkit-background-clip: text;
  font-weight: bold;
  text-transform: uppercase;
  color: transparent;
`;

export const GradientTextAction = styled(Poppins)<{ $underline?: boolean }>`
  cursor: pointer;
  color: ${colors.red};

  ${({ $underline }) =>
    $underline &&
    css`
      text-decoration: underline;
      text-decoration-line: underline;
    `};
`;
