import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal } from './comps';
import colors from 'src/theme/colors';
import { FormikProvider, useFormik } from 'formik';
import { Poppins, Spacer } from 'src/common';
import { queryCache, useMutation } from 'react-query';
import { Control, ControllStatus } from 'src/api/types';
import { Guard } from './Guard';
import { createControl, updateControl } from 'src/api/controls';
import { format } from 'date-fns';
import { getDiff } from 'src/utils/getDiff';
import RmpForm from '../formComposed/RmpForm';
import { Alert } from '@mui/material';
import { RmpFormValues, rmpInitialValues, rmpValidationSchema } from '../formComposed/RmpForm/util';
import { selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 950px;
  min-height: 200px;
  padding-left: 0;
  padding-right: 0;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .grid {
    display: grid;
    grid-gap: 20px;

    &--3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .helper-text-err {
    font-size: 14px;
    color: ${colors.error};
    margin-left: 14px;
  }
  .error {
    color: ${colors.error};
    font-size: 14px;
    text-align: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid {
      &--3 {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const ErrorLabel = styled.div`
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  color: ${colors.error};
  font-weight: 400;
`;

interface RiskManagementModalProps {
  onClose: () => void;
  initialValues?: Control;
}

export const RiskManagementModal: React.FC<RiskManagementModalProps> = ({ onClose, initialValues }) => {
  const [responseErr, setResponseErr] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const { usersOptios } = useAuth();
  const isInitialLive = initialValues?.status === ControllStatus.Live;

  const onSuccess = () => {
    queryCache.invalidateQueries();
    onClose();
  };
  const onError = (err: any) => setResponseErr(err.message || 'Server error');

  const [create] = useMutation(createControl, { onSuccess, onError });
  const [update] = useMutation(updateControl, { onSuccess, onError });

  const initValues: RmpFormValues = initialValues
    ? {
        strategy: initialValues.strategy,
        name: initialValues.name,
        status: initialValues.status,
        owner: initialValues.owner,
        description: initialValues.description,
        expected_active_date: initialValues.expected_active_date,
        review_date: initialValues.review_date,
        annual_cost: initialValues.annual_cost,
        vendor: initialValues.vendor,
        actual_active_date: initialValues.actual_active_date,
        source: initialValues.source ?? null,
        url_name: initialValues.url_name ?? null,
      }
    : rmpInitialValues;

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: rmpValidationSchema,
    validateOnMount: true,
    onSubmit: async (values) => {
      let expected_active_date = '';
      let review_date = '';
      let actual_active_date = '';
      if (values.expected_active_date) {
        expected_active_date = format(new Date(values.expected_active_date), 'yyyy-MM-dd') ?? undefined;
      }
      if (values.review_date) {
        review_date = format(new Date(values.review_date), 'yyyy-MM-dd') ?? undefined;
      }
      if (values.actual_active_date) {
        actual_active_date = format(new Date(values.actual_active_date), 'yyyy-MM-dd') ?? undefined;
      }

      const { userAdd, userUpdate } = selectUserOption({
        usersOptios,
        selectedUser: values.owner,
        initialUser: initValues.owner,
      });

      if (initialValues) {
        const diff = getDiff(initValues, {
          ...values,
          expected_active_date,
          review_date,
          actual_active_date,
        });

        return update({ ...diff, owner: userUpdate, id: initialValues.id });
      } else {
        return create({ ...values, expected_active_date, review_date, actual_active_date, owner: userAdd });
      }
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={formik.dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <div className="h-padding">
            <Poppins className="m-title" px={28}>
              {!!initialValues ? 'Edit' : 'Add'} Control
            </Poppins>
          </div>
          {isInitialLive ? (
            <div className="h-padding">
              <Spacer $px={10} />
              <Alert severity="warning">This control is already live.</Alert>
              <Spacer $px={10} />
            </div>
          ) : (
            <Spacer $px={19} />
          )}
          <FormikProvider value={formik}>
            <RmpForm
              buttonTitle={!!initialValues ? 'UPDATE' : 'CREATE'}
              onSubmitCb={() =>
                mpEvent(MPEvents.ButtonClick, {
                  button: initialValues ? 'Update' : 'Create',
                  modal: 'Risk management modal',
                  tags: ['RMP'],
                })
              }
            />
          </FormikProvider>
          {!!responseErr && <ErrorLabel>{responseErr}</ErrorLabel>}
        </StyledModal>
      }
    />
  );
};
