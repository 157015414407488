import React from 'react';

interface Props {
  isActive?: boolean;
}
export const RiskAssessments: React.FC<Props> = ({ isActive }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={isActive ? 'url(#icon-gradient)' : '#6A7590'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="2.85712" height="20" rx="1.42856" />
      <rect x="5.71423" y="5.45453" width="2.85712" height="14.5455" rx="1.42856" />
      <rect x="11.4286" y="13.6364" width="2.85712" height="6.36363" rx="1.42856" />
      <rect x="17.1428" y="2.72729" width="2.85712" height="17.2727" rx="1.42856" />
      <defs>
        <linearGradient id="icon-gradient" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
