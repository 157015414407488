import styled, { css } from 'styled-components';
import colors from 'src/theme/colors';

const TextSB14 = css`
  font-weight: 500;
  font-size: 14px;
  color: ${colors.cflowerBlue};
`;

const Input = styled.input.attrs((props) => ({ type: 'text', ...props }))`
  ::placeholder {
    ${TextSB14};
    color: ${colors.cflowerBlue};
    font-weight: 500;
  }
  appearance: none;
  &[type='text'],
  &[type='password'] {
    background: ${colors.cultured};
    border: 1px solid ${colors.stroke};
    padding: calc(0.5rem - 1px) 2rem;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 3px;
    font-stretch: normal;
    ${TextSB14};
    &:focus {
      outline: none;
    }
  }
  &[type='checkbox'],
  &[type='radio'] {
    --active: ${colors.gradient};
    --active-inner: #fff;
    --focus: 2px rgba(39, 94, 254, 0.3);
    --border: #bbc1e1;
    --border-hover: ${colors.gradient};
    --background: ${({ checked }) => (checked ? colors.gradient : colors.aliceBlue)};
    --disabled: #f6f8ff;
    --disabled-inner: #e1e6f9;
    appearance: none;
    height: 21px;
    outline: none;
    border: 0;
    display: inline-block;
    vertical-align: top;
    position: relative;
    margin: 0;
    cursor: pointer;
    background: var(--background);
    &:disabled {
      background: ${colors.cflowerBlue};
    }
  }
  &[type='checkbox']:after,
  &[type='radio']:after {
    content: '';
    display: block;
    left: 0;
    top: 0;
    position: absolute;
    transition:
      opacity var(--d-o, 0.2s),
      transform var(--d-t, 0.3s) var(--d-t-e, ease);
  }
  &[type='checkbox']:checked,
  &[type='radio']:checked {
    --b: var(--active);
    --bc: var(--active);
    --d-o: 0.3s;
    --d-t: 0.6s;
    --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
  }
  &[type='checkbox']:disabled,
  &[type='radio']:disabled {
    --b: var(--disabled);
    cursor: not-allowed;
    opacity: 0.9;
  }
  &[type='checkbox']:disabled:checked,
  &[type='radio']:disabled:checked {
    --b: var(--disabled-inner);
    --bc: var(--border);
  }
  &[type='checkbox']:disabled + label,
  &[type='radio']:disabled + label {
    cursor: not-allowed;
  }
  &[type='checkbox']:hover:not(:checked):not(:disabled),
  &[type='radio']:hover:not(:checked):not(:disabled) {
  }
  &[type='checkbox']:focus,
  &[type='radio']:focus {
    //box-shadow: 0 0 0 var(--focus);
  }
  &[type='checkbox']:not(.switch),
  &[type='radio']:not(.switch) {
    width: 21px;
  }
  &[type='checkbox']:not(.switch):after,
  &[type='radio']:not(.switch):after {
    opacity: var(--o, 0);
  }
  &[type='checkbox']:not(.switch):checked,
  &[type='radio']:not(.switch):checked {
    --o: 1;
  }
  &[type='checkbox'] + label,
  &[type='radio'] + label {
  }

  &[type='checkbox']:not(.switch) {
    border-radius: 7px;
  }
  &[type='checkbox']:not(.switch):after {
    width: 5px;
    height: 9px;
    border: 2px solid var(--active-inner);
    border-top: 0;
    border-left: 0;
    left: 7px;
    top: 4px;
    transform: rotate(var(--r, 20deg));
  }
  &[type='checkbox']:not(.switch):checked {
    --r: 43deg;
  }
  &[type='checkbox'].switch {
    width: 56px;
    height: 32px;
    border-radius: 100px;
  }
  &[type='checkbox'].switch:after {
    left: 2px;
    top: 2px;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background: #fff;
    transform: translateX(var(--x, 0));
  }
  &[type='checkbox'].switch:checked {
    --ab: var(--active-inner);
    --x: 24px;
  }
  &[type='checkbox'].switch:disabled:not(:checked):after {
    opacity: 0.6;
  }

  &[type='radio'] {
    border-radius: 50%;
  }
  &[type='radio']:after {
    width: 19px;
    height: 19px;
    border-radius: 50%;
    background: var(--active-inner);
    opacity: 0;
    transform: scale(var(--s, 0.7));
  }
  &[type='radio']:checked {
    --s: 0.5;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    &[type='checkbox'].switch {
      width: 40px;
      height: 22px;
      border-radius: 100px;
    }
    &[type='checkbox'].switch:after {
      width: 18px;
      height: 18px;
    }
    &[type='checkbox'].switch:checked {
      --ab: var(--active-inner);
      --x: 18px;
    }
  }
`;

export const Textarea = styled.textarea`
  resize: none;
  ${TextSB14};
  color: ${colors.prussianBlue};
  background: ${colors.cultured};
  border: 1px solid ${colors.stroke};
  padding: 18.5px 14px;
  width: 100%;
  box-sizing: border-box;
  border-radius: 3px;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: ${colors.cflowerBlue};
    font-weight: 500;
    font-size: 14px;
    opacity: 1;
  }
`;

export default Input;
