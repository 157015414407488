import React from 'react';
import { Poppins, Spacer } from 'src/common';
import { AssessmentDetails, OrgDetails } from './sections';
import { useStateSelector } from 'src/redux';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { SlideId, WizardFormValues } from 'src/api/types/wizard';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import styled from 'styled-components';

const Div = styled.div`
  .err-list {
    display: grid;
  }
`;

interface SlideProps {
  onChangeSlide: (id: SlideId) => void;
}

export const Finalise: React.FC<SlideProps> = ({ onChangeSlide }) => {
  const { errors } = useFormikContext<WizardFormValues>();
  const { hideAssessment, isScenarioType } = useStateSelector(({ app }) => {
    return {
      hideAssessment:
        app.wizardWorkspace?.intent.type === WizardIntent.workspace &&
        !app.wizardWorkspace.intent.params.createAssessment,
      isScenarioType: app.wizardWorkspace?.intent.type === WizardIntent.scenario,
    };
  });

  // const err = {
  //   assessmentName: errors?.assessment?.name,
  //   assessmentDescription: errors?.assessment?.description,
  //   assessmentReviewDate: errors?.assessment?.review_date,
  //   assessmentStartedDate: errors?.assessment?.started_date,
  // };
  // const hasErr = _.some(err, (el) => !_.isNil(el));

  return (
    <>
      {!isScenarioType && (
        <>
          <Poppins px={14} color="davysGrey">
            The values and their default variable names can be modified later in settings
          </Poppins>
          <Spacer $px={34} />
        </>
      )}

      <Div>
        {!isScenarioType && <OrgDetails onChangeSlide={onChangeSlide} />}
        {!hideAssessment && <AssessmentDetails onChangeSlide={onChangeSlide} />}
        {/* {hasErr && (
          <div className="err-list">
            <Spacer $px={20} />
            {err.assessmentName && (
              <Poppins px={14} color="error">
                Name: {err.assessmentName}
              </Poppins>
            )}
            {err.assessmentDescription && (
              <Poppins px={14} color="error">
                Description: {err.assessmentDescription}
              </Poppins>
            )}
            {err.assessmentReviewDate && (
              <Poppins px={14} color="error">
                Review date: {err.assessmentReviewDate}
              </Poppins>
            )}
            {err.assessmentStartedDate && (
              <Poppins px={14} color="error">
                Started date: {err.assessmentStartedDate}
              </Poppins>
            )}
          </div>
        )} */}
      </Div>
      <Spacer $px={34} />
    </>
  );
};
