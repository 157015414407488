import React from 'react';
import { useQuery } from 'react-query';
import { getAssessmentVersions } from 'src/api/assessment';
import { QKeys } from 'src/api/types';
import { Poppins, Spacer } from 'src/common';
import { DATE_FORMAT } from 'src/config';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { format, isValid } from 'date-fns';
import { ReactComponent as LockSvg } from 'src/assets/version-lock-icon.svg';

const VersionContainer = styled.div`
  display: flex;
  align-items: center;
  svg {
    margin-right: 10px;
    color: ${colors.cflowerBlue};
  }
`;

interface VersionHeaderProps {
  assessmentId: string;
  version: string;
}

export const VersionHeader: React.FC<VersionHeaderProps> = ({ assessmentId, version }) => {
  const { data: versionsData } = useQuery(
    [QKeys.Versions, { assessmentId }],
    () => getAssessmentVersions(assessmentId as string),
    {
      enabled: !!assessmentId,
    },
  );

  if (!versionsData) return null;

  const versionDate = versionsData?.versions.find((el) => el.id === version)?.created_at || '';

  return (
    <VersionContainer data-cy="version-header">
      <LockSvg />
      <Poppins px={14} color="cflowerBlue">
        {versionsData?.versions.find((el) => el.id === version)?.name}
      </Poppins>
      <Spacer $px={10} $horizontal />
      <Poppins px={14} color="cflowerBlue">
        |
      </Poppins>
      <Spacer $px={10} $horizontal />
      <Poppins px={14} color="cflowerBlue">
        {versionsData?.versions.find((el) => el.id === version)?.userName}
      </Poppins>
      <Spacer $px={10} $horizontal />
      <Poppins px={14} color="cflowerBlue">
        |
      </Poppins>
      <Spacer $px={10} $horizontal />
      <Poppins px={14} color="cflowerBlue">
        {isValid(new Date(versionDate)) && format(new Date(versionDate), DATE_FORMAT)}
      </Poppins>
    </VersionContainer>
  );
};
