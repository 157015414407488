import colors from 'src/theme/colors';

const col_a_live = colors.compareA;
const col_a_base = '#64eaab';
const col_a_complete = '#026436';

const col_b_live = colors.compareB;
const col_b_base = '#a95ff3';
const col_b_complete = '#450486';

export const plotColors = {
  col_a_live,
  col_a_base,
  col_a_complete,
  col_b_live,
  col_b_base,
  col_b_complete,
};
