import { useCallback, useEffect, useState } from 'react';
import { queryCache, useQuery } from 'react-query';
import { getAssessment } from 'src/api/assessment';
import { QKeys, Variable } from 'src/api/types';
import { getWorkspaceVariables } from 'src/api/workspace';
import _ from 'lodash';
import { StandardVariableValues } from './types';
import { genStandardVariableValues } from './util';

type UseVariables = (a: { assessmentId?: string; wvOverride?: boolean }) => {
  variables: Variable[] | null;
  isLoading: boolean;
  containsUndefinedVariableFromFormula: (expression: string) => boolean;
  standardVariableValues: StandardVariableValues | null;
  invalidate: (invalidateAssessment?: boolean) => Promise<void>;
};

const useVariables: UseVariables = ({ assessmentId, wvOverride }) => {
  const [variables, setVariables] = useState<Variable[] | null>(null);
  const [standardVariableValues, setStandardVariableValues] = useState<StandardVariableValues | null>(null);

  const { data, isLoading } = useQuery([QKeys.Assessment, { assessmentId }], getAssessment, {
    enabled: !!assessmentId,
  });
  const { data: wVariables, isLoading: isLoadingwVariables } = useQuery(
    [QKeys.WorkspaceVariables],
    getWorkspaceVariables,
  );

  const invalidate = async () => {
    await queryCache.invalidateQueries([QKeys.WorkspaceVariables]);
    await queryCache.invalidateQueries([QKeys.Assessment, { assessmentId }]);
  };

  useEffect(() => {
    if (!isLoading && !isLoadingwVariables && !!wVariables) {
      const assessmentVariables = data?.variables || [];
      const allVariables = _.sortBy([...assessmentVariables, ...(wVariables || [])], (el) =>
        el.alias ? el.alias.toLowerCase() : el.name.toLowerCase(),
      );

      if (wvOverride) {
        const grouped = _.groupBy(allVariables, 'name');
        const filteredVariables = _.flatMap(grouped, (group) => {
          if (group.length > 1) {
            return _.filter(group, (item) => !item.workspace_variable);
          }
          return group;
        });
        setVariables(filteredVariables);
      } else {
        setVariables(allVariables);
      }

      if (wVariables) {
        const gen = genStandardVariableValues(wVariables);
        setStandardVariableValues(gen);
      }
    }
  }, [data, isLoading, isLoadingwVariables, wvOverride, wVariables]);

  const containsUndefinedVariableFromFormula = useCallback(
    (expression: string) => {
      const variableIds = expression.match(/#\d+/g);
      return !!variableIds?.some((id) => variables?.find((v) => v.id === parseInt(id.slice(1)))?.value === null);
    },
    [variables],
  );

  return {
    variables,
    isLoading: isLoading || isLoadingwVariables || !standardVariableValues,
    containsUndefinedVariableFromFormula,
    standardVariableValues,
    invalidate,
  };
};

export default useVariables;
