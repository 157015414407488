// @ts-nocheck
import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, Text, View } from '@react-pdf/renderer';
import { Footer, HeaderCompany } from '../../comps';
import { isEqual } from 'lodash';
import { User } from 'src/api/types';

interface RmpHeadProps {
  numberOfStrategies: number;
  user: User | null;
}

export const RmpHead: React.FC<RmpHeadProps> = memo(
  ({ numberOfStrategies, user }) => {
    return (
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <HeaderCompany companyName={user?.organisation_name || ''} title="Risk Management Plan" companyImage={''} />
        <View style={{ ...pdfStyles.section, flex: 1, justifyContent: 'flex-end' }}>
          <Text style={pdfStyles.gradText}>
            NUMBER OF STRATEGIES: <Text style={{ ...pdfStyles.value, fontSize: 12 }}> {numberOfStrategies}</Text>
          </Text>
          <View style={pdfStyles.spacer1} />
        </View>

        <Footer />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
