import React, { useEffect, useRef, useState } from 'react';
import { Poppins, Spacer } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { Accordion, Chevron } from './comps';
import { AnimatePresence, motion } from 'framer-motion';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { EventsSelector, ListSelector } from './selectors';
import { useActivityState } from '../../state';
import { useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import { getAssessments } from 'src/api/assessment';
import { getControls } from 'src/api/controls';
import { getIncidents } from 'src/api/incidents';

const Div = styled.div`
  /* position: relative; */

  .afltr-control {
    height: 50px;
    border: 1px solid ${colors.stroke};
    background-color: ${colors.cultured};
    border-radius: 3px;
    padding: 0 17px;
    display: flex;
    align-items: center;
    grid-gap: 12px;
    cursor: pointer;
    * {
      user-select: none;
    }
  }

  .afltr-container {
    position: absolute;
    z-index: 10;
    left: 20px;
    right: 20px;
    display: flex;
    justify-content: flex-end;
    pointer-events: none;

    &__contents {
      flex: 1;
      padding: 24px 34px;
      border: 1px solid ${colors.stroke};
      background-color: ${colors.white};
      box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
      max-width: 775px;
      margin-bottom: 10px;
      pointer-events: all;
      max-height: 600px;
      overflow-y: auto;
      display: grid;
      grid-gap: 20px;

      &__egrid-items {
        display: grid;
        grid-gap: 6px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .afltr-control {
      height: 35px;
    }
    .afltr-container {
      left: 15px;
      right: 10px;
      &__contents {
        padding: 20px 25px;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .afltr-container {
      left: 10px;
      right: 10px;
      &__contents {
        padding: 15px 10px;
      }
    }
  }
`;

interface Option {
  id: string;
  label: string;
}

export const Filters = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [options, setOptions] = useState<{
    assessments: Option[];
    scenarios: Option[];
    controls: Option[];
    incidents: Option[];
  }>({
    assessments: [],
    scenarios: [],
    controls: [],
    incidents: [],
  });
  const { setAssessmentsFilters, setScenariosFilters, setControlsFilters, setIncidentsFilters, filters } =
    useActivityState();
  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsOpen(false));

  const { data = [] } = useQuery([QKeys.Assessments], getAssessments);
  const { data: controlsData = [] } = useQuery(QKeys.Controls, getControls);
  const { data: incidentsData = [] } = useQuery(QKeys.Incidents, getIncidents);

  useEffect(() => {
    const scen = data
      .map((el) => el.scenarios)
      .flat()
      .map((el) => {
        return {
          id: el.id,
          label: el.name,
        };
      });

    setAssessmentsFilters(data.map((el) => el.id));
    setScenariosFilters(scen.map((el) => el.id));

    setOptions((s) => ({ ...s, assessments: data.map((el) => ({ id: el.id, label: el.name })), scenarios: scen }));
  }, [data]);

  useEffect(() => {
    setOptions((s) => ({ ...s, controls: controlsData.map((el) => ({ id: el.id, label: el.name })) }));
    setControlsFilters(controlsData.map((el) => el.id));
  }, [controlsData]);

  useEffect(() => {
    setOptions((s) => ({ ...s, incidents: incidentsData.map((el) => ({ id: el.id, label: el.name })) }));
    setIncidentsFilters(incidentsData.map((el) => el.id));
  }, [incidentsData]);

  return (
    <Div ref={ref} onClick={(e) => e.stopPropagation()}>
      <div className="afltr-control" onClick={() => setIsOpen(!isOpen)}>
        <Poppins weight={600} color="davysGrey">
          Filter Timeline View
        </Poppins>
        <Chevron isOpen={isOpen} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="afltr-container"
            onClick={(e) => e.stopPropagation()}
            initial={{
              opacity: 0,
              scale: 0.99,
              y: -10,
            }}
            animate={{
              opacity: 1,
              scale: 1,
              y: 10,
            }}
            exit={{
              opacity: 0,
              scale: 0.99,
              y: 0,
            }}
            transition={{
              type: 'tween',
              duration: 0.1,
            }}
          >
            <div className="afltr-container__contents styled-scroll">
              <Accordion title="EVENTS" initialOpen>
                <EventsSelector />
              </Accordion>
              <Accordion title="ASSESSMENTS">
                <ListSelector
                  data={options.assessments}
                  selected={filters.assessments}
                  onSelectAll={(isAdd) => setAssessmentsFilters(isAdd ? options.assessments.map((el) => el.id) : [])}
                  onSelect={(id) => {
                    setAssessmentsFilters((prev) => {
                      if (prev.includes(id)) {
                        return prev.filter((el) => el !== id);
                      }
                      return [...prev, id];
                    });
                  }}
                />
              </Accordion>
              <Accordion title="SCENARIOS">
                <ListSelector
                  data={options.scenarios}
                  selected={filters.scenarios}
                  onSelectAll={(isAdd) => setScenariosFilters(isAdd ? options.scenarios.map((el) => el.id) : [])}
                  onSelect={(id) => {
                    setScenariosFilters((prev) => {
                      if (prev.includes(id)) {
                        return prev.filter((el) => el !== id);
                      }
                      return [...prev, id];
                    });
                  }}
                />
              </Accordion>
              <Accordion title="CONTROLS">
                <ListSelector
                  data={options.controls}
                  selected={filters.controls}
                  onSelectAll={(isAdd) => setControlsFilters(isAdd ? options.controls.map((el) => el.id) : [])}
                  onSelect={(id) => {
                    setControlsFilters((prev) => {
                      if (prev.includes(id)) {
                        return prev.filter((el) => el !== id);
                      }
                      return [...prev, id];
                    });
                  }}
                />
              </Accordion>
              <Accordion title="INCIDENTS">
                <ListSelector
                  data={options.incidents}
                  selected={filters.incidents}
                  onSelectAll={(isAdd) => setIncidentsFilters(isAdd ? options.incidents.map((el) => el.id) : [])}
                  onSelect={(id) => {
                    setIncidentsFilters((prev) => {
                      if (prev.includes(id)) {
                        return prev.filter((el) => el !== id);
                      }
                      return [...prev, id];
                    });
                  }}
                />
              </Accordion>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Div>
  );
};
