import React, { forwardRef } from 'react';
import styled from 'styled-components';

const ContentContainer = styled.div`
  flex-grow: 1;
`;

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const SidebarContent: React.ForwardRefRenderFunction<unknown, Props> = ({ children, className, ...rest }, ref) => {
  const sidebarContentRef = (ref as any) || React.createRef<HTMLDivElement>();
  return (
    <ContentContainer {...rest} ref={sidebarContentRef}>
      {children}
    </ContentContainer>
  );
};

export default forwardRef<unknown, Props>(SidebarContent);
