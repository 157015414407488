import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import colors from 'src/theme/colors';
// import { withStyles } from '@mui/material';

// const LightTooltip = withStyles((theme) => ({
//   tooltip: {
//     padding: '8px 12px',
//     backgroundColor: 'white',
//     color: colors.prussianBlue,
//     fontSize: 14,
//     border: `1px solid ${colors.stroke}`,
//     borderRadius: '6px',
//     boxShadow: '0px 0px 37px rgba(7, 46, 91, 0.23)',
//     position: 'relative',
//     zIndex: 1000000,
//     fontWeight: 500,
//   },
//   arrow: {
//     color: 'white',
//   },
// }))(Tooltip);

interface IProps {
  title: string;
  children: React.ReactNode;
  enabled?: boolean;
  arrow?: boolean;
}
export const StyledTooltip: React.FC<IProps> = ({ title, enabled, children, arrow = true }) => {
  if (!enabled) return <>{children}</>;
  return (
    // @ts-ignore
    <Tooltip arrow={arrow} placement="top" title={title}>
      {/* @ts-ignore */}
      {children}
    </Tooltip>
  );
};
