import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { getScenario } from 'src/api/scenario';
import { PageChartSkeleton, PageContainer, Spacer } from 'src/common';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { Charts } from './comps/charts';
import { getAssessment, getAssessmentVersions } from 'src/api/assessment';
import { Sheet } from './comps/sheets';
import { AssessmentVersion, QKeys } from 'src/api/types';
import useVersions from '../useVersions';

const SheetsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 6px;
`;

const CompareScenario = () => {
  const { assessmentAId, assessmentBId, scenarioAId, scenarioBId } = useParams<{
    assessmentAId: string;
    assessmentBId: string;
    scenarioAId: string;
    scenarioBId: string;
  }>();
  const { versionAId, versionBId } = useVersions();

  const [versionA, setVersionA] = useState<AssessmentVersion | undefined>(undefined);
  const [versionB, setVersionB] = useState<AssessmentVersion | undefined>(undefined);

  const { data: dataA, isLoading: ilda } = useQuery(
    [QKeys.Scenario, { scenarioId: scenarioAId, version: versionA?.id }],
    getScenario,
  );
  const { data: dataB, isLoading: ildb } = useQuery(
    [QKeys.Scenario, { scenarioId: scenarioBId, version: versionB?.id }],
    getScenario,
  );
  const { data: assA, isLoading: ilaa } = useQuery([QKeys.Assessment, { assessmentId: assessmentAId }], getAssessment);
  const { data: assB, isLoading: ilab } = useQuery([QKeys.Assessment, { assessmentId: assessmentBId }], getAssessment);

  const { isLoading: ilva } = useQuery(
    [QKeys.Versions, assessmentAId],
    () => getAssessmentVersions(assessmentAId as string),
    {
      onSuccess: (data) => {
        setVersionA(data.versions.find((v) => v.id === versionAId));
      },
    },
  );

  const { isLoading: ilvb } = useQuery(
    [QKeys.Versions, assessmentBId],
    () => getAssessmentVersions(assessmentBId as string),
    {
      onSuccess: (data) => {
        setVersionB(data.versions.find((v) => v.id === versionBId));
      },
    },
  );

  useGaEventPageView('Cydea | Compare scenarios');

  const isReady = !ilda && !ildb && !ilaa && !ilab && !ilva && !ilvb;

  return (
    <>
      <Meta title={`Cydea | Compare Scenarios`} feedbackScript />
      <PageContainer>
        {dataA && dataB && assA && assB && isReady ? (
          <>
            <Charts dataA={dataA} dataB={dataB} scenarioNameA={assA.name} scenarioNameB={assB.name} />
            <Spacer $px={60} />
            <SheetsWrapper>
              <Sheet scenario={dataA} assessmentName={assA.name} version={versionA} />
              <Sheet scenario={dataB} assessmentName={assB.name} version={versionB} />
            </SheetsWrapper>
          </>
        ) : (
          <PageChartSkeleton />
        )}
      </PageContainer>
    </>
  );
};

export default CompareScenario;
