import { SlideId } from 'src/api/types/wizard';
import {
  AssessmentDetails,
  Scenarios,
  Organisation,
  Customers,
  SoftwareDev,
  SupplyChain,
  CyberInsurance,
  Regulations,
  Finalise,
} from './comps/slides';

export const standardSlides = [
  {
    title: 'Workspace: Your Organisation',
    component: Organisation,
    id: SlideId.organisation,
  },
  {
    title: 'Workspace: Your Customers',
    component: Customers,
    id: SlideId.customers,
  },
  {
    title: 'Workspace: Software Development',
    component: SoftwareDev,
    id: SlideId.softwareDev,
  },
  {
    title: 'Workspace: Supply Chain',
    component: SupplyChain,
    id: SlideId.supplyChain,
  },
  {
    title: 'Workspace: Cyber Insurance',
    component: CyberInsurance,
    id: SlideId.cyberInsurance,
  },
  {
    title: 'Workspace: Regulations and Frameworks',
    component: Regulations,
    id: SlideId.regulations,
  },
  {
    title: 'Assessment: Details',
    component: AssessmentDetails,
    id: SlideId.assessmentDetails,
  },
  {
    title: 'Assessment: Scenarios',
    component: Scenarios,
    id: SlideId.scenarios,
  },
  {
    title: 'Review & Finalise',
    component: Finalise,
    id: SlideId.finalise,
  },
];

export const baseWorkspaceSlides = [
  {
    title: 'Workspace: Your Organisation',
    component: Organisation,
    id: SlideId.organisation,
  },
  {
    title: 'Workspace: Your Customers',
    component: Customers,
    id: SlideId.customers,
  },
  {
    title: 'Workspace: Software Development',
    component: SoftwareDev,
    id: SlideId.softwareDev,
  },
  {
    title: 'Workspace: Supply Chain',
    component: SupplyChain,
    id: SlideId.supplyChain,
  },
  {
    title: 'Workspace: Cyber Insurance',
    component: CyberInsurance,
    id: SlideId.cyberInsurance,
  },
  {
    title: 'Workspace: Regulations and Frameworks',
    component: Regulations,
    id: SlideId.regulations,
  },
  {
    title: 'Review & Finalise',
    component: Finalise,
    id: SlideId.finalise,
  },
];

export const scenarioSlides = [
  {
    title: 'Assessment: Scenarios',
    component: Scenarios,
    id: SlideId.scenarios,
  },
  {
    title: 'Review & Finalise',
    component: Finalise,
    id: SlideId.finalise,
  },
];
