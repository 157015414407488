import React, { useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { AssessmentCardSkeleton, PageContainer, Poppins, Spacer } from 'src/common';
import colors from 'src/theme/colors';
import { FrameworkLibraryModal } from '../../components/modals';
import { FrameworkLibraryCard } from './comps';
import { getFrameworksLibraries } from 'src/api/frameworklibraries';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import { QKeys } from 'src/api/types';
import _ from 'lodash';
import { Tile, TileContainer, TileTitle } from 'src/components/Tile';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';

const Div = styled(PageContainer)`
  .panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 15px;
  }

  .error {
    color: ${colors.error};
    font-size: 14px;
  }
  .success {
    color: ${colors.success};
    font-size: 14px;
  }
`;

const FrameworkLibrariesPage = () => {
  const [frameworkAddModal, setFrameworkAddModal] = useState(false);
  const { data = [], isLoading } = useQuery(QKeys.Frameworks, getFrameworksLibraries);
  const navigate = useNavigate();
  useGaEventPageView('Cydea | Control Frameworks');

  return (
    <>
      <Meta title={`Cydea | Control Frameworks`} feedbackScript />
      {frameworkAddModal && <FrameworkLibraryModal onClose={() => setFrameworkAddModal(false)} />}
      <Div>
        <Poppins px={32} weight={600}>
          Control Frameworks
        </Poppins>
        <Spacer $px={20} />
        <TileContainer>
          {!isLoading ? (
            <>
              <Tile
                className="hover-shadow"
                data-cy="all-controls-card"
                onClick={() => {
                  navigate('/control-frameworks/all-controls');
                  mpEvent(MPEvents.Link, {
                    source: {
                      value: ['Frameworks'],
                    },
                    destination: { value: ['Framework Controls all'] },
                    tags: ['FRAMEWORK'],
                  });
                }}
              >
                <Link
                  to="/control-frameworks/all-controls"
                  onClick={(e) => {
                    e.stopPropagation();
                    mpEvent(MPEvents.Link, {
                      source: {
                        value: ['Frameworks'],
                      },
                      destination: { value: ['Framework Controls all'] },
                      tags: ['FRAMEWORK'],
                    });
                  }}
                >
                  <TileTitle>View All Controls</TileTitle>
                </Link>
              </Tile>
              {_.orderBy(data, [(item) => item.shortname?.toLowerCase()]).map((el) => (
                <FrameworkLibraryCard key={el.ux_id} framework={el} />
              ))}
            </>
          ) : (
            <>
              <AssessmentCardSkeleton />
              <AssessmentCardSkeleton />
              <AssessmentCardSkeleton />
            </>
          )}
        </TileContainer>
      </Div>
    </>
  );
};

export default FrameworkLibrariesPage;
