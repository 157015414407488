import React from 'react';
import { Control, ControllStatus } from 'src/api/types';
import { numeralFormat } from 'src/utils/misc';
import { SDRDiv, SDRIndicator, SDRItem, SDRRowData } from '../../comps';

interface DetailsProps {
  controls: Control[];
}

export const Details: React.FC<DetailsProps> = ({ controls }) => {
  const notStartedControls = controls.filter((el) => el.status === ControllStatus.NotStarted);
  const notStartedCost = notStartedControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const inProgressControls = controls.filter((el) => el.status === ControllStatus.InProgress);
  const inProgressCost = inProgressControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const liveControls = controls.filter((el) => el.status === ControllStatus.Live);
  const liveCost = liveControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const abandonedControls = controls.filter((el) => el.status === ControllStatus.Abandoned);
  const abandonedCost = abandonedControls.reduce((acc, el) => acc + (el.annual_cost || 0), 0);

  const list: { indicator?: SDRIndicator; data: [SDRRowData, SDRRowData] }[] = [
    {
      data: [
        {
          title: 'Total:',
          value: controls.length,
        },
        {
          title: 'Cost:',
          value: numeralFormat(notStartedCost + inProgressCost + liveCost + abandonedCost),
        },
      ],
    },
    {
      indicator: 'black',
      data: [
        {
          title: 'Not Started:',
          value: notStartedControls.length,
        },
        {
          title: 'Cost:',
          value: numeralFormat(notStartedCost),
        },
      ],
    },
    {
      indicator: 'blue',
      data: [
        {
          title: 'In Progress:',
          value: inProgressControls.length,
        },
        {
          title: 'Cost:',
          value: numeralFormat(inProgressCost),
        },
      ],
    },
    {
      indicator: 'red',
      data: [
        {
          title: 'Live:',
          value: liveControls.length,
        },
        {
          title: 'Cost:',
          value: numeralFormat(liveCost),
        },
      ],
    },
    {
      indicator: 'gray',
      data: [
        {
          title: 'Abandoned:',
          value: abandonedControls.length,
        },
        {
          title: 'Cost:',
          value: numeralFormat(abandonedCost),
        },
      ],
    },
  ];

  return (
    <SDRDiv>
      {list.map((el, idx) => (
        <SDRItem key={idx} indicator={el.indicator} data={el.data} />
      ))}
    </SDRDiv>
  );
};
