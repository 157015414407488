import { AxiosArgs, ThenArgs, Incident } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';

type GetIncident = (id: string, query?: { version?: string }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Incident>>>>;

export const getIncident: GetIncident = (id, query = {}) => {
  const path = `/api/public/api/incidents/${id}${encodeQueryParams(query)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetIncidents = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<Incident[]>>>>;

export const getIncidents: GetIncidents = () => {
  const path = `/api/public/api/incidents`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type CreateIncident = (
  a: Partial<Omit<Incident, 'id' | 'scenarios' | 'scenariosCount'>>[],
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Incident[]>>>>;

export const createIncident: CreateIncident = (incidents) => {
  const path = `/api/public/api/incidents`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody({ incidents }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// type CreateIncident = (
//   a: Partial<Omit<Incident, 'id' | 'scenarios' | 'scenariosCount'>>,
// ) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Incident>>>>;

// export const createIncident: CreateIncident = (params) => {
//   const path = `/api/public/api/incidents`;
//   return axios({
//     method: 'POST',
//     url: path,
//     data: encodeBody(params),
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//   }).then((res) => res.data.data);
// };
// ------------------------------------------------------------

type DeleteIncident = (a: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<boolean>>>>;

export const deleteIncident: DeleteIncident = (id) => {
  const path = `/api/public/api/incidents/${id}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateIncident = (
  a: Partial<Omit<Incident, 'scenarios' | 'scenariosCount'>>,
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<boolean>>>>;

export const updateIncident: UpdateIncident = (data) => {
  const path = `/api/public/api/incidents/${data.id}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type LinkIncident = (a: {
  scenarios: string[];
  incidentId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<boolean>>>>;

export const linkIncident: LinkIncident = ({ scenarios, incidentId }) => {
  const path = `/api/public/api/incidents/${incidentId}/scenarios`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody({ scenarios }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------
type UnLinkIncident = (a: {
  scenarioId: string;
  incidentId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<boolean>>>>;

export const unlinkIncident: UnLinkIncident = ({ scenarioId, incidentId }) => {
  const path = `/api/public/api/incidents/${incidentId}/scenarios/${scenarioId}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
