import React from 'react';
import { Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';

const Div = styled.div`
  .crc-link {
    color: ${colors.prussianBlue};
    cursor: pointer;
    text-decoration: underline;
  }
`;

interface CustomResultComponentProps {
  onClick: () => void;
}

export const CustomResultComponent: React.FC<CustomResultComponentProps> = ({ onClick }) => {
  return (
    <Div>
      <Poppins px={14}>
        Result:{' '}
        <Poppins color="error" px={14}>
          Some variables have not been populated, so the value cannot be calculated. Click{' '}
          <span className="crc-link" onClick={onClick}>
            here
          </span>{' '}
          to add values
        </Poppins>
      </Poppins>
    </Div>
  );
};
