import React, { memo, useEffect, useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import {
  workspaceInviteAccept,
  workspaceJoinRequestCancel,
  workspaceJoin,
  workspaceJoinRequest,
} from 'src/api/workspace';
import { OrgTreeItem, OrganisationWorkspace, QKeys } from 'src/api/types';
import Button from 'src/components/form/Button';
import useWorkspaceSwitch from 'src/hooks/useWorkspaceSwitch';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import _ from 'lodash';
import { useAuth } from 'src/state/auth';
import { MPEvents } from 'src/utils/mixpanel/types';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { LSKEY_INITAIL_URL } from 'src/utils/auth';

const Div = styled.div`
  /* display: flex;
  align-items: flex-end;
  justify-content: flex-end; */
  button {
    width: 100% !important;
  }
`;

const SecondaryWhiteBtn = styled(Button).attrs((p) => {
  return { ...p, secondary: true };
})`
  background: white;
  -webkit-background-clip: none;
  -webkit-text-fill-color: ${colors.red};
`;

interface ActionsProps {
  data: OrgTreeItem;
  orgData: {
    organisations: OrganisationWorkspace[];
    myOrganisations: OrganisationWorkspace[];
    myRequests: OrganisationWorkspace[];
  };
}

type Action =
  | 'WORKSPACE_OPEN'
  | 'WORKSPACE_JOIN'
  | 'WORKSPACE_ACCEPT_INVITE'
  | 'WORKSPACE_ACCESS_REQUEST'
  | 'WORKSPACE_ACCESS_CANCEL';

export const Actions: React.FC<ActionsProps> = memo(
  ({ data, orgData }) => {
    const [action, setAction] = useState<Action | null>(null);
    const { user, userPermissions, isOrganisationAdmin } = useAuth();
    const { switchOrg, isLoading: isLoadingSwitch } = useWorkspaceSwitch({
      reloleadWindowOnSuccess: true,
    });

    useEffect(() => {
      const getActionType = () => {
        // WORKSPACE
        if (data.type === 'W') {
          if (userPermissions.app.platform.admin || isOrganisationAdmin) return 'WORKSPACE_OPEN';

          const isOwnDomain = data.domain === `@${user?.email.split('@')[1]}`;

          const originalOrg = orgData.organisations.find((el) => el.id === data.id);

          if (orgData.myOrganisations.some((el) => el.id === data.id)) return 'WORKSPACE_OPEN';

          if (user?.invites.some((el) => el.id === data.id)) return 'WORKSPACE_ACCEPT_INVITE';

          if (orgData.myRequests.some((el) => el.id === data.id)) return 'WORKSPACE_ACCESS_CANCEL';

          if (isOwnDomain && originalOrg?.is_restricted && !orgData.myRequests.some((el) => el.id === data.id))
            return 'WORKSPACE_ACCESS_REQUEST';

          if (isOwnDomain && !originalOrg?.is_restricted) return 'WORKSPACE_JOIN';
        }

        return null;
      };

      const actionType = getActionType();

      setAction(actionType);
    }, [data, orgData, userPermissions, isOrganisationAdmin]);

    const resetCache = () => {
      queryCache.invalidateQueries([QKeys.Organisations]);
      queryCache.invalidateQueries(QKeys.User);
      queryCache.invalidateQueries([QKeys.OrgHierarchy]);
    };

    const [wia, { isLoading: isWiaLoading }] = useMutation(workspaceInviteAccept, {
      onSuccess: resetCache,
    });

    const [wj, { isLoading: isLoadingWj }] = useMutation(workspaceJoin, {
      onError: (err) => {
        console.log('ERR', err);
      },
    });

    const [wjr, { isLoading: isLoadingWjr }] = useMutation(workspaceJoinRequest, {
      onSuccess: resetCache,
      onError: (err) => {
        console.log('ERR', err);
      },
    });

    const [wjrc, { isLoading: isLoadingWjrc }] = useMutation(workspaceJoinRequestCancel, {
      onSuccess: resetCache,
      onError: (err) => {
        console.log('ERR', err);
      },
    });

    return (
      <Div>
        {action === 'WORKSPACE_OPEN' && (
          <SecondaryWhiteBtn
            onClick={() => {
              window.localStorage.setItem(LSKEY_INITAIL_URL, '/risk-assessments');
              switchOrg(data.id);
              mpEvent(MPEvents.WorkspaceSwitch, { workspaceID: data.id });
            }}
            disabled={isLoadingSwitch}
          >
            OPEN
          </SecondaryWhiteBtn>
        )}
        {action === 'WORKSPACE_ACCEPT_INVITE' && (
          <Button
            primary
            onClick={() => {
              const invite = user?.invites.find((el) => el.id === data.id);
              const requestId = invite?.invite.id;
              if (requestId) {
                wia({ orgId: data.id, requestId: invite?.invite.id });
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Accept invite',
                });
              }
            }}
            disabled={isWiaLoading}
          >
            ACCEPT INVITE
          </Button>
        )}
        {action === 'WORKSPACE_ACCESS_CANCEL' && (
          <SecondaryWhiteBtn
            onClick={() => {
              wjrc(data.id);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Cancel request',
              });
            }}
            disabled={isLoadingWjrc}
          >
            CANCEL REQUEST
          </SecondaryWhiteBtn>
        )}
        {action === 'WORKSPACE_ACCESS_REQUEST' && (
          <SecondaryWhiteBtn
            onClick={() => {
              wjr(data.id);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Request access',
              });
            }}
            disabled={isLoadingWjr}
          >
            REQUEST ACCESS
          </SecondaryWhiteBtn>
        )}
        {action === 'WORKSPACE_JOIN' && (
          <SecondaryWhiteBtn
            onClick={() => {
              wj(data.id).then(() => switchOrg(data.id));
              mpEvent(MPEvents.ButtonClick, {
                button: 'Join',
              });
            }}
            disabled={isLoadingWj || isLoadingSwitch}
          >
            JOIN
          </SecondaryWhiteBtn>
        )}
      </Div>
    );
  },
  (prev, next) => _.isEqual(prev, next),
);
