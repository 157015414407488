import { useFormikContext } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { FormValues, NameMapping, Scope } from '../types';
import { ReactComponent as GlobalVariableIcon } from 'src/assets/icon-wizzard.svg';
import CurrencyInput from 'src/components/form/CurrencyInput';
import { get } from 'lodash';
import MaterialInput from 'src/components/form/MaterialInput';
import { Poppins, Tooltip } from 'src/common';
import colors from 'src/theme/colors';
import Input from 'src/components/form/Input';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { generateUsageMessage } from '../util';
import _ from 'lodash';

const Div = styled.div`
  display: grid;
  grid-template-columns: 20px auto 1fr 20% 22px;
  grid-gap: 10px;
  .row-item {
    display: flex;
    align-items: flex-start;

    &--center {
      display: flex;
      align-items: center !important;
      justify-content: center !important;
    }
  }
  .row-divider {
    border-bottom: 1px solid ${colors.aliceBlue};
    flex: 1;
  }

  .continous {
    display: grid;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    flex: 1;
    display: flex;
    padding: 0 10px;

    &--gap {
      grid-gap: 20px;
    }

    &__item {
      /* border: 1px solid red; */
      width: 142px;
    }
    &__input {
      display: flex;
      grid-template-columns: repeat(auto-fit, minmax(10px, 1fr));
      grid-gap: 10px;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .fixed-height {
    height: 50px;
  }

  .row-end {
    justify-content: flex-end;

    &--delete {
      svg {
        fill: ${colors.cflowerBlue};
      }
      &:hover {
        cursor: pointer;
        svg {
          fill: ${colors.red};
        }
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .fixed-height {
      height: 38px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .fixed-height {
      height: 30px;
    }
  }
`;

interface RowProps {
  idx: number;
  onRemove: () => void;
  scenarioNames: NameMapping;
  assessmentNames: NameMapping;
  scope: Scope;
  isVWar: boolean;
  canOverride: boolean;
  isOverride: boolean;
  onOverride: () => void;
  isHidden?: boolean;
}

export const Row: React.FC<RowProps> = ({
  idx,
  onRemove,
  scenarioNames,
  assessmentNames,
  scope,
  isVWar,
  canOverride,
  isOverride,
  onOverride,
  isHidden,
}) => {
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<FormValues>();
  const data = values.variables[idx];

  if (isHidden) return null;

  const isErrName = get(errors, `variables[${idx}].name`);
  const isTouchedName = get(touched, `variables[${idx}].name`);
  const isErrValue = get(errors, `variables[${idx}].value`);
  const isTouchedValue = get(touched, `variables[${idx}].value`);

  const canDelete = !data.isInUse.length && !data.isGlobal && !(scope.type === 'assessment' && isVWar);
  const canHandleCurrency =
    !data.isGlobal && (scope.type === 'workspace' ? true : !isOverride && !data.workspace_variable);

  return (
    <Div>
      <div className="row-item row-item--center fixed-height">{isVWar && <GlobalVariableIcon />}</div>
      {data._isNew ? (
        <div className="row-item">
          <MaterialInput
            placeholder="Add variable name"
            value={data.name}
            onChange={(e) => setFieldValue(`variables[${idx}].name`, e.target.value)}
            onBlur={() => setFieldTouched(`variables[${idx}].name`, true)}
            error={Boolean(isTouchedName && isErrName)}
            helperText={isTouchedName && isErrName}
            fullWidth={true}
            css="min-width: 300px"
            data-cy={`variable-name-input`}
          />
        </div>
      ) : (
        <div className="row-item row-item--center">
          <div className="var">
            <div className="var__chip">
              <Poppins px={16} weight={500} style={{ whiteSpace: 'pre' }}>
                {data.name}
              </Poppins>
            </div>
          </div>
        </div>
      )}

      <div className="row-item fixed-height">
        <div className={`continous ${canOverride ? 'continous--gap' : ''}`}>
          <div className="row-divider" />
          {scope.type === 'assessment' && (
            <div className="continous__item">
              {canOverride ? (
                <Tooltip
                  dataId={`ovr-${idx}`}
                  place="top"
                  dataHtml={
                    isOverride
                      ? 'Restore this variable to its original workspace value'
                      : 'Override this variable to make changes specific to this assessment'
                  }
                >
                  <div className="continous__input">
                    <Poppins px={14} weight={500} color="cflowerBlue" nowrap>
                      OVERRIDE
                    </Poppins>
                    <Input
                      checked={isOverride}
                      onChange={(e) => {
                        onOverride();
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Override',
                          modal: 'Variables modal',
                          tags: ['VARIABLES'],
                        });
                      }}
                      className="switch"
                      type="checkbox"
                    />
                  </div>
                </Tooltip>
              ) : (
                <div className="row-divider" />
              )}
            </div>
          )}
          <div
            className="continous__item"
            css={`
              margin-left: ${canHandleCurrency ? 20 : 0}px;
            `}
          >
            {canHandleCurrency ? (
              <div className="continous__input">
                <Poppins px={14} weight={500} color="cflowerBlue" nowrap>
                  CURRENCY
                </Poppins>
                <Input
                  checked={data.currency}
                  onChange={(e) => {
                    setFieldValue(`variables[${idx}].currency`, e.target.checked);
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Toggle currency',
                      modal: 'Variables modal',
                      tags: ['VARIABLES'],
                    });
                  }}
                  disabled={scope.type === 'assessment' && isOverride}
                  className="switch"
                  type="checkbox"
                />
              </div>
            ) : (
              <div className="row-divider" />
            )}
          </div>
        </div>
      </div>

      <div className="row-item">
        <CurrencyInput
          placeholder="Enter value"
          value={data.value}
          currency={data.currency}
          onValueChange={(e) => setFieldValue(`variables[${idx}].value`, e.floatValue)}
          onBlur={() => setFieldTouched(`variables[${idx}].value`, true)}
          error={Boolean(isTouchedValue && isErrValue)}
          helperText={isTouchedValue && isErrValue}
          data-cy={`${_.kebabCase(data?.name || 'empty')}__value`}
          fullWidth={true}
          disabled={scope.type === 'assessment' && isVWar}
        />
      </div>
      {canDelete && !(!data._isNew && isOverride) ? (
        <div
          className="row-item row-item--center row-end row-end--delete fixed-height"
          onClick={() => {
            onRemove();
            mpEvent(MPEvents.ButtonClick, {
              button: 'Delete variable',
              modal: 'Variables modal',
            });
          }}
          data-cy="variable-delete"
        >
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
          </svg>
        </div>
      ) : (
        <div className="row-item row-item--center row-end fixed-height">
          {data.isInUse.length ? (
            <>
              <Tooltip
                dataHtml={generateUsageMessage({
                  workspace_variable: isVWar,
                  id: data.id,
                  assessmentNames: assessmentNames[data.id],
                  scenarioNames: scenarioNames[data.id],
                  isGlobal: data.isGlobal,
                })}
                dataId={`row-${idx}`}
              >
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
                    fill={colors.cflowerBlue}
                  />
                </svg>
              </Tooltip>
            </>
          ) : (
            <>{data.isGlobal && <></>}</>
          )}
        </div>
      )}
    </Div>
  );
};
