import { RefObject, useEffect } from 'react';

const useOnClickOutside = (ref: RefObject<HTMLElement>, handler: (event: MouseEvent | TouchEvent) => void) => {
  const listener = (event: MouseEvent | TouchEvent) => {
    if (!ref.current || ref.current.contains(event.target as Node)) {
      return;
    }

    handler(event);
  };
  useEffect(() => {
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);

  return listener;
};

export default useOnClickOutside;
