import { FilterMeta, Row, Table } from '@tanstack/react-table';
import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils';
import { FilterFn } from '@tanstack/react-table';
import { GTInternalIds } from './types';
import _ from 'lodash';

declare module '@tanstack/react-table' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

type FuzzyFilter = (
  row: Row<any>,
  columnId: string,
  value: any,
  addMeta: (meta: FilterMeta) => void,
  searchable?: true | string[],
) => boolean;

export const fuzzyFilter: FuzzyFilter = (row, columnId, value, addMeta, searchable) => {
  let rank: any;

  if (searchable === true) {
    const itemRank = rankItem(row.getValue(columnId), value);

    addMeta({
      itemRank,
    });

    return itemRank.passed;
  } else if (Array.isArray(searchable)) {
    rank = searchable.reduce(
      (bestRank, key) => {
        const itemRank = rankItem(row.getValue(key), value);
        if (itemRank.rank < bestRank.rank && itemRank.passed) {
          return itemRank;
        } else {
          return bestRank;
        }
      },
      { rank: Number.POSITIVE_INFINITY, passed: false },
    );

    // rank.passed = rank.rank !== Number.POSITIVE_INFINITY;
  } else {
    rank = rankItem(row.getValue(columnId), value);
  }

  addMeta({ itemRank: rank });

  return rank.passed;
};

export const getClassNameByHeaderId = (headerId: string) => {
  switch (headerId) {
    case GTInternalIds.tdm:
      return 'gt-align-right';
    case GTInternalIds.expander:
      return 'gt-align-left';
    case GTInternalIds.checker:
      return 'gt-align-left';
    default:
      return '';
  }
};

type GTCheckHandler = <T = string>(
  params: { table: Table<any>; selector: string; checked: T[] },
  cb: (checked: T[]) => void,
) => { checkAllHandler: () => void; isAllChecked: boolean; visibleRows: T[] };

export const gTCheckHandler: GTCheckHandler = ({ table, selector, checked }, setChecked) => {
  const visibleRows = table.getRowModel().rows.map((el) => el.original[selector]);

  // UNCHECKS ROWS THAT RE FILTERED OUT
  // const remainingIds = table.getFilteredRowModel().rows.map((el) => el.original[selector]);
  // const updatedChecked = _.intersection(checked, remainingIds);

  // if (!_.isEqual(checked, updatedChecked)) {
  //   setChecked(updatedChecked);
  // }

  const isAllChecked = _.every(visibleRows, (element) => _.includes(checked, element));

  const checkAllHandler = () => {
    if (isAllChecked) {
      setChecked(_.difference(checked, visibleRows));
    } else {
      const ids = _.uniq([...checked, ...visibleRows]);
      setChecked(ids);
    }
  };

  return { checkAllHandler, isAllChecked, visibleRows };
};
