import { AxiosArgs, ThenArgs, Control, Strategy, ControllStatus, UserOptionType } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';

type CreateControl = (params: Partial<Control>) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Control>>>>;

export const createControl: CreateControl = (params) => {
  const path = `/api/public/api/controls`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetControls = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<Control[]>>>>;

export const getControls: GetControls = () => {
  const path = `/api/public/api/controls`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetControl = (id: string, query?: { version?: string }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Control>>>>;

export const getControl: GetControl = (id, query = {}) => {
  const path = `/api/public/api/controls/${id}${encodeQueryParams(query)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateControl = (params: {
  id: string;
  name?: string;
  shortname?: string;
  owner?: UserOptionType | null;
  description?: string | null;
  strategy?: Strategy | null;
  status?: ControllStatus | null;
  expected_active_date?: string | null;
  source?: string | null;
  url_name?: string | null;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Control>>>>;

export const updateControl: UpdateControl = ({ id, ...params }) => {
  const path = `/api/public/api/controls/${id}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type DeleteControls = (controls: string[]) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Control>>>>;

export const deleteControls: DeleteControls = (controls) => {
  const path = `/api/public/api/controls`;
  return axios({
    method: 'DELETE',
    url: path,
    data: { ids: controls },
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type AddControllsFromLibrary = (data: {
  controlLibraryIds: { id: string }[];
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Control[]>>>>;

export const addControllsFromLibrary: AddControllsFromLibrary = (data) => {
  const path = `/api/public/api/controls/addfromlibrary`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
