import React from 'react';
import GlobalStyles from './theme/GlobalStyles';
import { ThemeProvider } from 'styled-components';
import { ReactQueryConfig, ReactQueryConfigProvider } from 'react-query';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import AuthPage from 'src/pages/Auth/AuthPage';
import VerifyPage from 'src/pages/Auth/VerifyPage';
import BlockedPage from 'src/pages/Auth/BlockedPage';
import SignedInPage from 'src/pages/SignedInPage';
import { AuthProvider } from 'src/state/auth';
import { CallbackPage } from './pages/Auth/CallbackPage';
import { auth0Domain, auth0ClientID, auth0Audience } from './utils/auth';
import moment from 'moment-timezone';
import { Provider as StateProvider } from 'react-redux';
import { store } from './redux';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import theme from './theme';
import useMixpanel from './utils/mixpanel/useMixPanel';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from './graphql';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
moment.tz.setDefault('UTC');

const reactQueryConfig: ReactQueryConfig = {
  queries: {
    useErrorBoundary: false,
    refetchOnWindowFocus: false,
    retry: false,
    onError: (err) => {},
  },
  mutations: {
    onError: (err) => {},
  },
};

const RootNavigation = () => {
  useMixpanel();
  return (
    <Routes>
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="/auth" element={<AuthPage />} />
      <Route path="/verify" element={<VerifyPage />} />
      <Route path="/blocked" element={<BlockedPage />} />
      <Route path="*" element={<SignedInPage />} />
    </Routes>
  );
};

const App = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <ReactQueryConfigProvider config={reactQueryConfig}>
        <StateProvider store={store}>
          <MUIThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <GlobalStyles />
              <CssBaseline />
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <Router>
                  <Auth0Provider
                    domain={auth0Domain}
                    clientId={auth0ClientID}
                    authorizationParams={{
                      audience: `${auth0Audience}`,
                      redirect_uri: `${window.location.protocol}//${window.location.host}/callback`,
                    }}
                  >
                    <AuthProvider>
                      <RootNavigation />
                    </AuthProvider>
                  </Auth0Provider>
                </Router>
              </LocalizationProvider>
            </ThemeProvider>
          </MUIThemeProvider>
        </StateProvider>
      </ReactQueryConfigProvider>
    </ApolloProvider>
  );
};

export default App;
