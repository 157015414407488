import React from 'react';
import { Base } from './Base';
import { Text } from './Text';
import { MagCheckIcon } from './icons';

interface EFComplianceAuditDueProps {}

export const EFComplianceAuditDue: React.FC<EFComplianceAuditDueProps> = () => {
  return <Base iconComponent={<MagCheckIcon />} constentComponent={<Text>Compliance audit due.</Text>} />;
};
