import React from 'react';
import styled, { css } from 'styled-components';
import ReactModal, { Props } from 'react-modal';
import { ReactComponent as CrossIcon } from 'src/assets/cross.svg';
import colors from 'src/theme/colors';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import wandIcon from 'src/assets/wand-icon.png';

const CloseIcon = styled(CrossIcon)`
  position: absolute;
  right: 22px;
  top: 22px;
  cursor: pointer;
  &:hover {
    fill: ${colors.red};
  }
`;

interface ContainerProps {
  $isLoading?: boolean;
  translateX?: number;
}

// @ts-ignore
const Container = styled(ReactModal).attrs<{ overlayClassName?: string }>((props) => ({
  shouldCloseOnOverlayClick: true,
  ...props,
}))<ContainerProps>`
  background: ${colors.white};
  z-index: 1150;
  outline: none;
  border-radius: 6px;
  position: relative;
  padding: 40px;
  margin: 20px;
  ${({ translateX }) => translateX && `transform: translateX(${translateX}px);`};

  .wizzard {
    position: absolute;
    left: -10px;
    top: 5px;
    /* width: 0; */
  }

  .m-title {
    font-weight: 600;
    color: ${colors.cflowerBlue};
  }

  .h-padding {
    padding: 0 40px;
  }

  .children {
    ${({ $isLoading }) =>
      $isLoading &&
      css`
        * {
          cursor: wait;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          cursor: wait;
          z-index: 1000000;
        }
      `}
  }

  .cydea-select {
    min-width: unset;
  }

  .error {
    text-align: center;
    p,
    span {
      color: ${colors.error};
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 30px;
    margin: 10px;
    .h-padding {
      padding: 0 30px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 20px;
    .wizzard {
      display: none;
    }
    .h-padding {
      padding: 0 20px;
    }
  }
`;

// @ts-ignore
Container.setAppElement('#root');

interface ModalProps extends Omit<Props, 'onRequestClose'> {
  translateX?: number;
  isLoading?: boolean;
  center?: boolean;
  noCloseIcon?: boolean;
  onRequestClose: (a: { hook?: 'cross' | 'background' }) => void;
  disableAnalytics?: boolean;
  isWizzard?: boolean;
}

const el = document.createElement('div');
el.className = 'iosFixedFix';

export const Modal: React.FC<ModalProps> = ({
  children,
  translateX = 0,
  isLoading,
  center = true,
  noCloseIcon,
  onRequestClose,
  disableAnalytics,
  isWizzard,
  ...other
}) => {
  const parentSelector = () => document.querySelector('#main');

  return (
    <Container
      parentSelector={parentSelector as any}
      translateX={translateX}
      $isLoading={isLoading}
      // @ts-ignore
      overlayClassName={center ? 'modalOverlay-center' : 'modalOverlay'}
      onRequestClose={() => {
        onRequestClose({ hook: 'background' });
        if (!disableAnalytics) {
          mpEvent(MPEvents.ModalCanceled, {
            reason: 'background',
          });
        }
      }}
      {...other}
    >
      {!noCloseIcon && (
        <CloseIcon
          onClick={() => {
            onRequestClose({ hook: 'cross' });
            if (!disableAnalytics) {
              mpEvent(MPEvents.ModalCanceled, {
                reason: 'background',
              });
            }
          }}
          data-cy="close-modal-button"
        />
      )}
      {isWizzard && (
        <div className="h-padding wizzard">
          <img style={{ transform: 'scale(0.4)' }} src={wandIcon} />
        </div>
      )}
      <div className="children" data-cy="modal">
        {children}
      </div>
    </Container>
  );
};
