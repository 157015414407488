import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import colors from 'src/theme/colors';
import { FractionBarValue } from 'src/components/widgets/types';

const Wrap = styled(motion.div)<{ $flex: number; $disabled?: boolean }>`
  flex: ${({ $flex }) => $flex};
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    bottom: -10px;
  }

  .flabel {
    white-space: nowrap;
    position: absolute;
    font-size: 12px;
    color: ${colors.prussianBlue};
    opacity: 0;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      cursor: default;
    `}
`;

const Bar = styled(motion.div)<{ $color: string }>`
  background-color: ${({ $color }) => $color};
  height: 100%;
  transform: skewX(-35deg);
  box-sizing: border-box;
  border: 0px solid transparent;
`;

const barVariants = {
  hidden: { outline: '0px solid transparent' },
  visible: { outline: '2px solid #000', transition: { duration: 0 } },
};

interface FractionProps {
  value: FractionBarValue;
  labelBottom?: boolean;
  disabled?: boolean;
}

export const Fraction: React.FC<FractionProps> = ({ value, labelBottom, disabled }) => {
  const angleInRadians = (-55 * Math.PI) / 180;
  const distance = labelBottom ? -20 : 20;
  const baseX = distance * Math.cos(angleInRadians);
  const baseY = distance * Math.sin(angleInRadians);

  const flabelVariants = {
    visible: (custom = { x: baseX, y: baseY }) => ({
      x: custom.x,
      y: custom.y,
      opacity: 1,
      transition: {
        x: { duration: 0.2 },
        y: { duration: 0.2 },
        opacity: { duration: 0.1 },
      },
    }),
    hidden: (custom = { x: baseX / 2, y: baseY / 2 }) => ({
      x: custom.x,
      y: custom.y,
      opacity: 0,
      transition: { opacity: { duration: 0.1 } },
    }),
  };

  const [isHovered, setIsHovered] = useState(false);
  const [customPosition, setCustomPosition] = useState({ x: baseX, y: baseY });
  const wrapRef = React.useRef<HTMLDivElement>(null);
  const labelRef = React.useRef<HTMLSpanElement>(null);

  const handleHoverStart = () => {
    setIsHovered(true);
  };

  const handleHoverEnd = () => {
    setIsHovered(false);
  };

  useEffect(() => {
    if (isHovered && wrapRef.current && labelRef.current) {
      const wrapRect = wrapRef.current.getBoundingClientRect();
      const labelRect = labelRef.current.getBoundingClientRect();
      const container = wrapRef.current.parentElement;

      if (container) {
        const containerRect = container.getBoundingClientRect();

        // Calculate the projected right edge of the label within the container
        const projectedRight = wrapRect.left - containerRect.left + baseX + labelRect.width;

        let adjustedX = baseX;

        if (projectedRight > containerRect.width) {
          adjustedX = baseX - (projectedRight - containerRect.width) - 10; // Adjust with extra padding
        }

        setCustomPosition({ x: adjustedX, y: baseY });
      }
    } else {
      setCustomPosition({ x: baseX, y: baseY });
    }
  }, [isHovered]);

  return (
    <Wrap
      ref={wrapRef}
      onMouseEnter={handleHoverStart}
      onMouseLeave={handleHoverEnd}
      $flex={value.value}
      $disabled={disabled}
    >
      {!disabled && (
        <motion.span
          ref={labelRef}
          className="flabel"
          variants={flabelVariants}
          initial="hidden"
          animate={isHovered ? 'visible' : 'hidden'}
          custom={customPosition}
        >
          {value.label} ({value.value}%)
        </motion.span>
      )}
      <Bar
        variants={disabled ? undefined : barVariants}
        animate={isHovered ? 'visible' : 'hidden'}
        $color={disabled ? colors.cflowerBlue : value.color}
      />
    </Wrap>
  );
};
