import { ReactComponent as chevronUp } from 'src/assets/chevronUp.svg';
import { ReactComponent as chevronDown } from 'src/assets/chevronDown.svg';
import styled, { css } from 'styled-components';
import colors from 'src/theme/colors';

export const ChevronUp = styled(chevronUp)<{ active?: boolean }>`
  ${({ active }) =>
    active &&
    css`
      fill: ${colors.prussianBlue};
    `};
`;

export const ChevronDown = styled(chevronDown)<{ active?: boolean }>`
  ${({ active }) =>
    active &&
    css`
      fill: ${colors.prussianBlue};
    `};
`;
