import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';

interface DivProps {
  $completeness?: number;
}

const Div = styled.div<DivProps>`
  width: 6px;
  height: calc(100% + 10px);
  position: relative;
  transform: translateY(2px);
  display: flex;
  justify-content: center;

  background: repeating-linear-gradient(
    0deg,
    transparent,
    transparent 2px,
    ${colors.cflowerBlue} 2px,
    ${colors.cflowerBlue} 10px
  );

  .fill {
    width: 15px;
    height: ${({ $completeness }) => $completeness}%;
    background: ${colors.gradient};
  }
`;

interface DashProps {
  completeness?: number;
}

export const Dash: React.FC<DashProps> = ({ completeness = 0 }) => {
  return (
    <Div $completeness={completeness}>
      <div className="fill"></div>
    </Div>
  );
};
