import React, { forwardRef, MouseEventHandler } from 'react';
import classNames from 'classnames';
import { Item, ItemContent, IconWrapper, SuffixWrapper, PrefixWrapper, InnerItem } from './styled';
import { useNavigate, useLocation } from 'react-router';
import { useSidebar } from 'src/state/sidebar';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { Link } from 'react-router-dom';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';

export interface Props {
  children?: React.ReactNode;
  className?: string;
  Icon?: React.FC<{ isActive?: boolean }>;
  active?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  firstchild?: boolean;
  popperarrow?: boolean;
  path?: string;
  onClick?: (e?: MouseEventHandler<HTMLLIElement>) => void;
  dataCy?: string;
}

const MenuItem: React.ForwardRefRenderFunction<unknown, Props> = (
  { children, className, path, Icon, active, prefix, suffix, firstchild, popperarrow, onClick, dataCy, ...rest },
  ref,
) => {
  const menuItemRef = (ref as any) || React.createRef<HTMLLIElement | null>();
  const isMobile = useBreakpoints('sm');
  const navigate = useNavigate();
  const { setToggled } = useSidebar();
  const { pathname } = useLocation();

  const isActive = pathname.startsWith(path || '');

  return (
    <Link to={path || ''}>
      <Item
        data-cy={dataCy}
        onClick={() => {
          if (path) {
            if (typeof children === 'string') {
              mpEvent(MPEvents.SideMenuClick, { value: children, tags: ['NAVIGATION'] });
            }
            if (isMobile) {
              navigate(path);
              setToggled(false);
            } else {
              navigate(path);
            }
          }
          if (onClick) {
            onClick();
          }
        }}
        ref={menuItemRef}
        className={classNames('', className, { active })}
        {...rest}
      >
        <InnerItem active={isActive} tabIndex={0} role="button">
          {Icon ? (
            <IconWrapper>
              <Icon isActive={isActive} />
            </IconWrapper>
          ) : null}

          {prefix ? <PrefixWrapper>{prefix}</PrefixWrapper> : null}
          <ItemContent active={isActive}>{children}</ItemContent>
          {suffix ? <SuffixWrapper>{suffix}</SuffixWrapper> : null}
        </InnerItem>
      </Item>
    </Link>
  );
};

export default forwardRef<unknown, Props>(MenuItem);
