import React, { Dispatch, SetStateAction, useState } from 'react';

interface SidebarContextInterface {
  changeActiveSidebar: (number: number) => void;
  toggled: boolean;
  collapsed: boolean;
  setToggled: Dispatch<SetStateAction<boolean>>;
  setCollapsed: Dispatch<SetStateAction<boolean>>;
}

const SidebarContext = React.createContext<Partial<SidebarContextInterface>>({});

const SidebarProvider = (props: any) => {
  const [toggled, setToggled] = useState(false);
  const [collapsed, setCollapsed] = useState(false);

  return (
    <SidebarContext.Provider
      value={{
        toggled,
        collapsed,
        setCollapsed,
        setToggled,
      }}
      {...props}
    />
  );
};

const useSidebar = () => {
  const context = React.useContext(SidebarContext);
  if (context === undefined) {
    throw new Error('useSidebar must be used within a SidebarProvider');
  }
  return context as SidebarContextInterface;
};

export { SidebarProvider, useSidebar };
