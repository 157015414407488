import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { ListIconGrey } from './icons';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface EPScenarioLastEditedProps {
  scenario: {
    id: string;
    name: string;
  };
  assessment: {
    id: string;
  };
  isToday?: boolean;
}

export const EPScenarioLastEdited: React.FC<EPScenarioLastEditedProps> = ({ assessment, scenario, isToday }) => {
  return (
    <Base
      iconComponent={<ListIconGrey />}
      constentComponent={
        <Text $isPast={!isToday}>
          Risk Scenario{' '}
          <Link
            to={`/risk-assessments/${assessment.id}/scenario/${scenario.id}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['Timeline'],
                },
                destination: {
                  value: ['Scenario'],
                  params: {
                    id: scenario.id,
                  },
                },
                tags: ['TIMELINE', 'SCENARIO'],
              })
            }
          >
            <TextLink $isPast={!isToday}>{scenario.name}</TextLink>
          </Link>{' '}
          last edited.
        </Text>
      }
    />
  );
};
