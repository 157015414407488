import { Variable } from 'src/api/types';
import { StandardVariableValues } from './types';
import _ from 'lodash';

export const genStandardVariableValues = (variables: Variable[]): StandardVariableValues => {
  return {
    annualRevenue: variables.find((v) => v.id === 500001)?.value || null,
    numberOfStaff: variables.find((v) => v.id === 500002)?.value || null,
    annualWageBill: variables.find((v) => v.id === 500003)?.value || null,
    avgWorkingDays: variables.find((v) => v.id === 500004)?.value || null,
    annualCyberSpend: variables.find((v) => v.id === 500005)?.value || null,
    avgStaffHardwareCost: variables.find((v) => v.id === 500006)?.value || null,
    numberOfCustomers: variables.find((v) => v.id === 500007)?.value || null,
    avgContractValue: variables.find((v) => v.id === 500008)?.value || null,
    numberOfDataRecords: variables.find((v) => v.id === 500009)?.value || null,
    releaseCycleCost: variables.find((v) => v.id === 500010)?.value || null,
    releaseCycleLength: variables.find((v) => v.id === 500011)?.value || null,
    researchAndDevelopmentBudget: variables.find((v) => v.id === 500012)?.value || null,
    avgCostToProcureSupplier: variables.find((v) => v.id === 500013)?.value || null,
    avgInvoiceValue: variables.find((v) => v.id === 500014)?.value || null,
    largestRegularInvoiceValue: variables.find((v) => v.id === 500015)?.value || null,
    cyberInsuranceLimit: variables.find((v) => v.id === 500016)?.value || null,
    cyberInsuranceExcess: variables.find((v) => v.id === 500017)?.value || null,
    cyberInsurancePremium: variables.find((v) => v.id === 500018)?.value || null,
  };
};

export type GetIsStandardVariablesComplete = (
  variables: StandardVariableValues | null,
  params: { developsSoftware: boolean; cyberInsurance: boolean },
) => boolean;

export const getIsStandardVariablesComplete: GetIsStandardVariablesComplete = (
  variables,
  { developsSoftware, cyberInsurance },
) => {
  if (!variables) {
    return false;
  }
  let testVars = _.cloneDeep(variables);
  if (!developsSoftware) {
    testVars = _.omit(testVars, ['releaseCycleCost', 'releaseCycleLength', 'researchAndDevelopmentBudget']);
  }
  if (!cyberInsurance) {
    testVars = _.omit(testVars, ['cyberInsuranceLimit', 'cyberInsuranceExcess', 'cyberInsurancePremium']);
  }

  return Object.values(testVars).every((v) => !_.isNil(v));
};
