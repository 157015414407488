import _ from 'lodash';
import {
  AuditAction,
  RiskConsequences,
  RiskEvents,
  RiskSources,
  riskConsequencesNames,
  riskEventsNames,
  riskSourcesNames,
} from 'src/api/types';

export const constantsNaming = (key: string, value: string | number | null): string => {
  if (_.isNil(value)) return '[empty]';
  switch (key) {
    case 'Event':
      return riskEventsNames[parseInt(String(value)) as RiskEvents];
    case 'Consequence':
      return riskConsequencesNames[parseInt(String(value)) as RiskConsequences];
    case 'Source':
      return riskSourcesNames[parseInt(String(value)) as RiskSources];

    default:
      return String(value) || '[empty]';
  }
};

type ComposeSentence = (actions: AuditAction[] | null) => string;

export const composeSentence: ComposeSentence = (actions) => {
  if (!actions) return '';

  let sentence = `Changed `;

  actions.forEach((el, idx) => {
    const str = `${el.field_name}`;

    if (idx === 0) {
      sentence += str;
    } else {
      sentence += `, ${str}`;
    }

    // if (idx === actions.length - 1) {
    //   sentence += '.';
    // }
  });

  return sentence;
};
// export const composeSentence: ComposeSentence = (actions) => {
//   if (!actions) return '';

//   let sentence = `Changed `;

//   actions.forEach((el, idx) => {
//     const str = `${el.field_name} from ${constantsNaming(el.field_name, el.before)} to ${constantsNaming(
//       el.field_name,
//       el.after,
//     )}`;

//     if (idx === 0) {
//       sentence += str;
//     } else {
//       sentence += `, ${str}`;
//     }

//     if (idx === actions.length - 1) {
//       sentence += '.';
//     }
//   });

//   return sentence;
// };
