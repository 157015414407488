import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { PageContainer, Poppins, Spacer, TableSkeleton } from 'src/common';
import { queryCache } from 'react-query';
import Button from 'src/components/form/Button';
import _ from 'lodash';
import OrgRecursiveTable from 'src/components/OrgRecursiveTable';
import { OrganisationWorkspace, QKeys } from 'src/api/types';
import { useAuth } from 'src/state/auth';
import { Invites, Requests } from './comps';
import { OrganisationCreateModal, WorkspaceCreateModal } from 'src/components/modals';

const Div = styled(PageContainer)`
  .head {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;

    &__buttons {
      display: flex;
      gap: 14px;
      grid-gap: 14px;
    }
  }
`;

const OrganisationHierarchyPage = () => {
  const [organisations, setOrganisations] = useState<{
    organisations: OrganisationWorkspace[];
    myOrganisations: OrganisationWorkspace[];
    myRequests: OrganisationWorkspace[];
  }>({ organisations: [], myOrganisations: [], myRequests: [] });

  const [isOrganisationCreateModal, setIsOrganisationCreateModal] = useState(false);
  const [isWorspaceCreateModal, setIsWorspaceCreateModal] = useState(false);
  const { user, nav, userPermissions, organisations: userOranisations, isOrgsLoading } = useAuth();
  useGaEventPageView('Cydea | Organisations');

  useEffect(() => {
    nav.setNavLevel(1);

    if (userOranisations.length) {
      const myOrganisations = userOranisations.filter((el) => user?.workspaces?.some((o) => o.id === el.id));
      const myRequests = userOranisations.filter((el) => user?.requests?.some((o) => o.id === el.id));

      setOrganisations({
        organisations: userOranisations,
        myOrganisations,
        myRequests: myRequests,
      });
    }
  }, [userOranisations, user]);

  const resetCache = () => {
    queryCache.invalidateQueries([QKeys.Organisations]);
    queryCache.invalidateQueries(QKeys.User);
    queryCache.invalidateQueries([QKeys.OrgHierarchy]);
  };

  return (
    <>
      <Meta title={`Cydea | Organisations`} feedbackScript />
      {isOrganisationCreateModal && <OrganisationCreateModal onClose={() => setIsOrganisationCreateModal(false)} />}
      {isWorspaceCreateModal && <WorkspaceCreateModal onClose={() => setIsWorspaceCreateModal(false)} />}
      <Div>
        <div className="head">
          <Poppins px={32} weight={600}>
            Organisations
          </Poppins>
          {!isOrgsLoading && (
            <div className="head__buttons">
              {userPermissions.app.platform.admin ? (
                <>
                  <Button secondary onClick={() => setIsOrganisationCreateModal(true)} $constWidth={300}>
                    + CREATE ORGANISATION
                  </Button>
                  <Button secondary onClick={() => setIsWorspaceCreateModal(true)} $constWidth={300}>
                    + CREATE WORKSPACE
                  </Button>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </div>
        {!isOrgsLoading ? (
          <>
            <Invites onSuccess={resetCache} />
            <Requests myRequests={organisations.myRequests} />
            <Spacer $px={32} />
            <div
              className="styled-scroll"
              css={`
                overflow: auto;
              `}
            >
              <OrgRecursiveTable orgData={organisations} />
            </div>
          </>
        ) : (
          <>
            <Spacer $px={32} />
            <TableSkeleton />
          </>
        )}
      </Div>
    </>
  );
};

export default OrganisationHierarchyPage;
