import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconClear: React.FC<Props> = ({ isActive }) => {
  return (
    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
      <path
        fill={isActive ? colors.red : colors.cflowerBlue}
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      ></path>
    </svg>
  );
};
