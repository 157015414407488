import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { UserOptionType } from 'src/api/types';
import { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { TableUserItem } from 'src/common';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

type UseColumns = (a: {
  data?: UserOptionType[];
  defaultColumns?: string[];
  onInviteClick: (a: { id?: string; text: string }) => void;
}) => {
  columns: ColumnDef<UserOptionType>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

export const usePlaceholdersColumns: UseColumns = ({ data = [], defaultColumns = ['name'], onInviteClick }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.wsPeople}-columns`, defaultColumns);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<UserOptionType>[] = [
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.text,
        cell: ({ row }) => {
          return <TableUserItem name={row.original.text} />;
        },
        sortingFn: sortingFns.text,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          return (
            <div css="display: flex;grid-gap: 8px">
              {<GTTdm onInviteClick={() => onInviteClick({ id: row.original.id, text: row.original.text })} />}
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[{ key: 'name', title: 'Name' }]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
  };
};
