import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconEdit: React.FC<Props> = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill="none">
      <g clipPath="url(#clip0_998_1339)">
        <path
          d="M12.5953 1.58181L11.4172 0.403689C10.879 -0.134566 10.0032 -0.13454 9.465 0.403689L9.00391 0.864808L12.1342 3.99531L12.5953 3.5342C13.1348 2.99467 13.1348 2.12139 12.5953 1.58181Z"
          fill={isActive ? colors.red : colors.cflowerBlue}
        />
        <path
          d="M0.558895 9.56583L0.00637087 12.5498C-0.0164553 12.6731 0.0228494 12.7997 0.111539 12.8884C0.200329 12.9772 0.327028 13.0165 0.450198 12.9936L3.4339 12.4411L0.558895 9.56583Z"
          fill={isActive ? colors.red : colors.cflowerBlue}
        />
        <path
          d="M8.46428 1.40345L0.96875 8.89956L4.099 12.03L11.5945 4.53396L8.46428 1.40345Z"
          fill={isActive ? colors.red : colors.cflowerBlue}
        />
      </g>
      <defs>
        <clipPath id="clip0_998_1339">
          <rect width="13" height="13" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
