import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { Poppins } from 'src/common';
import { GridContainer } from '../../GridContainer';
import _ from 'lodash';
import { MultiSelect } from 'src/components/form/Select';
import CSelect, { Error } from 'src/components/form/Select/Select';
import { useQuery } from 'react-query';
import { getFrameworksLibraries } from 'src/api/frameworklibraries';
import { QKeys } from 'src/api/types';
import Button from 'src/components/form/Button';
import { ControlsModal } from '../../ControlsModal';
import { CyberFramework, cyberFrameworkOptions, WizardFormValues } from 'src/api/types/wizard';

export const Regulations = () => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<WizardFormValues>();
  const [frameworkId, setFrameworkId] = useState<string | undefined>(undefined);
  const [isControlsModalOpen, setIsControlsModalOpen] = useState(false);

  const { data: frameworks = [] } = useQuery(QKeys.Frameworks, getFrameworksLibraries);

  const legislationTouched = _.get(touched, 'regulations.legislation');
  const legislationError = _.get(errors, 'regulations.legislation');

  const controlFrameworksTouched = _.get(touched, 'regulations.controlFrameworks');
  const controlFrameworksError = _.get(errors, 'regulations.controlFrameworks');

  const primaryControlFrameworkTouched = _.get(touched, 'regulations.primaryControlFramework');
  const primaryControlFrameworkError = _.get(errors, 'regulations.primaryControlFramework');

  const cyberFrameworkOptionsComposed = [
    ...cyberFrameworkOptions,
    ...values.regulations.controlFrameworksOther.map((el) => {
      return {
        label: el,
        value: el,
      };
    }),
  ] as { label: string; value: string | CyberFramework; extId?: string }[];

  useEffect(() => {
    if (values.regulations.primaryControlFramework) {
      const framework = cyberFrameworkOptionsComposed.find(
        (o) => o.value === values.regulations.primaryControlFramework,
      );
      if (framework) {
        const uxId = frameworks.find((el) => el.external_id === framework.extId)?.ux_id;
        setFrameworkId(uxId);
      }
    }
  }, [frameworks, values.regulations.primaryControlFramework]);

  return (
    <>
      {isControlsModalOpen && <ControlsModal onClose={() => setIsControlsModalOpen(false)} frameworkId={frameworkId} />}
      <GridContainer>
        <div className="row">
          <Poppins color="davysGrey" className="row__title" px={14}>
            Which legislation or regulations are they subject to?
          </Poppins>
          <div className="row__end">
            <MultiSelect
              className="compact-input"
              options={[]}
              onChange={() => {}}
              value={[]}
              // placeholder="Add..."
              // selectedLabel="Added"
              createable={{
                values: values.regulations.legislation,
                onAdd: (value) =>
                  setFieldValue(
                    'regulations.legislation',
                    _.orderBy([...values.regulations.legislation, value], [(el) => el.toLowerCase()]),
                  ),
                onClear: () => setFieldValue('regulations.legislation', []),
                onRemove: (value) => {
                  setFieldValue(
                    'regulations.legislation',
                    values.regulations.legislation.filter((v) => v !== value),
                  );
                },
              }}
              // @ts-ignore
              error={!!legislationTouched && legislationError}
              onBlur={() => setFieldTouched(`regulations.legislation`, true)}
              dataCy="regulations-legislation"
            />
          </div>
        </div>

        <div className="row">
          <Poppins color="davysGrey" className="row__title" px={14}>
            Which cyber frameworks do you use?
          </Poppins>
          <div className="row__end">
            <MultiSelect<CyberFramework>
              className="compact-input"
              createable={{
                values: values.regulations.controlFrameworksOther,
                onAdd: (value) =>
                  setFieldValue(
                    'regulations.controlFrameworksOther',
                    _.orderBy([...values.regulations.controlFrameworksOther, value], [(el) => el.toLowerCase()]),
                  ),
                onClear: () => setFieldValue('regulations.controlFrameworksOther', []),
                onRemove: (value) => {
                  setFieldValue(
                    'regulations.controlFrameworksOther',
                    values.regulations.controlFrameworksOther.filter((v) => v !== value),
                  );
                },
              }}
              options={cyberFrameworkOptions}
              onChange={(value) => setFieldValue('regulations.controlFrameworks', value)}
              value={values.regulations.controlFrameworks}
              // @ts-ignore
              error={!!controlFrameworksTouched && controlFrameworksError}
              onBlur={() => setFieldTouched(`regulations.controlFrameworks`, true)}
              dataCy="regulations-use-frameworks"
            />
          </div>
        </div>

        <div className="row">
          <Poppins color="davysGrey" className="row__title" px={14}>
            What is your primary cyber framework?
          </Poppins>
          <div className="row__end">
            <CSelect
              className="cydea-select compact-input"
              options={_.orderBy(cyberFrameworkOptionsComposed, [(el) => el.label.toLowerCase()])}
              onChange={(o: { value: CyberFramework }) => {
                setFieldValue('regulations.primaryControlFramework', o.value);
                if (values.regulations.primaryControlFramework !== o.value) {
                  setFieldValue('regulations.primaryFrameworkControls', []);
                }
              }}
              value={cyberFrameworkOptionsComposed.find((o) => o.value === values.regulations.primaryControlFramework)}
              error={Boolean(primaryControlFrameworkTouched && primaryControlFrameworkError)}
              onBlur={() => setFieldTouched(`regulations.primaryControlFramework`, true)}
              data-cy="regulations-primary-framework"
            />
            {primaryControlFrameworkTouched && primaryControlFrameworkError && (
              <Error>{primaryControlFrameworkError}</Error>
            )}
          </div>
        </div>

        {frameworkId && (
          <div className="row">
            <Poppins color="davysGrey" className="row__title" px={14}>
              From your primary framework, which controls do you have in place?
            </Poppins>
            <div className="row__end">
              <div className="row__end__add-controls">
                <Poppins css={'flex:1'}>{values.regulations.primaryFrameworkControls.length} selected</Poppins>
                <Button primary className="compact-input add-controls-btn" onClick={() => setIsControlsModalOpen(true)}>
                  SELECT
                </Button>
              </div>
              {/* <MultiSelect<string>
            className="compact-input"
            options={controls.map((el) => ({
              label: el.shortname || el.name,
              value: el.ux_id,
            }))}
            onChange={(value) => setFieldValue('regulations.primaryFrameworkControls', value)}
            value={values.regulations.primaryFrameworkControls}
            // @ts-ignore
            error={!!secondaryControlFrameworksTouched && secondaryControlFrameworksError}
            onBlur={() => setFieldTouched(`regulations.primaryFrameworkControls`, true)}
            dataCy="regulations-primary-framework-controls"
          /> */}
            </div>
          </div>
        )}
      </GridContainer>
    </>
  );
};
