import { Incident } from 'src/api/types';
import { DATE_FORMAT_PICKER, MAX_FORMULA_INT } from 'src/config';
import * as Yup from 'yup';
import { formatUrl } from 'src/utils/misc';
import moment from 'moment';

export const incidentValidationSchema = Yup.object({
  refId: Yup.string().required('Required').max(100, 'Max 100 characters').nullable(),
  name: Yup.string().required('Required').max(200, 'Max 200 characters').nullable(),
  source: Yup.string()
    .nullable()
    .max(200, 'Max 200 characters')
    // @ts-ignore
    .test('source', 'Must be a valid url', (source) => {
      if (!source) return true;
      return formatUrl(source);
    }),
  description: Yup.string().nullable().max(2000, 'Max 2000 characters'),
  date: Yup.string()
    .test('date', 'Invalid Date', (date) => {
      if (!date) return true;
      if (typeof date === 'string') {
        return moment(date, [DATE_FORMAT_PICKER, moment.ISO_8601]).isValid();
      }
      return false;
    })
    .nullable(),
  owner: Yup.string().nullable().max(200, 'Max 200 characters'),
  impact: Yup.string().nullable().max(2000, 'Max 2000 characters'),
  financialImpactUpper: Yup.number()
    .nullable()
    .min(1, 'Minimum is 1')
    .max(MAX_FORMULA_INT, `Maximum number is ${MAX_FORMULA_INT}`),
  financialImpactLower: Yup.number()
    .nullable()
    .min(1, 'Minimum is 1')
    .max(MAX_FORMULA_INT, `Maximum number is ${MAX_FORMULA_INT}`),
  type: Yup.string()
    .nullable()
    .test('type', 'Invalid type', (type) => {
      if (!type) return true;
      return ['Controlled', 'Uncontrolled'].includes(type);
    }),
});

export const incidentInitialValues: Omit<Incident, 'scenarios' | 'scenariosCount'> = {
  id: '',
  refId: '',
  name: '',
  source: null,
  description: null,
  date: null,
  owner: null,
  impact: null,
  financialImpactUpper: null,
  financialImpactLower: null,
  type: null,
};
