import React, { useState } from 'react';
import { Modal } from '../comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import colors from 'src/theme/colors';
import Button from 'src/components/form/Button';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { updateAssessment } from 'src/api/assessment';
import { Assessment } from 'src/api/types';
import { Guard } from '../Guard';
import { Row } from './comps';
import { getPercentage } from 'src/utils/misc';
import { convertValuesToNumbers, parseInitialValues } from './util';
import _ from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 960px;
  min-height: 200px;

  .divider {
    position: absolute;
    left: 0;
    width: 100%;
    border-bottom: 1px solid ${colors.stroke};
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .rtm-btns {
    display: grid;
    /* grid-template-columns: 1fr 1fr;
    grid-gap: 20px; */
  }

  .err-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .rtm-btns {
      grid-gap: 10px;
    }
  }
`;

const validationSchema = Yup.array().of(
  Yup.object()
    .shape(
      {
        loss: Yup.number()
          .min(1, 'Minimum value is 1')
          .when(['frequency', 'years'], {
            is: (frequency, years) => frequency != null || years != null,
            then: Yup.number().required('Required'),
            otherwise: Yup.number().notRequired().nullable(),
          }),
        frequency: Yup.number()
          .min(1, 'Minimum value is 1')
          .when(['loss', 'years'], {
            is: (loss, years) => loss != null || years != null,
            then: Yup.number().required('Required'),
            otherwise: Yup.number().notRequired().nullable(),
          }),
        years: Yup.number()
          .min(1, 'Minimum value is 1')
          .when(['loss', 'frequency'], {
            is: (loss, frequency) => loss != null || frequency != null,
            then: Yup.number().required('Required'),
            otherwise: Yup.number().notRequired().nullable(),
          }),
      },
      [
        ['loss', 'frequency'],
        ['loss', 'years'],
        ['frequency', 'years'],
      ],
    )
    .nullable(),
);

interface IRiskToleranceModalV2Props {
  assessment: Assessment;
  onClose: () => void;
  refetchAssessment: () => Promise<any>;
  readOnly?: boolean;
}

export const RiskToleranceModalV2: React.FC<IRiskToleranceModalV2Props> = ({
  onClose,
  assessment,
  refetchAssessment,
  readOnly,
}) => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);

  const initialValues = parseInitialValues(assessment.risk_tolerance);

  // console.log('risk_tolerance', assessment.risk_tolerance);
  // console.log('initialValues', initialValues);

  const [update, { error }] = useMutation(updateAssessment, {
    onSuccess: () => {
      refetchAssessment();
      onClose();
    },
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (val) => {
      const mappedNew = val.map((el) => {
        const numericEl = convertValuesToNumbers(el);
        const validPercentageCalc = _.isNumber(numericEl.frequency) && _.isNumber(numericEl.years);

        if (!validPercentageCalc) return undefined;
        return {
          x: numericEl.loss,
          y: getPercentage(numericEl.frequency, numericEl.years) / 100,
          conf: numericEl,
        };
      });

      const filteredData = _.filter(mappedNew, (item) => {
        return (
          item && !_.some(item, (value) => _.isNil(value) || (_.isObject(value) && _.some(value, (v) => _.isNil(v))))
        );
      });

      const stringified = JSON.stringify(filteredData);

      return update({ id: assessment.id, risk_tolerance: stringified });
    },
  });

  const { values, isValid, dirty, isSubmitting, handleSubmit } = formik;

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen={true}>
          <Poppins className="m-title" px={28}>
            Risk Tolerance
          </Poppins>
          <Spacer $px={10} />
          <Poppins px={16} color="cflowerBlue">
            How many times a year would this monetary loss be acceptable?
          </Poppins>
          <Spacer $px={40} />
          {/* @ts-ignore */}
          <FormikProvider value={formik}>
            {values.map((_, idx) => (
              <div key={idx}>
                <div className="divider"></div>
                <Row idx={idx} readOnly={readOnly} />
              </div>
            ))}
          </FormikProvider>
          <>
            {/* {!containsMinimumTwoValidObjects(values) && dirty && (
              <Poppins px={14} color="red">
                Please fill in at least two rows
              </Poppins>
            )} */}

            {!readOnly && (
              <>
                <Spacer $px={20} />
                <div className="rtm-btns">
                  <Button
                    primary
                    disabled={isSubmitting || !dirty || !isValid}
                    onClick={() => {
                      handleSubmit();
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Save',
                        modal: 'Risk tolerance modal',
                      });
                    }}
                    data-cy="submit-btn"
                  >
                    SAVE
                  </Button>
                </div>
              </>
            )}
            {error && (
              <div className="err-container">
                <Spacer $px={10} />
                <Poppins px={14} color="red">
                  {/* @ts-ignore */}
                  {error.message || 'Something went wrong'}
                </Poppins>
              </div>
            )}
          </>
        </StyledModal>
      }
    />
  );
};
