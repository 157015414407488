import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title: string;
  description?: string;
  feedbackScript?: boolean;
}

const Meta: React.FC<Props> = ({ title = 'Cydea', description = 'Welcome to Cydea!', feedbackScript }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {feedbackScript && <script src="https://w.appzi.io/w.js?token=xS6Yx"></script>}
    </Helmet>
  );
};

export default Meta;
