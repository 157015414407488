import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import colors from '../../theme/colors';

const StyledMain = styled.main`
  position: relative;
  background: ${colors.cultured};
  height: 100vh;
  @supports (height: 100svh) {
    height: 100svh;
  }
`;

const Main: React.FC = ({ children }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { pathname } = useLocation();

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [pathname]);
  return (
    <StyledMain ref={ref} id="main">
      {children}
    </StyledMain>
  );
};

export default Main;
