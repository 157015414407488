import React from 'react';
import { useFormikContext } from 'formik';
import { Poppins } from 'src/common';
import { GridContainer } from '../../GridContainer';
import CurrencyInput from 'src/components/form/CurrencyInput';
import _ from 'lodash';
import Input from 'src/components/form/Input';
import { WizardFormValues } from 'src/api/types/wizard';

export const SoftwareDev = () => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<WizardFormValues>();

  const releaseCycleCostTouched = _.get(touched, 'softwareDev.releaseCycleCost');
  const releaseCycleCostError = _.get(errors, 'softwareDev.releaseCycleCost');
  const releaseCycleDaysTouched = _.get(touched, 'softwareDev.releaseCycleLength');
  const releaseCycleDaysError = _.get(errors, 'softwareDev.releaseCycleLength');
  const annualRnDInvestmentTouched = _.get(touched, 'softwareDev.researchAndDevelopmentBudget');
  const annualRnDInvestmentError = _.get(errors, 'softwareDev.researchAndDevelopmentBudget');

  return (
    <GridContainer>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you develop your own software?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <Poppins color="davysGrey" px={14}>
            No
          </Poppins>
          <Input
            className="switch"
            type="checkbox"
            checked={values.softwareDev.developsSoftware}
            onChange={(e) => {
              setFieldValue(`softwareDev.developsSoftware`, e.target.checked);
              if (!e.target.checked) {
                setFieldValue(`softwareDev.releaseCycleCost`, null);
                setFieldValue(`softwareDev.releaseCycleLength`, null);
                setFieldValue(`softwareDev.researchAndDevelopmentBudget`, null);
              }
            }}
          />
          <Poppins color="davysGrey" px={14}>
            Yes
          </Poppins>
        </div>
      </div>
      {values.softwareDev.developsSoftware && (
        <>
          <div className="row">
            <Poppins color="davysGrey" className="row__title" px={14}>
              What is the typical cost of a release cycle?
            </Poppins>
            <div className="row__end">
              <CurrencyInput
                className="compact-input"
                value={values.softwareDev.releaseCycleCost}
                currency
                onValueChange={(e) => setFieldValue(`softwareDev.releaseCycleCost`, e.floatValue)}
                onBlur={() => setFieldTouched(`softwareDev.releaseCycleCost`, true)}
                error={Boolean(releaseCycleCostTouched && releaseCycleCostError)}
                helperText={releaseCycleCostTouched && releaseCycleCostError}
                data-cy="softwareDev-release-cycle-cost"
              />
            </div>
          </div>
          <div className="row">
            <Poppins color="davysGrey" className="row__title" px={14}>
              How long is a typical release cycle? (Number of Days)
            </Poppins>
            <div className="row__end">
              <CurrencyInput
                className="compact-input"
                value={values.softwareDev.releaseCycleLength}
                onValueChange={(e) => setFieldValue(`softwareDev.releaseCycleLength`, e.floatValue)}
                onBlur={() => setFieldTouched(`softwareDev.releaseCycleLength`, true)}
                error={Boolean(releaseCycleDaysTouched && releaseCycleDaysError)}
                helperText={releaseCycleDaysTouched && releaseCycleDaysError}
                data-cy="softwareDev-release-cycle-days"
              />
            </div>
          </div>
          <div className="row">
            <Poppins color="davysGrey" className="row__title" px={14}>
              How much is typically invested in R&D annually?
            </Poppins>
            <div className="row__end">
              <CurrencyInput
                className="compact-input"
                value={values.softwareDev.researchAndDevelopmentBudget}
                currency
                onValueChange={(e) => setFieldValue(`softwareDev.researchAndDevelopmentBudget`, e.floatValue)}
                onBlur={() => setFieldTouched(`softwareDev.researchAndDevelopmentBudget`, true)}
                error={Boolean(annualRnDInvestmentTouched && annualRnDInvestmentError)}
                helperText={annualRnDInvestmentTouched && annualRnDInvestmentError}
                data-cy="softwareDev-annual-rnd-investment"
              />
            </div>
          </div>
        </>
      )}
    </GridContainer>
  );
};
