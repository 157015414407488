import { Modal } from 'src/components/modals/comps';
import styled from 'styled-components';
import { SLIDE_HEIGHT } from './util';

export const StyledModal = styled(Modal)`
  width: 1100px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  max-height: calc(100% - 40px);
  overflow-y: auto;
  transition: width 0.3s;
  padding-bottom: 0;

  .children {
    flex: 1;
    overflow-x: hidden;
  }

  .wm {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &__top {
      /* border: 1px solid red; */
    }
    &__content {
      /* border: 1px solid green; */
      flex: 1;
      display: flex;
      margin-bottom: 10px;
    }
    &__bottom {
      /* border: 1px solid blue; */
    }
  }

  .err-container {
    /* position: absolute; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* bottom: 10px; */
    width: 100%;
    transform: translateY(15px);
  }

  .keen-slider__slide {
    overflow: hidden;
    min-height: ${SLIDE_HEIGHT}px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    .compact-input {
      &.MuiTextField-root {
        & input,
        textarea {
          font-size: 14px;
          padding-top: 12px;
          padding-bottom: 12px;
        }
        & input::placeholder,
        textarea::placeholder {
          font-size: 14px;
        }
      }

      &.cydea-select {
        font-size: 14px;
        height: 40px;

        .cydea-select__control {
          height: 40px;
        }
        .cydea-select__option {
          font-size: 14px;
          padding: 0 10px;
        }
        .cydea-select__group-heading {
          font-size: 14px;
        }
        .cydea-select__placeholder {
          font-size: 14px;
        }
      }

      &.add-controls-btn {
        height: 34px;
        min-width: 100px;
        padding: 1px 0.8rem;
        font-size: 14px;
      }
    }
    .ms-value-container {
      height: 40px;
      padding: 0 20px;
    }
  }
`;
