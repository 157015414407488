import React from 'react';
import { Poppins } from 'src/common';
import styled from 'styled-components';

const Div = styled.div`
  display: inline-flex;
  gap: 8px;
  grid-gap: 8px;
  align-items: center;

  .b-icon {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface BaseProps {
  iconComponent: React.ReactNode;
  constentComponent: React.ReactNode;
}

export const Base: React.FC<BaseProps> = ({ iconComponent, constentComponent }) => {
  return (
    <Div>
      <div className="b-icon">{iconComponent}</div>
      {constentComponent}
    </Div>
  );
};
