// @ts-nocheck
import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import colors from 'src/theme/colors';

export const LegendExceedance = () => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 27,
            backgroundColor: colors.red,
            marginRight: 6,
          }}
        ></View>
        <Text style={{ fontSize: 8, fontWeight: 500, color: colors.prussianBlue }}>Loss Exceedance</Text>
      </View>
    </View>
  );
};

export const LegendTolerance = () => {
  return (
    <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 8,
            backgroundColor: colors.cflowerBlue,
            marginRight: 2,
          }}
        ></View>
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 8,
            backgroundColor: colors.cflowerBlue,
            marginRight: 2,
          }}
        ></View>
        <View
          style={{
            height: 2.5,
            borderRadius: 2,
            width: 8,
            backgroundColor: colors.cflowerBlue,
            marginRight: 6,
          }}
        ></View>

        <Text style={{ fontSize: 8, fontWeight: 500, color: colors.prussianBlue }}>Risk Tolerance</Text>
      </View>
    </View>
  );
};
