import React from 'react';
import styled from 'styled-components';

const Div = styled.div`
  display: inline;
  margin-left: 5px;
`;

interface SortIconProps {
  sortOrder: number;
}

export const SortIcon: React.FC<SortIconProps> = ({ sortOrder }) => {
  return (
    <Div>
      <svg width="11" height="15" viewBox="0 0 11 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.659141 6.18177H9.88629C10.0648 6.18177 10.2193 6.11659 10.3497 5.98612C10.48 5.85569 10.5455 5.70124 10.5455 5.52273C10.5455 5.34422 10.4801 5.18984 10.3497 5.05923L5.73612 0.445643C5.60577 0.315359 5.45135 0.25 5.27273 0.25C5.09411 0.25 4.9397 0.315359 4.80923 0.445643L0.195643 5.05923C0.0651783 5.1897 0 5.34422 0 5.52273C0 5.70121 0.0651783 5.85569 0.195643 5.98612C0.326252 6.11659 0.480667 6.18177 0.659141 6.18177Z"
          fill={sortOrder === 1 ? 'black' : '#6A7590'}
        />
        <path
          d="M9.88629 8.81836H0.659141C0.480523 8.81836 0.326108 8.88357 0.195643 9.01389C0.0651783 9.14436 0 9.29877 0 9.47728C0 9.65579 0.0651783 9.81032 0.195643 9.94071L4.80923 14.5542C4.93984 14.6847 5.09426 14.75 5.27273 14.75C5.45121 14.75 5.60577 14.6847 5.73612 14.5542L10.3497 9.94067C10.48 9.81032 10.5455 9.65579 10.5455 9.47725C10.5455 9.29877 10.4801 9.14436 10.3497 9.01386C10.2194 8.88343 10.0648 8.81836 9.88629 8.81836Z"
          fill={sortOrder === -1 ? 'black' : '#6A7590'}
        />
      </svg>
    </Div>
  );
};
