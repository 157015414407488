import Button from 'src/components/form/Button';
import React, { useState } from 'react';
import { Modal, ModalSubTitle } from './comps';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryCache, useMutation } from 'react-query';
import { manageRisk } from '../../api/scenario';
import MaterialInput from 'src/components/form/MaterialInput';
import { Guard } from './Guard';
import { Scenario, Strategy, strategyOptions } from 'src/api/types';
import CSelect from '../form/Select';
import colors from 'src/theme/colors';
import { InputLabel, Poppins, Spacer } from 'src/common';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 566px;
  min-height: 200px;

  .success {
    min-height: 20px;
    display: flex;
    align-items: center;
    text-align: center;
  }
`;

const ErrorText = styled.p`
  color: red;
  font-size: 14px;
`;

const ErrorLabel = styled.div`
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  color: ${colors.error};
  font-weight: 400;
`;

interface ManageRiskModalProps {
  scenarioId: string;
  onClose: () => void;
  scenarioDetails: Scenario;
  subTitle?: {
    title: string;
    value: string;
  };
}

export const ManageRiskModal: React.FC<ManageRiskModalProps> = ({ scenarioId, onClose, scenarioDetails, subTitle }) => {
  const [isManagementNoteTouched, setIsManagementNoteTouched] = useState(false);
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const validationSchema = Yup.object().shape({
    management_strategy: Yup.string()
      .oneOf(['Treat', 'Transfer', 'Tolerate', 'Terminate'], 'Invalid management strategy selected')
      .nullable(true),
    management_note: Yup.string().test('len', 'Management Note too long (maximum 2048 characters)', (val) => {
      if (val && val.length > 2048) {
        setIsManagementNoteTouched(true);
        return false;
      }
      return true;
    }),
  });

  const handleClose = () => {
    onClose();
  };

  const [update] = useMutation(manageRisk, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      handleClose();
    },
    onError: (error: any) => {
      setErrorMessage(error.msg);
    },
  });

  const {
    values,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    handleChange,
    handleSubmit,
    isValid,
    dirty,
    setFieldValue,
  } = useFormik({
    initialValues: {
      management_strategy: scenarioDetails.management_strategy || '',
      management_note: scenarioDetails.management_note || '',
    },
    validationSchema,
    onSubmit: (values) => {
      return update({ ...values, ux_id: scenarioId });
    },
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal isOpen onRequestClose={() => setIsCloseRequested(true)}>
          <Poppins className="m-title" px={28}>
            Risk Management Strategy
          </Poppins>
          {subTitle && <ModalSubTitle title={subTitle.title} value={subTitle.value} />}
          <Spacer $px={20} />

          <form onSubmit={handleSubmit} data-cy="rms">
            <InputLabel>MANAGEMENT STRATEGY</InputLabel>
            <CSelect
              name="management_strategy"
              onChange={(o: { value: Strategy }) => {
                setFieldValue('management_strategy', o.value);
              }}
              onBlur={handleBlur}
              value={strategyOptions.find((o) => o.value === values.management_strategy)}
              placeholder="Control strategy"
              css="width: 100%;"
              options={strategyOptions}
              inputId="control-strategy"
            />
            {errors.management_strategy && touched.management_strategy && (
              <ErrorText>{errors.management_strategy}</ErrorText>
            )}
            <Spacer $px={20} />

            <InputLabel>NOTE</InputLabel>
            <MaterialInput
              id="management_note"
              name="management_note"
              value={values.management_note}
              onChange={handleChange}
              onBlur={handleBlur}
              multiline
              fullWidth
              placeholder="Enter management note"
              rows={3}
              data-cy="note-input"
            />

            {errors.management_note && (touched.management_note || isManagementNoteTouched) && (
              <ErrorText>{errors.management_note}</ErrorText>
            )}
            <Spacer $px={20} />
            <Button
              color="primary"
              disabled={!isValid || isSubmitting}
              type="submit"
              primary
              css="width: 100%;"
              data-cy="add-btn"
              onClick={() =>
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Submit',
                  modal: 'Manage risk modal',
                  tags: ['SCENARIO'],
                })
              }
            >
              {isSubmitting
                ? 'Saving... Please wait'
                : scenarioDetails.management_strategy == null && scenarioDetails.management_note == null
                  ? 'Add'
                  : 'Update'}
            </Button>

            {errorMessage && <ErrorLabel>{errorMessage}</ErrorLabel>}
          </form>
        </StyledModal>
      }
    />
  );
};
