import React, { useRef, useState } from 'react';
import { Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { VersionsDuplicateModal, VersionsPublishModal } from 'src/components/modals';
import { Link } from 'react-router-dom';
import { AssessmentVersion } from 'src/api/types';
import Button from 'src/components/form/Button';
import useVersion from 'src/hooks/useVersion';
import { useColumns } from './useColumns';
import GenericTable from 'src/components/GenericTable';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { encodeQueryParams } from 'src/api/client';
import { TableIds } from 'src/api/types/misc';

const Div = styled.div<{ $top: number }>`
  position: absolute;
  z-index: 10;
  width: 100%;
  top: ${({ $top }) => $top}px;
  border-radius: 3px;
  background: #fff;
  box-shadow: 0px 0px 37px 0px rgba(7, 46, 91, 0.23);
  padding: 23px 27px;
  max-height: 580px;
  overflow-y: auto;

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .head__actions {
      display: flex;
      align-items: center;

      button {
        margin-left: 10px;
      }
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 10px;
  }
`;

const Bg = styled.div`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`;

interface VersionsProps {
  assessmentId: string;
  onClose: () => void;
  versions?: AssessmentVersion[];
  top?: number;
}

const Versions: React.FC<VersionsProps> = ({ assessmentId, onClose, versions = [], top = 60 }) => {
  const [duplicateModalId, setDuplicateModalId] = useState<string | null>(null);
  const [isCompare, setIsCompare] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);
  const { version } = useVersion();

  const ref = useRef<HTMLDivElement>(null);

  const { columns, GTColumnSelectAnchorExported, checked, setChecked } = useColumns({
    data: versions,
    assessmentId,
    onViewClick: onClose,
    onCopyClick: (v) => setDuplicateModalId(v.id as string),
    isCheckerEnabled: isCompare,
  });

  return (
    <>
      {duplicateModalId && <VersionsDuplicateModal onClose={() => setDuplicateModalId(null)} id={duplicateModalId} />}
      {isPublishModalOpen && (
        <VersionsPublishModal onClose={() => setIsPublishModalOpen(false)} assessmentId={assessmentId} />
      )}
      <Bg onClick={onClose} />
      <Div ref={ref} $top={top} className="styled-scroll" data-cy="versions-menu">
        <div className="head">
          <Poppins className="title" px={18} weight={500}>
            Version history
          </Poppins>
          <div className="head__actions">
            {!isCompare && !version && (
              <Button
                primary
                onClick={() => {
                  setIsPublishModalOpen(true);
                  mpEvent(MPEvents.ButtonClick, { button: 'Publish', tags: ['ASSESSMENT'] });
                }}
                $constWidth={150}
                data-cy="publish-btn"
              >
                PUBLISH
              </Button>
            )}
            {versions.length !== 0 && (
              <>
                {isCompare ? (
                  <>
                    <Button
                      secondary
                      onClick={() => {
                        setIsCompare(false);
                        setChecked([]);
                        mpEvent(MPEvents.ButtonClick, { button: 'Compare:cancel', tags: ['ASSESSMENT'] });
                      }}
                      $constWidth={150}
                    >
                      CANCEL
                    </Button>
                    {checked.length < 2 ? (
                      <Button primary disabled $constWidth={150}>
                        DONE
                      </Button>
                    ) : (
                      <Link
                        to={`/compare/assessments/${assessmentId}/${assessmentId}${encodeQueryParams({
                          vaId: checked[0],
                          vbId: checked[1],
                        })}`}
                        onClick={() => mpEvent(MPEvents.ButtonClick, { button: 'Compare:done', tags: ['ASSESSMENT'] })}
                        data-cy="compare-btn-done"
                      >
                        <Button primary $constWidth={150}>
                          DONE
                        </Button>
                      </Link>
                    )}
                  </>
                ) : (
                  <Button
                    primary
                    onClick={() => {
                      setIsCompare(true);
                      mpEvent(MPEvents.ButtonClick, { button: 'Compare', tags: ['ASSESSMENT'] });
                    }}
                    data-cy="compare-btn"
                  >
                    COMPARE
                  </Button>
                )}
              </>
            )}
          </div>
        </div>
        <Spacer $px={30} />
        <GenericTable
          tableId={TableIds.assessmentVersions}
          data={[{ name: '[CURRENT]', id: null }, ...versions]}
          columns={columns}
          GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
          itemHeight={50}
          searchable
        />
      </Div>
    </>
  );
};

export default Versions;
