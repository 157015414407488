import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page } from '@react-pdf/renderer';
import { FooterCompany } from '../../comps';
import { isEqual } from 'lodash';
import { PdfAssessment, User } from 'src/api/types';

interface AssessmentEndProps {
  assessment: PdfAssessment;
  user: User | null;
}

export const AssessmentEnd: React.FC<AssessmentEndProps> = memo(
  ({ assessment, user }) => {
    return (
      // @ts-ignore
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <FooterCompany
          companyName={user?.workspace_name || ''}
          title={assessment.name}
          label="RISK ASSESSMENT"
          companyImage={''}
        />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
