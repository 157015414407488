import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconDuplicate: React.FC<Props> = ({ isActive }) => {
  return (
    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" css="transform: scale(1.2)">
      <path
        fill={isActive ? colors.red : colors.cflowerBlue}
        d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4H8c-1.1 0-1.99.9-1.99 2L6 21c0 1.1.89 2 1.99 2H19c1.1 0 2-.9 2-2V11l-6-6zM8 21V7h6v5h5v9H8z"
      ></path>
    </svg>
  );
};
