import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import colors from './colors';

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: { main: colors.prussianBlue },
      secondary: { main: colors.red },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 450,
        md: 700,
        lg: 1280,
        xl: 1920,
      },
    },
    typography: {
      fontFamily: 'Poppins',
    },
  }),
);

export default theme;
