export enum WizardIntent {
  workspace = 'workspace',
  assessment = 'assessment',
  scenario = 'scenario',
}

export type WizzardWorkspaceType = {
  intent:
    | {
        type: WizardIntent.workspace;
        params: {
          createAssessment: boolean;
        };
      }
    | { type: WizardIntent.assessment }
    | {
        type: WizardIntent.scenario;
        params: {
          assessmentData: { id: string; name: string; review_date: string; description: string; started_date?: string };
        };
      };
};
