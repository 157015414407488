import { AxiosArgs, ThenArgs, OrganisationWorkspace, Plan, UserOrgProfile, OrgTreeItem, OrgUser } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';

type GetOrg = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const getOrg: GetOrg = (id) => {
  const path = `/api/public/api/organisations/${id}?users=true`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetOrgs = (a: {
  domain?: boolean;
  users?: boolean;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace[]>>>>;

export const getOrgs: GetOrgs = (params) => {
  const path = `/api/public/api/organisations${encodeQueryParams(params)}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------
// -------------------------ADMIN------------------------------
// ------------------------------------------------------------

type CreateOrg = (params: {
  name: string;
  primary_contact: string;
  billing_contact: string | null;
  parentOrganisation: string;
  domain: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const createOrg: CreateOrg = (params) => {
  const path = `/api/public/api/organisations`;
  return axios({
    method: 'POST',
    data: encodeBody(params),
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetOrgUsers = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrgUser[]>>>>;

export const getOrgUsers: GetOrgUsers = (id) => {
  const path = `/api/public/api/organisations/admin/${id}/users`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data.users);
};
// ------------------------------------------------------------

type GetOrgAdmin = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const getOrgAdmin: GetOrgAdmin = (id) => {
  const path = `/api/public/api/organisations/admin/${id}?users=true`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateOrg = (params: {
  id: string;
  name: string;
  primary_contact: string;
  billing_contact: string | null;
  plan: Plan;
  admin_id: number;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const updateOrg: UpdateOrg = ({ id, ...params }) => {
  const path = `/api/public/api/organisations/admin/${id}`;
  return axios({
    method: 'PUT',
    data: encodeBody(params),
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------
type RejetcUserJoinRequest = (a: {
  orgId: string;
  requestId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const rejetcUserJoinRequest: RejetcUserJoinRequest = ({ orgId, requestId }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/reject/${requestId}`;
  return axios({
    method: 'PUT',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type InviteToOrg = (a: {
  orgId: string;
  invitees: { owner_id?: string; email: string; name: string }[];
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const inviteToOrg: InviteToOrg = ({ orgId, ...data }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/invite`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type CancelInviteToOrg = (a: {
  orgId: string;
  requestId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const cancelInviteToOrg: CancelInviteToOrg = ({ orgId, requestId }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/invite/${requestId}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetOrgUser = (a: {
  orgId: string;
  userId: string | number;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrgUser>>>>;

export const getOrgUser: GetOrgUser = ({ orgId, userId }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/user/${userId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateOrgUser = (a: {
  params: { orgId: string; userId: number | string };
  data: { name: string; isAdmin: boolean };
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const updateOrgUser: UpdateOrgUser = ({ params, data }) => {
  const path = `/api/public/api/organisations/admin/${params.orgId}/user/${params.userId}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetAllCustomers = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<UserOrgProfile>>>>;

export const getAllCustomers: GetAllCustomers = () => {
  const path = `/api/public/api/organisations/all-customers`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

// type SetUserPermissionsWorkspace = (params: {
//   userId: string | number;
//   permissions: { isAdmin?: boolean };
// }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<UserOrgProfile>>>>;

// export const setUserPermissionsWorkspace: SetUserPermissionsWorkspace = ({ userId, permissions }) => {
//   const path = `/api/public/api/organisations/users/${userId}/permissions`;
//   return axios({
//     method: 'PUT',
//     url: path,
//     data: encodeBody(permissions),
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//   }).then((res) => res.data.data);
// };
// ------------------------------------------------------------

type GetOrgHierarchy = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrgTreeItem[]>>>>;

export const getOrgHierarchy: GetOrgHierarchy = () => {
  const path = `/api/public/api/organisations/hierarchy`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
