import React, { memo, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getIncidents } from 'src/api/incidents';
import { Incident, QKeys } from 'src/api/types';
import GenericTable, { genEmtyRowTextComponent, GTRowEmpty, TableSheetIncident } from 'src/components/GenericTable';
import styled from 'styled-components';
import { useColumns } from '../useColumn';
import { Spacer, TableSkeleton } from 'src/common';
import Button from 'src/components/form/Button';
import { isEqual } from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import { AddIncidentModal } from '../../AddIncidentModal';

const Div = styled.div``;

interface FromExistingProps {
  scenarioId: string;
  setChecked: (a: string[]) => void;
  isLoading: boolean;
  onSubmit: () => void;
}

export const FromExisting: React.FC<FromExistingProps> = memo(({ scenarioId, setChecked, onSubmit, isLoading }) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [linkedIncidents, setLinkedIncidents] = useState<Incident[]>([]);

  const { data: incidents = [], isLoading: isLoadingIncidents } = useQuery(QKeys.Incidents, getIncidents);

  useEffect(() => {
    setLinkedIncidents(incidents.filter((el) => el.scenarios.some((el) => el.id === scenarioId)));
  }, [incidents]);

  const { columns, GTColumnSelectAnchorExported, checked } = useColumns({
    data: incidents,
    linkedIncidents,
    scenarioId,
  });

  useEffect(() => {
    setChecked(checked);
  }, [checked]);

  return (
    <>
      {isAddModalOpen && <AddIncidentModal onClose={() => setIsAddModalOpen(false)} />}
      <Div>
        {!isLoadingIncidents ? (
          <>
            <GenericTable
              tableId={TableIds.scenarioLinkExistingIncident}
              data={incidents}
              columns={columns}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
              searchable={['type', 'name', 'refId', 'date']}
              expandContent={(data) => <TableSheetIncident data={data} />}
              itemHeight={50}
              emptyRow={
                <GTRowEmpty
                  itemHeight={50}
                  text={genEmtyRowTextComponent({
                    onClick: () => setIsAddModalOpen(true),
                    endText: 'to add incidents.',
                  })}
                />
              }
            />
            <Spacer $px={30} />
            <Button
              disabled={isLoading || !checked.length}
              onClick={() => {
                onSubmit();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Add',
                  modal: 'Link incidents to scenario modal',
                  tags: ['INCIDENT', 'SCENARIO'],
                });
              }}
              primary
              css="width: 100%;"
              data-cy="submit-btn"
            >
              + ADD
            </Button>
          </>
        ) : (
          <TableSkeleton />
        )}
      </Div>
    </>
  );
}, isEqual);
