import { Text, View } from '@react-pdf/renderer';
import React from 'react';
import colors from 'src/theme/colors';

interface FooterProps {
  footerComponent?: React.ReactNode;
}

export const Footer: React.FC<FooterProps> = ({ footerComponent }) => {
  return (
    // @ts-ignore
    <View
      fixed
      style={{
        position: 'absolute',
        minHeight: 36,
        margin: '0 36px',
        marginTop: 14,
        paddingVertical: 10,
        bottom: 0,
        borderTop: `1px solid ${colors.stroke}`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        left: 0,
        right: 0,
        flexDirection: 'row',
      }}
    >
      {footerComponent}
      {/* @ts-ignore */}
      <View style={{ width: 30 }} />
      {/* @ts-ignore */}
      <Text
        style={{
          fontSize: 10,
          textAlign: 'right',
          color: colors.davysGrey,
          fontWeight: 500,
        }}
        render={({ pageNumber }) => `${pageNumber}`}
      />
    </View>
  );
};
