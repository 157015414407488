import colors from 'src/theme/colors';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from 'src/assets/cross.svg';

export const CloseIcon = styled(CrossIcon)`
  cursor: pointer;
  fill: ${colors.cflowerBlue};
  &:hover {
    fill: ${colors.red};
  }
`;
