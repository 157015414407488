import React from 'react';
import { Poppins } from 'src/common';
import { GTSort } from 'src/components/GenericTable';
import styled from 'styled-components';
import { SortState } from '../types';

const Div = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 40px 0 30px;
  .vwhead-item {
    display: flex;
    align-items: center;
    grid-gap: 8px;
    cursor: pointer;
    user-select: none;
  }
`;

interface HeadProps {
  sort: SortState;
  setSort: (sort: SortState) => void;
}

export const Head: React.FC<HeadProps> = ({ sort, setSort }) => {
  return (
    <Div>
      <div
        className="vwhead-item"
        onClick={() => {
          if (sort.by === 'name') {
            setSort({ by: 'name', isAsc: !sort.isAsc });
          } else {
            setSort({ by: 'name', isAsc: true });
          }
        }}
      >
        <Poppins px={18} weight={600}>
          Variable Name
        </Poppins>
        <GTSort isSorted={sort.by === 'name'} isAsc={sort.isAsc} />
      </div>
      <div
        className="vwhead-item"
        onClick={() => {
          if (sort.by === 'value') {
            setSort({ by: 'value', isAsc: !sort.isAsc });
          } else {
            setSort({ by: 'value', isAsc: true });
          }
        }}
      >
        <Poppins px={18} weight={600}>
          Value
        </Poppins>
        <GTSort isSorted={sort.by === 'value'} isAsc={sort.isAsc} />
      </div>
    </Div>
  );
};
