import { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import { WizardFormValues } from 'src/api/types/wizard';
import { getWizard } from 'src/api/wizard';
import useVariables, { getIsStandardVariablesComplete } from 'src/hooks/useVariables';
import _ from 'lodash';
import { initialValues } from './WizzardWorkspaceModal/util';
import { getAssessments } from 'src/api/assessment';
import { useAuth } from 'src/state/auth';
import { WizardIntent } from 'src/redux/reducers/app/types';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { useLocation } from 'react-router-dom';

type UseWzardInit = () => {
  isLoading: boolean;
  wizardValues: WizardFormValues | null;
  isStdVarComplete: boolean;
};

const useWzardInit: UseWzardInit = () => {
  const [wizardValues, setWizardValues] = useState<WizardFormValues | null>(null);
  const [isStdVarComplete, setIsStdVarComplete] = useState(false);
  const { activeWorkspace, activeOrganisation, isTscsRequiredToAccept } = useAuth();
  const { isLoading: isVariablesLoading, standardVariableValues } = useVariables({});
  const { setWizardWorkspace } = useActionsApp();
  const location = useLocation();
  const startCheckDone = useRef(false);

  const { data: wizardData, isLoading: isLoadingWizard } = useQuery([QKeys.Wizard], () =>
    getWizard(activeWorkspace?.id as string),
  );
  const { data: assessments = [], isLoading: isLoadingAssessments } = useQuery([QKeys.Assessments], getAssessments);

  const isLoading = isLoadingWizard || isVariablesLoading || isLoadingAssessments;

  useEffect(() => {
    if (!isLoading && standardVariableValues) {
      const data = _.cloneDeep(wizardData || initialValues);

      _.set(data, 'organisation.annualRevenue', standardVariableValues.annualRevenue);
      _.set(data, 'organisation.numberOfStaff', standardVariableValues.numberOfStaff);
      _.set(data, 'organisation.annualWageBill', standardVariableValues.annualWageBill);
      _.set(data, 'organisation.avgWorkingDays', standardVariableValues.avgWorkingDays);
      _.set(data, 'organisation.annualCyberSpend', standardVariableValues.annualCyberSpend);
      _.set(data, 'organisation.avgStaffHardwareCost', standardVariableValues.avgStaffHardwareCost);

      _.set(data, 'customers.numberOfCustomers', standardVariableValues.numberOfCustomers);
      _.set(data, 'customers.avgContractValue', standardVariableValues.avgContractValue);
      _.set(data, 'customers.numberOfDataRecords', standardVariableValues.numberOfDataRecords);

      _.set(data, 'softwareDev.releaseCycleCost', standardVariableValues.releaseCycleCost);
      _.set(data, 'softwareDev.releaseCycleLength', standardVariableValues.releaseCycleLength);
      _.set(data, 'softwareDev.researchAndDevelopmentBudget', standardVariableValues.researchAndDevelopmentBudget);

      _.set(data, 'supplyChain.avgCostToProcureSupplier', standardVariableValues.avgCostToProcureSupplier);
      _.set(data, 'supplyChain.avgInvoiceValue', standardVariableValues.avgInvoiceValue);
      _.set(data, 'supplyChain.largestRegularInvoiceValue', standardVariableValues.largestRegularInvoiceValue);

      _.set(data, 'cyberInsurance.cyberInsuranceLimit', standardVariableValues.cyberInsuranceLimit);
      _.set(data, 'cyberInsurance.cyberInsuranceExcess', standardVariableValues.cyberInsuranceExcess);
      _.set(data, 'cyberInsurance.cyberInsurancePremium', standardVariableValues.cyberInsurancePremium);

      setWizardValues(data);

      const isComplete = getIsStandardVariablesComplete(standardVariableValues, {
        developsSoftware: data.softwareDev.developsSoftware,
        cyberInsurance: data.cyberInsurance.cyberInsurance,
      });

      setIsStdVarComplete(isComplete);
    }
  }, [wizardData, standardVariableValues, isLoading]);

  useEffect(() => {
    if (window.localStorage.getItem('wiz_auto_disabled')) return;
    const isPathAllowed = ![
      '/home',
      '/organisation',
      `/organisation/${activeOrganisation?.id}`,
      '/get-started',
    ].includes(location.pathname);

    if (
      !isLoading &&
      standardVariableValues &&
      !isTscsRequiredToAccept &&
      isPathAllowed &&
      !!wizardValues &&
      !startCheckDone.current
    ) {
      const createAssessment = !assessments?.length;

      if (!isStdVarComplete) {
        setWizardWorkspace({
          intent: {
            type: WizardIntent.workspace,
            params: {
              createAssessment,
            },
          },
        });
      }

      startCheckDone.current = true;
    }
  }, [
    isLoading,
    isLoadingAssessments,
    isTscsRequiredToAccept,
    assessments,
    location.pathname,
    activeOrganisation?.id,
    isStdVarComplete,
    wizardValues,
  ]);

  return { isLoading, wizardValues, isStdVarComplete };
};

export default useWzardInit;
