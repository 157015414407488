import React, { useEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import {
  DataTitle,
  DataValue,
  NoData,
  PageCard,
  PageContainer,
  PageFrameworkLibSkeleton,
  Poppins,
  Spacer,
  TitleWithBack,
} from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import { getControlLibraries } from 'src/api/controllibraries';
import { getFrameworkLibrary } from 'src/api/frameworklibraries';
import { queryCache, useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router';
import { ControlLibraryModal } from 'src/components/modals';
import { ControlLibrary, QKeys } from 'src/api/types';
import GenericTable, { TableSheetControlLibrary, useControlLibraryColumns } from 'src/components/GenericTable';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';
import { addControllsFromLibrary } from 'src/api/controls';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';

const Div = styled(PageContainer)`
  .content {
    display: grid;
    grid-gap: 20px;

    &__row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    }
  }
  .btn-container {
    display: flex;
    justify-content: flex-end;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    .content {
      grid-gap: 10px;
      &__row {
        grid-template-columns: 1fr;
      }
    }
  }
`;

const FrameworkLibrabryPage = () => {
  const { frameworklibraryId } = useParams<{ frameworklibraryId: string }>();
  const [controlLibraryAddModal, setControlLibraryAddModal] = useState(false);
  const [editControlLibraryData, setEditControlLibraryData] = useState<ControlLibrary | null>(null);
  const [isNewSelection, setIsNewSelection] = useState(true);
  const { setLocationPaths } = useActionsApp();

  useGaEventPageView('Cydea | Framework');

  const { data: controlLibs = [], isLoading: isLibsLoading } = useQuery(
    [QKeys.ControlLibraries, frameworklibraryId],
    () => getControlLibraries(frameworklibraryId as string),
  );

  const { data: frameworkData, isLoading: isDataLoading } = useQuery(
    [QKeys.FrameworkLibrary, { frameworkId: frameworklibraryId }],
    getFrameworkLibrary,
  );

  const [add, { isLoading: isAddLoading }] = useMutation(addControllsFromLibrary, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.Controls);
      queryCache.invalidateQueries([QKeys.ControlLibraries, frameworklibraryId]);
      setChecked([]);
      setIsNewSelection(false);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  useEffect(() => {
    if (frameworkData) {
      setLocationPaths({
        framework: { id: frameworkData.ux_id, name: frameworkData.shortname || frameworkData.name },
      });
    }
    return () => {
      setLocationPaths({ framework: undefined });
    };
  }, [frameworkData]);

  const { columns, GTColumnSelectAnchorExported, checked, setChecked } = useControlLibraryColumns({
    data: controlLibs,
    frameworklibraryId: frameworklibraryId as string,
    isCheckerEnabled: true,
    tdm: {
      onAddToRMPClick: true,
    },
    opensSameTab: true,
    tableId: TableIds.controlLibFramework,
  });

  useEffect(() => {
    if (checked.length) {
      setIsNewSelection(true);
    }
  }, [checked]);

  const isLoading = isLibsLoading || isDataLoading;

  return (
    <>
      <Meta title={`Cydea | Framework`} feedbackScript />
      {(controlLibraryAddModal || editControlLibraryData) && (
        <ControlLibraryModal
          controlLibraryData={editControlLibraryData}
          frameworklibraryId={frameworklibraryId as string}
          onClose={() => {
            setEditControlLibraryData(null);
            setControlLibraryAddModal(false);
          }}
        />
      )}
      {!isLoading ? (
        <>
          {frameworkData && (
            <Div data-cy="framework-library-page">
              <PageCard>
                <TitleWithBack title="FRAMEWORK" gradient />
                <Spacer $px={30} />
                {frameworkData.shortname ? (
                  <Poppins px={32} weight={600}>
                    {frameworkData.shortname}
                  </Poppins>
                ) : (
                  <NoData />
                )}
                <Spacer $px={10} />

                <div className="content">
                  <div className="content__row">
                    <div>
                      <DataTitle>FULL NAME</DataTitle>
                      {frameworkData.name ? (
                        <DataValue data-cy="scenario-source">{frameworkData.name}</DataValue>
                      ) : (
                        <NoData />
                      )}
                    </div>
                    <div>
                      <DataTitle>AUTHOR</DataTitle>
                      {frameworkData.author ? (
                        <DataValue data-cy="scenario-event">{frameworkData.author}</DataValue>
                      ) : (
                        <NoData />
                      )}
                    </div>
                  </div>
                  <div>
                    <DataTitle>DESCRIPTION</DataTitle>
                    {frameworkData.description ? <ExpandCollapse text={frameworkData.description} /> : <NoData />}
                  </div>
                </div>
              </PageCard>
              <Spacer $px={30} />
              <div className="btn-container">
                <Button
                  primary
                  disabled={!checked.length || isAddLoading}
                  onClick={() => {
                    add({
                      controlLibraryIds: checked.map((el) => {
                        return {
                          id: el,
                        };
                      }),
                    });
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Add to RMP',
                      tags: ['RMP'],
                    });
                  }}
                  $constWidth={200}
                  data-cy="add-to-rmp"
                >
                  {isNewSelection ? '+ ADD TO RMP' : 'ADDED TO RMP'}
                </Button>
              </div>
              <Spacer $px={30} />
              <GenericTable
                tableId={TableIds.controlLibFramework}
                data={controlLibs}
                columns={columns}
                GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                expandContent={(row) => <TableSheetControlLibrary data={row} />}
                searchable={['frameworkShortname', 'controlLibraryId', 'name', 'FrameworkAuthor']}
              />
            </Div>
          )}
        </>
      ) : (
        <Div>
          <PageFrameworkLibSkeleton />
        </Div>
      )}
    </>
  );
};

export default FrameworkLibrabryPage;
