import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { Poppins } from './Poppins';

export const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 12px;
  background: ${colors.stroke};
  border-radius: 4px;

  span {
    color: ${colors.cflowerBlue};
    user-select: none;
  }
`;

interface PermissionLabelProps {
  label: string;
}

export const PermissionLabel: React.FC<PermissionLabelProps> = ({ label }) => {
  return (
    <Div>
      <Poppins px={14} weight={600}>
        {label}
      </Poppins>
    </Div>
  );
};
