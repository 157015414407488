// @ts-nocheck
import { View, Text, Image } from '@react-pdf/renderer';
import React from 'react';
import colors from 'src/theme/colors';
import { noData } from '../imgData';

interface NoDataProps {
  title?: string;
}

export const NoData: React.FC<NoDataProps> = ({ title = 'No data' }) => {
  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <Image src={noData} style={{ width: 14, height: 14, marginRight: 6 }} />
      <Text style={{ fontSize: 10, color: colors.prussianBlue }}>{title}</Text>
    </View>
  );
};
