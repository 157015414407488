import { AxiosArgs, ThenArgs, Framework } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody } from 'src/api/client';
import { QueryKey } from 'react-query';

type CreateFrameworkLibrary = (a: {
  name: string;
  shortname: string;
  frameworkLibraryId: string;
  external_id: string;
  version: string;
  author: string;
  description: string;
  publish_date: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Framework>>>>;

export const createFrameworkLibrary: CreateFrameworkLibrary = (parameters) => {
  const path = `/api/public/api/frameworklibraries`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateFrameworkLibrary = (a: {
  ux_id: string | number;
  id: string | number;
  name: string;
  shortname: string;
  frameworkLibraryId: string;
  external_id: string;
  version: string;
  author: string;
  description: string;
  publish_date: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Framework>>>>;

export const updateFrameworkLibrary: UpdateFrameworkLibrary = ({ ux_id, ...parameters }) => {
  const path = `/api/public/api/frameworklibraries/${ux_id}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(parameters),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetFrameworksLibraries = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<Framework[]>>>>;

export const getFrameworksLibraries: GetFrameworksLibraries = () => {
  const path = `/api/public/api/frameworklibraries`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetFrameworkLibrary = (
  key: QueryKey,
  parameters: {
    frameworkId: string | number;
  },
) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Framework>>>>;

export const getFrameworkLibrary: GetFrameworkLibrary = (_, parameters) => {
  const path = `/api/public/api/frameworklibraries/${parameters.frameworkId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type DeleteFrameworkLibrary = (parameters: { id: string }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Framework>>>>;

export const deleteFrameworkLibrary: DeleteFrameworkLibrary = ({ id }) => {
  const path = `/api/public/api/frameworklibraries/${id}`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
