import styled from 'styled-components';

export const ViewContainer = styled.div`
  .closed {
    height: 0px;
    overflow: hidden;
  }

  .sw-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__end {
      display: flex;
      align-items: center;
      justify-content: space-between;
      grid-gap: 40px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .sw-top {
      padding-right: 30px;
    }
  }
`;
