import colors from 'src/theme/colors';
import styled from 'styled-components';

export const TabWrap = styled.div`
  background-color: white;
  padding: 40px 50px 50px 50px;
  border-radius: 6px;

  .err {
    color: ${colors.error};
    font-size: 14px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 30px 40px 40px 40px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px;
  }
`;
