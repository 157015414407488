import moment from 'moment';
import { ControllStatus, Strategy, UserOptionType } from 'src/api/types';
import * as Yup from 'yup';

export interface RmpFormValues {
  strategy: Strategy | null;
  name: string;
  status: ControllStatus | null;
  owner?: UserOptionType | null;
  description: string | null;
  expected_active_date: string | null;
  review_date: string | null;
  annual_cost: number | null;
  vendor: string | null;
  actual_active_date: string | null;
  source: string | null;
  url_name: string | null;
}

const validateDate = (date?: string | null) => {
  if (!date) return true;
  if (typeof date === 'string') return moment(date, 'DD-MM-YYYY').isValid();
  return false;
};

export const rmpValidationSchema = Yup.object({
  name: Yup.string().nullable().required('Required').max(255, 'Max 255 characters'),
  strategy: Yup.string().nullable().max(255, 'Max 255 characters'),
  status: Yup.string().nullable(),
  description: Yup.string().nullable().max(2048, 'Max 2048 characters'),
  expected_active_date: Yup.string().nullable().test('expected_active_date', 'Invalid Date', validateDate),
  review_date: Yup.string().nullable().test('review_date', 'Invalid Date', validateDate),
  actual_active_date: Yup.string().nullable().test('actual_active_date', 'Invalid Date', validateDate),
  source: Yup.string().nullable().url('Invalid URL'),
  url_name: Yup.string().nullable().max(255, 'Max 255 characters'),
});

export const rmpInitialValues: RmpFormValues = {
  strategy: null,
  name: '',
  status: null,
  owner: null,
  description: '',
  expected_active_date: null,
  review_date: null,
  annual_cost: null,
  vendor: '',
  actual_active_date: null,
  source: null,
  url_name: null,
};
