import React from 'react';
import { ChangeEvent } from 'react';
import { UserAssociation } from 'src/api/types';
import { UserCtxOption } from 'src/components/select';

export const handleImageChange = (e: ChangeEvent<HTMLInputElement>, cb: (path: string) => void) => {
  const file = e.target.files ? e.target.files[0] : null;
  if (file) {
    const reader = new FileReader();
    reader.onload = (event: ProgressEvent<FileReader>) => {
      cb(event.target?.result as string);
    };
    reader.readAsDataURL(file);
  }
};

export const flattenUserAssociations = (userAssociations: UserAssociation[]): UserCtxOption[] => {
  let result: UserCtxOption[] = [];

  const flatten = (associations: UserAssociation[]) => {
    associations.forEach((association) => {
      result.push({
        value: association.id,
        label: association.name,
        type: association.type,
      });

      if (association.children) {
        flatten(association.children);
      }
    });
  };

  flatten(userAssociations);

  return result;
};
