// @ts-nocheck
import { View } from '@react-pdf/renderer';
import React from 'react';
import { pdfStyles } from '../pdfStyles';
import colors from 'src/theme/colors';

interface IndicatorViewProps {
  children: React.ReactNode;
}

export const IndicatorView: React.FC<IndicatorViewProps> = ({ children }) => {
  return (
    <View style={{ ...pdfStyles.section, flexDirection: 'row' }}>
      <View
        style={{
          width: 5,
          backgroundColor: '#ECECEE',
          marginRight: 16,
          borderRadius: 10,
          borderTopLeftRadius: 0,
          overflow: 'hidden',
        }}
      >
        <View
          style={{
            width: '200%',
            height: 36,
            backgroundColor: colors.red,
            borderBottomRightRadius: 10,
            borderTopRightRadius: 10,
            left: -5,
          }}
        />
      </View>
      <View style={{ flex: 1, padding: '6px 0' }}>{children}</View>
    </View>
  );
};
