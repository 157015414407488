import React, { useRef, useState, useLayoutEffect } from 'react';
import styled from 'styled-components';
import { DataValue, Poppins } from './Poppins';
import colors from 'src/theme/colors';
import { motion } from 'framer-motion';
import { Spacer } from './Spacer';
import { MOTION_TRANSITION } from 'src/config';
import useBreakpoints from 'src/hooks/useBreakpoints';

const Div = styled.div`
  .truncate {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  #handle-btn {
    cursor: pointer;
    font-weight: 600;
    background: ${colors.gradient};
    -webkit-background-clip: text;
    color: transparent;
    user-select: none;
  }
`;

interface ExpandCollapseProps {
  text: string;
  dataCy?: string;
}

const ExpandCollapse: React.FC<ExpandCollapseProps> = ({ text, dataCy = 'expand-collapse-description' }) => {
  const [isTruncated, setIsTruncated] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);
  const isMd = useBreakpoints('md');
  const isSm = useBreakpoints('sm');

  const h = isSm ? 40 : isMd ? 50 : 60;
  const [height, setHeight] = useState(h);

  useLayoutEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current) {
        setHeight(textRef.current.scrollHeight);
        setShowButton(textRef.current.scrollHeight > textRef.current.offsetHeight);
        setIsTruncated(true);
      }
    };
    checkTextOverflow();
    window.addEventListener('resize', checkTextOverflow);
    return () => window.removeEventListener('resize', checkTextOverflow);
  }, [text, height]);

  return (
    <Div>
      <motion.div
        css={`
          overflow: hidden;
          height: ${h}px;
        `}
        animate={{
          height: isTruncated ? h : height,
        }}
        transition={MOTION_TRANSITION}
      >
        <DataValue data-cy={dataCy} ref={textRef} className={isTruncated ? 'truncate' : ''}>
          {text}
        </DataValue>
      </motion.div>
      {showButton && (
        <>
          {!isMd && <Spacer $px={10} />}
          <Poppins id="handle-btn" px={14} onClick={() => setIsTruncated(!isTruncated)}>
            {isTruncated ? 'Show more' : 'Show less'}
          </Poppins>
        </>
      )}
    </Div>
  );
};

export default ExpandCollapse;
