import React, { useEffect, useRef, useState } from 'react';
import { GradientTextAction, NoData, Poppins, Spacer, TimelineSkeleton } from 'src/common';
import styled from 'styled-components';
import moment from 'moment';
import { Timeline, Filters, FetchMore, HeadTitle } from './comps';
import _ from 'lodash';
import { useQuery } from 'react-query';
import { getTimeline } from 'src/api/dashboards';
import { QKeys } from 'src/api/types';
import { parseEvents } from './util';
import { res } from './temp';
import { ActivityProvider } from './state';

const Div = styled.div`
  background-color: white;
  padding: 20px 0 20px 30px;
  border-radius: 16px;
  position: relative;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  filter: drop-shadow(0px 0px 18px rgba(7, 46, 91, 0.12));

  .activity-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    padding-right: 20px;
    &__left {
      display: flex;
      flex-direction: column;
    }
  }

  .activity-container {
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: calc(100% - 70px);
    padding: 10px 0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 15px 0 15px 20px;
    .activity-controls {
      padding-right: 15px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 10px 0 10px 15px;
    .activity-controls {
      padding-right: 10px;
    }
  }
`;

const RootComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState({ idx: 0, focus: true });

  const activityRef = useRef<{ centerToday: () => void }>(null);

  const { data: res, isLoading: isInitialLoading, error } = useQuery(QKeys.DashboardTimeline, getTimeline);
  // const isInitialLoading = false;
  // const error = null;
  const fetchMore = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const today = moment().startOf('day');

  useEffect(() => {
    const todaysIdx = res?.data.findIndex((el) => moment(el.event_date).startOf('day').diff(today, 'days') === 0) || -1;
    setSelected({ idx: todaysIdx, focus: true });
  }, [res]);

  return (
    <Div>
      <div className="activity-controls">
        <div className="activity-controls__left">
          <Poppins weight={600} px={22}>
            Activity
          </Poppins>
          {!isInitialLoading && !error && (
            <GradientTextAction px={14} weight={500} $underline onClick={activityRef.current?.centerToday}>
              Return to today
            </GradientTextAction>
          )}
        </div>
        {/* <Filters /> */}
      </div>

      <Spacer $px={15} />
      <div className="activity-container styled-scroll">
        <Spacer $px={15} />
        {isInitialLoading && <TimelineSkeleton />}
        {!!res && (
          <>
            {res.data.length ? (
              <>
                {res.data[0].index !== 1 && (
                  <Timeline
                    isActive
                    completeness={100}
                    headComponent={<FetchMore onClick={() => fetchMore()} isLoading={isLoading} />}
                  />
                )}

                {res.data.map((el, idx) => {
                  const date = moment(el.event_date).startOf('day');
                  const isPast = date.diff(today, 'days') < 0;
                  const isToday = date.diff(today, 'days') === 0;

                  const isLast = idx === res.data.length - 1 && _.last(res.data)?.index === res.total;
                  const contentMarginBottom = isLast ? 0 : idx + 1 === selected.idx ? 35 : isToday ? 30 : 20;

                  return (
                    <Timeline
                      key={el.index}
                      isActive={isPast || isToday}
                      completeness={isPast ? 100 : 0}
                      headComponent={
                        <HeadTitle px={14}>{isToday ? 'TODAY' : moment(el.event_date).format('DD MMM YYYY')}</HeadTitle>
                      }
                      content={
                        <>
                          {parseEvents(el.events, isToday) || (
                            <Poppins px={14}>{isToday ? 'No activities or events today' : ''}</Poppins>
                          )}
                        </>
                      }
                      isLast={isLast}
                      highlight={isToday}
                      // highlight={selected.idx === idx}
                      shouldFocus={selected.focus}
                      contentMarginBottom={contentMarginBottom}
                      ref={isToday ? activityRef : undefined}
                      // onSelect={() => setSelected({ idx, focus: false })}
                    />
                  );
                })}

                {_.last(res.data)?.index !== res.total && (
                  <Timeline
                    isActive={false}
                    completeness={0}
                    headComponent={<FetchMore onClick={() => fetchMore()} isLoading={isLoading} isNext />}
                    isLast
                  />
                )}
              </>
            ) : (
              <NoData />
            )}
          </>
        )}
        {!!error && !isInitialLoading && (
          <Poppins px={14} color="error">
            {/* @ts-ignore */}
            {error.message || 'Something went wrong'}
          </Poppins>
        )}
        <Spacer $px={15} />
      </div>
    </Div>
  );
};

const Activity = () => {
  return (
    <ActivityProvider>
      <RootComponent />
    </ActivityProvider>
  );
};

export default Activity;
