import { FractionBarValue } from '../../types';

export const genFractionBarsValues = (values: FractionBarValue[]): FractionBarValue[] => {
  const totalValue = values.reduce((acc, item) => acc + item.value, 0);

  const withPercentage = values.map((item) => ({
    ...item,
    value: parseFloat(((item.value / totalValue) * 100).toFixed(0)),
  }));

  return withPercentage;
};
