import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';

interface IPoppins {
  weight?: 400 | 500 | 600;
  px?: 12 | 14 | 16 | 18 | 22 | 24 | 28 | 32 | 56;
  color?: keyof typeof colors;
  nowrap?: boolean;
}

export const Poppins = styled.span<IPoppins>`
  position: relative;
  color: ${colors.prussianBlue};

  ${({ color }) =>
    color &&
    css`
      color: ${colors[color]};
    `};

  ${({ weight }) =>
    weight &&
    css`
      font-weight: ${weight} !important;
    `};

  ${({ nowrap }) =>
    nowrap &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    `};

  ${({ px = 16 }) => css`
    font-size: ${px}px;
  `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: ${({ px = 16 }) => px * 0.85}px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    font-size: ${({ px = 16 }) => {
      if (px > 30) return 18;
      return px * 0.7;
    }}px;
  }

  /* @media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi) {
    font-size: ${({ px = 16 }) => px * 0.75}px;
  } */
`;
interface DataTitleProps {
  $noMargin?: boolean;
}

export const DataTitle = styled(Poppins).attrs((p) => {
  return { px: 14, ...p };
})<DataTitleProps>`
  display: block;
  font-weight: 600;
  color: ${colors.cflowerBlue};

  ${({ $noMargin }) =>
    !$noMargin &&
    css`
      margin-bottom: 6px;
      ${({ theme }) => theme.breakpoints.down('md')} {
        margin-bottom: 0px;
      }
    `};
`;

export const DataValue = styled(Poppins).attrs((p) => {
  return { px: 14, ...p };
})`
  display: block;
  font-weight: 500;
  color: ${colors.prussianBlue};
  white-space: pre-wrap;

  a {
    color: ${colors.prussianBlue};
    text-decoration: underline;
  }
`;
