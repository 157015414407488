import { useFormikContext } from 'formik';
import _ from 'lodash';
import React from 'react';
import { InputLabel, Poppins, Spacer } from 'src/common';
import CurrencyInput from 'src/components/form/CurrencyInput';
import MaterialInput from 'src/components/form/MaterialInput';
import { getPercentage, waitFor } from 'src/utils/misc';
import styled from 'styled-components';
import { Clear } from '@mui/icons-material';
import colors from 'src/theme/colors';
import { parseToNumber } from '../util';

const Div = styled.div`
  padding: 12px 0;
  display: grid;
  grid-template-columns: auto 1.5fr 1fr auto 1fr 85px 15px;
  grid-gap: 20px;
  .item {
    display: flex;
    flex-direction: column;

    &--center {
      align-items: center;
      justify-content: center;
    }

    &--frequency-text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }

  .MuiSvgIcon-root {
    cursor: pointer;
    &:hover {
      color: ${colors.red};
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-gap: 10px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-template-columns: auto 1.5fr 1fr 1fr auto 15px;
    .item {
      &--times-every {
        display: none;
      }
    }
  }
`;

const getPercentageString = (a: string | number, b: string | number) => {
  const aParsed = parseToNumber(a);
  const bParsed = parseToNumber(b);

  if (aParsed === undefined || bParsed === undefined || bParsed === 0) {
    return null;
  }
  return `${getPercentage(aParsed, bParsed)}%`;
};

interface RowValues {
  loss: number;
  frequency: number;
  years: number;
}

interface RowProps {
  idx: number;
  readOnly?: boolean;
}

export const Row: React.FC<RowProps> = ({ idx, readOnly }) => {
  const { values, handleBlur, setFieldValue, errors, touched, validateForm } = useFormikContext<RowValues[]>();

  const canClear = values[idx].loss || values[idx].frequency || values[idx].years;
  const percentage = getPercentageString(values[idx].frequency, values[idx].years);
  return (
    <Div>
      <div className="item item--center">
        <Spacer $px={20} />
        <Poppins px={18} color="cflowerBlue" weight={500}>
          #{idx + 1}
        </Poppins>
      </div>
      <div className="item">
        <InputLabel>LOSS</InputLabel>
        <CurrencyInput
          currency
          name={`[${idx}].loss`}
          value={values[idx].loss}
          onValueChange={({ floatValue }) => setFieldValue(`[${idx}].loss`, floatValue)}
          onBlur={handleBlur}
          error={touched[idx]?.loss && !!errors[idx]?.loss}
          helperText={touched[idx]?.loss && errors[idx]?.loss}
          disabled={readOnly}
          data-cy={`rt-loss-input-${idx}`}
        />
      </div>
      <div className="item">
        <InputLabel>FREQUENCY</InputLabel>
        <MaterialInput
          name={`[${idx}].frequency`}
          value={values[idx].frequency}
          type="number"
          onChange={(e) => setFieldValue(`[${idx}].frequency`, e.target.value)}
          onBlur={handleBlur}
          error={touched[idx]?.frequency && !!errors[idx]?.frequency}
          helperText={touched[idx]?.frequency && errors[idx]?.frequency}
          disabled={readOnly}
          data-cy={`rt-frequency-input-${idx}`}
        />
      </div>
      <div className="item  item--center item--times-every">
        <Spacer $px={20} />
        <InputLabel>TIMES EVERY</InputLabel>
      </div>
      <div className="item">
        <InputLabel>YEAR(S)</InputLabel>
        <MaterialInput
          name={`[${idx}].years`}
          value={values[idx].years}
          type="number"
          onChange={(e) => setFieldValue(`[${idx}].years`, e.target.value)}
          onBlur={handleBlur}
          error={touched[idx]?.years && !!errors[idx]?.years}
          helperText={touched[idx]?.years && errors[idx]?.years}
          disabled={readOnly}
          data-cy={`rt-years-input-${idx}`}
        />
      </div>
      <div className="item">
        {idx === 0 ? <InputLabel>FREQUENCY</InputLabel> : <Spacer $px={20} />}
        <div className="item item--frequency-text">
          <Poppins px={14} weight={500} color="cflowerBlue" css="text-align: center;">
            {percentage ? percentage : '-'}
          </Poppins>
        </div>
      </div>
      <div className="item item--center">
        <Spacer $px={20} />
        {canClear && !readOnly && (
          <Clear
            onClick={async () => {
              setFieldValue(`[${idx}].loss`, '');
              setFieldValue(`[${idx}].frequency`, '');
              setFieldValue(`[${idx}].years`, '');
              await waitFor(0);
              validateForm();
            }}
            data-cy={`rt-delete-${idx}`}
          />
        )}
      </div>
    </Div>
  );
};
