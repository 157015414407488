import { useEffect } from 'react';
import { isDev } from 'src/config';

export const gaEventEXPORT = (event: 'EXPORT_CSV' | 'EXPORT_PDF') => {
  // @ts-ignore
  !isDev && gtag('event', event);
};

export const gaEventCRUD = (event: 'ASSESSMENT' | 'SCENARIO', method: 'CREATE' | 'UPDATE' | 'DELETE') => {
  // @ts-ignore
  !isDev && gtag('event', event, { method: method });
};

export const gaEventUSER = (
  event: 'LOGIN' | 'LOGOUT' | 'SIGNUP' | 'CHANGE_PASSWORD' | 'CHANGE_EMAIL' | 'RESET_PASSWORD' | 'PROFILE_UPDATE',
  method?: 'CREDENTIALS' | 'GOOGLE' | 'MSO' | undefined,
) => {
  // @ts-ignore
  !isDev && gtag('event', event, { method: method });
};

type Title =
  | 'Cydea'
  | 'Cydea | My Assessments'
  | 'Cydea | Assessment'
  | 'Cydea | Scenario'
  | 'Get Started'
  | 'Cydea | Compare assessments'
  | 'Cydea | Compare scenarios'
  | string;

export const gaEventPageView = (title: Title) => {
  // @ts-ignore
  !isDev && gtag('event', 'page_view', { page_title: title });
};

export const useGaEventPageView = (title: Title) => {
  useEffect(() => {
    gaEventPageView(title);
  }, []);
};
