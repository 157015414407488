import { useFormikContext } from 'formik';
import React from 'react';
import { Poppins } from 'src/common';
import { GridContainer } from '../../GridContainer';
import CurrencyInput from 'src/components/form/CurrencyInput';
import _ from 'lodash';
import { WizardFormValues } from 'src/api/types/wizard';

export const Customers = () => {
  const { values, setFieldValue, setFieldTouched, touched, errors } = useFormikContext<WizardFormValues>();

  const numberOfCustomersTouched = _.get(touched, 'customers.numberOfCustomers');
  const numberOfCustomersError = _.get(errors, 'customers.numberOfCustomers');
  const averageAnnualRevenuePerCustomerTouched = _.get(touched, 'customers.avgContractValue');
  const averageAnnualRevenuePerCustomerError = _.get(errors, 'customers.avgContractValue');
  const personalDataRecordsHeldTouched = _.get(touched, 'customers.numberOfDataRecords');
  const personalDataRecordsHeldError = _.get(errors, 'customers.numberOfDataRecords');

  return (
    <GridContainer>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          How many customers does your organisation have?
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.customers.numberOfCustomers}
            onValueChange={(e) => setFieldValue(`customers.numberOfCustomers`, e.floatValue)}
            onBlur={() => setFieldTouched(`customers.numberOfCustomers`, true)}
            error={Boolean(numberOfCustomersTouched && numberOfCustomersError)}
            helperText={numberOfCustomersTouched && numberOfCustomersError}
            data-cy="customers-number-of-customers"
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          What is the average annual revenue from a typical customer?
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            currency
            value={values.customers.avgContractValue}
            onValueChange={(e) => setFieldValue(`customers.avgContractValue`, e.floatValue)}
            onBlur={() => setFieldTouched(`customers.avgContractValue`, true)}
            error={Boolean(averageAnnualRevenuePerCustomerTouched && averageAnnualRevenuePerCustomerError)}
            helperText={averageAnnualRevenuePerCustomerTouched && averageAnnualRevenuePerCustomerError}
            data-cy="customers-average-annual-revenue-per-customer"
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          How many personal data records do you hold?
        </Poppins>
        <div className="row__end">
          <CurrencyInput
            className="compact-input"
            value={values.customers.numberOfDataRecords}
            onValueChange={(e) => setFieldValue(`customers.numberOfDataRecords`, e.floatValue)}
            onBlur={() => setFieldTouched(`customers.numberOfDataRecords`, true)}
            error={Boolean(personalDataRecordsHeldTouched && personalDataRecordsHeldError)}
            helperText={personalDataRecordsHeldTouched && personalDataRecordsHeldError}
            data-cy="customers-personal-data-records-held"
          />
        </div>
      </div>
    </GridContainer>
  );
};
