import _ from 'lodash';

export function getDiff<T>(obj1: T, obj2: T): Partial<T> {
  let changedValues: Partial<T> = {};

  Object.keys(obj1 as any).forEach((key) => {
    if (_.isObject((obj1 as any)[key]) && _.isObject((obj2 as any)[key])) {
      const changes = getDiff((obj1 as any)[key], (obj2 as any)[key]);
      if (!_.isEmpty(changes)) {
        (changedValues as any)[key] = changes;
      }
    } else if (!_.isEqual((obj1 as any)[key], (obj2 as any)[key])) {
      (changedValues as any)[key] = (obj2 as any)[key];
    }
  });

  Object.keys(obj2 as any).forEach((key) => {
    if (!(obj1 as any).hasOwnProperty(key) && !(changedValues as any).hasOwnProperty(key)) {
      (changedValues as any)[key] = (obj2 as any)[key];
    }
  });

  return changedValues;
}
