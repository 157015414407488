// @ts-nocheck
import React from 'react';
import { Control } from 'src/api/types';
import { Image, Link, Text, View } from '@react-pdf/renderer';
import colors from 'src/theme/colors';
import { pdfStyles } from '../pdfStyles';
import { getExternalPath } from '../util';
import { extLinkImg } from '../imgData';

interface ControlsTableProps {
  data: Control[];
}

export const ControlsTable: React.FC<ControlsTableProps> = ({ data }) => {
  return (
    <>
      <View
        wrap={false}
        break={false}
        style={{
          height: 24,
          border: `1px solid ${colors.cflowerBlue}`,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
          <Text style={pdfStyles.tableHeader}>Strategy</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 1.3 }}>
          <Text style={pdfStyles.tableHeader}>Name</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
          <Text style={pdfStyles.tableHeader}>Owner</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
          <Text style={pdfStyles.tableHeader}>Framework</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
          <Text style={pdfStyles.tableHeader}>Framework ID</Text>
        </View>
        <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
          <Text style={pdfStyles.tableHeader}>Status</Text>
        </View>
      </View>
      {data.map((el, idx) => {
        return (
          <View
            wrap={false}
            break={false}
            style={{
              minHeight: 24,
              border: `1px solid ${colors.cflowerBlue}`,
              display: 'flex',
              flexDirection: 'row',
              marginTop: -1,
            }}
          >
            <View style={{ ...pdfStyles.tableCell, flex: 0.4 }}>
              <Text style={{ ...pdfStyles.tableValue }}>{el.strategy || '-'}</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 1.3 }}>
              <Link
                src={getExternalPath(`risk-management/control/${el.ux_id}`)}
                style={{
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  flexDirection: 'row',
                  textDecoration: 'none',
                }}
              >
                <Text style={{ ...pdfStyles.tableValue, textDecoration: 'underline', marginRight: 6 }}>
                  {el.name || '-'}
                </Text>
                <Image src={extLinkImg} style={{ width: 8, height: 8 }} />
              </Link>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
              <Text style={pdfStyles.tableValue}>{el.owner?.text || '-'}</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.6 }}>
              <Text style={pdfStyles.tableValue}>{el.frameworkLibrary_shortname || '-'}</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
              <Text style={pdfStyles.tableValue}>{el.controlId || '-'}</Text>
            </View>
            <View style={{ ...pdfStyles.tableCell, flex: 0.7 }}>
              <Text style={pdfStyles.tableValue}>{el.status || '-'}</Text>
            </View>
          </View>
        );
      })}
    </>
  );
};
