import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo } from 'react';
import { OrganisationWorkspace, QKeys } from 'src/api/types';
import { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { queryCache, useMutation } from 'react-query';
import { TableUserItem } from 'src/common';
import { cancelInviteToOrg } from 'src/api/organisation';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

type Invite = OrganisationWorkspace['invites'][0];

type UseColumns = (a: { data?: Invite[]; defaultColumns?: string[]; workspaceId: string }) => {
  columns: ColumnDef<Invite>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

export const useInvitesColumns: UseColumns = ({ data = [], defaultColumns = ['name', 'email'], workspaceId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.wsInvites}-columns`, defaultColumns);

  const resetCache = () => {
    queryCache.invalidateQueries([QKeys.Workspace, workspaceId]);
    queryCache.invalidateQueries([QKeys.WorkspaceUsers, workspaceId]);
    queryCache.invalidateQueries([QKeys.RiskOwners]);
  };

  const [cancelInvite] = useMutation(cancelInviteToOrg, {
    onSuccess: resetCache,
  });

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<Invite>[] = [
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: ({ row }) => {
          return <TableUserItem name={row.original.name} />;
        },
        sortingFn: sortingFns.text,
      },

      {
        id: 'email',
        header: 'Email',
        accessorFn: (row) => row.email || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },

      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const onCancelInviteClick = () => {
            cancelInvite({ orgId: workspaceId, requestId: row.original.invite.id });
          };

          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm onCancelInviteClick={onCancelInviteClick} />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Name' },
        { key: 'email', title: 'Email' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
  };
};
