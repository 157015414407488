import React, { memo } from 'react';
import { Control } from 'src/api/types';
import { TocItem } from '../../types';
import { FooterStartComponent, HeaderEndComponent, RmpPage, ScenarioPage } from '../../comps';
import _ from 'lodash';

interface RmpRenderProps {
  controls: Control[];
  onDone: (data: TocItem[]) => void;
}

export const RmpRender: React.FC<RmpRenderProps> = memo(
  ({ controls, onDone }) => {
    if (!controls.length) {
      onDone([]);
      return null;
    }
    const list: { [key: string]: TocItem } = {};

    const onRender = (pageNumber: number, idx: number, title: string) => {
      list[idx] = { pageNumber: pageNumber, title };

      if (idx === controls.length - 1) {
        const outputArray = Object.keys(list).map((key) => list[key]);
        onDone(outputArray);
      }
    };

    return (
      <>
        {controls.map((el, idx) => {
          return (
            <RmpPage
              control={el}
              onRender={(pageNumber) => onRender(pageNumber, idx, el.name)}
              endComponent={<HeaderEndComponent idx={idx + 1} title="CONTROL" />}
              footerComponent={<FooterStartComponent title={`Risk management plan / ${el.name}`} />}
            />
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps),
);
