import { TreeNode } from "primereact/treenode";
import { TreeTableSelectionKeysType } from "primereact/treetable";
import _ from "lodash";

export const extractKeys = (node: TreeNode | TreeNode[]): string[] => {
  let keys: string[] = [];

  if (Array.isArray(node)) {
    node.forEach((child) => {
      keys = keys.concat(extractKeys(child));
    });
  } else {
    // @ts-ignore
    keys.push(node.key);

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => {
        keys = keys.concat(extractKeys(child));
      });
    }
  }

  return keys;
};

type HandleExpand = (
  a: {
    node: TreeNode;
    isExpand: boolean;
    selectedNodeKeys: TreeTableSelectionKeysType;
  },
  cb: (a: TreeTableSelectionKeysType) => void
) => void;

export const handleExpand: HandleExpand = (
  { node, isExpand, selectedNodeKeys },
  cb
) => {
  const keys = extractKeys(node);

  const keysToObject = _.zipObject(keys, _.fill(Array(keys.length), isExpand));

  const isParent = keys.some((key) => key.indexOf("-") === -1);
  const parentKey = keys[0].split("-")[0];
  const parent = selectedNodeKeys[parentKey];

  if (!isParent) {
    if (parent && !isExpand) {
      return;
    }
  }

  const obj = { ...selectedNodeKeys, ...keysToObject };
  const filteredObj = _.pickBy(obj, (value) => value);

  cb(filteredObj);
};
