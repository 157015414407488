import styled, { css } from 'styled-components';

interface SpacerProps {
  $px: number;
  $horizontal?: boolean;
  $border?: boolean;
}

export const Spacer = styled.div<SpacerProps>`
  ${({ $px, $horizontal }) => css`
    height: ${$px}px;
    min-height: ${$px}px;
    width: ${$horizontal ? `${$px}px` : 'unset'};
    min-width: ${$horizontal ? `${$px}px` : 'unset'};
  `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    ${({ $px }) => css`
      height: ${$px * 0.7}px;
      min-height: ${$px * 0.7}px;
    `};
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    ${({ $px }) => css`
      height: ${$px * 0.5}px;
      min-height: ${$px * 0.5}px;
    `};
  }

  ${({ $border }) =>
    $border &&
    css`
      border: 1px solid red;
    `};
`;
