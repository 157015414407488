import { DatePickerToolbarProps } from '@mui/x-date-pickers';
import moment from 'moment';
import React from 'react';
import { Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';

const Div = styled.div`
  background: ${colors.prussianBlue};
  padding: 20px;
  display: grid;

  .tb-year {
    opacity: 0.7;
  }
`;

export const Toolbar: React.FC<DatePickerToolbarProps<moment.Moment>> = ({ className, value }) => {
  if (!value) {
    return null;
  }
  return (
    <Div className={className}>
      <Poppins color="white" weight={500} px={18} className="tb-year">
        {value?.year()}
      </Poppins>
      <Poppins color="white" weight={600} px={32}>
        {value?.format('ddd, MMM D')}
      </Poppins>
    </Div>
  );
};
