import React from 'react';
import { Poppins, Spacer } from 'src/common';
import { ArrowButton, NextIcon } from 'src/components/Wizzard/comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';

const Div = styled.div`
  .btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    &__reminder {
      cursor: pointer;
      text-decoration: underline;
    }
  }
`;

interface IntroProps {
  onClose: () => void;
  onProceed: () => void;
}

export const Intro: React.FC<IntroProps> = ({ onClose, onProceed }) => {
  return (
    <Div>
      <Spacer $px={10} />
      <div className="h-padding">
        <Poppins className="m-title" px={28}>
          Help Us Understand Your Organisation
        </Poppins>
        <Spacer $px={40} />
        <Poppins px={14} color="davysGrey">
          To help you create more accurate risk assessments, we need to gather more information about your organisation.
          Your responses will help us suggest relevant risk scenarios, pre-populate likelihood and consequence values,
          and create variables for reuse across multiple scenarios.
        </Poppins>
        <Spacer $px={20} />
        <Poppins px={14} color="davysGrey">
          If you need to gather information to answer the questions, you can leave and return to the wizard as many
          times as required. Approximate values are fine and can be changed or refined over time.
        </Poppins>
        <Spacer $px={20} />
        <Poppins px={14} color="davysGrey">
          You can save your answers and exit at any time.
        </Poppins>
        <Spacer $px={50} />
        <div className="btns">
          <Poppins
            className="btns__reminder"
            weight={500}
            onClick={() => {
              mpEvent(MPEvents.WizardClosed, {
                modal: 'Wizzard intro modal',
              });
              onClose();
            }}
          >
            Remind Me Later
          </Poppins>
          <ArrowButton
            primary
            onClick={() => {
              onProceed();
            }}
            $constWidth={225}
          >
            <div className="content">
              PROCEED
              <NextIcon />
            </div>
          </ArrowButton>
        </div>
      </div>
    </Div>
  );
};
