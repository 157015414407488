import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { PlacesType, Tooltip } from 'react-tooltip';
import { Scenario } from 'src/api/types';

const Div = styled.div`
  width: 22px;
  margin-left: auto;
  span {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    svg {
      width: 16px;
      height: 16px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    span {
      font-size: 12px !important;
    }
    svg {
      width: 14px;
      height: 14px;
    }
  }
`;

interface IncompleteStateMarkerProps {
  scenario?: { id: string; is_incomplete: number };
  place?: PlacesType;
  initState?: 'TECH' | 'PART';
  titlePart?: string;
  titleTech?: string;
}

export const getIncompleteState = (scenario: { id: string; is_incomplete: number }) => {
  if (scenario.is_incomplete === 1 || scenario.is_incomplete === 3) return 'TECH';
  if (scenario.is_incomplete === 2) return 'PART';
  return null;
};

export const IncompleteStateMarker: React.FC<IncompleteStateMarkerProps> = ({
  scenario,
  place,
  initState = 'PART',
  titlePart = 'The data in this scenario is incomplete.',
  titleTech = 'Please adjust the scenario to contain valid calculation data.',
}) => {
  const state = scenario ? getIncompleteState(scenario) : initState;

  if (!state) return null;

  const id = `iscen-${scenario?.id}${Date.now()}`;

  return (
    <Div>
      <Tooltip
        id={id}
        style={{ backgroundColor: colors.prussianBlue }}
        opacity={1}
        positionStrategy="fixed"
        place={place}
      />
      {state === 'PART' && (
        <div data-tooltip-id={id} data-tooltip-html={titlePart} data-cy="ism-incomplete">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
              d="M9.7963 6.27778V9.7963M9.7963 13.3148H9.80509M18.5926 9.7963C18.5926 14.6544 14.6544 18.5926 9.7963 18.5926C4.93824 18.5926 1 14.6544 1 9.7963C1 4.93824 4.93824 1 9.7963 1C14.6544 1 18.5926 4.93824 18.5926 9.7963Z"
              stroke="#F29539"
              strokeWidth="1.75926"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
      {state === 'TECH' && (
        <div data-tooltip-id={id} data-tooltip-html={titleTech} data-cy="ism-incomplete-tech">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19" fill="none">
            <path
              d="M2.42944 18H17.6584C17.9057 18 18.1488 17.9357 18.364 17.8136C18.5791 17.6915 18.7588 17.5157 18.8856 17.3033C19.0124 17.091 19.082 16.8494 19.0875 16.6021C19.093 16.3548 19.0342 16.1103 18.917 15.8925L11.303 1.75193C10.7628 0.749356 9.32505 0.749356 8.7849 1.75193L1.17086 15.8925C1.05361 16.1103 0.994855 16.3548 1.00035 16.6021C1.00585 16.8494 1.07541 17.091 1.20223 17.3033C1.32905 17.5157 1.50879 17.6915 1.72389 17.8136C1.939 17.9357 2.1821 18 2.42944 18Z"
              stroke="#E13D3D"
              strokeWidth="1.4297"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M9.86877 8.18146L10.0438 11.9019L10.2186 8.18298C10.2196 8.15921 10.2159 8.13546 10.2074 8.1132C10.199 8.09093 10.1862 8.07062 10.1696 8.05349C10.1531 8.03637 10.1333 8.02279 10.1113 8.0136C10.0894 8.0044 10.0658 7.99978 10.042 8.00001C10.0186 8.00024 9.9955 8.00515 9.97405 8.01445C9.9526 8.02375 9.93323 8.03726 9.91708 8.05418C9.90094 8.07109 9.88834 8.09107 9.88005 8.11293C9.87175 8.13479 9.86791 8.15809 9.86877 8.18146Z"
              stroke="#E13D3D"
              strokeWidth="0.975852"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M10.0435 14.3372C9.92287 14.3372 9.80495 14.3015 9.70466 14.2345C9.60436 14.1674 9.52618 14.0722 9.48002 13.9607C9.43386 13.8493 9.42178 13.7267 9.44531 13.6084C9.46885 13.49 9.52693 13.3814 9.61223 13.2961C9.69753 13.2108 9.8062 13.1527 9.92451 13.1292C10.0428 13.1056 10.1655 13.1177 10.2769 13.1639C10.3883 13.21 10.4836 13.2882 10.5506 13.3885C10.6176 13.4888 10.6534 13.6067 10.6534 13.7273C10.6534 13.8891 10.5892 14.0442 10.4748 14.1586C10.3604 14.273 10.2053 14.3372 10.0435 14.3372Z"
              fill="#E13D3D"
            />
          </svg>
        </div>
      )}
    </Div>
  );
};
