import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { useSidebar } from 'src/state/sidebar';

const FooterContainer = styled.div`
  overflow: hidden;
  padding: 20px 24px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 16px 20px;
  }
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 10px 16px;
  }
`;

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const SidebarFooter: React.ForwardRefRenderFunction<unknown, Props> = ({ children, className, ...rest }, ref) => {
  const { collapsed } = useSidebar();
  const sidebarFooterRef = (ref as any) || React.createRef<HTMLDivElement>();
  return (
    <FooterContainer {...rest} ref={sidebarFooterRef}>
      {!collapsed ? children : null}
    </FooterContainer>
  );
};

export default forwardRef<unknown, Props>(SidebarFooter);
