import React, { useEffect } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, PageControlSkeleton } from 'src/common';
import { getControlLibrary } from 'src/api/controllibraries';
import { useQuery } from 'react-query';
import { useParams } from 'react-router';
import { MetaSheet } from './comps';
import { QKeys } from 'src/api/types';
import useActionsApp from 'src/redux/actionHooks/useActionsApp';

const ControlLibraryPage = () => {
  const { controlLibraryId } = useParams<{ controlLibraryId: string }>();
  const { data: controlLibrary, isLoading } = useQuery([QKeys.ControlLibrary, controlLibraryId], () =>
    getControlLibrary(controlLibraryId as string),
  );

  const { setLocationPaths } = useActionsApp();

  useEffect(() => {
    if (controlLibrary) {
      setLocationPaths({
        frameworkControl: { id: controlLibrary.ux_id, name: controlLibrary.shortname || controlLibrary.name },
        framework: controlLibrary.framework_ux_id
          ? {
              id: controlLibrary.framework_ux_id,
              name: controlLibrary.framework_library.shortname || controlLibrary.framework_library.name,
            }
          : undefined,
      });
    }
    return () => {
      setLocationPaths({ framework: undefined, frameworkControl: undefined });
    };
  }, [controlLibrary]);

  useGaEventPageView(`Cydea | Control | ${controlLibrary?.shortname}`);

  return (
    <>
      <Meta title={`Cydea | Control | ${controlLibrary?.shortname}`} feedbackScript />
      {controlLibrary && (
        <PageContainer>
          {!isLoading ? <MetaSheet data={controlLibrary} /> : <PageControlSkeleton noTable />}
        </PageContainer>
      )}
    </>
  );
};

export default ControlLibraryPage;
