import React, { useCallback, useState } from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import styled, { DefaultTheme } from 'styled-components';
import { ActionMeta, Props, Theme } from 'react-select';
import { getAssessments } from 'src/api/assessment';
import { useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import colors from 'src/theme/colors';

const StyledSelect = styled(CSelect)`
  .cydea-select__control {
    background: ${colors.white};
    color: ${colors.prussianBlue};
  }
`;

interface AssessmentSelectProps extends Omit<Props<{ label: string; value: string }>, 'value'> {
  theme?: Theme & DefaultTheme;
  value?: string;
  onChange?: (value: { label: string; value: string }, meta: ActionMeta<any>) => void;
  placeholder?: string;
}

export const AssessmentSelect: React.FC<AssessmentSelectProps> = ({
  value,
  placeholder = 'Select Assessment',
  ...other
}) => {
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);

  useQuery(QKeys.Assessments, getAssessments, {
    onSuccess: (data) => {
      setOptions(
        data.map((el) => {
          return {
            label: el.name,
            value: el.id as string,
          };
        }),
      );
    },
  });

  const getOptionValue = useCallback((option) => option.value, []);
  return (
    <div data-cy="assessment-select">
      <StyledSelect
        value={options.find((el) => el.value === value)}
        getOptionValue={getOptionValue}
        placeholder={placeholder}
        options={options}
        {...other}
      />
      {other.error && other.helperText && <Error>{other.helperText}</Error>}
    </div>
  );
};
