import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';

export const Div = styled.div<{ $error?: boolean }>`
  .fi-root {
    box-sizing: border-box;
    border: 1px solid ${colors.stroke};
    background: ${colors.cultured};
    cursor: text;
    padding: 10px 12px;
    border-radius: 3px;
    display: flex;
    color: ${colors.prussianBlue};
    font-size: 14px;
    font-weight: 500;

    &__editor {
      flex: 1;
    }

    &__adorment-start {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 7px;
      height: 24px;
    }

    .DraftEditor-root {
      flex: 1;
      border: 1px solid transparent;
    }

    .mnw6qvm {
      border: 1px solid ${colors.stroke};
      position: absolute;
      background: white;
      filter: drop-shadow(0px 0px 20px rgba(7, 46, 91, 0.15));
      cursor: pointer;
      padding: 10px 8px;
      z-index: 2;
      display: grid;
      grid-gap: 2px;
      transform: scale(0);
      cursor: default;
      max-height: 400px;
      overflow-y: auto;
      ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        position: absolute;
      }
      ::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
        position: absolute;
      }
      ::-webkit-scrollbar-thumb {
        background: ${colors.aliceBlue};
        position: absolute;
      }

      &::before {
        content: 'VARIABLES';
        margin: 0 0 4px 26px;
        font-size: 14px;
        font-weight: 600 !important;
      }
    }
  }

  .fi-helper-text {
    margin-top: 4px;
    margin-left: 10px;
  }

  .fi-result {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
  }

  ${({ $error }) =>
    $error &&
    css`
      .fi-root {
        border: 1px solid ${colors.error};
      }
      .fi-helper-text {
        color: ${colors.error};
      }
    `}

  ${({ theme }) => theme.breakpoints.down('md')} {
    .fi-root {
      padding: 8px 12px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .fi-root {
      font-size: 12px;
      padding: 8px 10px;
      &__adorment-start {
        height: 20px;
        transform: translateY(0.5px);
      }
    }
  }
`;
