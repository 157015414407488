import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { useSidebar } from 'src/state/sidebar';

const collapsedCss = css`
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 30px;
  ${({ theme }) => theme.breakpoints.down('md')} {
    padding-right: 20px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-right: 15px;
  }
`;
const expandedCss = css`
  align-items: center;
  justify-content: center;
`;

const HeaderContainer = styled.div<{ collapsed?: boolean }>`
  display: flex;
  align-items: center;
  ${({ collapsed }) => (collapsed ? expandedCss : collapsedCss)};
  height: 85px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 55px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 39px;
  }
`;

export interface Props {
  className?: string;
  children?: React.ReactNode;
}

const SidebarHeader: React.ForwardRefRenderFunction<unknown, Props> = ({ children, className, ...rest }, ref) => {
  const sidebarHeaderRef = (ref as any) || React.createRef<HTMLDivElement>();
  const { collapsed } = useSidebar();
  return (
    <HeaderContainer collapsed={collapsed} {...rest} ref={sidebarHeaderRef}>
      {children}
    </HeaderContainer>
  );
};

export default forwardRef<unknown, Props>(SidebarHeader);
