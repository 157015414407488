import { AxiosArgs, OrganisationWorkspace, Plan, ThenArgs, UserAssociation, WorkspaceUser } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';

type GetUserAssociations = (id: number) => Promise<AxiosArgs<ThenArgs<AxiosResponse<UserAssociation[]>>>>;

export const getUserAssociations: GetUserAssociations = (id) => {
  const path = `/api/public/api/user/${id}/associations`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
// data: {
//   id: string;
//   name: string;
//   type: string;
//   children: {
//       id: string;
//       name: string;
//       type: string;
//   }[];
// }[]
