import React, { useEffect } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, Poppins } from 'src/common';
import Audit from 'src/components/Audit';
import { useAuth } from 'src/state/auth';
import { TableIds } from 'src/api/types/misc';
import { getAuditV2 } from 'src/api/other';

const WorkspaceAuditPage = () => {
  const { activeWorkspace, userFeatures } = useAuth();
  useGaEventPageView('Cydea | Workspace | Audit');

  useEffect(() => {
    getAuditV2('workspaces', { workspace_id: activeWorkspace?.id }).then((res) => console.log(res));
  }, []);

  if (!userFeatures.app.audit.report) return null;

  return (
    <>
      <Meta title={`Cydea | Workspace | Audit`} feedbackScript />
      <PageContainer>
        <Poppins px={32} weight={600}>
          Audit Log
        </Poppins>

        <Audit
          noHide
          maxHeight={'calc(100vh - 86px)'}
          rootPath="workspaces"
          initQuery={{ workspace_id: activeWorkspace?.id }}
          tableId={TableIds.auditWorkspace}
        />
      </PageContainer>
    </>
  );
};

export default WorkspaceAuditPage;
