import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { SidebarProvider } from 'src/state/sidebar';
import Aside from 'src/pages/SignedInPage/Aside';
import AssessmentsPage from 'src/pages/AssessmentsPage';
import ErrorPage from 'src/pages/ErrorPage';
import AssessmentPage from 'src/pages/AssessmentPage';
import { AssessmentProvider } from 'src/state/assessment';
import ScenarioPage from 'src/pages/ScenarioPage';
import { ScenarioProvider } from 'src/state/scenario';
import GetStarted from '../GetStarted';
import CompareAssessment from '../compare/CompareAssessment';
import ScenarioLibraryPage from '../ScenarioLibraryPage';
import IncidentsPage from '../IncidentsPage';
import CompareScenario from '../compare/CompareScenario';
import FrameworkLibrariesPage from '../FrameworkLibrariesPage';
import FrameworkLibrabryPage from '../FrameworkLibrabryPage';
import RiskManagementPage from '../RiskManagementPage';
import ControlPage from '../ControlPage';
import ControlLibraryPage from '../ControlLibraryPage';
import ControlsLibrariesPage from '../ControlsLibrariesPage';
import ImprovementPlansPage from '../ImprovementPlansPage';
import { useAuth } from 'src/state/auth';
import WorkspaceSettingsScreen from '../workspace/WorkspaceSettingsScreen';
import IncidentPage from '../IncidentPage';
import HeaderV2 from 'src/components/HeaderV2';
import OrganisationHierarchyPage from '../organisation/OrganisationHierarchyPage';
import WorkspaceAuditPage from 'src/pages/workspace/WorkspaceAuditPage';
import OrganisationSettingsPage from '../organisation/OrganisationSettingsPage';
import DashboardPage from '../DashboardPage';
import { TscsModal } from 'src/components/modals';
import Beamer from './Beamer';
import { useStateSelector } from 'src/redux';
import Loader from 'src/components/Loader/Loader';
import useNavLocation from 'src/hooks/useNavLocation';
import Main from './Main';
import { makeLogout } from 'src/utils/auth';
import { WizzardWorkspace } from 'src/components/Wizzard';
import ScenarioTemplatePage from '../ScenarioTemplatePage';

const LogoutPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    makeLogout();
    navigate('/');
  }, [navigate]);

  return null;
};

const SignedInPage = () => {
  const { isSignedIn, appLoaded } = useAuth();
  const isMaintenanceMode = useStateSelector(({ app }) => app.isMaintenanceMode);
  useNavLocation();

  if (!isSignedIn) return <Navigate to="/auth" replace />;
  if (!appLoaded || isMaintenanceMode) return <Loader />;

  return (
    <>
      <WizzardWorkspace />
      <TscsModal />
      <Beamer />
      <AssessmentProvider>
        <ScenarioProvider>
          <SidebarProvider>
            <Aside />
            <Main>
              <HeaderV2 />
              <Routes>
                <Route path="/" element={<Navigate to="/risk-assessments" replace />} />
                <Route path="/timeline" element={<DashboardPage />} />
                <Route path="/risk-assessments" element={<AssessmentsPage />} />
                <Route path="/risk-assessments/:assessmentId" element={<AssessmentPage />} />
                <Route path="/risk-assessments/:assessmentId/scenario/:scenarioId" element={<ScenarioPage />} />

                <Route path="/scenario-library" element={<ScenarioLibraryPage />} />
                <Route path="/scenario-library/:scenarioTemplateId" element={<ScenarioTemplatePage />} />

                <Route path="/control-frameworks" element={<FrameworkLibrariesPage />} />
                <Route path="/control-frameworks/all-controls" element={<ControlsLibrariesPage />} />
                <Route path="/control-frameworks/:frameworklibraryId" element={<FrameworkLibrabryPage />} />
                <Route path="/control-frameworks/all-controls/:controlLibraryId" element={<ControlLibraryPage />} />

                <Route path="/risk-management" element={<RiskManagementPage />} />
                <Route path="/risk-management/control/:controlId" element={<ControlPage />} />

                <Route path="/improvement-plans" element={<ImprovementPlansPage />} />

                <Route path="/incidents" element={<IncidentsPage />} />
                <Route path="/incidents/:incidentId" element={<IncidentPage />} />

                {/* COMPARE */}
                <Route path="/compare/assessments/:idA/:idB" element={<CompareAssessment />} />
                <Route
                  path="/compare/scenarios/:assessmentAId/:assessmentBId/:scenarioAId/:scenarioBId"
                  element={<CompareScenario />}
                />

                {/* ORGANISATION */}
                <Route path="/home" element={<OrganisationHierarchyPage />} />
                <Route path="/organisation/:id?" element={<OrganisationSettingsPage />} />

                {/* WORKSPACE */}
                <Route path="/audit/workspace" element={<WorkspaceAuditPage />} />
                <Route path="/workspace/:id?" element={<WorkspaceSettingsScreen />} />

                <Route path="/get-started" element={<GetStarted />} />

                <Route path="/error" element={<ErrorPage />} />

                <Route path="/logout" element={<LogoutPage />} />
                <Route path="*" element={<ErrorPage status={404} />} />
              </Routes>
            </Main>
          </SidebarProvider>
        </ScenarioProvider>
      </AssessmentProvider>
    </>
  );
};

export default SignedInPage;
