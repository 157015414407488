import React, { useState } from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { ControllsPanel } from '../../../comps/ControllsPanel';
import { PageCard, Spacer } from 'src/common';
import { Scenario } from 'src/api/types';
import Chart from 'src/components/charts/Chart';

const Div = styled.div`
  .lec {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

interface ChartsProps {
  dataA: Scenario;
  dataB: Scenario;
  scenarioNameA: string;
  scenarioNameB: string;
}

export const Charts: React.FC<ChartsProps> = ({ dataA, dataB, scenarioNameA, scenarioNameB }) => {
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const [seriesAEnabled, setSeriesAEnabled] = useState(true);
  const [seriesBEnabled, setSeriesBEnabled] = useState(true);

  const genName = (a: { nameA: string; nameB: string }) => {
    if (!a.nameA) return dataB.name;
    if (!a.nameB) return dataA.name;
    if (a.nameA === a.nameB) return a.nameA;
    return `${a.nameA} / ${a.nameB}`;
  };

  const charts = [
    {
      isEnabled: seriesAEnabled,
      params: {
        data: dataA.chart || [],
        upper: dataA.upper || 1,
        lower: dataA.lower || 1,
        name: 'Loss Exceedance A',
        color: colors.red,
        fillColor: colors.compareAFill,
        legendId: 'leg-ca',
      },
    },
    {
      isEnabled: seriesBEnabled,
      params: {
        data: dataB.chart || [],
        upper: dataB.upper || 1,
        lower: dataB.lower || 1,
        name: 'Loss Exceedance B',
        color: colors.compareBLine,
        fillColor: colors.compareBFill,
        legendId: 'leg-cb',
      },
    },
  ]
    .filter((el) => el.isEnabled)
    .map((el) => el.params);

  return (
    <Div>
      <PageCard>
        <ControllsPanel
          nameA={genName({ nameA: dataA.name, nameB: dataB.name })}
          scenarioNameA={scenarioNameA}
          scenarioNameB={scenarioNameB}
          seriesAEnabled={seriesAEnabled}
          seriesBEnabled={seriesBEnabled}
          isLogarithmic={isLogarithmic}
          setSeriesAEnabled={setSeriesAEnabled}
          setSeriesBEnabled={setSeriesBEnabled}
          setIsLogarithmic={setIsLogarithmic}
          typeScenario
        />
        <Spacer $px={10} />
        <Chart isLogarithmic={isLogarithmic} input={charts} />
      </PageCard>
    </Div>
  );
};
