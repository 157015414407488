import React from 'react';
import { Poppins } from 'src/common';
import CheckBox from 'src/components/form/CheckBox';
import styled from 'styled-components';

const Div = styled.div`
  user-select: none;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  cursor: pointer;
`;

interface CheckItemProps {
  title: string;
  isChecked: boolean;
  onClick: () => void;
}

export const CheckItem: React.FC<CheckItemProps> = ({ title, isChecked, onClick }) => {
  return (
    <Div onClick={onClick}>
      <CheckBox small isChecked={isChecked} />
      <Poppins color="davysGrey" px={14} weight={500} nowrap>
        {title}
      </Poppins>
    </Div>
  );
};
