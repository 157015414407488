import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { OrgUser, QKeys } from 'src/api/types';
import { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { filter } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import _ from 'lodash';
import { useAuth } from 'src/state/auth';
import { queryCache, useMutation } from 'react-query';
import { updateOrgUser } from 'src/api/organisation';
import { TableUserItem } from 'src/common';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

type UseColumns = (a: { data?: OrgUser[]; orgId: string }) => {
  columns: ColumnDef<OrgUser>[];
  GTColumnSelectAnchorExported: JSX.Element;
  profileModalId: number | null;
  setProfileModalId: (id: number | null) => void;
};

const defaultColumns = ['name', 'email', 'role'];

export const useMembersColumns: UseColumns = ({ data = [], orgId }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.orgMembers}-columns`, defaultColumns);
  const [profileModalId, setProfileModalId] = useState<number | null>(null);
  const { user } = useAuth();

  const resetCache = () => {
    queryCache.invalidateQueries([QKeys.OrganisationAdmin, orgId]);
    queryCache.invalidateQueries([QKeys.OrganisationUsers, orgId]);
    queryCache.invalidateQueries([QKeys.Organisations]);
    queryCache.invalidateQueries(QKeys.User);
  };

  const [setPermissions] = useMutation(updateOrgUser, {
    onSuccess: resetCache,
  });

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<OrgUser>[] = [
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.workspace_user_name,
        cell: ({ row }) => {
          return (
            <TableUserItem
              onClick={() => {
                setProfileModalId(row.original.id);
                mpEvent(MPEvents.ButtonClick, {
                  button: '[user click]',
                  value: row.original.id,
                  tags: ['ORGANISATION', 'USER'],
                });
              }}
              profilePhotoPath={row.original.profile_photo_path}
              name={`${row.original.workspace_user_name} ${user?.id === row.original.id ? '(You)' : ''}`}
            />
          );
        },
        sortingFn: sortingFns.text,
      },
      {
        id: 'email',
        header: 'Email',
        accessorFn: (row) => row.email || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'role',
        header: 'Role',
        accessorFn: (row) => row.member.role || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const user = row.original;
          const userId = row.original.id;

          const onMakeAdminClick =
            user.permissions['app.organisation.edit_users'] === '0'
              ? () =>
                  setPermissions({
                    params: { userId: user.id, orgId },
                    data: { name: user.name, isAdmin: true },
                  })
              : undefined;

          const onUnmakeAdminClick =
            user.permissions['app.organisation.edit_users'] === '1'
              ? () =>
                  setPermissions({
                    params: { userId: user.id, orgId },
                    data: { name: user.name, isAdmin: false },
                  })
              : undefined;

          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm onMakeAdminClick={onMakeAdminClick} onUnmakeAdminClick={onUnmakeAdminClick} />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Name' },
        { key: 'email', title: 'Email' },
        { key: 'role', title: 'Role' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
    profileModalId,
    setProfileModalId,
  };
};
