import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { Dash, Point } from './comps';
import colors from 'src/theme/colors';
import _ from 'lodash';
import { motion } from 'framer-motion';

interface DivProps {
  $contentMarginBottom: number;
  $highlight?: boolean;
}
const Div = styled.div<DivProps>`
  display: grid;
  grid-template-columns: 22px auto;
  grid-gap: 26px;
  position: relative;
  z-index: 1;

  .t-line {
    /* border: 1px solid red; */
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .t-content {
    position: relative;
    display: grid;
    grid-gap: 6px;
    margin-right: auto;
    max-width: calc(100% - 30px);
    flex: 1;
    transform: translateY(-2px);
    margin-bottom: ${({ $contentMarginBottom }) => $contentMarginBottom}px;

    &__head {
      cursor: pointer;
      user-select: none;
      display: inline-flex;
      align-items: center;
      grid-gap: 8px;
    }

    &__collapse {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      grid-gap: 6px;
      /* padding: 2px 0; */
      padding-top: 2px;
    }

    ${({ $highlight }) =>
      $highlight &&
      css`
        &::before {
          content: '';
          position: absolute;
          top: -17px;
          bottom: -17px;
          left: -15px;
          right: -15px;
          border-radius: 6px;
          background: ${colors.cultured};
          border: 1px solid ${colors.stroke};
          z-index: -1;
        }
      `}
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    grid-gap: 20px;
  }
`;

interface RefActions {
  centerToday: () => void;
}

interface TimelineProps {
  isActive?: boolean;
  completeness?: number;
  headComponent: JSX.Element;
  content?: JSX.Element;
  isLast?: boolean;
  highlight?: boolean;
  shouldFocus?: boolean;
  contentMarginBottom?: number;
  onSelect?: () => void;
  ref?: React.Ref<RefActions>;
}

export const Timeline: React.FC<TimelineProps> = memo(
  forwardRef<RefActions, TimelineProps>(
    (
      {
        isActive,
        completeness,
        headComponent,
        content,
        isLast,
        highlight,
        shouldFocus,
        contentMarginBottom = 20,
        onSelect,
      },
      ref,
    ) => {
      const [isOpen, setIsOpen] = useState(true);
      const innerRef = useRef<HTMLDivElement>(null);

      const centerToday = () => {
        if (innerRef.current) {
          const container = innerRef.current.closest('.activity-container.styled-scroll') as HTMLElement | null;
          if (container) {
            const elementRect = innerRef.current.getBoundingClientRect();
            const containerRect = container.getBoundingClientRect();

            const scrollTopPosition =
              innerRef.current.offsetTop - container.offsetTop - containerRect.height / 2 + elementRect.height / 2;
            container.scrollTop = scrollTopPosition;
          }
        }
      };

      useEffect(() => {
        if (highlight && shouldFocus) {
          centerToday();
        }
      }, [highlight, shouldFocus]);

      useEffect(() => {
        if (typeof ref === 'function') {
          ref({ centerToday });
        } else if (ref) {
          ref.current = { centerToday };
        }
      }, [ref]);

      return (
        <Div ref={innerRef} $contentMarginBottom={contentMarginBottom} $highlight={highlight} onClick={onSelect}>
          <div className="t-line">
            <Point isActive={isActive} />
            {!isLast && <Dash completeness={completeness} />}
          </div>
          <div className="t-content">
            <div className="t-content__head" onClick={() => setIsOpen(!isOpen)}>
              {headComponent}
              <motion.svg
                style={{ rotateZ: isOpen ? 0 : 180 }}
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
              >
                <path
                  d="M9.88629 5.93359H0.659141C0.480523 5.93359 0.326108 5.86838 0.195643 5.73806C0.0651783 5.60759 0 5.45318 0 5.27467C0 5.09616 0.0651783 4.94164 0.195643 4.81124L4.80923 0.197722C4.93984 0.0672574 5.09426 0.00193596 5.27273 0.00193596C5.45121 0.00193596 5.60577 0.0672574 5.73612 0.197722L10.3497 4.81128C10.48 4.94164 10.5455 5.09616 10.5455 5.2747C10.5455 5.45318 10.4801 5.60759 10.3497 5.73809C10.2194 5.86852 10.0648 5.93359 9.88629 5.93359Z"
                  fill="#9EA5B7"
                />
              </motion.svg>
            </div>

            <motion.div
              initial={{ height: 'unset' }}
              animate={{ height: isOpen ? 'unset' : 0 }}
              className="t-content__collapse"
              transition={{ type: 'tween' }}
            >
              {content}
            </motion.div>
          </div>
        </Div>
      );
    },
  ),
  (prev, next) => _.isEqual(prev, next),
);
