import { queryCache, useMutation } from 'react-query';
import { switchOrganisation } from 'src/api/auth';

type SwitchOrgParams = string | { workspaceId: string; redirectUrl?: string };

type UseWorkspaceSwitch = (params?: { reloleadWindowOnSuccess?: boolean; onError?: (data: any) => void }) => {
  switchOrg: (params: SwitchOrgParams) => void;
  isLoading: boolean;
  error: any;
};

const useWorkspaceSwitch: UseWorkspaceSwitch = (options = {}) => {
  const { onError, reloleadWindowOnSuccess } = options;

  const [switchOrg, { isLoading, error }] = useMutation(
    (arg: SwitchOrgParams) => {
      const workspaceId = typeof arg === 'string' ? arg : arg.workspaceId;
      return switchOrganisation(workspaceId);
    },
    {
      onSuccess: (data, variables) => {
        let redirectUrl;

        if (typeof variables === 'object' && 'redirectUrl' in variables) {
          redirectUrl = variables.redirectUrl;
        }

        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else if (reloleadWindowOnSuccess) {
          window.location.replace('/');
        } else {
          queryCache.invalidateQueries();
        }
      },
      onError: (err) => {
        if (onError) onError(err);
      },
    },
  );

  return { switchOrg, isLoading, error };
};

export default useWorkspaceSwitch;
