import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';

export const Divider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.stroke};
  position: absolute;
  left: 0;
`;
