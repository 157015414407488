import React, { ChangeEvent, useRef, useState } from 'react';
import { OrganisationWorkspace, QKeys } from 'src/api/types';
import { GradientText, GradientTextAction, InputLabel, Poppins, Spacer, StyledAvatar } from 'src/common';
import Button from 'src/components/form/Button';
import styled from 'styled-components';
import { getProfileCredentials } from 'src/utils/auth';
import MaterialInput from 'src/components/form/MaterialInput';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { queryCache, useMutation } from 'react-query';
import { updateOrg } from 'src/api/organisation';
import { ConfirmDeleteModal } from 'src/components/modals';
import _ from 'lodash';
import { TabWrap } from 'src/components/TabsMenu';
import { useAuth } from 'src/state/auth';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled(TabWrap)`
  .prof-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 12px;
    &__input {
      display: none;
    }
  }

  form {
    .contents {
      display: grid;
      grid-gap: 50px;
      grid-template-columns: 1fr 1fr;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    form {
      .contents {
        grid-gap: 20px;
      }
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    form {
      .contents {
        grid-template-columns: 1fr;
      }
    }
  }
`;

interface DetailsProps {
  organisation: OrganisationWorkspace;
}

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  primary_contact: Yup.string().email('Invalid email address').required('Required'),
  billing_contact: Yup.string().email('Invalid email address').nullable(),
});

export const Details: React.FC<DetailsProps> = ({ organisation }) => {
  const [selectedImage, setSelectedImage] = useState<string | undefined>(undefined);
  const [err, setErr] = useState('');
  const [deleteOrgId, setDeleteOrgId] = useState<string | undefined>(undefined);
  const { userPermissions } = useAuth();

  const inputRef = useRef<HTMLInputElement>(null);

  const [update] = useMutation(updateOrg, {
    onSuccess: () => {
      queryCache.invalidateQueries([QKeys.OrganisationAdmin, organisation.id]);
      queryCache.invalidateQueries([QKeys.Organisations]);
      queryCache.invalidateQueries(QKeys.User);
      queryCache.invalidateQueries([QKeys.OrgHierarchy]);
    },
    onError: (err: any) => {
      setErr(err.message || 'Something went wrong');
    },
  });

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (event: ProgressEvent<FileReader>) => {
        setSelectedImage(event.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const { values, handleChange, handleBlur, touched, errors, handleSubmit, isValid, isSubmitting, dirty } = useFormik({
    validationSchema,
    initialValues: {
      name: organisation.name,
      primary_contact: organisation.primary_contact,

      billing_contact: organisation.billing_contact,
      plan: organisation.plan,
      admin_id: organisation.admin_id,
      is_restricted: organisation.is_restricted,
      domain: organisation.domain,
    },
    onSubmit: (values) => {
      return update({ ...values, id: organisation.id });
    },
    enableReinitialize: true,
  });

  return (
    <>
      {deleteOrgId && (
        <>
          <ConfirmDeleteModal
            title={null}
            onConfirm={() => {
              setDeleteOrgId(undefined);
            }}
            onClose={() => setDeleteOrgId(undefined)}
          >
            <Poppins px={18} weight={600} color="cflowerBlue">
              Are you sure you want to delete{' '}
              <Poppins px={18} weight={600} color="prussianBlue">
                {organisation.name}
              </Poppins>{' '}
              ?
            </Poppins>
          </ConfirmDeleteModal>
        </>
      )}
      <Div>
        <GradientText>DETAILS</GradientText>
        <Spacer $px={32} />
        <div className="prof-img">
          <StyledAvatar src={selectedImage}>{getProfileCredentials(organisation.name)}</StyledAvatar>
          <GradientTextAction onClick={() => inputRef.current?.click()}>Update Photo</GradientTextAction>
          <input className="prof-img__input" ref={inputRef} type="file" onChange={handleImageChange} accept="image/*" />
        </div>

        <Spacer $px={32} />
        <form onSubmit={handleSubmit}>
          <div className="contents">
            <div>
              <InputLabel>NAME</InputLabel>
              <MaterialInput
                id="name"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
                fullWidth
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <Spacer $px={20} />
              <InputLabel>PRIMARY CONTACT EMAIL ADDRESS</InputLabel>
              <MaterialInput
                id="primary_contact"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.primary_contact}
                type="email"
                fullWidth
                error={touched.primary_contact && Boolean(errors.primary_contact)}
                helperText={touched.primary_contact && errors.primary_contact}
              />
              <Spacer $px={20} />
              <InputLabel>ORGANISATION</InputLabel>
              <MaterialInput value={organisation.name} fullWidth disabled />
            </div>
            <div>
              <InputLabel>ASSOCIATED DOMAIN</InputLabel>
              <MaterialInput value={organisation.domain?.replace(/^@/, '')} fullWidth disabled />
              <Spacer $px={20} />
              <InputLabel>BILLING CONTACT EMAIL ADDRESS (OPTIONAL)</InputLabel>
              <MaterialInput
                id="billing_contact"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.billing_contact}
                type="email"
                fullWidth
                error={touched.billing_contact && Boolean(errors.billing_contact)}
                helperText={touched.billing_contact && errors.billing_contact}
              />
              {!userPermissions.app.platform.admin && (
                <>
                  <Spacer $px={20} />
                  <InputLabel>ACCOUNT TYPE</InputLabel>
                  <MaterialInput value={'Organisation'} disabled fullWidth />
                </>
              )}
            </div>
          </div>
          {err && (
            <>
              <Spacer $px={20} />
              <div className="err">{err}</div>
            </>
          )}
          <Spacer $px={50} />

          <Button
            primary
            type="submit"
            disabled={!isValid || isSubmitting || !dirty}
            $constWidth={220}
            onClick={() =>
              mpEvent(MPEvents.ButtonClick, {
                button: 'Update',
                tags: ['ORGANISATION'],
              })
            }
          >
            UPDATE
          </Button>
        </form>
      </Div>
    </>
  );
};
