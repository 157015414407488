import React, { useState } from 'react';
import { Divider, GradientText, Poppins, Spacer, TitleWithBack } from 'src/common';
import styled, { css } from 'styled-components';
import Input from 'src/components/form/Input';
import colors from 'src/theme/colors';
import { LogSwitch } from 'src/components/charts/comps';
import { Assessment, AssessmentVersion } from 'src/api/types';
import Versions from './Versions';
import { VersionString } from '../../comps';
import { Tooltip } from 'react-tooltip';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { formatDate } from 'src/utils/misc';
import { LegendComposed } from './LegendComposed';

const Div = styled.div`
  position: relative;

  .panel {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    align-items: center;
    position: relative;

    &__head {
      display: grid;
      grid-template-columns: minmax(10px, auto) minmax(10px, auto) minmax(10px, auto) minmax(10px, auto);
      grid-gap: 10px;
      margin-right: auto;
      align-items: center;

      &__versions-controll {
        height: 30px;
        width: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    &__title {
      font-weight: 600;
      color: ${colors.prussianBlue};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .data-row {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: 20px;
    max-width: 100%;

    &__item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      /* border: 1px solid red; */
      &__indicator {
        width: 24px;
        height: 4px;
        border-radius: 50px;
        background-color: ${colors.compareA};
      }
      &__name {
        font-weight: 600;
        font-size: 16px;
        color: ${colors.prussianBlue};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 8px;
        display: inline-flex;
        align-items: center;
        grid-gap: 8px;
        transform: translateY(-3px);
      }
      &__desc {
        font-weight: 500;
        font-size: 16px;
        color: ${colors.cflowerBlue};
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__date-label {
        font-weight: 500;
        font-size: 14px;
        color: ${colors.cflowerBlue};
      }
      &__date-value {
        display: inline-block;
        text-align: end;
        font-weight: 500;
        font-size: 14px;
        color: ${colors.prussianBlue};
        margin-left: 8px;
        min-width: 90px !important;
      }
    }
  }

  .switch-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .purple {
    background-color: ${colors.compareB};
  }
`;

const VersionArrow = styled.svg<{ $isOpen: boolean }>`
  ${({ $isOpen }) =>
    $isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

interface HeadProps {
  dataA: Assessment;
  dataB: Assessment;
  versionA?: AssessmentVersion;
  versionB?: AssessmentVersion;
  isLogarithmic: boolean;
  setIsLogarithmic: (val: boolean) => void;
  isArea: boolean;
  setIsArea: (val: boolean) => void;
  showProjectedA: boolean;
  showProjectedB: boolean;
}

export const Head: React.FC<HeadProps> = ({
  dataA,
  dataB,
  versionA,
  versionB,
  isLogarithmic,
  setIsLogarithmic,
  isArea,
  setIsArea,
  showProjectedA,
  showProjectedB,
}) => {
  const [isVersionsOpen, setIsVersionsOpen] = useState(false);

  return (
    <Div>
      <div className="paddings">
        <div className="panel">
          <TitleWithBack title={'ASSESSMENTS'} gradient />
          <div css="position: relative; z-index:5000">
            {isVersionsOpen && (
              <Versions assesmentA={dataA} assesmentB={dataB} handleClose={() => setIsVersionsOpen(false)} />
            )}
          </div>
          <div className="panel__head">
            <Poppins px={24} weight={600}>
              Compare Assessments
            </Poppins>
            <div
              className="panel__head__versions-controll"
              onClick={() => {
                setIsVersionsOpen(!isVersionsOpen);
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Versions',
                  value: isVersionsOpen ? 'Close' : 'Open',
                });
              }}
            >
              <VersionArrow
                xmlns="http://www.w3.org/2000/svg"
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                $isOpen={isVersionsOpen}
              >
                <path
                  d="M9.88629 0H0.659141C0.480523 0 0.326108 0.0652146 0.195643 0.195535C0.0651783 0.326 0 0.480415 0 0.658925C0 0.837435 0.0651783 0.991959 0.195643 1.12235L4.80923 5.73587C4.93984 5.86634 5.09426 5.93166 5.27273 5.93166C5.45121 5.93166 5.60577 5.86634 5.73612 5.73587L10.3497 1.12232C10.48 0.991958 10.5455 0.837435 10.5455 0.658889C10.5455 0.480415 10.4801 0.326 10.3497 0.195499C10.2194 0.06507 10.0648 0 9.88629 0Z"
                  fill={colors.cflowerBlue}
                />
              </VersionArrow>
            </div>
          </div>
        </div>
        {(versionA || versionB) && <Spacer $px={5} />}
        {versionA && (
          <VersionString name={versionA.name} userName={versionA.userName} createdAt={versionA.created_at} />
        )}
        {versionB && (
          <VersionString name={versionB.name} userName={versionB.userName} createdAt={versionB.created_at} />
        )}
        <Spacer $px={20} />
        <div className="data-row">
          <div className="data-row__item">
            <span className="data-row__item__name">
              <span className="data-row__item__indicator" />
              Assessment 1:
            </span>
            <span className="data-row__item__desc">{dataA.name}</span>
          </div>
          <div className="data-row__item">
            <span className="data-row__item__date-label">REVIEW DATE</span>
            <span className="data-row__item__date-value">{formatDate(dataA.review_date)}</span>
          </div>
        </div>
        <Spacer $px={5} />
        <div className="data-row">
          <div className="data-row__item">
            <div className="data-row__item__name">
              <div className="data-row__item__indicator purple" />
              Assessment 2:
            </div>
            <span className="data-row__item__desc">{dataB.name}</span>
          </div>
          <div className="data-row__item">
            <span className="data-row__item__date-label">REVIEW DATE</span>
            <span className="data-row__item__date-value">{formatDate(dataB.review_date)}</span>
          </div>
        </div>
      </div>

      <Spacer $px={20} />
      <Divider />
      <Spacer $px={20} />
      <div className="lec paddings">
        <GradientText px={18}>LOSS EXCEEDANCE CURVE</GradientText>
        <div className="switch-container" css="gap: 20px;">
          <LegendComposed showProjectedA={showProjectedA} showProjectedB={showProjectedB} />
          <div className="switch-container" css="gap: 20px;">
            <div className="switch-container">
              <Poppins css="margin-right: 10px;" px={14} color="cflowerBlue">
                Area
              </Poppins>
              <Input
                checked={!isArea}
                onChange={(e) => {
                  setIsArea(!e.target.checked);
                }}
                className="switch"
                type="checkbox"
              />
            </div>
            <LogSwitch isLogarithmic={isLogarithmic} setIsLogarithmic={setIsLogarithmic} />
          </div>
        </div>
      </div>
    </Div>
  );
};
