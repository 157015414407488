import React from 'react';
import { Assessment } from 'src/api/types';
import Chart, { ChartConf } from 'src/components/charts/Chart/Chart';
import { getMinMax, parseRiskTolerance, plotLineGradient } from 'src/components/charts/util';
import colors from 'src/theme/colors';

interface VersionedChartProps {
  isLogarithmic: boolean;
  isLegacyVersion: boolean;
  showAllProjected?: boolean;
  live: Assessment;
  base?: Assessment;
  complete?: Assessment;
}

export const VersionedChart: React.FC<VersionedChartProps> = ({
  isLogarithmic,
  isLegacyVersion,
  showAllProjected,
  base,
  live,
  complete,
}) => {
  const input = !isLegacyVersion
    ? ([
        showAllProjected
          ? {
              data: base?.chart || [],
              upper: getMinMax(base?.scenarios).upper || 1,
              lower: getMinMax(base?.scenarios).lower || 1,
              name: 'Unmodified Risk',
              color: colors.prussianBlue,
              fillColor: 'rgba(2, 3, 51, 0.07)',
              legendId: 'leg-base-m',
            }
          : null,
        showAllProjected
          ? {
              data: complete?.chart || [],
              upper: getMinMax(complete?.scenarios).upper || 1,
              lower: getMinMax(complete?.scenarios).lower || 1,
              name: 'Projected Risk',
              color: colors.brightBlue,
              fillColor: 'rgba(100, 149, 237, 0.07)',
              legendId: 'leg-s2-m',
            }
          : null,
        {
          data: live?.chart || [],
          upper: getMinMax(live?.scenarios).upper || 1,
          lower: getMinMax(live?.scenarios).lower || 1,
          name: 'Live Risk',
          color: plotLineGradient,
          fillColor: 'rgba(230, 46, 97, 0.07)',
          legendId: 'leg-s1-m',
        },
        {
          data: parseRiskTolerance(base?.risk_tolerance || ''),
          name: 'Risk Tolerance',
          type: 'spline',
          dashStyle: 'ShortDash',
          legendId: 'leg-rt',
          fillColor: colors.cflowerBlue,
          dataLabels: {
            enabled: false,
          },
          color: colors.cflowerBlue,
          isRiskTolerance: true,
        },
      ].filter(Boolean) as ChartConf[])
    : [
        {
          data: base?.chart || [],
          upper: getMinMax(base?.scenarios || []).upper || 1,
          lower: getMinMax(base?.scenarios || []).lower || 1,
          name: 'Loss Exceedance',
          color: colors.gradientPart,
          fillColor: colors.aliceBlue,
          legendId: 'legend1',
        },
        {
          data: parseRiskTolerance(base?.risk_tolerance),
          name: 'Risk Tolerance',
          type: 'spline',
          dashStyle: 'ShortDash',
          legendId: 'leg-rt',
          fillColor: colors.cflowerBlue,
          dataLabels: {
            enabled: false,
          },
          color: colors.cflowerBlue,
          isRiskTolerance: true,
        },
      ];

  // ChartConf

  return <Chart isLogarithmic={isLogarithmic} input={input} />;
};
