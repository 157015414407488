import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { WarnIconGrey } from './icons';

interface EPIncidentCreatedProps {
  incident: {
    id: string;
    name: string;
    occuredDate: string | null;
    refId: string;
  };
  isToday?: boolean;
}

export const EPIncidentCreated: React.FC<EPIncidentCreatedProps> = ({ incident, isToday }) => {
  return (
    <Base
      iconComponent={<WarnIconGrey />}
      constentComponent={
        <Text $isPast={!isToday}>
          Incident{' '}
          <Link to={`/incidents/${incident.id}`}>
            <TextLink $isPast={!isToday}>{incident.name}</TextLink>
          </Link>{' '}
          occurred.
        </Text>
      }
    />
  );
};
