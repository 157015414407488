import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { ChartIconGrey } from './icons';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

interface EPAssessmentCreatedProps {
  assessment: {
    id: string;
    name: string;
  };
  isToday?: boolean;
}

export const EPAssessmentCreated: React.FC<EPAssessmentCreatedProps> = ({ assessment, isToday }) => {
  return (
    <Base
      iconComponent={<ChartIconGrey />}
      constentComponent={
        <Text $isPast={!isToday}>
          Assessment{' '}
          <Link
            to={`/risk-assessments/${assessment.id}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['Timeline'],
                },
                destination: {
                  value: ['Assessment'],
                  params: {
                    id: assessment.id,
                  },
                },
                tags: ['TIMELINE', 'ASSESSMENT'],
              })
            }
          >
            <TextLink $isPast={!isToday}>{assessment.name}</TextLink>
          </Link>{' '}
          created.
        </Text>
      }
    />
  );
};
