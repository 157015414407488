import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconDelete: React.FC<Props> = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="11" height="13" viewBox="0 0 11 13" fill="none">
      <g clipPath="url(#clip0_998_1321)">
        <path
          d="M0.412992 3.68164C0.123776 3.68164 -0.07687 3.40996 0.0316544 3.16088C0.416819 2.27627 1.35116 1.65039 2.44023 1.65039H2.94348C3.13128 0.729473 4.00057 0 5.06448 0H5.93923C7.00233 0 7.87216 0.728965 8.06023 1.65039H8.56349C9.65228 1.65039 10.5869 2.27627 10.9721 3.16088C11.0806 3.40996 10.8799 3.68164 10.5905 3.68164H0.412992ZM5.93923 0.761719H5.06448C4.46583 0.761719 3.95765 1.13572 3.78653 1.65039H7.21719C7.04607 1.13572 6.53789 0.761719 5.93923 0.761719Z"
          fill={isActive ? colors.red : colors.cflowerBlue}
        />
        <path
          d="M9.46419 11.9387C9.41854 12.5338 8.87947 13 8.23707 13H2.7666C2.1242 13 1.58514 12.5338 1.53949 11.9387L0.965158 4.44336H10.0391L9.46419 11.9387ZM4.38079 6.48096C4.36958 6.27098 4.17659 6.10898 3.95079 6.11965C3.72472 6.13006 3.55032 6.30906 3.5618 6.51904L3.78049 10.5815C3.79142 10.7852 3.97266 10.9434 4.18971 10.9434C4.42589 10.9434 4.61123 10.76 4.59948 10.5435L4.38079 6.48096ZM7.05343 6.11965C6.82763 6.10924 6.63464 6.27098 6.62343 6.48096L6.40474 10.5435C6.39299 10.76 6.5786 10.9434 6.81451 10.9434C7.03156 10.9434 7.2128 10.7852 7.22373 10.5815L7.44242 6.51904C7.45363 6.30906 7.2795 6.13006 7.05343 6.11965Z"
          fill={isActive ? colors.red : colors.cflowerBlue}
        />
      </g>
      <defs>
        <clipPath id="clip0_998_1321">
          <rect width="11" height="13" fill="white" transform="matrix(-1 0 0 1 11 0)" />
        </clipPath>
      </defs>
    </svg>
  );
};
