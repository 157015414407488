import Select from 'react-select';
import colors from 'src/theme/colors';
import styled from 'styled-components';

export const Error = styled.span`
  font-size: 12px;
  margin-left: 14px;
  margin-right: 14px;
  text-align: left;
  color: ${colors.error};
`;

interface CSelectProps {
  circle?: boolean;
  error?: boolean;
}

// @ts-ignore
const CSelect = styled(Select).attrs((props) => ({
  classNamePrefix: 'cydea-select',
  className: 'cydea-select',
  noOptionsMessage: () => '',
  isSearchable: false,
  ...props,
}))<CSelectProps>`
  min-width: 260px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: ${colors.prussianBlue};
  background: ${colors.white};

  .cydea-select__value-container {
    padding: 0 20px !important;
  }

  .cydea-select__control {
    cursor: pointer;
    border-radius: 3px;
    height: 50px;
    border: 1px solid ${colors.stroke};
    ${({ error }) => error && `border: 1px solid ${colors.error}`};
    z-index: 3;
    background: ${colors.cultured};
    box-shadow: none;

    &:hover {
      border: 1px solid ${colors.stroke};
    }

    &--is-focused {
      border: 1px solid ${colors.stroke};
      outline: none !important;
    }

    &--is-disabled {
      pointer-events: visible;
      cursor: not-allowed;
      .cydea-select__dropdown-indicator {
        color: transparent;
      }
    }
  }

  .cydea-select__menu {
    margin-top: 0px;
    filter: drop-shadow(0px 0px 37px rgba(7, 46, 91, 0.23));
    z-index: 1000;
    border: 1px solid ${colors.stroke};
    box-shadow: 0px 0px 37px rgba(0, 0, 0, 0);
    transform: translateY(-2px);
    background: #fff;
  }

  .cydea-select__option {
    font-weight: 500;
    font-size: 14px;
    width: auto;
    padding: 0 12px;
    margin: 5px 8px;
    background: ${colors.white};
    color: ${colors.prussianBlue};

    &--is-disabled {
      background: white !important;
      border-radius: 3px;
      color: ${colors.cflowerBlue} !important;
      cursor: default !important;
    }

    &--is-selected {
      font-weight: 600;
    }

    &:hover {
      background: ${colors.gradient};
      border-radius: 3px;
      color: ${colors.white};
      cursor: pointer;
    }
  }

  .cydea-select__group-heading {
    font-size: 14px;
  }

  .cydea-select__value-container {
    padding: 0 26px;
  }
  .cydea-select__single-value {
    color: ${colors.prussianBlue};
  }

  .cydea-select__dropdown-indicator {
    color: ${colors.davysGrey};
    margin-right: 12px;
  }

  .cydea-select__indicator-separator {
    display: none;
  }

  .cydea-select__placeholder {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.cflowerBlue};
    opacity: 0.6;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px;
    height: 40px;

    .cydea-select__control {
      height: 40px;
    }
    .cydea-select__option {
      font-size: 14px;
      padding: 0 10px;
    }
    .cydea-select__group-heading {
      font-size: 14px;
    }
    .cydea-select__placeholder {
      font-size: 14px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 34px;
    font-size: 12px;

    .cydea-select__control {
      height: 34px;
    }
    .cydea-select__option {
      font-size: 12px;
      padding: 0 10px;
    }
    .cydea-select__group-heading {
      font-size: 12px;
    }
    .cydea-select__placeholder {
      font-size: 12px;
    }
  }
`;

export default CSelect;
