import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { ChartIcon } from './icons';
import { MPEvents, mpEvent } from 'src/utils/mixpanel';

interface EFAssessmentStartDueProps {
  assessment: {
    id: string;
    name: string;
  };
}

export const EFAssessmentStartDue: React.FC<EFAssessmentStartDueProps> = ({ assessment }) => {
  return (
    <Base
      iconComponent={<ChartIcon />}
      constentComponent={
        <Text>
          Assessment{' '}
          <Link
            to={`/risk-assessments/${assessment.id}`}
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['Timeline'],
                },
                destination: {
                  value: ['Assessment'],
                  params: {
                    id: assessment.id,
                  },
                },
                tags: ['TIMELINE', 'ASSESSMENT'],
              })
            }
          >
            <TextLink>{assessment.name}</TextLink>
          </Link>{' '}
          due to start.
        </Text>
      }
    />
  );
};
