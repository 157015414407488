import React from 'react';
import Chart from 'src/components/charts/Chart';
import { useQuery } from 'react-query';
import { ProjectionVariant, QKeys } from 'src/api/types';
import { getScenario } from 'src/api/scenario';
import useVersion from 'src/hooks/useVersion';
import { useParams } from 'react-router-dom';
import colors from 'src/theme/colors';
import { CSkeleton } from 'src/common';
import _ from 'lodash';
import { ChartConf } from 'src/components/charts/Chart/Chart';
import { plotLineGradient } from 'src/components/charts/util';

interface VersionedChartProps {
  isLogarithmic: boolean;
  isLegacyVersion: boolean;
  showAllProjected?: boolean;
}

export const VersionedChart: React.FC<VersionedChartProps> = ({ isLogarithmic, isLegacyVersion, showAllProjected }) => {
  const { scenarioId } = useParams<{ scenarioId: string }>();
  const { version } = useVersion();

  const { data: scenarioLive, isLoading: isLiveLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.live, version }],
    getScenario,
    { enabled: !isLegacyVersion },
  );
  const { data: scenarioBase, isLoading: isBaseLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.base, version }],
    getScenario,
    { enabled: isLegacyVersion || showAllProjected },
  );
  const { data: dataComplete, isLoading: isCompleteLoading } = useQuery(
    [QKeys.Scenario, { scenarioId, variant: ProjectionVariant.complete, version }],
    getScenario,
    { enabled: !isLegacyVersion && showAllProjected },
  );

  const isLoading = isLiveLoading || isCompleteLoading || isBaseLoading;

  if (isLoading) return <CSkeleton height={250} />;

  const input = (
    !isLegacyVersion
      ? [
          showAllProjected
            ? {
                data: scenarioBase?.chart || [],
                upper: scenarioBase?.upper || 1,
                lower: scenarioBase?.lower || 1,
                name: 'Unmodified Risk',
                color: colors.prussianBlue,
                fillColor: 'rgba(2, 3, 51, 0.07)',
                legendId: 'leg-base-m',
              }
            : null,
          showAllProjected
            ? {
                data: dataComplete?.chart || [],
                upper: dataComplete?.upper || 1,
                lower: dataComplete?.lower || 1,
                name: 'Projected Risk',
                color: colors.brightBlue,
                fillColor: 'rgba(100, 149, 237, 0.07)',
                legendId: 'leg-s2-m',
              }
            : null,
          {
            data: scenarioLive?.chart || [],
            upper: scenarioLive?.upper || 1,
            lower: scenarioLive?.lower || 1,
            name: 'Live Risk',
            color: plotLineGradient,
            fillColor: 'rgba(230, 46, 97, 0.07)',
            legendId: 'leg-s1-md',
          },
        ].filter(Boolean)
      : [
          {
            data: scenarioBase?.chart || [],
            upper: scenarioBase?.upper || 1,
            lower: scenarioBase?.lower || 1,
            name: 'Projected Risk',
            color: plotLineGradient,
            fillColor: colors.aliceBlue,
            legendId: 'legend1',
          },
        ]
  ) as ChartConf[];

  return <Chart isLogarithmic={isLogarithmic} input={input} />;
};
