import { AxiosArgs, OrganisationWorkspace, Plan, ThenArgs, Variable, VariablePost, WorkspaceUser } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody, encodeQueryParams } from 'src/api/client';

type WorkspaceJoinRequest = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const workspaceJoinRequest: WorkspaceJoinRequest = (id) => {
  const path = `/api/public/api/organisations/${id}/request`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type WorkspaceJoin = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const workspaceJoin: WorkspaceJoin = (id) => {
  const path = `/api/public/api/organisations/${id}/join`;
  return axios({
    method: 'POST',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type WorkspaceJoinRequestCancel = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const workspaceJoinRequestCancel: WorkspaceJoinRequestCancel = (id) => {
  const path = `/api/public/api/organisations/${id}/request`;
  return axios({
    method: 'DELETE',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type WorkspaceInviteAccept = (a: {
  orgId: string;
  requestId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const workspaceInviteAccept: WorkspaceInviteAccept = ({ orgId, requestId }) => {
  const path = `/api/public/api/organisations/user/${orgId}/accept/${requestId}`;
  return axios({
    method: 'PUT',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type WorkspaceInviteReject = (a: {
  orgId: string;
  requestId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const workspaceInviteReject: WorkspaceInviteReject = ({ orgId, requestId }) => {
  const path = `/api/public/api/organisations/user/${orgId}/reject/${requestId}`;
  return axios({
    method: 'PUT',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------
// -------------------------ADMIN------------------------------
// ------------------------------------------------------------

type GetWorkspaceAdmin = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const getWorkspaceAdmin: GetWorkspaceAdmin = (id) => {
  const path = `/api/public/api/organisations/admin/${id}?users=true`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type WorkspaceCreate = (params: {
  name: string;
  primary_contact: string;
  billing_contact: string | null;
  plan: Plan;
  admin_id: number;
  parent_id: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const workspaceCreate: WorkspaceCreate = (params) => {
  const path = `/api/public/api/workspaces`;
  return axios({
    method: 'POST',
    data: encodeBody(params),
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type GetWorkspaceUsers = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<WorkspaceUser[]>>>>;

export const getWorkspaceUsers: GetWorkspaceUsers = (id) => {
  const path = `/api/public/api/workspaces/admin/${id}/users`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data.users);
};
// ------------------------------------------------------------

type GetWorkspaceUser = (a: {
  workspaceId: string;
  userId: string | number;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<WorkspaceUser>>>>;

export const getWorkspaceUser: GetWorkspaceUser = ({ workspaceId, userId }) => {
  const path = `/api/public/api/workspaces/admin/${workspaceId}/user/${userId}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type UpdateWorkspaceUser = (a: {
  params: { workspaceId: string; userId: number | string };
  data: { name: string; isAdmin?: boolean };
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const updateWorkspaceUser: UpdateWorkspaceUser = ({ params, data }) => {
  const path = `/api/public/api/workspaces/admin/${params.workspaceId}/user/${params.userId}`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type WorkspaceUsersLink = (a: {
  workspaceId: string;
  add_users: number[];
  remove_users: number[];
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const workspaceUsersLink: WorkspaceUsersLink = ({ workspaceId, ...data }) => {
  const path = `/api/public/api/organisations/admin/${workspaceId}/users`;
  return axios({
    method: 'PATCH',
    url: path,
    data: encodeBody(data),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type WorkspaceUserJoinRequest = (a: {
  orgId: string;
  requestId: string;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const workspaceUserJoinRequest: WorkspaceUserJoinRequest = ({ orgId, requestId }) => {
  const path = `/api/public/api/organisations/admin/${orgId}/accept/${requestId}`;
  return axios({
    method: 'PUT',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type UpdateWorkspace = (params: {
  id: string;
  name: string;
  primary_contact: string;
  plan: Plan;
  admin_id: number;
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<OrganisationWorkspace>>>>;

export const updateWorkspace: UpdateWorkspace = ({ id, ...params }) => {
  const path = `/api/public/api/organisations/admin/${id}`;
  return axios({
    method: 'PUT',
    data: encodeBody(params),
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type GetWorkspaceVariables = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<Variable[]>>>>;

export const getWorkspaceVariables: GetWorkspaceVariables = () => {
  const path = `/api/public/api/workspaces/variables`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateWorkspaceVariables = (variables: VariablePost[]) => Promise<AxiosArgs<ThenArgs<AxiosResponse<Variable[]>>>>;

export const updateWorkspaceVariables: UpdateWorkspaceVariables = (variables) => {
  const path = `/api/public/api/workspaces/variables`;

  return axios({
    method: 'POST',
    url: path,
    data: encodeBody({ entries: variables }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
