import React from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { PageContainer, Poppins, Spacer } from 'src/common';
import colors from 'src/theme/colors';

const Div = styled(PageContainer)`
  .panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 15px;
  }

  .error {
    color: ${colors.error};
    font-size: 14px;
  }
  .success {
    color: ${colors.success};
    font-size: 14px;
  }
`;

const ImprovementPlansPage = () => {
  useGaEventPageView('Cydea | Improvement Plans');

  return (
    <>
      <Meta title={`Cydea | Improvement Plans`} feedbackScript />
      <Div>
        <Poppins px={32} weight={600}>
          Improvement Plans
        </Poppins>
        <Spacer $px={10} />
        <Poppins px={18} weight={600} color="cflowerBlue">
          Coming soon
        </Poppins>
      </Div>
    </>
  );
};

export default ImprovementPlansPage;
