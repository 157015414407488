import React from 'react';
import { ControlLibraryShort } from 'src/api/types';
import { Spacer } from 'src/common';
import styled from 'styled-components';
import { Label, Wrap } from './styles';

const Div = styled(Wrap)``;

interface TableSheetControlLibraryProps {
  data: ControlLibraryShort;
}

export const TableSheetControlLibrary: React.FC<TableSheetControlLibraryProps> = ({ data }) => {
  return (
    <Div>
      <div className="grid grid--2">
        <div>
          <Label>NAME</Label>
          <div className="value" data-cy="sheet-name">
            {data.name}
          </div>
        </div>
        <div>
          <Label>Short Name</Label>
          <div className="value">{data.shortname}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <div>
        <Label>Framework</Label>
        <div className="value">{data.frameworkLibraryName}</div>
      </div>

      <Spacer $px={10} />
      <div className="grid grid--3">
        <div>
          <Label>Framework ID</Label>
          <div className="value">{data.controlLibraryId}</div>
        </div>
        <div>
          <Label>Strategy</Label>
          <div className="value">{data.strategy}</div>
        </div>
        <div>
          <Label>Author</Label>
          <div className="value">{data.FrameworkAuthor}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <Label>DESCRIPTION</Label>
      <div className="value">{data.description}</div>
      {/* <Spacer $px={10} />
      <div className="grid grid--2">
        <div>
          <Label>STATUS</Label>
          <div className="value">{data.status}</div>
        </div>
        <div>
          <Label>DATE STARTED</Label>
          <div className="value">{data.created_at}</div>
        </div>
      </div> */}
    </Div>
  );
};
