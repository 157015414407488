import React, { useState } from 'react';
import styled from 'styled-components';
import { ThreeDots } from 'src/components/CommonMenu/comps';
import CommonMenu from 'src/components/CommonMenu';

const Div = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 8px;
  margin-right: 4px;
  min-width: 22px;
  margin-left: auto;
`;

interface GTTdmProps {
  onViewClick?: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
  onAddToRMPClick?: () => void;
  onMakeAdminClick?: () => void;
  onUnmakeAdminClick?: () => void;
  onRemoveUserClick?: () => void;
  onCancelInviteClick?: () => void;
  onAcceptRequestClick?: () => void;
  onRejectRequestClick?: () => void;
  onUnlinkScenarioClick?: () => void;
  onUnlinkIncidentClick?: () => void;
  onCopyClick?: () => void;
  onUnlickControl?: () => void;
  onInviteClick?: () => void;
  extraComponentLeft?: React.ReactNode;
}

export const GTTdm: React.FC<GTTdmProps> = ({
  onViewClick,
  onEditClick,
  onDeleteClick,
  onAddToRMPClick,
  onMakeAdminClick,
  onUnmakeAdminClick,
  onRemoveUserClick,
  onCancelInviteClick,
  onAcceptRequestClick,
  onRejectRequestClick,
  onCopyClick,
  onUnlinkScenarioClick,
  onUnlickControl,
  onUnlinkIncidentClick,
  extraComponentLeft,
  onInviteClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (e: React.MouseEvent<SVGElement>) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb?: () => void) => {
    e.stopPropagation();
    if (!cb) return;
    handleClose();
    cb();
  };

  const containsMenu =
    onViewClick ||
    onEditClick ||
    onDeleteClick ||
    onAddToRMPClick ||
    onMakeAdminClick ||
    onUnmakeAdminClick ||
    onCancelInviteClick ||
    onAcceptRequestClick ||
    onRejectRequestClick ||
    onCopyClick ||
    onUnlinkScenarioClick ||
    onUnlickControl ||
    onRemoveUserClick ||
    onUnlinkIncidentClick ||
    onInviteClick;

  return (
    <Div onClick={(e) => e.stopPropagation()}>
      {extraComponentLeft}
      {!!containsMenu && (
        <>
          <ThreeDots onClick={handleClick} dataCy="table-dots-menu" />
          <CommonMenu
            onViewClick={onViewClick ? (e) => onMenuClick(e, onViewClick) : undefined}
            onEditClick={onEditClick ? (e) => onMenuClick(e, onEditClick) : undefined}
            onDeleteClick={onDeleteClick ? (e) => onMenuClick(e, onDeleteClick) : undefined}
            onAddToRMPClick={onAddToRMPClick ? (e) => onMenuClick(e, onAddToRMPClick) : undefined}
            onMakeAdminClick={onMakeAdminClick ? (e) => onMenuClick(e, onMakeAdminClick) : undefined}
            onUnmakeAdminClick={onUnmakeAdminClick ? (e) => onMenuClick(e, onUnmakeAdminClick) : undefined}
            onRemoveUserClick={onRemoveUserClick ? (e) => onMenuClick(e, onRemoveUserClick) : undefined}
            onCancelInviteClick={onCancelInviteClick ? (e) => onMenuClick(e, onCancelInviteClick) : undefined}
            onAcceptRequestClick={onAcceptRequestClick ? (e) => onMenuClick(e, onAcceptRequestClick) : undefined}
            onRejectRequestClick={onRejectRequestClick ? (e) => onMenuClick(e, onRejectRequestClick) : undefined}
            onCopyClick={onCopyClick ? (e) => onMenuClick(e, onCopyClick) : undefined}
            onUnlinkScenarioClick={onUnlinkScenarioClick ? (e) => onMenuClick(e, onUnlinkScenarioClick) : undefined}
            onUnlickControl={onUnlickControl ? (e) => onMenuClick(e, onUnlickControl) : undefined}
            onUnlinkIncidentClick={onUnlinkIncidentClick ? (e) => onMenuClick(e, onUnlinkIncidentClick) : undefined}
            onInviteClick={onInviteClick ? (e) => onMenuClick(e, onInviteClick) : undefined}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          />
        </>
      )}
    </Div>
  );
};
