import { AxiosArgs, ThenArgs, ScenarioTemplate } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody } from './client';

type GetScenarioTemplates = () => Promise<AxiosArgs<ThenArgs<AxiosResponse<ScenarioTemplate[]>>>>;

export const getScenarioTemplates: GetScenarioTemplates = () => {
  const path = `/api/public/api/scenario-templates`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

export const getScenarioTemplate = (id: string): Promise<ScenarioTemplate> => {
  const path = `/api/public/api/scenario-templates/${id}`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};

// ------------------------------------------------------------

type AddTemplatesToAssessment = (a: {
  assessments: string[];
  templates: string[];
}) => Promise<AxiosArgs<ThenArgs<AxiosResponse<any>>>>;

export const addTemplatesToAssessment: AddTemplatesToAssessment = (params) => {
  const path = `/api/public/api/assessments/scenarios-from-templates`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
