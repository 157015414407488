import React, { useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import MaterialInput from 'src/components/form/MaterialInput';
import colors from 'src/theme/colors';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../form/Button';
import { queryCache, useMutation } from 'react-query';
import { publishAssessmentVersion } from 'src/api/assessment';
import { Guard } from './Guard';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 540px;
  overflow-y: auto;
  max-height: 100%;

  .container {
    display: grid;
  }

  .error {
    color: ${colors.error};
    text-align: center;
  }
`;

const validationSchema = Yup.object({
  name: Yup.string().min(1, 'Name is too short').max(100, 'No more that 100 characters').required('Required'),
});

interface VersionsPublishModalProps {
  onClose: () => void;
  assessmentId: string;
}

export const VersionsPublishModal: React.FC<VersionsPublishModalProps> = ({ onClose, assessmentId }) => {
  const [error, setError] = useState('');
  const [isCloseRequested, setIsCloseRequested] = useState(false);

  const [publish] = useMutation(publishAssessmentVersion, {
    onSuccess: () => {
      onClose();
      queryCache.invalidateQueries();
    },
    onError: (err: any) => {
      setError(err.message || 'Server error');
    },
  });

  const formik = useFormik({
    initialValues: { name: '' },
    validationSchema,
    onSubmit: (values) => {
      return publish({
        assessmentId,
        name: values.name,
      });
    },
  });

  const { values, errors, handleSubmit, handleBlur, handleChange, touched, isSubmitting, isValid, dirty } = formik;

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={onClose}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <Poppins className="m-title" px={28}>
            Publish
          </Poppins>
          <Spacer $px={24} />
          <div className="container">
            <MaterialInput
              name="name"
              placeholder="Name of assessment"
              onBlur={handleBlur}
              value={values.name}
              onChange={handleChange}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
              data-cy="name-input"
            />
          </div>
          <Spacer $px={32} />
          <div className="container">
            <Button
              disabled={!isValid || isSubmitting}
              primary
              onClick={() => {
                handleSubmit();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Publish',
                  tags: ['ASSESSMENT'],
                  modal: 'Versions publish modal',
                });
              }}
              data-cy="version-publish-btn"
            >
              PUBLISH
            </Button>
          </div>
          {error && (
            <>
              <Spacer $px={20} />
              <Poppins className="error" px={14}>
                {error}
              </Poppins>
            </>
          )}
        </StyledModal>
      }
    />
  );
};
