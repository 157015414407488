import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { Control, ControllStatus } from 'src/api/types';
import { GTCheckBox, GTColumnSelectAnchor, GTExpander, GTTdm } from 'src/components/GenericTable';
import { formatDate, formatNumber } from 'src/utils/misc';
import { filter } from 'lodash';
import { Link } from 'react-router-dom';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { gTCheckHandler } from 'src/components/GenericTable/util';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { OpenInNew } from '@mui/icons-material';

type DeleteModalValue = {
  id: string | null;
  hasScenarios?: number;
  isLive?: boolean;
};

type UseColumns = (a: { data?: Control[] }) => {
  columns: ColumnDef<Control>[];
  GTColumnSelectAnchorExported: JSX.Element;
  deleteModal: DeleteModalValue;
  setDeleteModal: (a: DeleteModalValue) => void;
  controlEditData: Control | undefined;
  setControlEditData: (data: Control | undefined) => void;
  checked: string[];
  resetChecked: () => void;
};

const defaultColumns = ['strategy', 'name', 'owner', 'frameworkLibrary_shortname', 'controlId', 'status', 'updated_at'];

export const useColumns: UseColumns = ({ data = [] }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(`${TableIds.rmp}-columns`, defaultColumns);
  const [deleteModal, setDeleteModal] = useState<DeleteModalValue>({ id: null });
  const [controlEditData, setControlEditData] = useState<Control | undefined>(undefined);
  const [checked, setChecked] = useState<string[]>([]);

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.expander, GTInternalIds.tdm, GTInternalIds.checker];

    const list: ColumnDef<Control>[] = [
      {
        id: GTInternalIds.checker,
        header: ({ table }) => {
          const { isAllChecked, checkAllHandler } = gTCheckHandler(
            {
              checked,
              selector: 'id',
              table,
            },
            setChecked,
          );

          return <GTCheckBox isChecked={isAllChecked} onClick={checkAllHandler} />;
        },
        cell: ({ row }) => {
          const id = row.original.id;
          return (
            <GTCheckBox
              isChecked={checked.includes(id)}
              onClick={() => {
                if (checked.includes(id)) {
                  setChecked(checked.filter((el) => el !== id));
                } else {
                  setChecked([...checked, id]);
                }
              }}
            />
          );
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: GTInternalIds.expander,
        header: () => null,
        cell: ({ row }) => {
          return row.getCanExpand() ? (
            <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
          ) : null;
        },
        size: 1,
        enableSorting: false,
      },
      {
        id: 'strategy',
        header: 'Strategy',
        accessorFn: (row) => row.strategy || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'name',
        header: 'Name',
        accessorFn: (row) => row.name,
        cell: (info) => (
          <Link
            to={`/risk-management/control/${info.row.original.id}`}
            className="table-link"
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['RMP'],
                },
                destination: { value: ['Control'], params: { id: info.row.original.id } },
                tags: ['RMP'],
              })
            }
          >
            {info.getValue()}
          </Link>
        ),
        sortingFn: sortingFns.text,
        maxSize: 300,
      },
      {
        id: 'owner',
        header: 'Owner',
        accessorFn: (row) => row.owner?.text || null,
        cell: (info) => info.getValue(),
        sortingFn: sortingFns.text,
      },
      {
        id: 'frameworkLibrary_shortname',
        header: 'Framework',
        accessorFn: (row) => row.frameworkLibrary_shortname || null,
        cell: (info) => (
          <Link
            to={`/control-frameworks/${info.row.original.frameworkLibrary_ux_id}`}
            className="table-link"
            onClick={() =>
              mpEvent(MPEvents.Link, {
                source: {
                  value: ['RMP'],
                },
                destination: { value: ['Framework'], params: { id: info.row.original.frameworkLibrary_ux_id } },
                tags: ['RMP'],
              })
            }
          >
            {info.getValue()}
          </Link>
        ),
        sortingFn: sortingFns.text,
      },
      {
        id: 'controlId',
        header: 'Framework ID',
        accessorFn: (row) => row.controlId || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'annual_cost',
        header: 'Annual Cost',
        accessorFn: (row) => row.annual_cost || null,
        cell: ({ row }) => (row.original.annual_cost === null ? '' : `£${formatNumber(row.original.annual_cost)}`),
        sortingFn: sortingFns.alphanumeric,
      },
      {
        id: 'status',
        header: 'Status',
        accessorFn: (row) => row.status || null,
        cell: (info) => info.getValue(),
      },
      {
        id: 'updated_at',
        header: 'Last Edited',
        accessorFn: (row) => row.updated_at || null,
        cell: ({ row }) => formatDate(row.original.updated_at),
      },
      {
        id: 'source',
        header: 'Link',
        accessorFn: (row) => row.source || null,
        cell: (info) => {
          const url = info.getValue();
          const linkText = info.row.original.url_name || url;

          return url ? (
            <a
              href={url as string}
              target="_blank"
              rel="noopener noreferrer"
              className="table-link"
              onClick={(e) => e.stopPropagation()}
            >
              {linkText}{' '}
              <OpenInNew
                css={`
                  font-size: 14px !important;
                  color: inherit;
                `}
              />
            </a>
          ) : (
            <span>{linkText || '-'}</span>
          );
        },
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          const onEditClick = () => setControlEditData(row.original);
          const onDeleteClick = () =>
            setDeleteModal({
              id: row.original.id,
              hasScenarios: row.original.scenarios?.length || 0,
              isLive: row.original.status === ControllStatus.Live,
            });

          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
            </div>
          );
        },
        size: 1,
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [data, selectedColumns, checked]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'strategy', title: 'Strategy' },
        { key: 'name', title: 'Name' },
        { key: 'owner', title: 'Owner' },
        { key: 'frameworkLibrary_shortname', title: 'Framework' },
        { key: 'controlId', title: 'Framework ID' },
        { key: 'annual_cost', title: 'Annual Cost' },
        { key: 'status', title: 'Status' },
        { key: 'updated_at', title: 'Last Edited' },
        { key: 'source', title: 'Link' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return {
    columns,
    GTColumnSelectAnchorExported,
    deleteModal,
    setDeleteModal,
    controlEditData,
    setControlEditData,
    checked,
    resetChecked: () => setChecked([]),
  };
};
