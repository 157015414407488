import styled from 'styled-components';

export const PageContainer = styled.div<{ $noSideMargin?: boolean }>`
  position: relative;
  padding: 50px;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 30px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 15px;
    margin-left: ${({ $noSideMargin }) => ($noSideMargin ? 0 : 40)}px;
  }
`;
