import React from 'react';
import { GradientText, Poppins } from 'src/common';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  grid-gap: 8px;

  svg {
    flex-shrink: 0;
    transform: translateY(-1px);
    /* margin-left: 4px; */
  }

  span {
    font-weight: 600;
    user-select: none;
  }
`;

const GradientTitle = styled(GradientText)`
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

interface NavTitleProps {
  title?: string;
  iconHome?: boolean;
  isActive?: boolean;
}

export const NavTitle: React.FC<NavTitleProps> = ({ title = '', iconHome, isActive }) => {
  return (
    <Div>
      {iconHome && (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M13.2621 5.45919L7.58247 0.29782C7.37307 0.106245 7.09954 0 6.81572 0C6.53191 0 6.25837 0.106245 6.04897 0.29782L0.369328 5.45919C0.253041 5.56558 0.160165 5.695 0.0966074 5.83923C0.0330499 5.98346 0.000201566 6.13933 0.000151481 6.29694V12.8356C-0.00446641 13.122 0.0966388 13.4001 0.284134 13.6166C0.390547 13.7376 0.521636 13.8344 0.668585 13.9005C0.815534 13.9667 0.974939 14.0006 1.13608 14H4.54387C4.6945 14 4.83896 13.9401 4.94548 13.8336C5.05199 13.7271 5.11183 13.5826 5.11183 13.432V10.0242C5.11183 9.87357 5.17167 9.72911 5.27818 9.6226C5.3847 9.51608 5.52916 9.45624 5.67979 9.45624H7.95165C8.10228 9.45624 8.24675 9.51608 8.35326 9.6226C8.45978 9.72911 8.51961 9.87357 8.51961 10.0242V13.432C8.51961 13.5826 8.57945 13.7271 8.68597 13.8336C8.79248 13.9401 8.93694 14 9.08758 14H12.4954C12.6838 14.0016 12.8695 13.9552 13.0349 13.8651C13.215 13.767 13.3653 13.6224 13.4702 13.4462C13.5752 13.2701 13.6308 13.069 13.6313 12.864V6.29694C13.6312 6.13933 13.5984 5.98346 13.5348 5.83923C13.4713 5.695 13.3784 5.56558 13.2621 5.45919Z"
            fill="url(#paint0_linear_3940_37074)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_3940_37074"
              x1="6.81565"
              y1="0"
              x2="6.81565"
              y2="14"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#E62E61" />
              <stop offset="1" stopColor="#FD5630" />
            </linearGradient>
          </defs>
        </svg>
      )}
      {isActive ? (
        <GradientTitle $vertical className="title" px={16}>
          {title.toUpperCase()}
        </GradientTitle>
      ) : (
        <Poppins className="title" nowrap color={'cflowerBlue'}>
          {title.toUpperCase()}
        </Poppins>
      )}
    </Div>
  );
};
