import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useAuth } from 'src/state/auth';
import SettingsIcon from '@mui/icons-material/Settings';
import colors from 'src/theme/colors';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledLink = styled(Link)`
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover {
    svg {
      fill: url(#icon-gradient);
    }
  }

  &::before {
    content: '';
    z-index: -1;
    top: -10px;
    left: -2px;
    position: absolute;
    width: 45px;
    height: 45px;
    background: transparent;
  }
`;

const MotionStyledLink = motion(StyledLink);

interface OrgSettingsBtnProps {
  orgId: string;
}

export const OrgSettingsBtn: React.FC<OrgSettingsBtnProps> = ({ orgId }) => {
  const [canShow, setCanShow] = useState(false);
  const { organisations, isPlatformAdmin } = useAuth();

  useLayoutEffect(() => {
    if (isPlatformAdmin) {
      setCanShow(true);
      return;
    }
    const org = organisations.find((el) => el.id === orgId && el.type === 'O');

    if (org?.permissions['app.organisation.edit_users'] === '1') {
      setCanShow(true);
    }
  }, [organisations, orgId, isPlatformAdmin]);

  if (!canShow) {
    return null;
  }

  return (
    <MotionStyledLink
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 500, damping: 15 }}
      to={`/organisation/${orgId}`}
      onClick={() =>
        mpEvent(MPEvents.Link, {
          source: { value: ['Organisations'] },
          destination: { value: ['Organisation Settings'], params: { id: orgId } },
        })
      }
      data-cy="gear-icon"
    >
      <SettingsIcon
        css={`
          color: ${colors.cflowerBlue};
          font-size: 24px;
        `}
      />
    </MotionStyledLink>
  );
};
