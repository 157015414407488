import React from 'react';
import { Tooltip } from './Tooltip';
import { NoData } from './NoData';

export const renderNoData = () => {
  return (
    <div css={'display: inline-block;'}>
      <Tooltip
        dataHtml={`<span style="font-size:14px !important; font-weight: 400">Please adjust the scenario to contain valid calculation data.</span>`}
        dataId="exl"
        place="top"
      >
        <NoData techIncomplete iconOnly />
      </Tooltip>
    </div>
  );
};
