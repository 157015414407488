import { format, parseISO, isValid } from 'date-fns';
import { DATE_FORMAT, DATE_FORMAT_SHORT } from 'src/config';

export const currencyFormatter = (num: number) => {
  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let str = formatter.format(num || 0);

  if (num < 0) {
    str = '-' + str;
  }

  return str;
};

export const formatDate = (date: any, useShortFormat: boolean = false) => {
  if (!date) return '-';

  const dateFormat = useShortFormat ? DATE_FORMAT_SHORT : DATE_FORMAT;
  const parsedDate = typeof date === 'string' ? parseISO(date) : new Date(date);

  return isValid(parsedDate) ? format(parsedDate, dateFormat) : '-';
};
export const getExternalPath = (path: string) => {
  return `${window.location.origin}/${path}`;
};
