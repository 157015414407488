import React from 'react';
import styled from 'styled-components';
import { ReactComponent as InfoIcon } from 'src/assets/info.svg';
import { Tooltip as ExternalTooltip } from 'react-tooltip';
import colors from 'src/theme/colors';

const Div = styled.div`
  svg {
    outline: none;
  }
`;

interface TooltipProps {
  dataId?: string;
  dataHtml: string;
  children?: React.ReactNode;
  place?: 'top' | 'bottom' | 'left' | 'right';
  maxWidth?: number;
}

export const Tooltip: React.FC<TooltipProps> = ({
  dataId = 't-tip',
  dataHtml,
  children,
  place = 'left',
  maxWidth = 500,
}) => {
  return (
    <Div>
      <ExternalTooltip
        id={dataId}
        place={place}
        style={{ backgroundColor: colors.prussianBlue, zIndex: 10, maxWidth }}
        opacity={1}
      />
      {children ? (
        <div data-tooltip-id={dataId} data-tooltip-html={dataHtml} className="tooltip-children">
          {children}
        </div>
      ) : (
        <InfoIcon data-tooltip-id={dataId} data-tooltip-html={dataHtml} />
      )}
    </Div>
  );
};
