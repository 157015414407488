import React, { useEffect, useState } from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import Button from 'src/components/form/Button';
import { Poppins, Spacer } from 'src/common';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 566px;
  .cdm-children {
    display: grid;
    grid-gap: 10px;
  }
  .buttons-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }
`;

interface ConfirmDeleteModalProps {
  onClose: () => void;
  onConfirm: (e: any) => void;
  title?: string | null;
  children?: React.ReactNode;
}
export const ConfirmDeleteModal: React.FC<ConfirmDeleteModalProps> = ({
  title = 'Are you sure you want to delete?',
  onClose,
  onConfirm,
  children,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    return () => setIsDisabled(false);
  }, []);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <StyledModal onRequestClose={onClose} isOpen center>
        {title && (
          <Poppins className="m-title" px={28}>
            {title}
          </Poppins>
        )}
        {children && (
          <div className="cdm-children">
            <Spacer $px={20} />
            {children}
          </div>
        )}
        <Spacer $px={28} />
        <div className="buttons-container">
          <Button
            onClick={() => {
              onClose();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Cancel',
                modal: 'Confirm delete modal',
              });
            }}
            secondary
          >
            CANCEL
          </Button>
          <Button
            disabled={isDisabled}
            onClick={(e) => {
              setIsDisabled(true);
              onConfirm(e);
              mpEvent(MPEvents.ButtonClick, {
                button: 'Delete',
                modal: 'Confirm delete modal',
              });
            }}
            primary
            data-cy="confirm-delete"
          >
            DELETE
          </Button>
        </div>
      </StyledModal>
    </div>
  );
};
