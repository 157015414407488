import React from 'react';
import useVariables from 'src/hooks/useVariables';
import styled from 'styled-components';
import { parseToJsx } from './util';

const Div = styled.div`
  display: flex;
  align-items: center;
  grid-gap: 4px;
  flex-wrap: wrap;
`;

interface FormulaPreviewProps {
  value: string | number;
}

const FormulaPreview: React.FC<FormulaPreviewProps> = ({ value }) => {
  const { variables } = useVariables({});

  const formula = '2+1*(50/#100001)-6+525-#100002';

  return <Div>{parseToJsx(value, variables || [])}</Div>;
};

export default FormulaPreview;
