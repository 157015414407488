import _ from 'lodash';

interface Data {
  x: number;
  y: number;
}

interface DataNew extends Data {
  conf: {
    loss?: number;
    frequency?: number;
    years?: number;
  };
}

export const parseInitialValues = (dataString: string) => {
  const emptyValue = {
    loss: undefined,
    frequency: undefined,
    years: undefined,
  };
  try {
    const data = JSON.parse(dataString) as (DataNew | Data)[];

    const mapped = data
      .filter((el) => el)
      .map((el) => {
        if ('conf' in el) {
          return {
            loss: el.conf.loss,
            frequency: el.conf.frequency,
            years: el.conf.years,
          };
        } else {
          return {
            loss: el.x,
            frequency: el.y * 100,
            years: 100,
          };
        }
      });

    while (mapped.length < 5) {
      mapped.push(emptyValue);
    }

    return mapped;
  } catch (error) {
    console.error(error);
    return [emptyValue, emptyValue, emptyValue, emptyValue, emptyValue];
  }
};

export const containsMinimumTwoValidObjects = (values: {}[]) => {
  const isCompleteAndValid = (object: {}) => _.every(object, (value) => !!value);
  return _.filter(values, isCompleteAndValid).length >= 2;
};

export const parseToNumber = (input: string | number): number | undefined => {
  if (typeof input === 'number') return input;
  const parsedNumber = parseFloat(input);
  return isNaN(parsedNumber) ? undefined : parsedNumber;
};

export const convertValuesToNumbers = (obj: any): any => {
  return _.transform(obj, (result, value, key) => {
    if (_.isObject(value)) {
      result[key] = convertValuesToNumbers(value);
    } else {
      result[key] = _.isString(value) ? parseFloat(value) : value;
    }
  });
};
