import { AxiosArgs, ThenArgs } from 'src/api/types';
import axios, { AxiosResponse } from 'axios';
import { encodeBody } from 'src/api/client';
import { ActivityData } from './types/dashboard';

type getTimeline = () => Promise<
  AxiosArgs<ThenArgs<AxiosResponse<{ total: number; today: number; data: ActivityData[] }>>>
>;

export const getTimeline: getTimeline = () => {
  const path = `/api/public/api/timeline`;
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data);
};
// ------------------------------------------------------------
