import React from 'react';
import { ScenarioTemplate, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { Spacer } from 'src/common';
import styled from 'styled-components';
import { Label, Wrap } from './styles';
import FormulaPreview from 'src/components/FormulaPreview';

const Frequency = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  grid-gap: 8px;
`;

const LabelBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 40px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 24px;
  }
`;

interface TableSheetScenarioLibraryProps {
  data: ScenarioTemplate;
}

export const TableSheetScenarioLibrary: React.FC<TableSheetScenarioLibraryProps> = ({ data }) => {
  return (
    <Wrap>
      <Label>AUTHOR</Label>
      <div className="value">{data.author}</div>
      <Spacer $px={10} />
      <Label>DESCRIPTION</Label>
      <div className="value">{data.description}</div>
      <Spacer $px={15} />
      <div className="divider" />
      <Spacer $px={10} />
      <Label>NAME</Label>
      <div className="value">{data.name}</div>
      <Spacer $px={10} />
      <div className="grid grid--3">
        <div>
          <Label>SOURCES</Label>
          <div className="value">{riskSourcesNames[data.source]}</div>
        </div>
        <div>
          <Label>EVENTS</Label>
          <div className="value">{riskEventsNames[data.event]}</div>
        </div>
        <div>
          <Label>CONSEQUENCES</Label>
          <div className="value">{riskConsequencesNames[data.consequence]}</div>
        </div>
      </div>
      <Spacer $px={10} />
      <Label>RISK NARRATIVE</Label>
      <div className="value">{data.risk_narrative}</div>
      <Spacer $px={10} />
      <Frequency>
        <div css="flex:1">
          <Label>FREQUENCY</Label>
          <div className="value">{data.frequency_times}</div>
        </div>
        <LabelBox>
          <Label>TIMES EVERY</Label>
        </LabelBox>
        <div className="value">{data.frequency_years}</div>
        <LabelBox>
          <Label>YEAR(S)</Label>
        </LabelBox>
      </Frequency>
      <Spacer $px={10} />
      <Label>FREQUENCY - NOTE</Label>
      <div className="value">{data.frequency_note}</div>
      <Spacer $px={10} />
      <Label>CONSEQUENCE - LOWER</Label>
      <div className="value">
        <FormulaPreview value={data.lower_formula} />
      </div>
      <Spacer $px={10} />
      <Label>CONSEQUENCE - UPPER</Label>
      <div className="value">
        <FormulaPreview value={data.upper_formula} />
      </div>
      <Spacer $px={10} />
      <Label>CONSEQUENCE - NOTE</Label>
      <div className="value">{data.note}</div>
    </Wrap>
  );
};
