import { flexRender, Row } from '@tanstack/react-table';
import React, { Fragment } from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import colors from 'src/theme/colors';
import { Spacer } from 'src/common';
import { MOTION_TRANSITION } from 'src/config';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Tr = styled.tr<{ $itemHeight: number; $cursorPointer: boolean; $disabled?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  border-top: 1px solid ${colors.stroke};
  height: ${({ $itemHeight }) => $itemHeight}px !important;
  cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'default')};
  ${({ $disabled }) =>
    $disabled &&
    css`
      div,
      span,
      p {
        color: gray !important;
      }
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    font-size: 14px !important;
    height: ${({ $itemHeight }) => $itemHeight * 0.8}px !important;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: ${({ $itemHeight }) => $itemHeight * 0.6}px !important;
    font-size: 11px !important;
  }
  /* 
  span,
  p {
    color: red;
  } */
`;

interface TdContentProps {
  $size?: number;
  $maxSize?: number;
  $minSize?: number;
}

const TdContent = styled.div<TdContentProps>`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ExpandTr = styled.tr`
  .ext-content {
    margin: 0 20px;
    overflow: hidden;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .ext-content {
      margin: 0 10px;
    }
  }
`;

interface GTRowProps<T> {
  row: Row<T>;
  content?: React.ReactNode;
  itemHeight: number;
  disabled?: boolean;
  tableId?: string;
}

export const GTRow = <T,>({ row, content, itemHeight, disabled, tableId }: GTRowProps<T>) => {
  const canExpand = row.getCanExpand();
  return (
    <Fragment key={row.id}>
      <Tr
        $itemHeight={itemHeight}
        data-cy={`gtrow-${tableId}`}
        data-expanded={row.getIsExpanded()}
        onClick={() => {
          if (canExpand) {
            row.toggleExpanded();
            mpEvent(MPEvents.TableRowExpand, { value: !row.getIsExpanded() ? 'open' : 'close' });
          }
        }}
        $cursorPointer={canExpand}
        $disabled={disabled}
      >
        <td className="gt-left-spacer" />
        {row.getVisibleCells().map((cell) => {
          const { size, maxSize, minSize } = cell.column.columnDef;
          return (
            <td
              key={cell.id}
              style={{
                maxWidth: maxSize,
              }}
            >
              <TdContent $size={size} $maxSize={maxSize} $minSize={minSize} data-cy="gt-row-content">
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TdContent>
            </td>
          );
        })}
      </Tr>
      <ExpandTr>
        <td colSpan={row.getVisibleCells().length + 1}>
          <motion.div
            className="ext-content"
            initial={{ height: 0, marginTop: 0, marginBottom: 0 }}
            animate={{
              height: row.getIsExpanded() ? 'unset' : 0,
              marginTop: row.getIsExpanded() ? 20 : 0,
              marginBottom: row.getIsExpanded() ? 20 : 0,
            }}
            transition={MOTION_TRANSITION}
          >
            <Spacer $px={5} />
            {content}
          </motion.div>
        </td>
      </ExpandTr>
    </Fragment>
  );
};
