import { RefetchQueriesInclude, useApolloClient } from '@apollo/client';

type Params = { include?: RefetchQueriesInclude };
type UseInvalidateQuery = (params?: Params) => (params?: Params) => Promise<void>;

export const useInvalidateQuery: UseInvalidateQuery = (hookParams = {}) => {
  const client = useApolloClient();

  const refetch = async (functionParams: Params = {}) => {
    const { include = 'active' } = { ...hookParams, ...functionParams };

    await client.refetchQueries({
      // updateCache(cache) {
      //   cache.evict({ fieldName: 'someRootField' });
      // },
      include,
    });
  };

  return refetch;
};
