import React from 'react';
import { Poppins } from 'src/common';
import styled, { css } from 'styled-components';

const Div = styled.div<{ $isLoading: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  grid-gap: 6px;
  cursor: pointer;

  span {
    font-weight: 500;
    font-size: 14px;
  }

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  ${({ $isLoading }) =>
    $isLoading &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

interface FetchMoreProps {
  onClick: () => void;
  isNext?: boolean;
  isLoading: boolean;
}

export const FetchMore: React.FC<FetchMoreProps> = ({ onClick, isNext, isLoading }) => {
  return (
    <Div onClick={!isLoading ? onClick : undefined} $isLoading={isLoading}>
      <Poppins>{isNext ? 'View All' : 'View Earlier'}</Poppins>
      <svg
        style={{ transform: `rotateZ(${isNext ? 180 : 0}deg)` }}
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
      >
        <path
          d="M9.88629 5.96484H0.659141C0.480523 5.96484 0.326108 5.89963 0.195643 5.76931C0.0651783 5.63884 0 5.48443 0 5.30592C0 5.12741 0.0651783 4.97289 0.195643 4.84249L4.80923 0.228972C4.93984 0.0985074 5.09426 0.033186 5.27273 0.033186C5.45121 0.033186 5.60577 0.0985074 5.73612 0.228972L10.3497 4.84253C10.48 4.97289 10.5455 5.12741 10.5455 5.30595C10.5455 5.48443 10.4801 5.63884 10.3497 5.76934C10.2194 5.89977 10.0648 5.96484 9.88629 5.96484Z"
          fill="#6A7590"
        />
      </svg>
    </Div>
  );
};
