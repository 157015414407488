import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useVersions = () => {
  const { search } = useLocation();
  const [versionA, setVersionA] = useState<string | undefined>(undefined);
  const [versionB, setVersionB] = useState<string | undefined>(undefined);

  useEffect(() => {
    const parsedQuery = qs.parse(search, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
      plainObjects: true,
      depth: 1,
      decoder: (s) => s,
    });

    const va = parsedQuery.vaId ? (parsedQuery.vaId === 'null' ? undefined : (parsedQuery.vaId as string)) : undefined;
    const vb = parsedQuery.vbId ? (parsedQuery.vbId === 'null' ? undefined : (parsedQuery.vbId as string)) : undefined;

    setVersionA(va);
    setVersionB(vb);
  }, [search]);

  return { versionAId: versionA, versionBId: versionB };
};

export default useVersions;
