import React from 'react';
import { UserOptionType } from 'src/api/types';
import { GradientText, Poppins, StyledAvatar, Tooltip } from 'src/common';
import colors from 'src/theme/colors';
import { getProfileCredentials } from 'src/utils/auth';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useAuth } from 'src/state/auth';

const Div = styled.div<{ $cursorPointer: boolean }>`
  display: flex;
  align-items: center;
  grid-gap: 7px;
  position: relative;
  padding: 9px 9px;

  .ui-meta {
    display: grid;
    /* border: 1px solid green; */

    &__email {
      opacity: 0.5;
    }

    span {
      line-height: 1.2;
    }
  }

  .ui-add {
    margin-left: auto;
    &__inner {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      cursor: pointer;
    }
  }

  &:hover {
    border-radius: 3px;
    overflow: hidden;
    &::before {
      background: ${colors.cultured};
    }
    cursor: ${({ $cursorPointer }) => ($cursorPointer ? 'pointer' : 'default')};
  }

  &::before {
    content: '';
    z-index: -1;
    position: absolute;
    left: -9px;
    right: -9px;
    top: 0px;
    bottom: 0px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    grid-gap: 5px;
    padding: 6px 9px;
  }
`;

interface UserListItemProps {
  user: UserOptionType;
  onClick?: () => void;
  onInviteClick: () => void;
}

export const UserListItem: React.FC<UserListItemProps> = ({ user, onClick, onInviteClick }) => {
  const isPlaceholder = !user.email;
  const { userPermissions } = useAuth();

  const canInvite = userPermissions.app.organisation.manageUsers;

  return (
    <Div onClick={onClick} $cursorPointer={!!onClick}>
      <StyledAvatar $small src={user.profile_photo_path || ''}>
        {getProfileCredentials(user.text)}
      </StyledAvatar>
      <div className="ui-meta">
        {user.text && (
          <Poppins px={14} color="cflowerBlue" weight={500} nowrap>
            {user.text}
          </Poppins>
        )}
        {user.email && (
          <Poppins px={14} className="ui-meta__email" color="cflowerBlue" nowrap>
            {user.email}
          </Poppins>
        )}
      </div>
      {isPlaceholder && canInvite && (
        <div className="ui-add">
          <Tooltip dataHtml="Invite user">
            <motion.div
              className="ui-add__inner"
              onClick={(e) => {
                e.stopPropagation();
                onInviteClick();
              }}
              whileHover={{ scale: 1.25 }}
              transition={{
                duration: 0,
              }}
            >
              <GradientText px={22} weight={400}>
                +
              </GradientText>
            </motion.div>
          </Tooltip>
        </div>
      )}
    </Div>
  );
};
