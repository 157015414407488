import React from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { ActionMeta, Props } from 'react-select';
import _ from 'lodash';
import { restrictAccessOptions } from 'src/api/types';

interface RestrictAccessSelectProps extends Omit<Props, 'value'> {
  value: boolean | undefined;
  onChange: (value: { label: string; value: boolean }, meta: ActionMeta<any>) => void;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  placeholder?: string;
}

export const RestrictAccessSelect: React.FC<RestrictAccessSelectProps> = ({
  value,
  onChange,
  error,
  helperText,
  disabled,
  placeholder = 'Restricted access',
}) => {
  return (
    <>
      <CSelect
        isDisabled={disabled}
        value={_.find(restrictAccessOptions, (o) => o.value === value)}
        placeholder={placeholder}
        options={restrictAccessOptions}
        onChange={onChange}
      />
      {error && helperText && <Error>{helperText}</Error>}
    </>
  );
};
