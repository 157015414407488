import { useEffect } from 'react';
import { isDev } from 'src/config';
import { useAuth } from 'src/state/auth';

type AddBeamerScript = (params: { email: string; name: string }) => void;
const addBeamerScript: AddBeamerScript = ({ email, name }) => {
  const beamerConfigScript = document.createElement('script');
  beamerConfigScript.innerHTML = `
        var beamer_config = {
          product_id: 'nBvRDKRZ66415',
          selector: '#beamerButton',
          user_email: '${email}',
          user_firstname: '${name}',
          user_lastname: '',
        };
      `;
  document.body.appendChild(beamerConfigScript);

  const beamerScript = document.createElement('script');
  beamerScript.src = 'https://app.getbeamer.com/js/beamer-embed.js';
  beamerScript.type = 'text/javascript';
  beamerScript.defer = true;
  document.body.appendChild(beamerScript);
};

const Beamer = () => {
  // const { user } = useAuth();
  // useEffect(() => {
  //   if (user && !isDev) {
  //     addBeamerScript({ email: user.email, name: user.name });
  //   }

  //   return () => {
  //     const script = document.querySelector('script[src="https://app.getbeamer.com/js/beamer-embed.js"]');
  //     script?.remove();

  //     const configScripts = document.querySelectorAll('script');
  //     configScripts.forEach((script) => {
  //       if (script.innerHTML.includes('var beamer_config')) {
  //         script.remove();
  //       }
  //     });
  //   };
  // }, [user]);

  return null;
};

export default Beamer;
