export const DATE_FORMAT = 'dd-MM-yyyy, HH:mm';
export const DATE_FORMAT_SHORT = 'dd-MM-yyyy';
export const DATE_FORMAT_PICKER = 'DD-MM-YYYY';

export const MOBILE_HEADER_HEIGHT = 85;
export const MAX_FORMULA_INT = 9223372036854775807;

export const MOTION_TRANSITION = { type: 'spring', damping: 38, stiffness: 450, restDelta: 0.0001, restSpeed: 0.0001 };

export const isDev = process.env.NODE_ENV === 'development';
