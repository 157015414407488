import colors from 'src/theme/colors';
import styled from 'styled-components';

export const PageCard = styled.div`
  background: ${colors.white};
  border: 1px solid ${colors.stroke};
  box-sizing: border-box;
  border-radius: 6px;
  width: 100%;
  padding: 20px 30px 30px 30px;
  position: relative;

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 15px 20px 20px 20px;
  }
`;
