import { IncidentCsvHeaders } from 'src/api/types/misc';
import * as Yup from 'yup';
import _ from 'lodash';
import { IncidentCSV } from './types';
import { incidentValidationSchema } from 'src/components/formComposed/IncidentForm/util';
import { Incident } from 'src/api/types';

export const generateCsvTemplateLink = () => {
  const csvString = Object.values(IncidentCsvHeaders).join(',') + '\n';

  const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
  return URL.createObjectURL(blob);
};

const pathNames = {
  id: IncidentCsvHeaders.ID,
  refId: IncidentCsvHeaders.RefID,
  name: IncidentCsvHeaders.Name,
  description: IncidentCsvHeaders.Description,
  source: IncidentCsvHeaders.Source,
  date: IncidentCsvHeaders.Date,
  owner: IncidentCsvHeaders.Owner,
  impact: IncidentCsvHeaders.Impact,
  financialImpactUpper: IncidentCsvHeaders.FinancialImpactUpper,
  financialImpactLower: IncidentCsvHeaders.FinancialImpactLower,
  type: IncidentCsvHeaders.Type,
};

export const validateRow = async (row: Partial<Incident>, rowIndex: number) => {
  try {
    await incidentValidationSchema.validate(row, { abortEarly: false });
    return null;
  } catch (err) {
    console.log({ err, row });
    if (err instanceof Yup.ValidationError) {
      const customErrors = err.inner.map((e) => {
        return { path: _.get(pathNames, e.path), message: e.message };
      });
      return { rowIndex, errors: customErrors };
    } else {
      return {
        rowIndex,
        errors: [{ path: 'Unexpected Error', message: 'An unexpected error occurred during validation.' }],
      };
    }
  }
};

export const isValidHeaders = (data: IncidentCSV) => {
  const expectedHeaders = Object.values(IncidentCsvHeaders).map(String);
  const parsedHeaders = Object.keys(data);

  const missingElements = _.difference(expectedHeaders, parsedHeaders);

  return missingElements.length === 0;
};
