import React, { useState } from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { getMinMax, parseRiskTolerance } from 'src/components/charts/util';
import { Spacer } from 'src/common';
import { Assessment, AssessmentVersion } from 'src/api/types';
import { Head } from '../Head';
import Chart from 'src/components/charts/Chart';
import { plotColors } from '../../util';

const Div = styled.div`
  .lec {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

interface ChartsProps {
  dataA: { live: Assessment; base: Assessment; complete: Assessment };
  dataB: { live: Assessment; base: Assessment; complete: Assessment };
  versionA?: AssessmentVersion;
  versionB?: AssessmentVersion;
  assessmentA: {
    seriesEnabled: boolean;
    showProjected: boolean;
  };
  assessmentB: {
    seriesEnabled: boolean;
    showProjected: boolean;
  };
}

export const Charts: React.FC<ChartsProps> = ({ dataA, dataB, versionA, versionB, assessmentA, assessmentB }) => {
  const [isLogarithmic, setIsLogarithmic] = useState(true);
  const [isArea, setIsArea] = useState(true);

  const fillColor = 'rgba(0,0,0,0.1)';

  const fillBase = fillColor;
  const fillComplete = fillColor;
  const fillLive = fillColor;

  const charts = [
    {
      isEnabled: assessmentA.seriesEnabled,
      params: {
        data: dataA.live.chart || [],
        upper: getMinMax(dataA?.live.scenarios || []).upper || 1,
        lower: getMinMax(dataA?.live.scenarios || []).lower || 1,
        name: 'Loss Exceedance A',
        color: plotColors.col_a_live,
        fillColor: isArea ? 'transparent' : fillLive,
        legendId: 'col_a_live',
        lineWidth: 4,
      },
    },
    {
      isEnabled: assessmentA.seriesEnabled && assessmentA.showProjected,
      params: {
        data: dataA.base.chart || [],
        upper: getMinMax(dataA?.base.scenarios || []).upper || 1,
        lower: getMinMax(dataA?.base.scenarios || []).lower || 1,
        name: 'Loss Exceedance A base',
        color: plotColors.col_a_base,
        fillColor: isArea ? 'transparent' : fillBase,
        legendId: 'col_a_base',
        // dashStyle: 'LongDashDot',
        lineWidth: 4,
      },
    },
    {
      isEnabled: assessmentA.seriesEnabled && assessmentA.showProjected,
      params: {
        data: dataA.complete.chart || [],
        upper: getMinMax(dataA?.complete.scenarios || []).upper || 1,
        lower: getMinMax(dataA?.complete.scenarios || []).lower || 1,
        name: 'Loss Exceedance A complete',
        color: plotColors.col_a_complete,
        fillColor: isArea ? 'transparent' : fillComplete,
        legendId: 'col_a_complete',
        // dashStyle: 'ShortDot',
        lineWidth: 4,
      },
    },
    // -----------------------------------B
    {
      isEnabled: assessmentB.seriesEnabled,
      params: {
        data: dataB.live.chart || [],
        upper: getMinMax(dataB?.live.scenarios || []).upper || 1,
        lower: getMinMax(dataB?.live.scenarios || []).lower || 1,
        name: 'Loss Exceedance B',
        color: plotColors.col_b_live,
        fillColor: isArea ? 'transparent' : fillLive,
        legendId: 'col_b_live',
        lineWidth: 4,
      },
    },
    {
      isEnabled: assessmentB.seriesEnabled && assessmentB.showProjected,
      params: {
        data: dataB.base.chart || [],
        upper: getMinMax(dataB?.base.scenarios || []).upper || 1,
        lower: getMinMax(dataB?.base.scenarios || []).lower || 1,
        name: 'Loss Exceedance B base',
        color: plotColors.col_b_base,
        fillColor: isArea ? 'transparent' : fillBase,
        legendId: 'col_b_base',
        // dashStyle: 'LongDashDot',
        lineWidth: 4,
      },
    },
    {
      isEnabled: assessmentB.seriesEnabled && assessmentB.showProjected,
      params: {
        data: dataB.complete.chart || [],
        upper: getMinMax(dataB?.complete.scenarios || []).upper || 1,
        lower: getMinMax(dataB?.complete.scenarios || []).lower || 1,
        name: 'Loss Exceedance B complete',
        color: plotColors.col_b_complete,
        fillColor: isArea ? 'transparent' : fillComplete,
        legendId: 'col_b_complete',
        // dashStyle: 'ShortDot',
        lineWidth: 4,
      },
    },
    // -----------------------------------Risk Tolerance
    {
      isEnabled: assessmentA.seriesEnabled,
      params: {
        data: parseRiskTolerance(dataA.live.risk_tolerance),
        name: 'Risk Tolerance A',
        type: 'spline',
        dashStyle: 'ShortDash',
        color: colors.compareA,
        legendId: 'leg-car-a',
        isRiskTolerance: true,
      },
    },
    {
      isEnabled: assessmentB.seriesEnabled,
      params: {
        data: parseRiskTolerance(dataB.live.risk_tolerance),
        name: 'Risk Tolerance B',
        type: 'spline',
        dashStyle: 'ShortDash',
        color: colors.compareB,
        legendId: 'leg-car-b',
        isRiskTolerance: true,
      },
    },
  ].map((el) => {
    return { ...el.params, data: el.isEnabled ? el.params.data : [] };
  });

  return (
    <Div>
      <Head
        dataA={dataA.live}
        dataB={dataB.live}
        versionA={versionA}
        versionB={versionB}
        isLogarithmic={isLogarithmic}
        setIsLogarithmic={setIsLogarithmic}
        isArea={isArea}
        setIsArea={setIsArea}
        showProjectedA={assessmentA.showProjected}
        showProjectedB={assessmentB.showProjected}
      />
      <Spacer $px={10} />
      <div className="paddings">
        <Chart isLogarithmic={isLogarithmic} input={charts} />
      </div>
    </Div>
  );
};
