import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import colors from 'src/theme/colors';

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  cursor: pointer;
  padding-left: 0px;
  width: 10px;

  &:after {
    content: '';
    display: block;
    width: 15px;
    height: 30px;
    position: absolute;
  }
`;

interface GTExpanderProps {
  onClick: () => void;
  isExpanded: boolean;
}

export const GTExpander: React.FC<GTExpanderProps> = ({ onClick, isExpanded }) => {
  return (
    <Div
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      data-cy="gt-expander"
      data-expanded={isExpanded ? 'true' : 'false'}
    >
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="11"
        viewBox="0 0 6 11"
        fill="none"
        animate={{
          rotate: isExpanded ? 90 : 0,
        }}
        transition={{ type: 'tween', duration: 0.1 }}
      >
        <path
          d="M-4.32143e-07 0.660586L-2.8812e-08 9.88773C-2.10043e-08 10.0664 0.0652145 10.2208 0.195535 10.3512C0.326 10.4817 0.480415 10.5469 0.658925 10.5469C0.837435 10.5469 0.991959 10.4817 1.12235 10.3512L5.73587 5.73764C5.86634 5.60703 5.93166 5.45262 5.93166 5.27414C5.93166 5.09567 5.86634 4.94111 5.73587 4.81075L1.12231 0.197161C0.991958 0.0668401 0.837434 0.00140949 0.658888 0.0014095C0.480414 0.00140951 0.325999 0.0668039 0.195498 0.197161C0.0650696 0.327518 -4.39946e-07 0.482077 -4.32143e-07 0.660586Z"
          fill={colors.prussianBlue}
        />
      </motion.svg>
    </Div>
  );
};
