import axios from 'axios';
import qs from 'qs';
import { store } from 'src/redux';
import { makeLogout, getToken } from 'src/utils/auth';

const stringifyConfig = {
  arrayFormat: 'brackets' as const,
};

export const stringify = (data: Record<string, any>): string => {
  const cleanedData = Object.keys(data).reduce<Record<string, any>>((acc, key) => {
    if (data[key] !== undefined && data[key] !== null) {
      acc[key] = data[key];
    }
    return acc;
  }, {});

  return qs.stringify(cleanedData);
};

export const encodeQueryParams = (queryParameters: Record<string, any> | null | undefined): string => {
  const queryParams =
    queryParameters && Object.keys(queryParameters).length ? qs.stringify(queryParameters, stringifyConfig) : null;

  return queryParams ? `?${queryParams}` : '';
};

export const encodeBody = (body: any[] | Record<string, any> | null | undefined): string | undefined => {
  if (body && !(body instanceof Array) && !Object.keys(body).length) {
    return undefined;
  }
  return JSON.stringify(body);
};

axios.defaults.baseURL = process.env.BASE_URL || '/';

export const getAuthHeaders = () => {
  const headers: any = {};
  const token = getToken();
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

axios.interceptors.request.use((request) => {
  request.headers = {
    ...getAuthHeaders(),
    ...request.headers,
  };
  request.withCredentials = true;
  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response) {
      const status = err.response.status;
      const originalUrl = window.location.href;

      if (status === 401) {
        makeLogout();
      } else if (status === 503) {
        store.dispatch({ type: 'app/setMaintenanceMode', payload: true });
      } else if (status === 409) {
        const { workspace_id, workspace_name } = err.response.data;
        window.location.href = `/error?status=409&workspace_id=${workspace_id}&workspace_name=${workspace_name}&redirect_url=${encodeURIComponent(
          originalUrl,
        )}`;
      } else if (status === 403) {
        const { workspace_id } = err.response.data;
        window.location.href = `/error?status=403&workspace_id=${workspace_id || ''}&redirect_url=${encodeURIComponent(
          originalUrl,
        )}`;
      } else if (status === 404) {
        window.location.href = `/error?status=404&redirect_url=${encodeURIComponent(originalUrl)}`;
      }
    }

    return Promise.reject({ ...err.response?.data, status: err.response?.status });
  },
);
