import React from 'react';
import { Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { useActivityState } from '../../../state';
import { CheckItem } from './comps';

const Div = styled.div`
  .es-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 8px;
  }
  .es-btns {
    display: flex;
    justify-content: flex-end;
    grid-gap: 20px;
    span {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .es-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .es-grid {
      grid-template-columns: 1fr;
    }
  }
`;

export const EventsSelector = () => {
  const { filters, setEventsFilters } = useActivityState();

  const onSelectAll = () => {
    setEventsFilters((s) => ({
      ...s,
      assessments: { created: true, dueToStart: true, dueForReview: true, started: true, versionsPublished: true },
      scenarios: { created: true, identified: true, addedToAssessment: true, lastEdited: true },
      controls: { created: true, liveDates: true, dueForReview: true, lastEdited: true },
      incidents: { occured: true },
    }));
  };

  const onRestoreDefault = () => {
    setEventsFilters((s) => ({
      ...s,
      assessments: { created: true, dueToStart: true, dueForReview: true, started: false, versionsPublished: true },
      scenarios: { created: true, identified: true, addedToAssessment: true, lastEdited: true },
      controls: { created: true, liveDates: true, dueForReview: true, lastEdited: true },
      incidents: { occured: true },
    }));
  };
  return (
    <Div>
      <div className="es-grid">
        <div>
          <Poppins px={14} weight={500} color="cflowerBlue">
            ASSESSMENTS
          </Poppins>
          <Spacer $px={16} />
          <div className="afltr-container__contents__egrid-items">
            <CheckItem
              title="Created"
              isChecked={filters.events.assessments.created}
              onClick={() =>
                setEventsFilters((s) => ({ ...s, assessments: { ...s.assessments, created: !s.assessments.created } }))
              }
            />
            <CheckItem
              title="Due To Start"
              isChecked={filters.events.assessments.dueToStart}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  assessments: { ...s.assessments, dueToStart: !s.assessments.dueToStart },
                }))
              }
            />
            <CheckItem
              title="Due For Review"
              isChecked={filters.events.assessments.dueForReview}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  assessments: { ...s.assessments, dueForReview: !s.assessments.dueForReview },
                }))
              }
            />
            <CheckItem
              title="Started"
              isChecked={filters.events.assessments.started}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  assessments: { ...s.assessments, started: !s.assessments.started },
                }))
              }
            />
            <CheckItem
              title="Versions Published"
              isChecked={filters.events.assessments.versionsPublished}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  assessments: { ...s.assessments, versionsPublished: !s.assessments.versionsPublished },
                }))
              }
            />
          </div>
        </div>
        <div>
          <Poppins px={14} weight={500} color="cflowerBlue">
            RISK SCENARIOS
          </Poppins>
          <Spacer $px={16} />
          <div className="afltr-container__contents__egrid-items">
            <CheckItem
              title="Created"
              isChecked={filters.events.scenarios.created}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  scenarios: { ...s.scenarios, created: !s.scenarios.created },
                }))
              }
            />
            <CheckItem
              title="Identified"
              isChecked={filters.events.scenarios.identified}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  scenarios: { ...s.scenarios, identified: !s.scenarios.identified },
                }))
              }
            />
            <CheckItem
              title="Added To Assessment"
              isChecked={filters.events.scenarios.addedToAssessment}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  scenarios: { ...s.scenarios, addedToAssessment: !s.scenarios.addedToAssessment },
                }))
              }
            />
            <CheckItem
              title="Last Edited"
              isChecked={filters.events.scenarios.lastEdited}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  scenarios: { ...s.scenarios, lastEdited: !s.scenarios.lastEdited },
                }))
              }
            />
          </div>
        </div>
        <div>
          <Poppins px={14} weight={500} color="cflowerBlue">
            CONTROLS
          </Poppins>
          <Spacer $px={16} />
          <div className="afltr-container__contents__egrid-items">
            <CheckItem
              title="Created"
              isChecked={filters.events.controls.created}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  controls: { ...s.controls, created: !s.controls.created },
                }))
              }
            />
            <CheckItem
              title="Live Dates"
              isChecked={filters.events.controls.liveDates}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  controls: { ...s.controls, liveDates: !s.controls.liveDates },
                }))
              }
            />
            <CheckItem
              title="Due For Review"
              isChecked={filters.events.controls.dueForReview}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  controls: { ...s.controls, dueForReview: !s.controls.dueForReview },
                }))
              }
            />
            <CheckItem
              title="Last Edited"
              isChecked={filters.events.controls.lastEdited}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  controls: { ...s.controls, lastEdited: !s.controls.lastEdited },
                }))
              }
            />
          </div>
        </div>
        <div>
          <Poppins px={14} weight={500} color="cflowerBlue">
            INCIDENTS
          </Poppins>
          <Spacer $px={16} />
          <div className="afltr-container__contents__egrid-items">
            <CheckItem
              title="Occured"
              isChecked={filters.events.incidents.occured}
              onClick={() =>
                setEventsFilters((s) => ({
                  ...s,
                  incidents: { ...s.incidents, occured: !s.incidents.occured },
                }))
              }
            />
          </div>
        </div>
      </div>
      <Spacer $px={4} />
      <div className="es-btns">
        <Poppins px={14} weight={500} onClick={onSelectAll}>
          Select All
        </Poppins>
        <Poppins px={14} weight={500} onClick={onRestoreDefault}>
          Restore Default
        </Poppins>
      </div>
    </Div>
  );
};
