import { Button } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import moment, { Moment } from 'moment';
import React from 'react';
import { Poppins, Spacer } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';

const Div = styled.div`
  margin-top: -10px !important;
  padding: 10px 20px;
  background: ${colors.prussianBlue};
  z-index: 10;
  max-width: 100%;
  .ab-actions {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
    grid-auto-rows: auto;
    &__item {
      display: grid;
      /* border: 1px solid white; */
    }
  }
`;

const Btn = styled(Button)`
  color: white !important;
  text-transform: none !important;
  font-weight: 500 !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  border-radius: 0px !important;
  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

interface ActionBarProps extends React.PropsWithChildren<PickersActionBarProps> {
  onPeriodSelect: (e: Moment) => void;
}

export const ActionBar: React.FC<ActionBarProps> = ({ className, onPeriodSelect }) => {
  return (
    <Div className={className}>
      <Poppins px={12} weight={600} color="white">
        From today:
      </Poppins>
      <Spacer $px={5} />
      <div className="ab-actions">
        <div className="ab-actions__item">
          <Btn size="small" onClick={() => onPeriodSelect(moment().add(1, 'week'))}>
            1 week
          </Btn>
        </div>
        <div
          className="ab-actions__item"
          css={`
            border-left: 0.1px solid rgba(255, 255, 255, 0.1);
            border-right: 0.1px solid rgba(255, 255, 255, 0.1);
          `}
        >
          <Btn size="small" onClick={() => onPeriodSelect(moment().add(1, 'month'))}>
            1 month
          </Btn>
        </div>
        <div className="ab-actions__item">
          <Btn size="small" onClick={() => onPeriodSelect(moment().add(3, 'month'))}>
            3 months
          </Btn>
        </div>
      </div>
      <div className="ab-actions">
        <div
          className="ab-actions__item"
          css={`
            border-top: 0.1px solid rgba(255, 255, 255, 0.1);
          `}
        >
          <Btn size="small" onClick={() => onPeriodSelect(moment().add(6, 'month'))}>
            6 months
          </Btn>
        </div>
        <div
          className="ab-actions__item"
          css={`
            border-top: 0.1px solid rgba(255, 255, 255, 0.1);
            border-left: 0.1px solid rgba(255, 255, 255, 0.1);
          `}
        >
          <Btn size="small" onClick={() => onPeriodSelect(moment().add(1, 'year'))}>
            1 year
          </Btn>
        </div>
      </div>
    </Div>
  );
};
