export const calcModifierReduction = (cash: number, mod: number = 0, isPercentage: boolean) => {
  if (isPercentage) {
    return cash * (1 - mod / 100);
  }
  return cash - mod;
};
export const calcConsequencesLimit = ({
  lowerConsequence,
  upperConsequence,
  excess = 0,
  tpc = 0,
}: {
  lowerConsequence: number;
  upperConsequence: number;
  excess: number;
  tpc: number;
}) => {
  const calculatedConsequences = {
    lower: 0,
    upper: 0,
  };

  if (tpc >= lowerConsequence) {
    calculatedConsequences.lower = excess;
  } else {
    calculatedConsequences.lower = lowerConsequence - tpc + excess;
  }

  if (tpc >= upperConsequence) {
    calculatedConsequences.upper = excess;
  } else {
    calculatedConsequences.upper = upperConsequence - tpc + excess;
  }

  return calculatedConsequences;
};
