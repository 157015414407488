import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Control, Scenario } from 'src/api/types';
import GenericTable, { GTColumnSelectAnchor, GTExpander, GTModifierLabel } from 'src/components/GenericTable';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { formatDate, formatNumber } from 'src/utils/misc';
import { filter } from 'lodash';
import { checkIsModifierStrategy } from 'src/components/Modifier/util';
import { getIncompleteState } from 'src/common';
import { convertToModifiers } from 'src/components/ProjectedRisk/util';
import { Modifier } from 'src/components/Modifier';
import useVersion from 'src/hooks/useVersion';
import { encodeQueryParams } from 'src/api/client';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { TableIds } from 'src/api/types/misc';

interface ScenarioTableExpandContentProps {
  data: { control: Control; modifiers: any[] }[];
  scenario: Scenario;
}

const defaultColumns = ['strategy', 'name', 'owner', 'status', 'updated_at', '_mod_applied'];
const internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

export const ScenarioTableExpandContent: React.FC<ScenarioTableExpandContentProps> = ({ data, scenario }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.prAssessmentScenariosControls}-columns`,
    defaultColumns,
  );
  const { version } = useVersion();

  const columns: ColumnDef<{ control: Control; modifiers: any[] }>[] = [
    {
      id: GTInternalIds.expander,
      header: () => null,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
        ) : null;
      },
      size: 1,
      enableSorting: false,
    },
    {
      id: 'strategy',
      header: 'Strategy',
      accessorFn: (row) => row.control.strategy || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'name',
      header: 'Control',
      accessorFn: (row) => row.control.name,
      cell: (info) => (
        <Link
          className="table-link"
          // @ts-ignore
          to={`/risk-management/control/${info.row.original.control.ux_id}${
            version ? encodeQueryParams({ version, assessmentId: scenario.assessment_id }) : ''
          }`}
        >
          {info.getValue()}
        </Link>
      ),
      sortingFn: sortingFns.text,
      maxSize: 300,
    },
    {
      id: 'owner',
      header: 'Owner',
      accessorFn: (row) => row.control.owner?.text || null,
      cell: (info) => info.getValue(),
      sortingFn: sortingFns.text,
    },
    {
      id: 'frameworkLibrary_shortname',
      header: 'Framework',
      accessorFn: (row) => row.control.frameworkLibrary_shortname || null,
      cell: (info) => (
        <Link className="table-link" to={`/control-frameworks/${info.row.original.control.frameworkLibrary_ux_id}`}>
          {info.getValue()}
        </Link>
      ),
      sortingFn: sortingFns.text,
    },
    {
      id: 'controlId',
      header: 'Framework ID',
      accessorFn: (row) => row.control.controlId || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'annual_cost',
      header: 'Annual Cost',
      accessorFn: (row) => row.control.annual_cost || null,
      cell: ({ row }) =>
        row.original.control.annual_cost === null ? '' : `£${formatNumber(row.original.control.annual_cost)}`,
      sortingFn: sortingFns.alphanumeric,
    },
    {
      id: 'status',
      header: 'Status',
      accessorFn: (row) => row.control.status || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'updated_at',
      header: 'Last Edited',
      accessorFn: (row) => row.control.updated_at || null,
      cell: ({ row }) => formatDate(row.original.control.updated_at),
    },
    {
      id: '_mod_applied',
      header: 'Modifiers',
      accessorFn: (row) => row.modifiers?.length || 0,
      cell: (info) => {
        const isModStrategy = checkIsModifierStrategy(info.row.original.control.strategy);
        if (!isModStrategy) return null;

        return (
          <GTModifierLabel
            length={info.getValue() as number}
            satisfiesModifier={getIncompleteState(scenario) !== 'TECH'}
            plainText
          />
        );
      },
    },
    {
      id: GTInternalIds.tdm,
      header: () => null,
      cell: () => null,
      size: 1,
    },
  ];

  return (
    <GenericTable
      tableId={TableIds.prAssessmentScenariosControls}
      data={data}
      columns={filter(columns, (el) => [...internalIds, ...selectedColumns].includes(el.id as string))}
      GTColumnSelectAnchorExported={
        <GTColumnSelectAnchor
          options={[
            { key: 'strategy', title: 'Strategy' },
            { key: 'name', title: 'Control' },
            { key: 'owner', title: 'Owner' },
            { key: 'frameworkLibrary_shortname', title: 'Framework' },
            { key: 'controlId', title: 'Framework ID' },
            { key: 'annual_cost', title: 'Annual Cost' },
            { key: 'status', title: 'Status' },
            { key: 'updated_at', title: 'Last Edited' },
            { key: '_mod_applied', title: 'Modifiers' },
          ]}
          defaultOptions={defaultColumns}
          value={selectedColumns}
          onChange={setSelectedColumns}
        />
      }
      expandContent={(el) => {
        const modsArr = el.modifiers.map((el) => {
          return { ...el, data: JSON.parse(el.data) };
        });

        if (!modsArr.length) return null;
        const modifiers = convertToModifiers(modsArr);
        return <Modifier control={{ ...el.control, modifiers }} projectedScenario={scenario} disabled noResult />;
      }}
      darkMode
    />
  );
};
