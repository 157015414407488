import React from 'react';
import Meta from 'src/components/Meta';
import styled from 'styled-components';
import { useGaEventPageView } from '../../utils/gaEvent';
import colors from 'src/theme/colors';
import { PageContainer, Spacer } from 'src/common';

const VideoContainer = styled.div`
  width: 1150px;
  height: 699px;

  position: relative;
  overflow: hidden;

  @media only screen and (max-width: 1800px) {
    width: 980px;
    height: 596px;
  }

  @media only screen and (max-width: 1300px) {
    width: 650px;
    height: 395px;
  }

  @media only screen and (max-width: 980px) {
    width: 420px;
    height: 255px;
  }

  @media only screen and (max-width: 770px) {
    width: 100%;
    height: 256px;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    height: 100%;
    overflow: hidden;

    iframe {
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
`;

const Header = styled.h1`
  margin: 0;
  font-weight: 600;
  font-size: 32px;
  color: ${colors.prussianBlue};
`;

const GetStarted = () => {
  useGaEventPageView('Cydea | Help');
  return (
    <>
      <Meta title={`Cydea | Help`} feedbackScript />
      <PageContainer>
        <Header>Help</Header>
        <Spacer $px={30} />
        <VideoContainer>
          <div className="inner">
            <iframe
              src="https://www.loom.com/embed/1b1762f78766465cad52836d0df3c593?hide_share=true"
              // frameBorder="0"
              // webkitallowfullscreen
              // mozallowfullscreen
              allowFullScreen
              allow="fullscreen"
            ></iframe>
          </div>
        </VideoContainer>
      </PageContainer>
    </>
  );
};

export default GetStarted;
