import React from 'react';
import { Modal } from './comps';
import styled from 'styled-components';
import { Poppins, Spacer } from 'src/common';
import Button from '../form/Button';

const StyledModal = styled(Modal)`
  width: 540px;
  max-height: calc(100% - 40px);
  overflow-y: auto;

  .btn-container {
    display: flex;
    justify-content: center;
    button {
      min-width: 180px;
    }
  }
`;

interface SimpleModalProps {
  title?: string;
  onClose: () => void;
  onOk: () => void;
  content: JSX.Element;
}

export const SimpleModal: React.FC<SimpleModalProps> = ({ title, onClose, onOk, content }) => {
  return (
    <StyledModal onRequestClose={onClose} isOpen>
      {title && (
        <>
          <Poppins className="m-title" px={28}>
            Publish
          </Poppins>
          <Spacer $px={24} />
        </>
      )}
      {content}
      <Spacer $px={40} />
      <div className="btn-container">
        <Button primary onClick={onOk}>
          OK
        </Button>
      </div>
    </StyledModal>
  );
};
