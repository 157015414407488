import { useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import React, { useLayoutEffect, useState } from 'react';
import { VersionHeader } from './comps';

type UseVersion = (a?: { assessmentId?: string }) => {
  version: string | undefined;
  VHeader: JSX.Element | null;
  assessmentId?: string;
};

const useVersion: UseVersion = (params = {}) => {
  const { assessmentId: assessmentIdInit } = params;
  const { assessmentId: assessmentIdParams } = useParams();
  const { search } = useLocation();
  const [version, setVersion] = useState<string | undefined>(undefined);
  const [assessmentId, setAssessmentId] = useState<string | undefined>(assessmentIdInit || assessmentIdParams);

  useLayoutEffect(() => {
    const parsedQuery = qs.parse(search, {
      ignoreQueryPrefix: true,
      strictNullHandling: true,
      plainObjects: true,
      depth: 1,
      decoder: (s) => s,
    });

    if (parsedQuery.version) {
      setVersion(parsedQuery.version as string);
      parsedQuery.assessmentId && setAssessmentId(parsedQuery.assessmentId as string);
    } else {
      setVersion(undefined);
    }
  }, [search]);

  return {
    version,
    VHeader: assessmentId && version ? <VersionHeader assessmentId={assessmentId} version={version} /> : null,
    assessmentId,
  };
};

export default useVersion;
