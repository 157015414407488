import { LinkLocations, Tags } from './types';

export const getLocationParam = (path: string): { location: LinkLocations | undefined; tags: Tags[] } => {
  if (/^\/risk-assessments\/[a-zA-Z0-9]+$/.test(path)) {
    return { location: 'Assessment', tags: ['ASSESSMENT'] };
  }

  if (/^\/risk-assessments\/[a-zA-Z0-9]+\/scenario\/[a-zA-Z0-9]+$/.test(path)) {
    return { location: 'Scenario', tags: ['SCENARIO'] };
  }

  if (/^\/risk-management\/control\/[a-zA-Z0-9]+$/.test(path)) {
    return { location: 'Control', tags: ['RMP'] };
  }

  if (/^\/incidents\/[a-zA-Z0-9]+$/.test(path)) {
    return { location: 'Incident', tags: ['INCIDENT'] };
  }

  if (/^\/control-frameworks\/[a-zA-Z0-9]+$/.test(path)) {
    return { location: 'Framework', tags: ['FRAMEWORK'] };
  }

  if (/^\/control-frameworks\/all-controls\/[a-zA-Z0-9]+$/.test(path)) {
    return { location: 'Framework Control', tags: ['FRAMEWORK'] };
  }
  if (/^\/organisation\/[a-zA-Z0-9]+$/.test(path)) {
    return { location: 'Organisation Settings', tags: ['ORGANISATION'] };
  }

  switch (path) {
    case '/timeline':
      return { location: 'Timeline', tags: ['TIMELINE'] };
    case '/risk-assessments':
      return { location: 'Assessments', tags: ['ASSESSMENT'] };
    case '/risk-assessments':
      return { location: 'Assessments', tags: ['ASSESSMENT'] };
    case '/scenario-library':
      return { location: 'Scenario Library', tags: ['SCENARIO LIBRARY'] };
    case '/control-frameworks':
      return { location: 'Framework Control', tags: ['FRAMEWORK'] };
    case '/control-frameworks/all-controls':
      return { location: 'Framework Controls all', tags: ['FRAMEWORK'] };
    case '/risk-management':
      return { location: 'RMP', tags: ['RMP'] };
    case '/improvement-plans':
      return { location: 'Improvement Plans', tags: [] };
    case '/incidents':
      return { location: 'Incidents', tags: ['INCIDENT'] };
    case '/workspace':
      return { location: 'Workspace Settings', tags: ['WORKSPACE'] };
    case '/audit/workspace':
      return { location: 'Workspace Audit', tags: ['WORKSPACE'] };
    case '/get-started':
      return { location: 'Help', tags: [] };
    case '/organisation':
      return { location: 'Organisation Settings', tags: ['ORGANISATION'] };
    case '/home':
      return { location: 'Organisations', tags: ['ORGANISATION'] };
    case '/auth':
      return { location: 'Auth', tags: [] };
    default:
      return { location: undefined, tags: [] };
  }
};
