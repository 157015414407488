import React from 'react';
import { DataTitle, Poppins, Spacer } from 'src/common';
import colors from 'src/theme/colors';
import styled from 'styled-components';

const Div = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  grid-gap: 10px;

  .m-sub-title {
    font-weight: 600;
    color: ${colors.cflowerBlue};
  }
  .m-sub-value {
    font-weight: 500;
  }
`;

interface ModalSubTitleProps {
  title: string;
  value: string;
}

export const ModalSubTitle: React.FC<ModalSubTitleProps> = ({ title, value }) => {
  return (
    <>
      <Spacer $px={4} />
      <Div>
        <Poppins className="m-sub-title" px={18}>
          {title}:
        </Poppins>
        <Poppins className="m-sub-value" px={14}>
          {value}
        </Poppins>
      </Div>
    </>
  );
};
