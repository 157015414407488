import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconView: React.FC<Props> = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="9" viewBox="0 0 14 9" fill="none">
      <path
        d="M7 0C4.32515 0 1.89946 1.5108 0.109543 3.96473C-0.0365143 4.16578 -0.0365143 4.44665 0.109543 4.6477C1.89946 7.10459 4.32515 8.61539 7 8.61539C9.67485 8.61539 12.1005 7.10459 13.8905 4.65065C14.0365 4.44961 14.0365 4.16874 13.8905 3.96769C12.1005 1.5108 9.67485 0 7 0ZM7.19188 7.34111C5.41628 7.45642 3.94998 5.94562 4.06168 4.1096C4.15332 2.59585 5.34182 1.36888 6.80812 1.27427C8.58372 1.15897 10.05 2.66976 9.93832 4.50578C9.84382 6.01658 8.65531 7.24355 7.19188 7.34111ZM7.1031 5.93971C6.14657 6.0018 5.35614 5.18874 5.41915 4.20126C5.46783 3.38525 6.10934 2.72594 6.89977 2.67272C7.8563 2.61063 8.64672 3.42368 8.58372 4.41117C8.53217 5.23014 7.89066 5.88945 7.1031 5.93971Z"
        fill={isActive ? colors.red : colors.cflowerBlue}
      />
      {/* <defs>
        <linearGradient
          id="paint0_linear_1_7453"
          x1="0"
          y1="0"
          x2="14.5693"
          y2="6.83863"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#E62E78" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs> */}
    </svg>
  );
};
