import { Incident, incidentTypeNames } from 'src/api/types';
import { formatDate, sanitizeForCSV } from 'src/utils/misc';
import Papa from 'papaparse';
import { IncidentCsvHeaders } from 'src/api/types/misc';

export const downloadCsv = (data: Incident[]) => {
  const rows = [
    [
      IncidentCsvHeaders.ID,
      IncidentCsvHeaders.RefID,
      IncidentCsvHeaders.Name,
      IncidentCsvHeaders.Description,
      IncidentCsvHeaders.Source,
      IncidentCsvHeaders.Date,
      IncidentCsvHeaders.Owner,
      IncidentCsvHeaders.Impact,
      IncidentCsvHeaders.FinancialImpactUpper,
      IncidentCsvHeaders.FinancialImpactLower,
      IncidentCsvHeaders.Type,
    ],
    ...data.map((el) => {
      const type = el.type ? incidentTypeNames[el.type] : '';
      return [
        sanitizeForCSV(el.id),
        sanitizeForCSV(el.refId),
        sanitizeForCSV(el.name),
        sanitizeForCSV(el.description),
        sanitizeForCSV(el.source),
        formatDate(el.date || ''),
        sanitizeForCSV(el.owner?.text),
        sanitizeForCSV(el.impact),
        sanitizeForCSV(el.financialImpactLower),
        sanitizeForCSV(el.financialImpactUpper),
        sanitizeForCSV(type),
      ];
    }),
  ];
  const csvString = Papa.unparse(rows);
  const anchor = document.createElement('a');
  anchor.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(csvString);
  anchor.target = '_blank';
  anchor.download = `Security Incidents.csv`;
  anchor.click();
};
