import React, { forwardRef } from 'react';
import styled from 'styled-components';

const MenuContainer = styled.nav`
  padding-top: 0px;
  padding-bottom: 10px;
`;

export interface Props {
  className?: string;
  children?: React.ReactNode;
  popperArrow?: boolean;
}

const Menu: React.ForwardRefRenderFunction<unknown, Props> = ({ children, className, popperArrow, ...rest }, ref) => {
  const menuRef = (ref as any) || React.createRef<HTMLElement | null>();
  return (
    <MenuContainer {...rest} ref={menuRef}>
      <ul>
        {React.Children.map(children, (child) =>
          React.cloneElement(child as React.ReactElement, {
            firstchild: 1,
            popperarrow: popperArrow === true ? 1 : 0,
          }),
        )}
      </ul>
    </MenuContainer>
  );
};

export default forwardRef<unknown, Props>(Menu);
