import React from 'react';
import { Base } from './Base';
import { Text, TextLink } from './Text';
import { Link } from 'react-router-dom';
import { ShieldIcon } from './icons';

interface EFImprovementPlanReviewDueProps {
  plan: {
    id: string;
    name: string;
  };
}

export const EFImprovementPlanReviewDue: React.FC<EFImprovementPlanReviewDueProps> = ({ plan }) => {
  return (
    <Base
      iconComponent={<ShieldIcon />}
      constentComponent={
        <Text>
          Improvement plan{' '}
          <Link to={`/improvement-plans/plan/${plan.id}`}>
            <TextLink>{plan.name}</TextLink>
          </Link>{' '}
          due for review.
        </Text>
      }
    />
  );
};
