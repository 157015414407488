import React, { memo } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, Text, View } from '@react-pdf/renderer';
import { Footer, Header, HeaderEndComponent, IndicatorView, NoData } from '../../comps';
import { isEqual } from 'lodash';
import colors from 'src/theme/colors';

interface AssessmentExecSummaryProps {
  onDone: (pageNumber: number) => void;
  execSummary: string;
}

export const AssessmentExecSummary: React.FC<AssessmentExecSummaryProps> = memo(
  ({ onDone, execSummary }) => {
    return (
      // @ts-ignore
      <Page size={'A4'} wrap style={pdfStyles.page}>
        <Header endComponent={<HeaderEndComponent title="BACK TO TOP" />} />
        {/* @ts-ignore */}
        <View
          render={(data) => {
            onDone(data.pageNumber);
          }}
        />
        <IndicatorView>
          {/* @ts-ignore */}
          <Text style={{ fontSize: 18, fontWeight: 600, color: colors.prussianBlue }} id={'exec.summary'}>
            EXECUTIVE SUMMARY
          </Text>
          {/* @ts-ignore */}
          <View style={{ ...pdfStyles.spacer8 }} />
          {/* @ts-ignore */}
          {execSummary ? <Text style={{ ...pdfStyles.p }}>{execSummary}</Text> : <NoData />}
        </IndicatorView>
        <Footer />
      </Page>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
