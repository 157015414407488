import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';

const primaryCSS = css`
  background: ${colors.gradient};
  color: ${colors.white};
  &:hover {
    background: ${colors.hoverGradient};
    color: ${colors.white};
    //outline: none;
  }
`;

const secondaryCSS = css`
  color: ${colors.gradient};
  background: ${colors.gradient};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 1px solid ${colors.gradientPart};
  padding: calc(0.5rem - 1px) 2rem;

  &:hover {
    background: ${colors.hoverGradient};
    color: ${colors.white};
    background-clip: inherit;
    -webkit-background-clip: inherit;
    -webkit-text-fill-color: ${colors.white};
  }

  @supports not (-webkit-background-clip: text) {
    background-clip: none;
  }
`;

const disabledCSS = css`
  color: ${colors.white};
  background: ${colors.cflowerBlue};
  cursor: not-allowed;
`;
const disabledSecondaryCSS = css`
  color: ${colors.cflowerBlue};
  background: transparent;
  background-clip: inherit;
  -webkit-text-fill-color: ${colors.cflowerBlue};
  border: 1px solid ${colors.cflowerBlue};
  -webkit-background-clip: inherit;
  cursor: not-allowed;
`;

interface ButtonProps {
  primary?: boolean;
  secondary?: boolean;
  $constWidth?: number;
}

const Button = styled.button.attrs<{ primary?: boolean; secondary?: boolean }>((props) => ({
  type: 'button',
  ...props,
}))<ButtonProps>`
  font-family: Poppins, sans-serif;
  display: inline-block;
  appearance: none;
  border: none;
  padding: 1px 1.8rem;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  margin: 0;
  text-decoration: none;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
  height: 48px;
  min-width: 150px;
  ${({ primary }) => primary && primaryCSS};
  ${({ secondary }) => secondary && secondaryCSS};
  &:disabled {
    ${({ secondary }) => (secondary ? disabledSecondaryCSS : disabledCSS)};
  }
  border-radius: 3px;

  ${({ $constWidth }) =>
    $constWidth &&
    css`
      width: ${$constWidth}px;
      ${({ theme }) => theme.breakpoints.down('md')} {
        width: ${$constWidth * 0.8}px;
      }
      ${({ theme }) => theme.breakpoints.down('sm')} {
        width: ${$constWidth * 0.6}px;
      }
    `}
  ${({ theme }) => theme.breakpoints.down('md')} {
    height: 40px;
    min-width: 120px;
    padding: 1px 1.3rem;
    font-size: 12px;
    svg {
      transform: scale(0.8);
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    height: 34px;
    min-width: 100px;
    padding: 1px 0.8rem;
    font-size: 10px;
    svg {
      transform: scale(0.6);
    }
  }
`;

export default Button;
