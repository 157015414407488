import React from 'react';
import styled from 'styled-components';
import { WizardIcon } from './WizardIcon';
import colors from 'src/theme/colors';
import { Variable } from 'src/api/types';
import _ from 'lodash';
import { Tooltip } from 'react-tooltip';
import { currencyFormatter } from 'src/utils/misc';

const Wrap = styled.span`
  background-color: ${colors.aliceBlue};
  border: 1px solid ${colors.stroke};
  border-radius: 10px;
  padding: 0 10px;
  margin: 0 2px;
  display: inline-flex;
  align-items: center;
  flex-wrap: nowrap;

  * {
    white-space: pre;
  }

  .wizard-icon {
    margin-right: 5px;
  }

  .dc-no-data {
    transform: scale(0.8) translateX(3px) translateY(-1px);
  }

  .dc-children {
    display: none;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 0 8px;
    .wizard-icon {
      transform: scale(0.9) translateY(-1px);
    }

    .dc-no-data {
      transform: scale(0.7) translateX(3px) translateY(-1px);
    }
  }
`;

interface DecoratorComponentProps {
  children: React.ReactNode;
  mention: any;
}

export const DecoratorComponent: React.FC<DecoratorComponentProps> = ({ children, mention }) => {
  const data = mention as Variable | undefined;
  const tooltipValue = _.isNil(data?.value)
    ? `<div style='display: flex; grid-gap: 6px;'>
    <span>No Data</span>
        <svg
        style='transform: scale(0.8)'
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        data-cy="no-data"
      >
        <path
          d="M9.7963 6.48145V9.99997M9.7963 13.5185H9.80509M18.5926 9.99997C18.5926 14.858 14.6544 18.7963 9.7963 18.7963C4.93824 18.7963 1 14.858 1 9.99997C1 5.14191 4.93824 1.20367 9.7963 1.20367C14.6544 1.20367 18.5926 5.14191 18.5926 9.99997Z"
          stroke="#F29539"
          stroke-width="1.75926"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>`
    : currencyFormatter(data?.value || 0, !data?.currency);

  return (
    <>
      <Tooltip id={'dc-data-id'} style={{ backgroundColor: colors.prussianBlue, zIndex: 10 }} opacity={1} />
      <Wrap data-tooltip-id={'dc-data-id'} data-tooltip-html={`${tooltipValue}`}>
        {(mention?.workspace_variable || mention?.isGlobal) && <WizardIcon />}
        {data?.alias || data?.name}
        <div className="dc-children">{children}</div>
        {_.isNil(data?.value) && (
          <svg
            className="dc-no-data"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            data-cy="no-data"
          >
            <path
              d="M9.7963 6.48145V9.99997M9.7963 13.5185H9.80509M18.5926 9.99997C18.5926 14.858 14.6544 18.7963 9.7963 18.7963C4.93824 18.7963 1 14.858 1 9.99997C1 5.14191 4.93824 1.20367 9.7963 1.20367C14.6544 1.20367 18.5926 5.14191 18.5926 9.99997Z"
              stroke="#F29539"
              strokeWidth="1.75926"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        )}
      </Wrap>
    </>
  );
};
