import React, { useRef, useState } from 'react';
import { useAuth } from 'src/state/auth';
import useOnClickOutside from 'src/hooks/useOnClickOutside';
import { getProfileCredentials } from 'src/utils/auth';
import { UserMenu } from './comps';
import { StyledAvatar } from 'src/common';
import styled from 'styled-components';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  position: relative;
  .beamer-badge {
    z-index: 1;
    position: absolute;
    top: -6px;
    left: 12px;
    width: 0px;
    height: 0px;
  }
`;

const UserPanel = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { user } = useAuth();
  const ref = useRef<HTMLElement>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (!!anchorEl) {
      setAnchorEl(null);
    } else {
      setAnchorEl(event.currentTarget);
    }

    mpEvent(MPEvents.ButtonClick, {
      button: 'User menu',
      tags: ['USER'],
    });
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  useOnClickOutside(ref, handlePopoverClose);

  return (
    <Div>
      <div className="beamer-badge" id="beamerButton" />
      <StyledAvatar
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        src={user?.profile_photo_path as string | undefined}
        alt="User Image"
        data-cy="user-menu-button"
        $medium
        css="cursor: pointer;"
      >
        {getProfileCredentials(user?.name)}
      </StyledAvatar>
      <UserMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} />
    </Div>
  );
};

export default UserPanel;
