import React, { useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import styled from 'styled-components';
import { ButtonSkeleton, PageContainer, Poppins, Settings, Spacer, TableSkeleton } from 'src/common';
import { queryCache, useMutation, useQuery } from 'react-query';
import Button from 'src/components/form/Button';
import colors from 'src/theme/colors';
import { ConfirmDeleteModal, PdfExportModalRM, RiskManagementModal, UpdateControlsModal } from 'src/components/modals';
import { QKeys } from 'src/api/types';
import { deleteControls, getControls } from 'src/api/controls';
import { useAuth } from 'src/state/auth';
import _ from 'lodash';
import { useColumns } from './useColumns';
import GenericTable, { genEmtyRowTextComponent, GTRowEmpty } from 'src/components/GenericTable';
import { ControlTableExpandContent } from './comps';
import { AddRmpModal } from 'src/components/modals/AddRmpModal';
import Audit from 'src/components/Audit';
import { Alert } from '@mui/material';
import { downloadCsv } from './util';
import CommonMenu from 'src/components/CommonMenu';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import { WidgetRMP } from 'src/components/widgets';

const Div = styled(PageContainer)`
  .panel {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    grid-gap: 10px;

    &__btns {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-gap: 10px;
      flex-wrap: wrap;
    }
  }

  .error {
    color: ${colors.error};
    font-size: 14px;
  }
  .success {
    color: ${colors.success};
    font-size: 14px;
  }
`;

const RiskManagementPage = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isExportPdfModalOpen, setIsExportPdfModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<React.MouseEvent['currentTarget'] | null>(null);

  const { userPermissions, userFeatures } = useAuth();

  useGaEventPageView('Cydea | Risk Management');

  const { data: controls = [], isLoading: isControlsLoading } = useQuery(QKeys.Controls, getControls);

  const {
    columns,
    GTColumnSelectAnchorExported,
    setDeleteModal,
    deleteModal,
    controlEditData,
    setControlEditData,
    checked,
    resetChecked,
  } = useColumns({
    data: controls,
  });

  const selectedControlData = controls.filter((control) => checked.includes(control.id));

  const [removeAll, { isLoading }] = useMutation(deleteControls, {
    onSuccess: () => {
      queryCache.invalidateQueries();
      setDeleteModal({ id: null, hasScenarios: 0 });
      setIsUpdateModalOpen(false);
      resetChecked();
    },
  });

  const [remove] = useMutation(deleteControls, {
    onSuccess: async () => {
      queryCache.invalidateQueries();
      setDeleteModal({ id: null, hasScenarios: 0 });
    },
  });

  const onMenuClick = (e: React.MouseEvent<HTMLLIElement>, cb: () => void) => {
    e.stopPropagation();
    handleClose();
    cb();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Meta title={`Cydea | Risk Management`} feedbackScript />
      {isAddModalOpen && <AddRmpModal onClose={() => setIsAddModalOpen(false)} />}
      {controlEditData && (
        <RiskManagementModal initialValues={controlEditData} onClose={() => setControlEditData(undefined)} />
      )}
      {deleteModal.id && (
        <ConfirmDeleteModal
          onClose={() => setDeleteModal({ id: null })}
          // @ts-ignore
          onConfirm={() => remove([deleteModal.id])}
        >
          {!!deleteModal.hasScenarios && (
            <Alert severity="warning">
              {deleteModal.hasScenarios > 1
                ? 'This control is linked to scenarios.'
                : 'This control is linked to a scenario.'}
            </Alert>
          )}
          {deleteModal.isLive && <Alert severity="warning">This control is already live.</Alert>}
        </ConfirmDeleteModal>
      )}
      {isExportPdfModalOpen && <PdfExportModalRM onClose={() => setIsExportPdfModalOpen(false)} controls={controls} />}

      {isUpdateModalOpen && (
        <UpdateControlsModal
          initialValues={selectedControlData}
          onClose={() => setIsUpdateModalOpen(false)}
          onDelete={removeAll}
          isLoading={isLoading}
        />
      )}

      <Div>
        <div className="panel">
          <Poppins px={32} weight={600}>
            Risk Management
          </Poppins>
          <div className="panel__btns">
            {!isControlsLoading ? (
              <>
                {selectedControlData.length ? (
                  <Button
                    onClick={() => {
                      setIsUpdateModalOpen(true);
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Edit selected controls',
                        tags: ['RMP'],
                      });
                    }}
                    $constWidth={270}
                    primary
                    disabled={!selectedControlData.length}
                    data-cy="edit-selected-controls-btn"
                  >
                    EDIT SELECTED CONTROLS
                  </Button>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setIsAddModalOpen(true);
                        mpEvent(MPEvents.ButtonClick, {
                          button: 'Add control',
                          tags: ['RMP'],
                        });
                      }}
                      $constWidth={200}
                      primary
                      data-cy="add-control"
                    >
                      + ADD CONTROL
                    </Button>
                    <Settings
                      active={!!anchorEl}
                      onClick={(e) => {
                        e.stopPropagation();
                        setAnchorEl(e.currentTarget);
                      }}
                      data-cy="common-menu"
                      variant="button"
                    />
                    <CommonMenu
                      onCsvClick={(e) => onMenuClick(e, () => downloadCsv(controls))}
                      onPdfClick={(e) => onMenuClick(e, () => setIsExportPdfModalOpen(true))}
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <ButtonSkeleton />
                <ButtonSkeleton />
              </>
            )}
          </div>
        </div>
        <Spacer $px={30} />
        {!isControlsLoading ? (
          <>
            {userFeatures.app.widgets.rmp && (
              <>
                <WidgetRMP controls={controls} />
                <Spacer $px={18} />
              </>
            )}
            <GenericTable
              tableId={TableIds.rmp}
              data={controls}
              columns={columns}
              GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
              expandContent={(control) => {
                const scenarios = control.scenarios;
                if (!scenarios?.length) return null;
                return <ControlTableExpandContent data={scenarios} />;
              }}
              searchable={['strategy', 'name', 'owner', 'frameworkLibrary_shortname', 'controlId', 'status']}
              emptyRow={
                <GTRowEmpty
                  text={genEmtyRowTextComponent({
                    onClick: () => setIsAddModalOpen(true),
                    endText: 'to add controls.',
                  })}
                />
              }
            />
            {userFeatures.app.audit.report && userFeatures.app.audit.controls.report && (
              <Audit rootPath="controls" initQuery={{}} tableId={TableIds.auditRmp} />
            )}
          </>
        ) : (
          <TableSkeleton />
        )}
      </Div>
    </>
  );
};

export default RiskManagementPage;
