import { Avatar } from '@mui/material';
import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';

interface StyledAvatarProps {
  $xsmall?: boolean;
  $small?: boolean;
  $medium?: boolean;
  $gradientBlue?: boolean;
}

export const StyledAvatar = styled(Avatar).attrs((p) => {
  return { alt: '', ...p };
})<StyledAvatarProps>`
  background: ${({ $gradientBlue }) => ($gradientBlue ? colors.gradientBlue : colors.gradient)};
  width: 83px;
  height: 83px;
  font-size: 20px;

  ${({ $medium }) =>
    $medium &&
    css`
      width: 45px !important;
      height: 45px !important;
      font-size: 18px !important;
    `};

  ${({ $small }) =>
    $small &&
    css`
      width: 34px !important;
      height: 34px !important;
      font-size: 14px !important;
    `};

  ${({ $xsmall }) =>
    $xsmall &&
    css`
      width: 24px !important;
      height: 24px !important;
      font-size: 10px !important;
    `};

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 60px !important;
    height: 60px !important;
    font-size: 28px !important;
    ${({ $medium }) =>
      $medium &&
      css`
        width: 40px !important;
        height: 40px !important;
        font-size: 16px !important;
      `};
    ${({ $small }) =>
      $small &&
      css`
        width: 30px !important;
        height: 30px !important;
        font-size: 12px !important;
      `};
    ${({ $xsmall }) =>
      $xsmall &&
      css`
        width: 20px !important;
        height: 20px !important;
      `};
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 40px !important;
    height: 40px !important;
    font-size: 20px !important;
    ${({ $medium }) =>
      $medium &&
      css`
        width: 30px !important;
        height: 30px !important;
        font-size: 14px !important;
      `};
    ${({ $small }) =>
      $small &&
      css`
        width: 20px !important;
        height: 20px !important;
        font-size: 10px !important;
      `};
    ${({ $xsmall }) =>
      $xsmall &&
      css`
        width: 18px !important;
        height: 18px !important;
        font-size: 9px !important;
      `};
  }
`;
