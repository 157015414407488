import React from 'react';
import { queryCache, useMutation, useQuery } from 'react-query';
import { Link } from 'react-router-dom';
import { addControllsFromLibrary, getControls } from 'src/api/controls';
import { ControlLibrary, QKeys } from 'src/api/types';
import { DataTitle, DataValue, NoData, PageCard, Poppins, Spacer, TitleWithBack } from 'src/common';
import ExpandCollapse from 'src/common/ExpandCollapse';
import Button from 'src/components/form/Button';
import styled from 'styled-components';

const Card = styled(PageCard)`
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .grid-view {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 50px;
    max-width: 1100px;

    &--1-3 {
      grid-template-columns: 1fr 3fr;
      padding-right: 100px;
    }
  }
  .panel {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 15px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid-view {
      grid-gap: 10px;
      grid-template-columns: 1fr 1fr;
      &--1-3 {
        grid-template-columns: 1fr 1fr;
        padding-right: 0;
      }
    }
  }
`;

interface MetaSheetProps {
  data: ControlLibrary;
}

export const MetaSheet: React.FC<MetaSheetProps> = ({ data }) => {
  const { data: controls = [] } = useQuery(QKeys.Controls, getControls);

  const [add, { isLoading }] = useMutation(addControllsFromLibrary, {
    onSuccess: () => {
      queryCache.invalidateQueries(QKeys.Controls);
    },
    onError: (err: any) => {
      console.log(err);
    },
  });

  const isIn = controls.some((item) => item.controlLibrary_Id === data.ux_id);

  return (
    <Card data-cy="framework-control-page">
      <div className="head">
        <TitleWithBack title="CONTROL" gradient />
        {isIn ? (
          <Poppins px={14} color="cflowerBlue">
            Control added to Risk Management Plan
          </Poppins>
        ) : (
          <Button
            primary
            disabled={isLoading}
            onClick={() =>
              add({
                controlLibraryIds: [{ id: data.ux_id }],
              })
            }
            data-cy="add-to-rmp"
          >
            + ADD CONTROL TO RMP
          </Button>
        )}
      </div>
      <Spacer $px={30} />
      <Poppins px={28} weight={600} data-cy="control-title">
        {data.name}
      </Poppins>
      <Spacer $px={30} />
      <div className="grid-view">
        <div>
          <DataTitle>STRATEGY</DataTitle>
          {data.strategy ? <DataValue>{data.strategy}</DataValue> : <NoData />}
        </div>
      </div>
      <Spacer $px={30} />
      <div>
        <DataTitle>FRAMEWORK</DataTitle>
        {data.framework_library?.name ? (
          <DataValue>
            <Link to={`/control-frameworks/${data.framework_library.ux_id}`}>{data.framework_library.name}</Link>
          </DataValue>
        ) : (
          <NoData />
        )}
      </div>
      <Spacer $px={25} />
      <div>
        <DataTitle>DESCRIPTION</DataTitle>
        {data.description ? <ExpandCollapse text={data.description} /> : <NoData />}
      </div>
    </Card>
  );
};
