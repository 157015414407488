import React from 'react';
import { TimelineEvent } from 'src/api/types/dashboard';
import {
  EPScenarioReviewDue,
  EFAssessmentReviewDue,
  EFComplianceAuditDue,
  EFControlLiveDue,
  EFControldReviewDue,
  EFImprovementPlanReviewDue,
  EFScenarioReviewDue,
  EPAssessmentCreated,
  EPAssessmentVersionPublished,
  EPControlCreated,
  EPIncidentCreated,
  EPScenarioAdded,
  EPScenarioCreated,
  EPControlWentLive,
  EPControlAddedToScenario,
  EFAssessmentStartDue,
  EPAssessmentStarted,
  EPScenarioIdentified,
  EFScenarioIdentified,
  EFControDueLiveActiveDate,
  EPControWentLiveActiveDate,
  EPControWasDueLive,
  EPScenarioLastEdited,
  EPControlLastEdited,
  EPAssessmentReviewDue,
  EPControlReviewDue,
} from './comps/Timeline/Events';

export const parseEvents = (events: TimelineEvent[] = [], isToday: boolean) => {
  if (!events.length) return null;

  return events.map((el, idx) => {
    switch (el.type) {
      case 'PAST_ASSESSMENT_CREATED':
        return <EPAssessmentCreated key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_CONTROL_CREATED':
        return <EPControlCreated key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_CONTROL_WENT_LIVE':
        return <EPControlWentLive key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_CONTROL_REVIEW_DUE':
        return <EPControlReviewDue key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_CONTROL_ADDED_TO_SCENARIO':
        return <EPControlAddedToScenario key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_INCIDENT_CREATED':
        return <EPIncidentCreated key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_ASSESSMENT_VERSION_PUBLISHED':
        return <EPAssessmentVersionPublished key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_SCENARIO_CREATED_ASSESSMENT':
        return <EPScenarioCreated key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_SCENARIO_ADDED_ASSESSMENT':
        return <EPScenarioAdded key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_ASSESSMENT_STARTED':
        return <EPAssessmentStarted key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_ASSESSMENT_REVIEW_DUE':
        return <EPAssessmentReviewDue key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_SCENARIO_IDENTIFIED':
        return <EPScenarioIdentified key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_CONTROL_WAS_EXPECTED_LIVE':
        return <EPControWentLiveActiveDate key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_CONTROL_WAS_DUE_LIVE_ACTUAL': //deprecated?
        return <EPControWasDueLive key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_SCENARIO_EDITED':
        return <EPScenarioLastEdited key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_SCENARIO_REVIEW_DUE':
        return <EPScenarioReviewDue key={idx} {...el.data} isToday={isToday} />;

      case 'PAST_CONTROL_EDITED':
        return <EPControlLastEdited key={idx} {...el.data} isToday={isToday} />;

      case 'FUTURE_ASSESSMENT_REVIEW_DUE':
        return <EFAssessmentReviewDue key={idx} {...el.data} />;

      case 'FUTURE_SCENARIO_REVIEW_DUE':
        return <EFScenarioReviewDue key={idx} {...el.data} />;

      case 'FUTURE_CONTROL_REVIEW_DUE':
        return <EFControldReviewDue key={idx} {...el.data} />;

      case 'FUTURE_CONTROL_DUE_LIVE_ACTUAL':
        return <EFControlLiveDue key={idx} {...el.data} />;

      case 'FUTURE_COMPLIANCE_AUDIT_DUE':
        return <EFComplianceAuditDue key={idx} />;

      case 'FUTURE_IMPROVEMENT_PLAN_REVIEW_DUE':
        return <EFImprovementPlanReviewDue key={idx} {...el.data} />;

      case 'FUTURE_ASSESSMENT_START_DUE':
        return <EFAssessmentStartDue key={idx} {...el.data} />;

      case 'FUTURE_SCENARIO_IDENTIFIED':
        return <EFScenarioIdentified key={idx} {...el.data} />;

      case 'FUTURE_CONTROL_DUE_LIVE_EXPECTED':
        return <EFControDueLiveActiveDate key={idx} {...el.data} />;

      default:
        return null;
    }
  });
};
