import axios, { AxiosResponse } from 'axios';
import { encodeBody } from './client';
import { AxiosArgs, ThenArgs, User } from './types';
import { QueryKey } from 'react-query';
import { gaEventUSER } from '../utils/gaEvent';

type GetUser = (key: QueryKey) => Promise<AxiosArgs<ThenArgs<AxiosResponse<User>>>>;

export const getUser: GetUser = () => {
  const path = '/api/public/api/auth/profile';
  return axios({
    method: 'GET',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type UpdateProfile = (params: { name: string }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<User>>>>;

export const updateProfile: UpdateProfile = (params) => {
  gaEventUSER('PROFILE_UPDATE');
  const path = `/api/public/api/auth/profile`;
  return axios({
    method: 'PUT',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type SwitchOrganisation = (id: string) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const switchOrganisation: SwitchOrganisation = (id) => {
  const path = `/api/public/api/auth/profile/use-organisation/${id}`;
  return axios({
    method: 'PUT',
    url: path,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type TermsAccept = (params: { terms_id: string }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const termsAccept: TermsAccept = (params) => {
  const path = `/api/public/api/auth/terms/accept`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody({ ...params, accepted_method: 'checkbox,button' }),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------

type TermsDefer = (params: { terms_id: string }) => Promise<AxiosArgs<ThenArgs<AxiosResponse<unknown>>>>;

export const termsDefer: TermsDefer = (params) => {
  const path = `/api/public/api/auth/terms/defer`;
  return axios({
    method: 'POST',
    url: path,
    data: encodeBody(params),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }).then((res) => res.data.data);
};
// ------------------------------------------------------------
