// @ts-nocheck
import { Page, Text, Link, View, Image } from '@react-pdf/renderer';
import React from 'react';
import { Incident, incidentTypeNames } from 'src/api/types';
import { Header } from './Header';
import { pdfStyles } from '../pdfStyles';
import { Footer } from './Footer';
import { formatDate } from '../util';
import { NoData } from './NoData';
import { TitleLink } from './TitleLink';
import { extLinkImg } from '../imgData';
import { formatUrl } from 'src/utils/misc';

interface IncidentPageProps {
  incident: Incident;
  onRender: (pageNumber: number) => void;
  endComponent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  titleSmall?: boolean;
}

const truncateWord = (word, maxLength) => {
  if (word.length > maxLength) {
    return word.slice(0, maxLength) + '...';
  }
  return word;
};

export const IncidentPage: React.FC<IncidentPageProps> = ({
  incident,
  onRender,
  endComponent,
  footerComponent,
  titleSmall,
}) => {
  return (
    <Page size={'A4'} style={pdfStyles.page}>
      <Header endComponent={endComponent} />

      <View render={(data) => onRender(data.pageNumber)} />
      <View style={pdfStyles.section}>
        <TitleLink title={incident.name} intLinkId={incident.name.toLowerCase()} extLink={`incidents/${incident.id}`} />

        <View style={pdfStyles.spacer8} />
        <View style={pdfStyles.spacer4} />

        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 220 }}>
            <Text style={pdfStyles.label}>URL</Text>
            <View style={pdfStyles.spacer1} />
            {incident.source ? (
              <View>
                <Link src={incident.source} style={{ flexDirection: 'row', textDecoration: 'none' }}>
                  <Text style={{ ...pdfStyles.value }}>{truncateWord(formatUrl(incident.source), 32)}</Text>
                  <Image src={extLinkImg} style={{ marginLeft: 4, width: 8, height: 8 }} />
                </Link>
              </View>
            ) : (
              <NoData />
            )}
          </View>
          <View>
            <Text style={pdfStyles.label}>REFERENCE ID</Text>
            <View style={pdfStyles.spacer1} />
            {incident.refId ? <Text style={pdfStyles.value}>{incident.refId}</Text> : <NoData />}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 220 }}>
            <Text style={pdfStyles.label}>OWNER</Text>
            <View style={pdfStyles.spacer1} />
            {incident.owner?.text ? <Text style={pdfStyles.value}>{incident.owner.text}</Text> : <NoData />}
          </View>
          <View>
            <Text style={pdfStyles.label}>DATE OF INCIDENT</Text>
            <View style={pdfStyles.spacer1} />
            {incident.date ? <Text style={pdfStyles.value}>{formatDate(incident.date, true)}</Text> : <NoData />}
          </View>
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ width: 220 }}>
          <Text style={pdfStyles.label}>IMPACT</Text>
          <View style={pdfStyles.spacer1} />
          {incident.impact ? <Text style={pdfStyles.value}>{incident.impact}</Text> : <NoData />}
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ width: 220 }}>
          <Text style={pdfStyles.label}>DESCRIPTION</Text>
          <View style={pdfStyles.spacer1} />
          {incident.description ? <Text style={pdfStyles.value}>{incident.description}</Text> : <NoData />}
        </View>

        <View style={pdfStyles.spacer8} />
        <View style={{ flexDirection: 'row' }}>
          <View style={{ width: 220 }}>
            <Text style={pdfStyles.label}>IMPACT - LOWER</Text>
            <View style={pdfStyles.spacer1} />
            {incident.financialImpactLower ? (
              <Text style={pdfStyles.value}>{incident.financialImpactLower}</Text>
            ) : (
              <NoData />
            )}
          </View>
          <View>
            <Text style={pdfStyles.label}>IMPACT - UPPER</Text>
            <View style={pdfStyles.spacer1} />
            {incident.financialImpactUpper ? (
              <Text style={pdfStyles.value}>{incident.financialImpactUpper}</Text>
            ) : (
              <NoData />
            )}
          </View>
        </View>
        <View style={pdfStyles.spacer8} />
        <View style={{ width: 220 }}>
          <Text style={pdfStyles.label}>TYPE</Text>
          <View style={pdfStyles.spacer1} />
          {incident.type ? <Text style={pdfStyles.value}>{incidentTypeNames[incident.type]}</Text> : <NoData />}
        </View>
      </View>
      <Footer footerComponent={footerComponent} />
    </Page>
  );
};
