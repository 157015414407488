import React from 'react';
import { Poppins, Tooltip } from 'src/common';
import styled from 'styled-components';
import Input from 'src/components/form/Input';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  display: flex;
  flex-direction: column;

  .switch-container {
    display: flex;
    align-items: center;
  }
`;

interface LogSwitchProps {
  isLogarithmic: boolean;
  setIsLogarithmic: (val: boolean) => void;
}

export const LogSwitch: React.FC<LogSwitchProps> = ({ isLogarithmic, setIsLogarithmic }) => {
  return (
    <Div>
      <Tooltip dataHtml={`Switch graph between standard and logarithmic scales`} place="bottom" maxWidth={250}>
        <div className="switch-container">
          <Poppins css="margin-right: 10px;" px={14} color="cflowerBlue">
            Logarithmic
          </Poppins>
          <Input
            checked={isLogarithmic}
            onChange={(e) => {
              setIsLogarithmic(e.target.checked);
              mpEvent(MPEvents.GraphScaleSwitch, { isLogScale: e.target.checked });
            }}
            className="switch"
            type="checkbox"
          />
        </div>
      </Tooltip>
    </Div>
  );
};
