import { Control, ControllStatus, UserOptionType } from 'src/api/types';
import * as Yup from 'yup';
import { isValid } from 'date-fns';
import _ from 'lodash';

export const getLinkedScenariosWarning = (controls: Control[]): string | null => {
  const state = {
    isMultiControl: controls.length > 1,
    isLinkedScenarios: controls.some((el) => el.scenarios?.length),
    isLinkedScenariosMulti: controls.map((el) => el.scenarios?.length || 0).reduce((a, b) => a + b, 0) > 1,
  };

  if (!state.isMultiControl) {
    if (state.isLinkedScenariosMulti) {
      return 'This control is linked to scenarios.';
    } else if (state.isLinkedScenarios) {
      return 'This control is linked to a scenario.';
    }
  } else if (state.isLinkedScenarios) {
    return 'These controls are linked to scenarios.';
  }

  return null;
};

export const getLiveStatusWarning = (controls: Control[]): string | null => {
  const state = {
    isMultiControl: controls.length > 1,
    isLive: controls.some((el) => el.status === ControllStatus.Live),
  };

  if (state.isLive) {
    if (state.isMultiControl) {
      return 'These controls are already live.';
    } else {
      return 'This control is already live.';
    }
  }
  return null;
};

export const validationSchema = Yup.object({
  status: Yup.string().nullable(),
  //   owner: Yup.string().nullable().max(255, 'Max 255 characters'),
  expected_active_date: Yup.string()
    .nullable()
    .test('review_date', 'Invalid Date', (date) => {
      if (!date) return true;
      if (typeof date === 'string') return isValid(new Date(date));
      return false;
    }),
});

export const getPlaceholderOwner = (initialValues: Control[], commonOwner: UserOptionType | null) => {
  const isMulti = initialValues.length > 1;
  const isMixedOwners = initialValues.some((c) => !_.isEqual(c.owner, commonOwner));

  const placeholder = !isMulti
    ? commonOwner
      ? ''
      : 'Assign owner'
    : commonOwner
      ? ''
      : isMixedOwners
        ? '(multiple values)'
        : 'Assign owner';

  return placeholder;
};

export const getPlaceholderStatus = (initialValues: Control[], commonStatus: ControllStatus | null) => {
  const isMulti = initialValues.length > 1;
  const isMixedStatus = initialValues.some((c) => c.status !== commonStatus);

  const placeholder = !isMulti
    ? commonStatus
      ? ''
      : 'Select status'
    : commonStatus
      ? ''
      : isMixedStatus
        ? '(multiple values)'
        : 'Select status';

  return placeholder;
};

export const getPlaceholderExpectedActiveDate = (initialValues: Control[], commonExpectedActiveDate: string | null) => {
  const isMulti = initialValues.length > 1;
  const isMixedExpectedActiveDate = initialValues.some((c) => c.expected_active_date !== commonExpectedActiveDate);

  const placeholder = !isMulti
    ? commonExpectedActiveDate
      ? ''
      : 'Expected live date'
    : commonExpectedActiveDate
      ? ''
      : isMixedExpectedActiveDate
        ? '(multiple values)'
        : 'Expected live date';

  return placeholder;
};
