import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { queryCache, useMutation } from 'react-query';
import { ControllStatus, Modifiers, PlanScenarios, ProjectionVariant, QKeys, Scenario } from 'src/api/types';
import { DataTitle, Poppins, Spacer, Tooltip } from 'src/common';
import Button from 'src/components/form/Button';
import styled from 'styled-components';
import { Modifier } from 'src/components/Modifier';
import _ from 'lodash';
import { checkValidity, recalc } from 'src/components/ProjectedRisk/util';
import { calcModChart } from 'src/api/scenario';
import { ControlStateItem, ModifierStateItem } from 'src/components/ProjectedRisk/types';
import { getPercentage, waitFor } from 'src/utils/misc';
import GenericTable from 'src/components/GenericTable';
import { useColumnsControls } from './useColumnsControls';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import useVersion from 'src/hooks/useVersion';
import { TableIds } from 'src/api/types/misc';

const Div = styled.div`
  .tf-head {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
  }

  .switch-container {
    display: flex;
    justify-content: center;
  }
`;

interface TableFormProps {
  onClose: () => void;
  scenario: Scenario;
  planScenarios: PlanScenarios[];
  setCompleteChart: (s: { x: number; y: number }[]) => void;
  setLiveChart: (s: { x: number; y: number }[]) => void;
  isInModal?: boolean;
}

export const TableForm: React.FC<TableFormProps> = ({
  onClose,
  scenario,
  setCompleteChart,
  setLiveChart,
  planScenarios,
  isInModal,
}) => {
  const [modifiers, setModifiers] = useState<ModifierStateItem[]>([]);
  const [controls, setControls] = useState<ControlStateItem[]>([]);
  const [submitHandlers, setSubmitHandlers] = useState<(() => void)[]>([]);
  const [isSavingAll, setIsSavingAll] = useState(false);
  const { version } = useVersion();

  const isStateDirty = !_.isEqual(
    modifiers.map((el) => el.modifiers),
    planScenarios.map((el) => el.control_data.modifiers),
  );

  const original = useMemo(
    () => ({
      id: scenario.id,
      upper: scenario.upper,
      lower: scenario.lower,
      frequency_times: getPercentage(scenario.frequency_times, scenario.frequency_years),
      frequency_years: scenario.frequency_years,
    }),
    [scenario],
  );

  const [callCalculate, { isLoading }] = useMutation(calcModChart);

  useEffect(() => {
    if (planScenarios.length === 0) return;

    const modifiers = _.chain(planScenarios)
      .map((el) => ({
        id: el.control_data.id,
        modType: _.get(el, 'control_data.strategy', '')?.toLowerCase(),
        modifiers: el.control_data.modifiers,
        isValid: true,
      }))
      .filter((el) => ['treat', 'transfer'].includes(el.modType))
      .value();

    setModifiers(modifiers);

    const sortedControls = _.chain(planScenarios)
      .map((el) => {
        const mod = _.find(modifiers, { id: el.control_data.id })?.modifiers;
        const strategy = el.control_data.strategy?.toLowerCase() || '';
        const isTolerateTerminate = ['tolerate', 'terminate'].includes(strategy);
        const isDisabled = isInModal && isTolerateTerminate;

        return {
          control: { ...el.control_data, modifiers: mod },
          isEnabled: !isDisabled,
          projectedScenario: original,
          sortPriority: isDisabled ? 1 : 0,
        };
      })
      .orderBy(['sortPriority', 'id'], ['asc', 'asc'])
      .value();

    setControls(sortedControls);
  }, [planScenarios, isInModal, original]);

  useEffect(() => {
    recalc({
      controls,
      original,
      modifiers,
      setControls,
    });
  }, [modifiers, controls, original]);

  const toggleControl = async (isEnabled: boolean, id: string) => {
    setControls((s) =>
      s.map((control) => {
        if (control.control.id === id) {
          return { ...control, isEnabled };
        }
        return control;
      }),
    );
    await waitFor(0);
    recalc({ controls, original, modifiers, setControls });
  };

  const addSubmitHandler = useCallback((handler: () => void) => {
    setSubmitHandlers((prevHandlers) => [...prevHandlers, handler]);
  }, []);

  const saveAll = async () => {
    setIsSavingAll(true);
    submitHandlers.forEach((handler) => handler());
    await waitFor(submitHandlers.length * 500);
    await queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id, variant: ProjectionVariant.live }]);
    await queryCache.invalidateQueries([QKeys.Scenario, { scenarioId: scenario.id, variant: ProjectionVariant.base }]);
    await queryCache.invalidateQueries([
      QKeys.Scenario,
      { scenarioId: scenario.id, variant: ProjectionVariant.complete },
    ]);
    await queryCache.invalidateQueries([QKeys.ScenarioControls, scenario.id]);
    queryCache.refetchQueries([QKeys.ScenarioControls, scenario.id]);
    setIsSavingAll(false);
  };

  const handleCalculate = () => {
    const filteredModifiers = modifiers.filter((m) => controls.find((el) => el.control.id === m.id)?.isEnabled);

    const params: {
      scenario_id: string;
      modifiers: Modifiers[];
    } = {
      scenario_id: scenario.id,
      modifiers: {
        // @ts-ignore
        treat: _.chain(filteredModifiers)
          .flatMap((el) => {
            const controlIsLive = controls.find((c) => c.control.id === el.id)?.control.status === ControllStatus.Live;
            return _.get(el, 'modifiers.treat', []).map((treatMod) => ({
              ...treatMod,
              isLive: controlIsLive,
            }));
          })
          .value(),

        transfer: _.chain(filteredModifiers)
          .flatMap((el) => {
            const controlIsLive = controls.find((c) => c.control.id === el.id)?.control.status === ControllStatus.Live;
            return _.get(el, 'modifiers.transfer', []).map((transMod) => ({
              ...transMod,
              isLive: controlIsLive,
            }));
          })
          .value(),
      },
    };

    callCalculate(params)
      .then((res) => {
        if (res && res.data.completeChart && res.data.liveChart) {
          setCompleteChart(res.data.completeChart.chart);
          setLiveChart(res.data.liveChart.chart);
        }
      })
      .catch((error) => {
        console.error('Error processing the calculate response:', error);
      });
  };

  const allModifiersValid = _.every(modifiers, (el) => el.isValid !== false);
  const { columns, GTColumnSelectAnchorExported } = useColumnsControls({
    data: controls,
    scenarioId: scenario.id,
    assessmentId: scenario.assessment_id,
    toggleControl,
  });

  return (
    <Div>
      <DataTitle $noMargin>CONTROLS</DataTitle>
      <Poppins px={14}>
        You can adjust which controls are applied to the risk scenario. If controls have risk modifiers set, this will
        adjust the loss exceedence curves accordingly. Modifiers may be adjusted on this screen to simulate possible
        future risk states. After updating controls, use the “Recalculate” button to run the simulation. To save any
        modifier changes to the scenario, use the “Save Updated Modifiers” button.
      </Poppins>
      {!version && (
        <>
          <Spacer $px={10} />
          <div className="tf-head">
            <Button
              secondary
              onClick={() => {
                saveAll();
                mpEvent(MPEvents.ButtonClick, {
                  button: 'Save updated modifiers',
                  modal: 'Projected risk:Scenario modal',
                  tags: ['SCENARIO', 'MODIFIERS'],
                });
              }}
              disabled={!isStateDirty || !allModifiersValid || isSavingAll}
            >
              SAVE UPDATED MODIFIERS
            </Button>
            <Tooltip
              dataHtml="Get an updated view of the risks without saving them"
              dataId="recalculate"
              place="bottom"
            >
              <Button
                primary
                onClick={() => {
                  handleCalculate();
                  mpEvent(MPEvents.ButtonClick, {
                    button: 'Recalculate',
                    modal: 'Projected risk:Scenario modal',
                    tags: ['SCENARIO', 'MODIFIERS'],
                  });
                }}
                disabled={!allModifiersValid || isLoading}
              >
                RECALCULATE
              </Button>
            </Tooltip>
          </div>
        </>
      )}
      <Spacer $px={20} />
      <GenericTable
        tableId={TableIds.prScenarioScenarios}
        columns={columns}
        data={controls}
        GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
        searchable={['strategy', 'name', 'owner', 'frameworkLibrary_shortname', 'controlId', 'status', 'updated_at']}
        initialSort={[{ id: 'expected_active_date', desc: false }]}
        rowDisabled={(el) => {
          return el.control.strategy !== 'Treat' && el.control.strategy !== 'Transfer';
        }}
        expandContent={({ control: cont, projectedScenario }) => {
          if (cont.strategy === 'Treat' || (cont.strategy === 'Transfer' && projectedScenario)) {
            const mod = modifiers?.find((el) => el.id === cont.id)?.modifiers;

            const original = planScenarios.find((el) => el.control_data.id === cont.id)?.control_data.modifiers;
            const isDirtyOutside = !_.isEqual(mod, original);

            return (
              <>
                {projectedScenario && (
                  <Modifier
                    projectedScenario={projectedScenario}
                    control={{ ...cont, modifiers: mod }}
                    useCallback={async (s) => {
                      const updatedModifiers = modifiers?.map((el) => {
                        if (el.id === cont.id) {
                          return {
                            ...el,
                            modifiers: s.values,
                            isValid:
                              s.isValid &&
                              checkValidity({
                                mods: mod,
                                strategy: cont.strategy?.toLowerCase() || '',
                                projectedScenario: { ...projectedScenario },
                              }),
                          };
                        }
                        return el;
                      });

                      if (_.isEqual(modifiers, updatedModifiers)) return;

                      setModifiers(updatedModifiers);
                    }}
                    addSubmitHandler={addSubmitHandler}
                    isDirtyOutside={isDirtyOutside}
                    isInModal={isInModal}
                    disabled={!!version}
                  />
                )}
              </>
            );
          }
          return null;
        }}
      />
    </Div>
  );
};
