import React, { useEffect, useRef, memo, useState } from 'react';
import styled from 'styled-components';
import { toPng } from 'html-to-image';
import { isEqual } from 'lodash';
import Chart from 'src/components/charts/Chart';
import { ChartConf } from 'src/components/charts/Chart/Chart';

const Div = styled.div`
  z-index: -100;
  width: 900px;
  position: absolute;
`;

interface SnapChartImageV2Props {
  isLogarithmic: boolean;

  chartConf: {
    charts: ChartConf[];
  }[];

  onSnap: (data: string[]) => void;
  disabled: boolean;
}

export const SnapChartImageV2: React.FC<SnapChartImageV2Props> = memo(
  ({ isLogarithmic, onSnap, disabled, chartConf }) => {
    const elementRef = useRef<HTMLDivElement>(null);
    const [renderChart, setRenderChart] = useState<{
      charts: ChartConf[];
    } | null>(null);

    useEffect(() => {
      const fn = async () => {
        const strings: string[] = [];

        for await (const el of chartConf) {
          setRenderChart(el);
          await new Promise((resolve) => {
            setTimeout(() => {
              toPng(elementRef.current as HTMLElement, {
                cacheBust: false,
                quality: 0.1,
                backgroundColor: 'white',
                pixelRatio: 0.8,
              })
                .then((dataUrl) => {
                  strings.push(dataUrl);
                  resolve(null);
                })
                .catch((err) => {
                  console.log(err);
                  resolve(null);
                });
            }, 1);
          });
        }

        onSnap(strings);
      };

      !disabled && fn();
    }, [disabled]);

    if (!renderChart || disabled) {
      return null;
    }

    return (
      <Div ref={elementRef}>
        <Chart isLogarithmic={isLogarithmic} isPrintable input={renderChart.charts} />
      </Div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
