import React, { useCallback, useState } from 'react';
import { queryCache } from 'react-query';
import { GradientTextAction, InputLabel, Poppins, Spacer } from 'src/common';
import Button from 'src/components/form/Button';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import * as Yup from 'yup';
import { createScenario } from 'src/api/scenario';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';
import { ConsequencesSelect, EventsSelect, SourcesSelect } from 'src/components/select';
import { Headers, ScenarioCSV, ValidationErrorItem } from './types';
import {
  chunkArray,
  generateCsvTemplateLink,
  mapConsequenceToNumber,
  mapEventToNumber,
  mapSourceToNumber,
} from './util';
import moment from 'moment';
import { DATE_FORMAT } from 'src/config';
import { removeCommas, selectUserOption } from 'src/utils/misc';
import { useAuth } from 'src/state/auth';
import { mpEvent } from 'src/utils/mixpanel/useMixPanel';
import { MPEvents } from 'src/utils/mixpanel/types';

const Div = styled.div`
  .grid-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    .grid-list {
      grid-gap: 10px;
    }
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    .grid-list {
      grid-template-columns: 1fr;
    }
  }
`;

const Dropzone = styled.div`
  border: 2px dashed ${colors.stroke};
  border-radius: 5px;
  text-align: center;
  padding: 70px 20px;
  cursor: pointer;
  background-color: #f6f6f9;
  &:hover {
    background-color: #f0f0f0;
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 30px 10px;
  }
`;

const ErrorLog = styled.div`
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
  background-color: #ffeded;
  border: 1px solid #ffcccc;
  border-radius: 5px;
  color: ${colors.error};
  padding: 10px;
  display: grid;
  grid-gap: 5px;
`;

const ErrorItem = styled.div`
  padding: 5px;
  border-bottom: 1px solid #ffcfcf;
  &:last-child {
    border-bottom: none;
  }
`;

const scenarioSchema = Yup.object({
  [Headers.Name]: Yup.string().required('Name field is required').typeError('Name field is required'),
  [Headers.ID]: Yup.string().nullable(),
  [Headers.Sources]: Yup.number().nullable(),
  [Headers.Events]: Yup.number().nullable(),
  [Headers.Consequences]: Yup.number().nullable(),
  [Headers.RiskOwner]: Yup.string().nullable(),
  [Headers.RiskNarrative]: Yup.string().nullable(),
  [Headers.FrequencyTimes]: Yup.number()
    .nullable()
    .typeError('Should be a number')
    .min(0.001, 'Must be greater than 0'),
  [Headers.FrequencyUnit]: Yup.number().nullable().typeError('Should be a number').min(0.001, 'Must be greater than 0'),
  [Headers.FrequencyNote]: Yup.string().nullable(),
  [Headers.ConsequenceLower]: Yup.string().nullable(),
  [Headers.ConsequenceUpper]: Yup.string().nullable(),
  [Headers.ConsequenceNote]: Yup.string().nullable(),
  [Headers.IdentifiedDate]: Yup.string()
    .nullable()
    .test(Headers.IdentifiedDate, 'Invalid Date', (date) => {
      if (!date) return true;
      return moment(date, DATE_FORMAT).isValid();
    }),
  [Headers.ReviewDate]: Yup.string()
    .nullable()
    .test(Headers.ReviewDate, 'Invalid Date', (date) => {
      if (!date) return true;
      return moment(date, DATE_FORMAT).isValid();
    }),
  [Headers.RiskManagementStrategy]: Yup.string().nullable(),
  [Headers.RiskManagementNote]: Yup.string().nullable(),
});

interface ParseResult {
  data: any[];
  errors: any[];
  meta: {
    delimiter: string;
    linebreak: string;
    aborted: boolean;
    truncated: boolean;
    cursor: number;
  };
}

interface FromCsvProps {
  assessmentId: string;
  onClose: () => void;
  setIsScvDirty: (isDirty: boolean) => void;
  state: {
    file: File | null;
    setFile: (file: File | null) => void;
    readyForUpload: boolean;
    setReadyForUpload: (ready: boolean) => void;
  };
}

export const FromCsv: React.FC<FromCsvProps> = ({ assessmentId, onClose, setIsScvDirty, state }) => {
  const { usersOptios } = useAuth();
  const [error, setError] = useState('');
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrorItem[]>([]);
  const [scenariosProcessed, setScenariosProcessed] = useState(0);
  const [totalScenarios, setTotalScenarios] = useState(0);
  const [validScenarios, setValidScenarios] = useState<ScenarioCSV[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const { file, setFile, readyForUpload, setReadyForUpload } = state;

  const isButtonDisabled = !file || (scenariosProcessed < totalScenarios && scenariosProcessed > 0);
  const csvTemplateLink = generateCsvTemplateLink();

  const onDrop = useCallback((acceptedFiles) => {
    mpEvent(MPEvents.FileLoaded, {
      modal: 'Scenario add modal',
      tags: ['SCENARIO'],
    });
    if (acceptedFiles && acceptedFiles.length > 0) {
      const selectedFile = acceptedFiles[0];
      setFile(selectedFile);
      setError('');
      setIsUploadSuccessful(false);
      setValidationErrors([]);
      parseCSVToJSON(selectedFile);
      setIsScvDirty(true);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'text/csv': ['.csv'],
    },
  });

  const validateRow = async (
    row: Record<string, any>,
    rowIndex: number,
  ): Promise<Record<string, any> | { rowIndex: number; errors: { path: string; message: string }[] }> => {
    const mappedSource = mapSourceToNumber(row.Sources);
    const mappedEvent = mapEventToNumber(row.Events);
    const mappedConsequence = mapConsequenceToNumber(row.Consequences);

    const adjustedRow = {
      ...row,
      Sources: mappedSource,
      Events: mappedEvent,
      Consequences: mappedConsequence,
      [Headers.FrequencyTimes]: row[Headers.FrequencyTimes] !== null ? row[Headers.FrequencyTimes].toString() : null,
      [Headers.FrequencyUnit]: row[Headers.FrequencyUnit] !== null ? row[Headers.FrequencyUnit].toString() : null,
      [Headers.ConsequenceLower]:
        row[Headers.ConsequenceLower] !== null ? removeCommas(row[Headers.ConsequenceLower]) : null,
      [Headers.ConsequenceUpper]:
        row[Headers.ConsequenceUpper] !== null ? removeCommas(row[Headers.ConsequenceUpper]) : null,
    };

    try {
      await scenarioSchema.validate(adjustedRow, { abortEarly: false });
      return adjustedRow;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const customErrors = err.inner.map((e) => {
          if (e.path === 'Sources' && typeof mappedSource === 'string') {
            return { path: 'Sources', message: mappedSource };
          } else if (e.path === 'Events' && typeof mappedEvent === 'string') {
            return { path: 'Events', message: mappedEvent };
          } else if (e.path === 'Consequences' && typeof mappedConsequence === 'string') {
            return { path: 'Consequences', message: mappedConsequence };
          } else {
            return { path: e.path, message: e.message };
          }
        });
        return { rowIndex, errors: customErrors };
      } else {
        console.error('Unexpected error during validation:', err);
        return {
          rowIndex,
          errors: [{ path: 'Unexpected Error', message: 'An unexpected error occurred during validation.' }],
        };
      }
    }
  };

  const parseCSVToJSON = (file: File) => {
    Papa.parse(file, {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      complete: async (result: ParseResult) => {
        if (result?.data && result.data.length > 60) {
          setError('The number of scenarios exceeds the limit of 60.');
          setFile(null);
          return;
        }
        if (result?.data) {
          let firstRow = result.data[0];
          const expectedHeaders = Object.values(Headers).map(String);
          let parsedHeaders = firstRow ? Object.keys(firstRow) : [];
          if (!parsedHeaders) {
            result.data = result.data.slice(1);
          }

          result.data = result.data.map((row: { [key: string]: any }) => {
            return Object.keys(row)
              .filter((key) => expectedHeaders.includes(key))
              .reduce((obj: ScenarioCSV, key: string) => {
                obj[key as keyof ScenarioCSV] = row[key];
                return obj;
              }, {} as ScenarioCSV);
          });

          firstRow = result.data[0];
          parsedHeaders = firstRow ? Object.keys(firstRow) : [];

          const hasAllExpectedHeaders = expectedHeaders.every((header) => parsedHeaders.includes(header));
          const hasNoExtraHeaders = parsedHeaders.every((header) => expectedHeaders.includes(header));

          if (result.data.length === 0) {
            setValidationErrors([
              { rowIndex: 0, errors: [{ path: 'Data', message: 'Error: No scenario data to load.' }] },
            ]);
            return;
          }

          if (!hasAllExpectedHeaders || !hasNoExtraHeaders) {
            setValidationErrors([
              { rowIndex: 0, errors: [{ path: 'Header', message: 'Invalid CSV template headers.' }] },
            ]);
            return;
          }

          const validationPromises = result.data.map((row: any, index: number) => validateRow(row, index + 1));
          Promise.all(validationPromises).then((validationResults) => {
            if (validationResults) {
              const errorLog = validationResults.filter((result) => result !== null && result.errors);

              if (errorLog.length > 0) {
                const errorLog: ValidationErrorItem[] = validationResults
                  .filter((result) => result !== null && 'errors' in result)
                  .map((result) => result as ValidationErrorItem);

                setValidationErrors(errorLog);
                setIsUploadSuccessful(false);
                setReadyForUpload(false);
              } else {
                const filteredValidScenarios: ScenarioCSV[] = validationResults
                  .filter((result) => result !== null && !('errors' in result))
                  .map((result) => result as ScenarioCSV);

                setValidScenarios(filteredValidScenarios);

                setValidationErrors([]);
                setError('');
                setReadyForUpload(true);
              }
            }
          });
        } else {
          setError('No data found in the CSV file');
        }
      },
      error: (error: { message: string }) => {
        setValidationErrors([
          { rowIndex: 0, errors: [{ path: 'File', message: `CSV parsing error: ${error.message}` }] },
        ]);
      },
    });
  };

  const saveScenarios = async (scenarios: ScenarioCSV[]) => {
    mpEvent(MPEvents.ButtonClick, {
      button: 'Upload',
      modal: 'Scenario add modal',
      tags: ['SCENARIO'],
    });

    const chunkedScenarios = chunkArray(scenarios, 30);
    setTotalScenarios(scenarios.length);
    setScenariosProcessed(0);
    setReadyForUpload(false);
    setIsUploading(true);

    for (const chunk of chunkedScenarios) {
      const requests = chunk.map((scenario) => {
        const { userAdd } = selectUserOption({
          usersOptios,
          selectedUser: { text: scenario[Headers.RiskOwner] || '' },
        });

        return createScenario({
          name: scenario[Headers.Name],
          ux_id: scenario[Headers.ID],
          // @ts-ignore
          source: scenario[Headers.Sources],
          // @ts-ignore
          event: scenario[Headers.Events],
          // @ts-ignore
          consequence: scenario[Headers.Consequences],
          risk_owner: userAdd,
          // @ts-ignore
          risk_narrative: scenario[Headers.RiskNarrative],
          // @ts-ignore
          frequency_times: scenario[Headers.FrequencyTimes],
          // @ts-ignore
          frequency_years: scenario[Headers.FrequencyUnit],
          // @ts-ignore
          frequency_note: scenario[Headers.FrequencyNote],
          // @ts-ignore
          upper_formula: scenario[Headers.ConsequenceUpper],
          // @ts-ignore
          lower_formula: scenario[Headers.ConsequenceLower],
          // @ts-ignore
          note: scenario[Headers.ConsequenceNote],
          assessment_id: assessmentId,
          // @ts-ignore
          management_strategy: scenario[Headers.RiskManagementStrategy],
          management_note: scenario[Headers.RiskManagementNote],
          review_date: scenario[Headers.ReviewDate]
            ? (moment(scenario[Headers.ReviewDate], 'DD-MM-YYYY').toISOString() as string)
            : undefined,
          risk_identified_date: scenario[Headers.IdentifiedDate]
            ? (moment(scenario[Headers.IdentifiedDate], 'DD-MM-YYYY').toISOString() as string)
            : undefined,
        })
          .then(() => {
            setScenariosProcessed((prev) => prev + 1);
          })
          .catch((error) => {
            console.error('Error saving scenario:', error);
            setScenariosProcessed((prev) => prev + 1);
            return { error, scenario };
          });
      });
      await Promise.all(requests).then(() => {
        queryCache.invalidateQueries();
        setIsUploading(false);
        setIsScvDirty(false);
      });

      if (scenariosProcessed === totalScenarios) {
        setIsUploadSuccessful(scenariosProcessed === totalScenarios);
        setReadyForUpload(false);
      }
    }
  };

  const resetUpload = () => {
    setIsUploadSuccessful(false);
    setValidationErrors([]);
    setError('');
    setFile(null);
    setTotalScenarios(0);
    setScenariosProcessed(0);
    setReadyForUpload(false);
  };

  return (
    <Div className="h-padding">
      <a
        href={csvTemplateLink}
        download="scenario_template.csv"
        style={{ textDecoration: 'none' }}
        onClick={() =>
          mpEvent(MPEvents.DownloadCSVTemplate, {
            modal: 'Scenario add modal',
            tags: ['SCENARIO'],
          })
        }
      >
        <GradientTextAction $underline>Download CSV Template</GradientTextAction>
      </a>
      <Spacer $px={20} />
      {!isUploadSuccessful && (
        <Dropzone {...getRootProps()}>
          <input {...getInputProps()} />
          <Poppins px={14}>
            {isDragActive ? 'Drop the file here ...' : 'Drag and drop a file here, or click to select file'}
          </Poppins>
        </Dropzone>
      )}
      {!isUploadSuccessful && <Spacer $px={20} />}
      {file && (
        <>
          <Poppins px={14}>File selected: {file.name}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {file && validationErrors.length === 0 && (
        <>
          <Poppins px={14}>Scenarios ready for upload: {validScenarios.length}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {!isUploadSuccessful && scenariosProcessed < totalScenarios && (
        <>
          <Poppins px={14}>{`Adding scenarios: ${scenariosProcessed}/${totalScenarios}`}</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {isUploadSuccessful && (
        <>
          <Poppins px={14}>File uploaded successfully!</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {readyForUpload && !isUploadSuccessful && (
        <>
          <Poppins px={14}>Validation successful, ready for upload</Poppins>
          <Spacer $px={15} />
        </>
      )}
      {validationErrors.length > 0 && (
        <>
          <ErrorLog>
            {validationErrors.map((error, index) => (
              <ErrorItem key={index}>
                <Poppins color="error" px={16} weight={600}>
                  Error in row {error.rowIndex + 1}:
                </Poppins>
                {error.errors.map((e, idx) => (
                  <Poppins px={14} color="error" css="display: block" key={idx}>{`${e.path}: ${e.message}`}</Poppins>
                ))}
              </ErrorItem>
            ))}
          </ErrorLog>
          <Spacer $px={15} />
        </>
      )}
      {!isUploadSuccessful ? (
        <Button
          primary
          onClick={readyForUpload ? () => saveScenarios(validScenarios) : resetUpload}
          css="width: 100%;"
          disabled={isButtonDisabled || !!validationErrors.length || isUploading}
        >
          {readyForUpload || isUploading ? 'Upload' : 'Select File'}
        </Button>
      ) : (
        <>
          <Button
            primary
            onClick={() => {
              resetUpload();
              setFile(null);
            }}
            css="width: 100%;"
          >
            Upload Another File
          </Button>
          <Spacer $px={20} />
          <Button primary onClick={onClose} css="width: 100%;">
            Done
          </Button>
        </>
      )}
      <Spacer $px={30} />
      {!file && (
        <div>
          <Poppins px={14}>
            The valid values of Sources, Events and Consequences are listed in the 3 drop down lists below.
          </Poppins>
          <Poppins css="display: block" px={14}>
            Blank is also valid for each.
          </Poppins>
          <Spacer $px={24} />

          <div className="grid-list">
            <div>
              <InputLabel>Sources</InputLabel>
              <SourcesSelect placeholder="Sources" menuPlacement="top" />
            </div>
            <div>
              <InputLabel>Events</InputLabel>
              <EventsSelect placeholder="Events" menuPlacement="top" />
            </div>
            <div>
              <InputLabel>Consequences</InputLabel>
              <ConsequencesSelect placeholder="Consequences" menuPlacement="top" />
            </div>
          </div>
          <Spacer $px={20} />
        </div>
      )}
      {error && (
        <>
          <Spacer $px={20} />
          <div className="error">{error}</div>
        </>
      )}
    </Div>
  );
};
