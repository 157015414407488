import React from 'react';
import styled from 'styled-components';
import { ChevronDown, ChevronUp } from 'src/common/Chevron';

const Div = styled.div`
  display: flex;
  flex-direction: column;
`;

interface GTSortProps {
  isSorted: boolean;
  isAsc: boolean;
}

export const GTSort: React.FC<GTSortProps> = ({ isSorted, isAsc }) => {
  return (
    <Div>
      <ChevronUp active={isSorted && isAsc} />
      <ChevronDown active={isSorted && !isAsc} css="margin-top: 3px;" />
    </Div>
  );
};
