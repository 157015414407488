import React, { useState, createContext, useContext, useEffect, useLayoutEffect } from 'react';
import { getUser } from 'src/api/auth';
import { useQuery } from 'react-query';
import { getIsSignedIn, LSKEY_INITAIL_URL } from 'src/utils/auth';
import { OrgTreeItem, OrganisationWorkspace, QKeys, User, UserOptionType } from 'src/api/types';
import { getOrgHierarchy, getOrgs } from 'src/api/organisation';
import { findOrgPath, genPermissions, genFeatures } from './util';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { getRiskOwners } from 'src/api/scenario';
import * as Sentry from '@sentry/react';

interface AuthContextInterface {
  user: User | null;
  isSignedIn: boolean;
  setIsSignedIn: (a: boolean) => void;
  organisations: OrganisationWorkspace[];
  hierarchy: OrgTreeItem[];
  userPermissions: ReturnType<typeof genPermissions>;
  userFeatures: ReturnType<typeof genFeatures>;
  activeOrganisation?: OrganisationWorkspace;
  activeWorkspace?: OrganisationWorkspace;
  nav: {
    navLevel: number;
    setNavLevel: (a: number) => void;
    orgPath: OrgTreeItem[];
  };
  isPlatformAdmin: boolean;
  isOrganisationAdmin: boolean;
  isWorkspaceAdmin: boolean;
  appLoaded: boolean;
  isOrgsLoading: boolean;
  isTscsRequiredToAccept: boolean;
  usersOptios: UserOptionType[];
}

const AuthContext = createContext<Partial<AuthContextInterface>>({});

const AuthProvider = (props: any) => {
  const [isSignedIn, setIsSignedIn] = useState(getIsSignedIn());
  const [activeOrganisation, setActiveOrganisation] = useState<OrganisationWorkspace | undefined>(undefined);
  const [activeWorkspace, setActiveWorkspace] = useState<OrganisationWorkspace | undefined | null>(undefined);
  const [navLevel, setNavLevel] = useState(1);
  const [orgPath, setOrgPath] = useState<OrgTreeItem[]>([]);
  const [hierarchy, setHierarchy] = useState<OrgTreeItem[]>([]);
  const navigate = useNavigate();
  const [shouldInitialRedirect, setShouldInitialRedirect] = useState(true);

  const { data: user = null } = useQuery(QKeys.User, getUser, {
    enabled: isSignedIn,
    refetchInterval: 1000 * 60 * 60,
  });

  const { isLoading: isHierarchyLoading } = useQuery([QKeys.OrgHierarchy], () => getOrgHierarchy(), {
    onSuccess: setHierarchy,
    enabled: !!user,
  });

  const { data: organisations = [], isLoading: isOrgsLoading } = useQuery(
    [QKeys.Organisations],
    () => getOrgs({ domain: true, users: true }),
    {
      enabled: !!user,
    },
  );

  const { data: usersOptios = [] } = useQuery([QKeys.RiskOwners], getRiskOwners, {
    enabled: !!user,
  });

  useEffect(() => {
    if (user && !isHierarchyLoading) {
      const path = findOrgPath(hierarchy, user.workspace_id);
      if (path.length) {
        setOrgPath(path);
        setNavLevel(path.length);
      } else {
        setOrgPath(
          hierarchy.map((el) => {
            return _.omit(el, 'children');
          }),
        );
        setNavLevel(1);
      }
    }
  }, [user?.workspace_id, hierarchy, isHierarchyLoading]);

  useEffect(() => {
    if (user) {
      const activeWorkspace = user?.workspaces.find((el) => el.active) || null;
      const activeOrganisation = user?.organisations?.find((o) => o.id === activeWorkspace?.parent_id);
      setActiveWorkspace(activeWorkspace);
      setActiveOrganisation(activeOrganisation);
      Sentry.setUser({
        id: user.id,
        organisation_id: activeOrganisation?.id,
        workspace_id: user.workspace_id,
      });
    }
  }, [user]);

  useLayoutEffect(() => {
    if (shouldInitialRedirect) {
      const initialUrl = window.localStorage.getItem(LSKEY_INITAIL_URL);

      if (activeWorkspace !== undefined) {
        const invites = user?.invites || [];
        if (user?.workspaces.length === 1 && invites.length === 0) {
          navigate(initialUrl || '/risk-assessments', { replace: true });
        } else {
          navigate(initialUrl || '/home', { replace: true });
        }

        window.localStorage.removeItem(LSKEY_INITAIL_URL);
        setShouldInitialRedirect(false);
      }
    }
  }, [location.pathname, activeWorkspace, shouldInitialRedirect, user]);

  useLayoutEffect(() => {
    const allowedPaths = ['/home', '/get-started'];
    if (!!user && activeWorkspace === null && !allowedPaths.includes(location.pathname)) {
      navigate('/home');
    }
  }, [location.pathname, activeWorkspace, user]);

  const userPermissions = genPermissions(user);
  const isPlatformAdmin = userPermissions.app.platform.admin;
  const isOrganisationAdmin = userPermissions.app.organisation.editUsers;
  const isWorkspaceAdmin = isOrganisationAdmin || userPermissions.app.organisation.manageUsers;
  const userFeatures = genFeatures(user);

  return (
    <AuthContext.Provider
      value={{
        user,
        isSignedIn,
        setIsSignedIn,
        organisations,
        hierarchy,
        userPermissions,
        userFeatures,
        activeOrganisation,
        activeWorkspace,
        nav: {
          orgPath,
          navLevel,
          setNavLevel,
        },
        isPlatformAdmin,
        isOrganisationAdmin,
        isWorkspaceAdmin,
        appLoaded: !isHierarchyLoading && !!user,
        isOrgsLoading,
        isTscsRequiredToAccept:
          user?.terms?.status === 'REQUIRED' || user?.terms?.status === 'NOT_AUTHORISED_TO_ACCEPT',
        usersOptios,
      }}
      {...props}
    />
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context as AuthContextInterface;
};

export { AuthProvider, useAuth };
