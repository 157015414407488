import styled from 'styled-components';
import colors from 'src/theme/colors';

export const MessageContainer = styled.div<{ isError?: boolean; isAbsolute?: boolean }>`
  width: 100%;
  left: 0;
  bottom: 16px;
  display: flex;
  justify-content: center;

  position: ${({ isAbsolute }) => (isAbsolute ? 'absolute' : 'unset')};
  .message-text {
    color: ${({ isError }) => (isError ? colors.error : colors.davysGrey)};
  }
`;
