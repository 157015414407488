import React from 'react';
import { Poppins } from './Poppins';

interface CountLabelProps {
  label: string;
  length: number;
  noPlural?: boolean;
}

export const CountLabel: React.FC<CountLabelProps> = ({ label, length, noPlural }) => {
  return (
    <Poppins color="cflowerBlue">
      <Poppins px={22} weight={600} color="prussianBlue">
        {length}
      </Poppins>{' '}
      {label}
      {length > 1 && !noPlural ? 's' : ''}
    </Poppins>
  );
};
