import React, { memo, useState } from 'react';
import styled, { css } from 'styled-components';
import colors from 'src/theme/colors';
import CheckBox from 'src/components/form/CheckBox';
import { Poppins } from 'src/common';
import Menu from '@mui/material/Menu';
import _ from 'lodash';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div<{ $isOpen: boolean }>`
  width: 100%;
  margin-right: 2px;
  position: relative;
  display: inline-flex;
  width: 26px;
  position: relative;
  z-index: 1;
  background-color: white;

  &:before {
    content: '';
    width: 40px;
    height: 45px;
    position: absolute;
    top: -7px;
    left: -7px;
  }

  &:hover {
    cursor: pointer;
    #p-bg {
      fill: #fd563052;
    }
    #p-icon {
      fill: ${colors.red};
    }
  }

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      #p-bg {
        fill: #fd563052;
      }
      #p-icon {
        fill: ${colors.red};
      }
    `}
`;

const StyledMenu = styled(Menu)`
  .MuiList-padding {
    padding: 0 !important;
  }
  & .MuiPaper-root {
    border-radius: 3px;
    box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
  }

  .gtcs-options {
    border: 1px solid red;
    right: 0;

    padding-top: 18px;
    min-width: 250px;
    background-color: white;
    border: 1px solid ${colors.stroke};
    cursor: default;
    display: grid;
    grid-gap: 10px;
    user-select: none;
    box-shadow: 0px 0px 37px rgba(7, 46, 91, 0.23);
    z-index: 100;

    &__title {
      font-size: 14px;
      color: ${colors.cflowerBlue};
      margin: 0 20px;
      margin-bottom: 4px;
    }

    &__item {
      padding: 0 20px;
      display: flex;
      grid-gap: 10px;
      cursor: pointer;
      overflow: hidden;

      &__title {
        color: ${colors.cflowerBlue};
        font-weight: 500;
        font-size: 14px;
      }
    }

    &__clear {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      user-select: none;
      border-top: 1px solid ${colors.stroke};
      background-color: ${colors.cultured};
      margin-top: 4px;
      span {
        font-size: 14px;
        color: ${colors.cflowerBlue};
      }

      &:hover {
        cursor: pointer;
        background: ${colors.gradient};
        span {
          color: white;
        }
      }
    }
  }
`;

interface GTColumnSelectAnchorProps {
  options: { key: string; title: string }[];
  value: string[];
  defaultOptions: string[];
  onChange: (value: string[]) => void;
}

export const GTColumnSelectAnchor: React.FC<GTColumnSelectAnchorProps> = memo(
  ({ options, defaultOptions, onChange, value }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLDivElement>(null);

    const handleSelect = (key: string) => {
      let values: string[] = [];
      if (value.includes(key)) {
        values = value.filter((el) => el !== key);
      } else {
        values = [...value, key];
      }
      onChange(values);

      mpEvent(MPEvents.TableColumnsChange, { columns: values });
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
      setAnchorEl(e.currentTarget);
      e.stopPropagation();
    };

    const isAllSelected = options.every((el) => value.includes(el.key));

    return (
      <>
        <Div onClick={handleClick} $isOpen={!!anchorEl} data-cy="gt-csa">
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <path
              id="p-bg"
              d="M0 3C0 1.34315 1.34315 0 3 0H23C24.6569 0 26 1.34315 26 3V23C26 24.6569 24.6569 26 23 26H3C1.34315 26 0 24.6569 0 23V3Z"
              fill="#f6f6f9"
            />
            <path
              id="p-icon"
              d="M18.7686 8.35468C18.8717 8.60325 18.8292 8.81546 18.6413 8.99128L14.1578 13.4748V19.6989C14.1578 19.9535 14.0395 20.1324 13.8031 20.2355C13.7243 20.2658 13.6485 20.2809 13.5757 20.2809C13.412 20.2809 13.2756 20.2233 13.1665 20.1081L10.8384 17.78C10.7232 17.6648 10.6656 17.5284 10.6656 17.3708V13.4748L6.18208 8.99128C5.99413 8.81546 5.95169 8.60325 6.05476 8.35468C6.15782 8.11823 6.33668 8 6.59132 8H18.232C18.4867 8 18.6655 8.11823 18.7686 8.35468Z"
              fill="#6a7590"
            />
          </svg>
        </Div>

        <StyledMenu
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          autoFocus={false}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <div className="gtcs-options" onClick={(e) => e.stopPropagation()}>
            <div
              className="gtcs-options__item"
              onClick={() => onChange(isAllSelected ? [] : options.map((el) => el.key))}
            >
              <CheckBox isChecked={isAllSelected} small />
              <Poppins className="gtcs-options__item__title" nowrap>
                All
              </Poppins>
            </div>

            {options.map((el) => (
              <div
                className="gtcs-options__item"
                data-cy={`gt-csa-${el.key}`}
                key={el.key}
                onClick={(e) => {
                  handleSelect(el.key);
                }}
              >
                <CheckBox isChecked={value.includes(el.key)} small />
                <Poppins className="gtcs-options__item__title" nowrap>
                  {el.title}
                </Poppins>
              </div>
            ))}

            {!!defaultOptions && (
              <div className="gtcs-options__clear" onClick={() => onChange(defaultOptions)}>
                <Poppins>Reset to default</Poppins>
              </div>
            )}
          </div>
        </StyledMenu>
      </>
    );
  },
  (prev, next) => _.isEqual(prev, next),
);
