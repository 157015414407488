import React from 'react';

interface Props {
  isActive?: boolean;
}
export const Timeline: React.FC<Props> = ({ isActive }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.70907 6.11997C5.11991 5.83359 6.1818 4.58625 6.1818 3.0909C6.1818 1.38384 4.79796 0 3.0909 0C1.38384 0 0 1.38384 0 3.0909C0 4.58625 1.06188 5.83358 2.47271 6.11997V7.48085C1.06188 7.76724 0 9.01457 0 10.5099C0 12.0053 1.06188 13.2526 2.47271 13.539V14.8963C1.06188 15.1827 0 16.43 0 17.9254C0 19.6324 1.38384 21.0163 3.0909 21.0163C4.79796 21.0163 6.1818 19.6324 6.1818 17.9254C6.1818 16.43 5.11991 15.1827 3.70907 14.8963V13.539C5.11991 13.2526 6.1818 12.0053 6.1818 10.5099C6.1818 9.01456 5.11991 7.76723 3.70907 7.48085V6.11997ZM10.6562 5.82676H15.8752C16.5153 5.82676 17.1172 5.57572 17.5702 5.11975C18.0231 4.66378 18.2726 4.05776 18.2726 3.41338C18.2726 2.76863 18.0231 2.16245 17.5702 1.70653C17.1176 1.25094 16.5156 1 15.8752 1H10.6562C10.0165 1 9.41471 1.25072 8.96064 1.70701C8.50807 2.16346 8.25879 2.76949 8.25879 3.41338C8.25879 4.057 8.50807 4.66282 8.96171 5.12034C9.41503 5.57588 10.0168 5.82676 10.6562 5.82676ZM16.4169 10.7782H9.23315C8.69683 10.7782 8.26202 10.3434 8.26202 9.80707C8.26202 9.27074 8.69683 8.83594 9.23315 8.83594H16.4169C16.9532 8.83594 17.388 9.27074 17.388 9.80707C17.388 10.3434 16.9532 10.7782 16.4169 10.7782ZM9.23315 13.9423H12.4444C12.9807 13.9423 13.4155 13.5074 13.4155 12.9711C13.4155 12.4347 12.9807 12 12.4444 12H9.23315C8.69683 12 8.26202 12.4347 8.26202 12.9711C8.26202 13.5074 8.69683 13.9423 9.23315 13.9423Z"
        fill={isActive ? 'url(#icon-gradient)' : '#6A7590'}
      />
      <defs>
        <linearGradient id="icon-gradient" x1="9.13628" y1="0" x2="9.13628" y2="21.0163" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
