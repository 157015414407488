import { useFormikContext } from 'formik';
import React from 'react';
import { Poppins, Spacer } from 'src/common';
import { GridContainer } from '../../GridContainer';
import _ from 'lodash';
import CheckBox from 'src/components/form/CheckBox';
import { WizardFormValues } from 'src/api/types/wizard';

export const Scenarios = () => {
  const { values, setFieldValue } = useFormikContext<WizardFormValues>();

  return (
    <GridContainer $large>
      <Poppins px={14} color="davysGrey">
        Your answers to these questions will allow us to pre-populate your assessment with relevant scenarios.
      </Poppins>
      <Spacer $px={8} />
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you have a significant volume of business customers (B2B)?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox isChecked={values.scenarios.B2B} onClick={(e) => setFieldValue(`scenarios.B2B`, e)} />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you have a significant volume of individual consumers (B2C)?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox isChecked={values.scenarios.B2C} onClick={(e) => setFieldValue(`scenarios.B2C`, e)} />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Does your business rely on a publicly accessible app, platform or ecommerce operation to generate revenue?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.publiclyAccessibleInternetBusiness}
            onClick={(e) => setFieldValue(`scenarios.publiclyAccessibleInternetBusiness`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you process a large volume of, or high value of payments?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.paymentVolumeOrValue}
            onClick={(e) => setFieldValue(`scenarios.paymentVolumeOrValue`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you need functioning IT equipment as a primary way of generating revenue?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.significantITContributionToRevenue}
            onClick={(e) => setFieldValue(`scenarios.significantITContributionToRevenue`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you store large amounts of company data in the cloud?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.significantDataInCloud}
            onClick={(e) => setFieldValue(`scenarios.significantDataInCloud`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you rely on your own bespoke software to generate revenue?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.softwareBusiness}
            onClick={(e) => setFieldValue(`scenarios.softwareBusiness`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you operate critical infrastructure, or part of the defence supply chain?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.cniOrDefenseInvolvement}
            onClick={(e) => setFieldValue(`scenarios.cniOrDefenseInvolvement`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you spend more than 5% of revenue on research & development?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.significantRandD}
            onClick={(e) => setFieldValue(`scenarios.significantRandD`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Are you a regulated business?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox isChecked={values.scenarios.regulated} onClick={(e) => setFieldValue(`scenarios.regulated`, e)} />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Do you have a significant physical or manufacturing element to your business?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.operationalTechnology}
            onClick={(e) => setFieldValue(`scenarios.operationalTechnology`, e)}
          />
        </div>
      </div>
      <div className="row">
        <Poppins color="davysGrey" className="row__title" px={14}>
          Does your business heavily rely on any third party organisations or suppliers?
        </Poppins>
        <div className="row__end row__end--checkbox">
          <CheckBox
            isChecked={values.scenarios.supplyChainReliant}
            onClick={(e) => setFieldValue(`scenarios.supplyChainReliant`, e)}
          />
        </div>
      </div>
      <Spacer $px={10} />
    </GridContainer>
  );
};
