import colors from 'src/theme/colors';
import styled from 'styled-components';

export const DateCapsule = styled.div<{ $highlighted?: boolean }>`
  padding: 10px;
  border-radius: 3px;
  background: ${({ $highlighted }) => (!!$highlighted ? colors.error : colors.cultured)};
  white-space: nowrap;
  span {
    color: ${({ $highlighted }) => ($highlighted ? colors.white : colors.davysGrey)};
  }

  ${({ theme }) => theme.breakpoints.down('md')} {
    padding: 2px 8px;
  }
`;
