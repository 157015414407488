import React from 'react';
import styled from 'styled-components';
import { ModifiersTreat, ProjectedScenario, TreatReduceConsequenceType, TreatReduceFrequencyType } from 'src/api/types';
import Action from './Action';
import { GradientTextAction } from 'src/common';
import { FieldArray, useFormikContext } from 'formik';
import Button from 'src/components/form/Button';
import { Note } from '../../comps';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled.div`
  display: grid;
  grid-gap: 10px;
  button[type='submit'] {
    width: 120px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
`;

const options: { label: string; value: 'TREAT_REDUCE_CONSEQUENCE' | 'TREAT_REDUCE_FREQUENCY' }[] = [
  {
    label: 'Reduce Consequence',
    value: 'TREAT_REDUCE_CONSEQUENCE',
  },
  {
    label: 'Reduce Frequency',
    value: 'TREAT_REDUCE_FREQUENCY',
  },
];

const getInitialData = (option: string) => {
  switch (option) {
    case 'TREAT_REDUCE_CONSEQUENCE':
      return { lower: 0, upper: 0, isPercentage: false };
    case 'TREAT_REDUCE_FREQUENCY':
      return { value: 0, isPercentage: false };
    default:
      return {};
  }
};

interface TreatModifierProps {
  projectedScenario: ProjectedScenario;
  disabled?: boolean;
  isDirtyOutside?: boolean;
  isInModal?: boolean;
  noResult?: boolean;
}

const TreatModifier: React.FC<TreatModifierProps> = ({ projectedScenario, disabled, isInModal, noResult }) => {
  const { values, handleSubmit, setFieldValue, dirty, resetForm, isSubmitting, isValid } =
    useFormikContext<ModifiersTreat<TreatReduceFrequencyType | TreatReduceConsequenceType>>();

  const handleAddModifier = (type: 'TREAT_REDUCE_CONSEQUENCE' | 'TREAT_REDUCE_FREQUENCY') => {
    const newModifier = { type, data: getInitialData(type), note: '' };
    const updatedTreat = [...values.treat, newModifier];
    setFieldValue('treat', updatedTreat);
  };

  const existingTypes = values.treat.map((mod) => mod.type);
  const availableOptions = options.filter((opt) => !existingTypes.includes(opt.value));

  return (
    <Div>
      {/* @ts-ignore */}
      <FieldArray name="treat">
        {({ form, ...helpers }) => (
          <>
            {values.treat.map((el, idx) => (
              <Action
                key={el.type + idx}
                idx={idx}
                options={options}
                projectedScenario={projectedScenario}
                onRemove={() => helpers.remove(idx)}
                disabled={disabled}
                noResult={noResult}
              />
            ))}
            {!disabled &&
              availableOptions.length > 0 &&
              (availableOptions.length === 1 ? (
                <GradientTextAction
                  onClick={() => {
                    handleAddModifier(availableOptions[0].value);
                    mpEvent(MPEvents.ButtonClick, {
                      button: 'Modifier:Add',
                      tags: ['MODIFIERS'],
                    });
                  }}
                >
                  + Add {availableOptions[0].label}
                </GradientTextAction>
              ) : (
                values.treat.length === 0 && (
                  <GradientTextAction
                    onClick={() => {
                      helpers.push({ type: '', data: {}, note: '' });
                      mpEvent(MPEvents.ButtonClick, {
                        button: 'Modifier:Add',
                        tags: ['MODIFIERS'],
                      });
                    }}
                  >
                    + Add Modifier
                  </GradientTextAction>
                )
              ))}
          </>
        )}
      </FieldArray>
    </Div>
  );
};

export default TreatModifier;
