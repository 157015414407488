import React from 'react';
import CSelect, { Error } from 'src/components/form/Select';
import { ActionMeta, Props } from 'react-select';
import _ from 'lodash';
import { workspaceTypeOptions } from 'src/api/types';

interface WorkspaceTypeSelectProps extends Omit<Props, 'value'> {
  value: string;
  onChange: (value: { label: string; value: string }, meta: ActionMeta<any>) => void;
  error?: boolean;
  helperText?: string | false;
  disabled?: boolean;
  placeholder?: string;
}

export const WorkspaceTypeSelect: React.FC<WorkspaceTypeSelectProps> = ({
  value,
  onChange,
  error,
  helperText,
  disabled,
  placeholder = 'Workspace type',
}) => {
  return (
    <>
      <CSelect
        isDisabled={disabled}
        value={_.find(workspaceTypeOptions, (o) => o.value === value)}
        placeholder={placeholder}
        options={workspaceTypeOptions}
        onChange={onChange}
      />
      {error && helperText && <Error>{helperText}</Error>}
    </>
  );
};
