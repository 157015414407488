import React, { useLayoutEffect } from 'react';
import { Sidebar, Menu, MenuItem, SidebarHeader, SidebarFooter, SidebarContent } from './Sidebar';
import logoSvg from 'src/assets/cydea.svg';
import { ReactComponent as ChevronLeft } from 'src/assets/chevron-left-encircled.svg';
import { ReactComponent as ChevronRight } from 'src/assets/chevron-right-encircled.svg';
import styled from 'styled-components';
import { useSidebar } from 'src/state/sidebar';
import { useAuth } from 'src/state/auth';
import { Poppins } from '../../../common';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { AuditLog, ControlLib, Help, Improovement, Incid, RiskAssessments, RiskMng, ScenLib, Settings } from './icons';
import { useAssessment } from 'src/state/assessment';
import { Timeline } from './icons/Timeline';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Logo = styled.img.attrs({
  src: logoSvg,
  alt: 'Cydea Logo',
})`
  width: 100px;
  height: 38px;
  margin-left: 8px;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 80px;
    height: 30px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 60px;
    height: 22px;
  }
`;

const Aside = () => {
  const { collapsed, setCollapsed } = useSidebar();
  const { nav, isWorkspaceAdmin, isOrganisationAdmin, userFeatures } = useAuth();
  const { setPage } = useAssessment();
  const navigate = useNavigate();
  const isSm = useBreakpoints('sm');

  const isWorkspaceMenu = _.last(nav.orgPath)?.type === 'W' && nav.navLevel === nav.orgPath.length;
  const activeNav = _.get(nav, `orgPath[${nav.navLevel - 1}]`);
  const orgIdPath = nav.navLevel === 1 ? '' : `/${activeNav?.id}`;

  useLayoutEffect(() => {
    if (isSm) {
      setCollapsed(true);
    }
  }, [isSm]);

  return (
    <Sidebar>
      <SidebarHeader>
        {!collapsed && (
          <Logo
            onClick={() => {
              navigate('/home');
              mpEvent(MPEvents.LogoClick, {});
            }}
          />
        )}
        {collapsed ? (
          <ChevronRight
            onClick={(e) => {
              e.stopPropagation();
              setCollapsed(false);
              mpEvent(MPEvents.SideBarToggle, {
                value: 'open',
              });
            }}
            css="cursor: pointer;"
          />
        ) : (
          <ChevronLeft
            onClick={(e) => {
              e.stopPropagation();
              setCollapsed(true);
              mpEvent(MPEvents.SideBarToggle, {
                value: 'close',
              });
            }}
            css="cursor: pointer;"
          />
        )}
      </SidebarHeader>
      {!isWorkspaceMenu ? (
        <SidebarContent>
          <Menu>
            {isOrganisationAdmin ? (
              <MenuItem path={`/organisation${orgIdPath}`} Icon={Settings} dataCy="sidemenu-org-settings">
                Settings
              </MenuItem>
            ) : (
              <></>
            )}
            <MenuItem path="/get-started" Icon={Help}>
              Help
            </MenuItem>
          </Menu>
        </SidebarContent>
      ) : (
        <SidebarContent>
          <Menu>
            <MenuItem path="/timeline" Icon={Timeline} dataCy="sidemenu-tl">
              Timeline
            </MenuItem>
            <MenuItem path="/risk-assessments" Icon={RiskAssessments} dataCy="sidemenu-ra" onClick={() => setPage(1)}>
              Risk Assessments
            </MenuItem>
            <MenuItem path="/scenario-library" Icon={ScenLib} dataCy="sidemenu-sl">
              Scenario Library
            </MenuItem>
            <MenuItem path="/control-frameworks" Icon={ControlLib} dataCy="sidemenu-cf">
              Control Frameworks
            </MenuItem>
            <MenuItem path="/risk-management" Icon={RiskMng} dataCy="sidemenu-rm">
              Risk Management
            </MenuItem>
            <MenuItem path="/improvement-plans" Icon={Improovement}>
              Improvement Plans
            </MenuItem>
            <MenuItem path="/incidents" Icon={Incid} dataCy="sidemenu-si">
              Security Incidents
            </MenuItem>
            {isWorkspaceAdmin ? (
              <MenuItem path={`/workspace`} Icon={Settings} dataCy="sidemenu-workspace-settings">
                Settings
              </MenuItem>
            ) : (
              <></>
            )}
            {userFeatures.app.audit.report ? (
              <MenuItem path={`/audit/workspace`} Icon={AuditLog} dataCy="sidemenu-auw">
                Audit Log
              </MenuItem>
            ) : (
              <></>
            )}
            <MenuItem path="/get-started" Icon={Help}>
              Help
            </MenuItem>
          </Menu>
        </SidebarContent>
      )}

      <SidebarFooter>
        <Poppins px={14} weight={600} color="cflowerBlue" css="display: block" nowrap>
          Cydea Risk Assessment Tool
        </Poppins>
        <Poppins px={14} color="cflowerBlue" css="display: block" nowrap>
          © {new Date().getFullYear()} All Rights Reserved
        </Poppins>
      </SidebarFooter>
    </Sidebar>
  );
};

export default Aside;
