import React from 'react';
import { OrganisationWorkspace } from 'src/api/types';
import { CountLabel, Spacer } from 'src/common';
import { RowRequest } from 'src/components/orgRows';
import styled from 'styled-components';

const Div = styled.div`
  .orgs-list {
    display: grid;
    grid-gap: 10px;
  }
`;

interface RequestsProps {
  myRequests: OrganisationWorkspace[];
}

export const Requests: React.FC<RequestsProps> = ({ myRequests }) => {
  return (
    <Div>
      {myRequests.length > 0 && (
        <>
          <Spacer $px={30} />
          <CountLabel label="request" length={myRequests.length} />
          <Spacer $px={5} />
          <div className="orgs-list">
            {myRequests.map((el) => (
              <RowRequest key={el.id} id={el.id} name={el.name} img={null} isRequested />
            ))}
          </div>
        </>
      )}
    </Div>
  );
};
