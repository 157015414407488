import React, { useLayoutEffect, useState } from 'react';
import Meta from 'src/components/Meta';
import { useGaEventPageView } from 'src/utils/gaEvent';
import { PageContainer, PageOrgSkeleton, PermissionLabel, Poppins, Spacer, StyledAvatar } from 'src/common';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { getProfileCredentials } from 'src/utils/auth';
import { useQuery } from 'react-query';
import { QKeys } from 'src/api/types';
import { getOrgAdmin, getOrgUsers } from 'src/api/organisation';
import { useAuth } from 'src/state/auth';
import TabsMenu from 'src/components/TabsMenu';
import _ from 'lodash';
import { getOrgRoleLabel } from 'src/utils/misc';
import { Details, Members } from './tabs';
import useBreakpoints from 'src/hooks/useBreakpoints';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const Div = styled(PageContainer)`
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 17px;
      grid-gap: 17px;
    }
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    .head {
      flex-direction: column;
      align-items: flex-start;
      grid-gap: 10px;
    }
  }
`;

const OrganisationSettingsPage = () => {
  const { id } = useParams<{ id?: string }>();
  const { userPermissions, nav, user, isOrganisationAdmin } = useAuth();
  const [activeTab, setActiveTab] = useState(1);
  const isSm = useBreakpoints('sm');
  const navigate = useNavigate();
  useGaEventPageView('Cydea | Organisation | Settings');
  const orgId = id || _.get(nav, `orgPath[0].id`);

  useLayoutEffect(() => {
    if (!isOrganisationAdmin) {
      navigate('/home');
    }
  }, [isOrganisationAdmin]);

  const { data: organisation, error } = useQuery([QKeys.OrganisationAdmin, orgId], () => getOrgAdmin(orgId), {
    retry: false,
    onError: (err) => {
      console.error('err', err);
    },
  });

  const { data: members = [] } = useQuery([QKeys.OrganisationUsers, orgId], () => getOrgUsers(orgId), {
    enabled: !!organisation,
  });

  useLayoutEffect(() => {
    const step = nav.orgPath.findIndex((el) => el.id === id);
    if (step === -1) {
      nav.setNavLevel(1);
    } else {
      nav.setNavLevel(step + 1);
    }
  }, [nav, id]);

  return (
    <>
      <Meta title={`Cydea | Organisation | Settings`} feedbackScript />
      <>
        <Div>
          <Poppins px={32} weight={600}>
            Organisation Settings
          </Poppins>
          <Spacer $px={isSm ? 30 : 65} />
          {error && (
            <Poppins px={18}>
              {/* @ts-ignore */}
              {error.status} {error.msg || error.message}
            </Poppins>
          )}
          {organisation ? (
            <>
              <div className="head">
                <div className="head__left">
                  <StyledAvatar $medium src={''}>
                    {getProfileCredentials(organisation?.name)}
                  </StyledAvatar>
                  <Poppins px={22} weight={600}>
                    {organisation?.name}
                  </Poppins>
                </div>
                <PermissionLabel label={getOrgRoleLabel(user?.permissions)} />
              </div>
              <Spacer $px={30} />
              <TabsMenu
                activeTab={activeTab}
                setActiveTab={(tab) => {
                  setActiveTab(tab);
                  mpEvent(MPEvents.ButtonClick, {
                    button:
                      tab === 1
                        ? userPermissions.app.platform.admin
                          ? 'Tab:Details'
                          : 'Tab:Organisation Details'
                        : 'Tab:Members',
                    tags: ['ORGANISATION'],
                  });
                }}
                tabs={[
                  { label: userPermissions.app.platform.admin ? 'Details' : 'Organisation Details', value: 1 },
                  { label: 'Members', value: 2 },
                ]}
              />
              {activeTab === 1 && <Details organisation={organisation} />}
              {activeTab === 2 && <Members orgId={organisation.id} members={members} />}
            </>
          ) : (
            <PageOrgSkeleton />
          )}
        </Div>
      </>
    </>
  );
};

export default OrganisationSettingsPage;
