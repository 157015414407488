import React from 'react';
import colors from 'src/theme/colors';
import styled from 'styled-components';

const Svg = styled.svg`
  cursor: pointer;
  outline: none !important;
  ${({ theme }) => theme.breakpoints.down('md')} {
    width: 16px;
    height: 16px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 14px;
    height: 14px;
  }
`;

interface Props {
  tooltipId?: string;
}

export const InfoIcon: React.FC<Props> = ({ tooltipId = '' }) => {
  return (
    <Svg
      data-tooltip-id={tooltipId}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM11 15H9V9H11V15ZM11 7H9V5H11V7Z"
        fill={colors.cflowerBlue}
      />
    </Svg>
  );
};
