// @ts-nocheck
import React, { memo, useRef } from 'react';
import { pdfStyles } from '../../pdfStyles';
import { Page, View } from '@react-pdf/renderer';
import {
  ControlsTable,
  Footer,
  FooterStartComponent,
  Header,
  HeaderEndComponent,
  NoData,
  RmpPage,
  TitleLink,
} from '../../comps';
import { isEqual } from 'lodash';
import { Control, PdfAssessment } from 'src/api/types';
import { TocItem } from '../../types';

interface RmpRenderProps {
  assessmentOriginal: PdfAssessment;
  onDone: (pageNumber: number, toc: TocItem[]) => void;
  controls: Control[];
}

export const RmpRender: React.FC<RmpRenderProps> = memo(
  ({ assessmentOriginal, onDone, controls }) => {
    const mainPageNumber = useRef(0);

    const list: { [key: string]: TocItem } = {};

    const onRender = (pageNumber: number, idx: number, title: string) => {
      list[idx] = { pageNumber: pageNumber, title };

      if (idx === controls.length - 1) {
        const outputArray = Object.keys(list).map((key) => list[key]);
        onDone(mainPageNumber.current, outputArray);
      }
    };

    return (
      <>
        <Page size={'A4'} wrap style={pdfStyles.page}>
          <Header endComponent={<HeaderEndComponent title="BACK TO TOP" />} />
          <View
            render={(data) => {
              if (!controls.length) {
                onDone(data.pageNumber, []);
              } else {
                mainPageNumber.current = data.pageNumber;
              }
            }}
          />
          <View style={pdfStyles.section}>
            <TitleLink title={'Risk Management Plan'} intLinkId={'rmp.plan'} extLink={`risk-management`} />

            <View style={pdfStyles.spacer8} />
            <View style={pdfStyles.spacer4} />
            {controls.length ? <ControlsTable data={controls} /> : <NoData />}
          </View>

          <Footer
            footerComponent={<FooterStartComponent title={`${assessmentOriginal.name} / Risk Management Plan`} />}
          />
        </Page>
        {controls.map((el, idx) => {
          return (
            <RmpPage
              key={el.ux_id}
              control={el}
              onRender={(pageNumber) => onRender(pageNumber, idx, el.name)}
              endComponent={<HeaderEndComponent title="BACK TO TOP" />}
              footerComponent={
                <FooterStartComponent title={`${assessmentOriginal.name} / Risk Management Plan / ${el.name}`} />
              }
            />
          );
        })}
      </>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
