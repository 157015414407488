import { useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { getScenarioTemplates } from 'src/api/scenarioTemplates';
import { QKeys } from 'src/api/types';
import { TableIds } from 'src/api/types/misc';
import { Spacer, TableSkeleton } from 'src/common';
import GenericTable, { TableSheetScenarioLibrary, useScenarioLibraryColumns } from 'src/components/GenericTable';
import Button from 'src/components/form/Button';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import styled from 'styled-components';

const Div = styled.div``;

export const FromLib = () => {
  const { values, isSubmitting, handleSubmit, setFieldValue } = useFormikContext<{ selected: string[] }>();

  const { data = [], isLoading: isLoadingTemplates } = useQuery(QKeys.ScenarioTemplates, getScenarioTemplates);

  const { columns, checked, GTColumnSelectAnchorExported, setChecked } = useScenarioLibraryColumns({
    data,
    isCheckerEnabled: true,
    tableId: TableIds.addScenarioScenarioLib,
  });

  useEffect(() => {
    setFieldValue('selected', checked);
  }, [checked]);

  useEffect(() => {
    setChecked(values.selected);
  }, []);

  return (
    <Div className="h-padding">
      {!isLoadingTemplates ? (
        <>
          <GenericTable
            tableId={TableIds.addScenarioScenarioLib}
            data={data}
            columns={columns}
            GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
            expandContent={(row) => <TableSheetScenarioLibrary data={row} />}
            itemHeight={50}
            searchable={['author', 'name', 'source', 'event', 'consequence', 'description']}
          />
          <Spacer $px={30} />
          <Button
            primary
            onClick={() => {
              handleSubmit();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Add',
                modal: 'Scenario add modal',
                tags: ['SCENARIO', 'SCENARIO LIBRARY'],
              });
            }}
            disabled={!values.selected.length || isSubmitting}
            css="width: 100%;"
            data-cy="btn-add"
          >
            + ADD
          </Button>
        </>
      ) : (
        <TableSkeleton />
      )}
    </Div>
  );
};
