import React from 'react';

interface Props {
  isActive?: boolean;
}
export const ControlLib: React.FC<Props> = ({ isActive }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="16"
      viewBox="0 0 24 16"
      fill={isActive ? 'url(#icon-gradient)' : '#6A7590'}
    >
      <path d="M15.9279 1.30385C15.9279 0.583752 15.3442 0 14.6241 0H1.58558C0.86549 0 0.281738 0.583752 0.281738 1.30385C0.281738 2.02394 0.865491 2.60769 1.58559 2.60769H14.6241C15.3442 2.60769 15.9279 2.02394 15.9279 1.30385Z" />
      <path d="M15.9279 6.51924C15.9279 5.79914 15.3442 5.21539 14.6241 5.21539H1.58558C0.86549 5.21539 0.281738 5.79914 0.281738 6.51924C0.281738 7.23933 0.865491 7.82308 1.58559 7.82308H14.6241C15.3442 7.82308 15.9279 7.23933 15.9279 6.51924Z" />
      <path d="M0.281738 11.7346C0.281738 11.0145 0.865491 10.4308 1.58559 10.4308H9.40867C10.1288 10.4308 10.7125 11.0145 10.7125 11.7346C10.7125 12.4547 10.1288 13.0385 9.40867 13.0385H1.58559C0.865491 13.0385 0.281738 12.4547 0.281738 11.7346Z" />
      <path d="M12.6496 12.4135C12.2932 12.0571 12.2932 11.4792 12.6496 11.1228L13.203 10.5694C13.5594 10.213 14.1374 10.213 14.4938 10.5695L16.614 12.6901L21.5005 7.80378C21.8569 7.44736 22.4348 7.44736 22.7912 7.80379L23.3444 8.35696C23.7008 8.71338 23.7008 9.29127 23.3444 9.6477L17.2594 15.7327C16.903 16.0891 16.3251 16.0891 15.9686 15.7327L12.6496 12.4135Z" />

      <defs>
        <linearGradient id="icon-gradient" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stopColor="#E62E61" />
          <stop offset="1" stopColor="#FD5630" />
        </linearGradient>
      </defs>
    </svg>
  );
};
