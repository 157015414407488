import React from 'react';
import { GradientTextAction, Poppins } from 'src/common';
import colors from 'src/theme/colors';
import styled, { css } from 'styled-components';

const Label = styled(GradientTextAction)<{ $disabled: boolean }>`
  cursor: inherit;
  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${colors.cflowerBlue};
      cursor: default;
    `}
`;

interface GTModifierLabelProps {
  length: number;
  satisfiesModifier?: boolean;
  plainText?: boolean;
}

export const GTModifierLabel: React.FC<GTModifierLabelProps> = ({ length, satisfiesModifier = true, plainText }) => {
  const text = `${length} Modifier${length === 1 ? '' : 's'}`;

  if (plainText) {
    return <Poppins px={14}>{text}</Poppins>;
  }
  return (
    <Label px={14} $disabled={!satisfiesModifier} $underline>
      {text}
    </Label>
  );
};
