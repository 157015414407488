import { InputAdornment, StandardTextFieldProps } from '@mui/material';
import React from 'react';
import { Poppins } from 'src/common';
import MaterialInput from 'src/components/form/MaterialInput';
import colors from 'src/theme/colors';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';

interface StyleProps extends StandardTextFieldProps {
  currency?: boolean;
}

const MaterialInputCustom = styled(MaterialInput).attrs<StyleProps>(({ currency, ...p }) => {
  return {
    fullWidth: true,
    InputProps: currency
      ? {
          startAdornment: (
            <InputAdornment position="start">
              <Poppins px={18} weight={500} color="cflowerBlue">
                £
              </Poppins>
            </InputAdornment>
          ),
        }
      : undefined,
    ...p,
  };
})<StyleProps>`
  .MuiInputBase-root {
    background-color: ${colors.cultured};
  }

  & .MuiInputBase-root {
    padding-left: 10px;
  }

  input {
    padding-left: ${({ currency }) => (currency ? 0 : 8)}px !important;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      display: none;
    }
  }

  .MuiFormHelperText-root {
    bottom: -20px;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    input {
      padding-left: ${({ currency }) => (currency ? 0 : 20)}px;
    }
  }
`;

interface CurrencyInputProps extends StyleProps {
  onValueChange?: (a: { value: string; floatValue: number; formattedValue: string }) => void;
}

const CurrencyInput = (props: CurrencyInputProps) => {
  // @ts-ignore
  return <NumericFormat customInput={MaterialInputCustom} thousandSeparator="," {...props} />;
};

export const Symbol = styled(Poppins).attrs((p) => {
  return { px: 18, ...p };
})`
  color: ${colors.cflowerBlue};
  font-weight: 500;
`;

export default CurrencyInput;
