import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React, { memo, useCallback, useMemo } from 'react';
import { GTColumnSelectAnchor, GTTdm } from 'src/components/GenericTable';
import { filter, get, isEqual } from 'lodash';
import { GTInternalIds } from 'src/components/GenericTable/types';
import useVersion from 'src/hooks/useVersion';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import Input from 'src/components/form/Input';
import { ReactComponent as WizzardIcon } from 'src/assets/icon-wizzard.svg';
import { useFormikContext } from 'formik';
import { FormValues, VariableEdit } from '../../types';
import CurrencyInput from 'src/components/form/CurrencyInput';
import MaterialInput from 'src/components/form/MaterialInput';
import { Poppins } from 'src/common';

type UseColumns = () => {
  columns: ColumnDef<VariableEdit>[];
  GTColumnSelectAnchorExported: JSX.Element;
};

const defaultColumns = ['workspace_variable', 'name', 'currency', 'value'];
export const useColumns: UseColumns = () => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.incidentSecnarios}-columns`,
    defaultColumns,
  );
  const { version } = useVersion();
  const { values, setFieldValue, setFieldTouched, errors, touched } = useFormikContext<FormValues>();

  const remove = (id: number) => {
    setFieldValue(
      'variables',
      values.variables.filter((el) => el.id !== id),
    );
  };

  const columns = useMemo(() => {
    const internalIds = [GTInternalIds.tdm];

    const list: ColumnDef<VariableEdit>[] = [
      {
        id: 'workspace_variable',
        header: '',
        accessorFn: (row) => row.workspace_variable || null,
        cell: (info) => (info.getValue() ? <WizzardIcon /> : null),
        enableSorting: false,
        // size: 15,
        // maxSize: 15,
      },
      {
        id: 'name',
        header: 'Variable Name',
        accessorFn: (row) => row.name || null,
        cell: (info) => {
          const idx = info.row.index;
          const isErr = get(errors, `variables[${idx}].name`);
          const isTouched = get(touched, `variables[${idx}].name`);

          return info.row.original._isNew ? (
            <MaterialInput
              placeholder="Add variable name"
              value={info.row.original.name}
              onChange={(e) => setFieldValue(`variables[${idx}].name`, e.target.value)}
              onBlur={() => setFieldTouched(`variables[${idx}].name`, true)}
              error={Boolean(isTouched && isErr)}
              // helperText={isTouched && isErr}
              data-cy="variable-name-input"
              fullWidth={true}
            />
          ) : (
            <div className="var">
              <div className="var__chip">
                <Poppins px={16} weight={500}>
                  {info.getValue()}
                </Poppins>
              </div>
            </div>
          );
        },
        sortingFn: sortingFns.text,
      },
      {
        id: 'currency',
        header: 'Currency',
        accessorFn: (row) => row.currency,
        cell: (info) => {
          return (
            <div className="currency-wrap">
              <Input
                checked={info.row.original.currency}
                onChange={(e) => {
                  setFieldValue(`variables[${info.row.index}].currency`, e.target.checked);
                }}
                className="switch"
                type="checkbox"
              />
            </div>
          );
        },
      },
      {
        id: 'value',
        header: 'Value',
        accessorFn: (row) => row.value || null,
        cell: (info) => {
          const idx = info.row.index;
          const isErr = get(errors, `variables[${idx}].value`);
          const isTouched = get(touched, `variables[${idx}].value`);

          return (
            <CurrencyInput
              placeholder="Enter value"
              value={info.getValue() as number}
              currency={info.row.original.currency}
              onValueChange={(e) => setFieldValue(`variables[${idx}].value`, e.floatValue)}
              onBlur={() => setFieldTouched(`variables[${idx}].value`, true)}
              error={Boolean(isTouched && isErr)}
              // helperText={isTouched && isErr}
              data-cy="variable-value-input"
              fullWidth={true}
            />
          );
        },
        sortingFn: sortingFns.alphanumeric,
      },
      {
        id: GTInternalIds.tdm,
        header: () => null,
        cell: ({ row }) => {
          return (
            <div css="display: flex;grid-gap: 8px">
              <GTTdm onDeleteClick={() => remove(row.original.id)} />
            </div>
          );
        },
      },
    ];

    return filter(list, (el) => [...internalIds, ...selectedColumns].includes(el.id as string));
  }, [selectedColumns, version, values.variables.length]);

  const GTColumnSelectAnchorExported = (
    <GTColumnSelectAnchor
      options={[
        { key: 'name', title: 'Variable Name' },
        { key: 'currency', title: 'Currency' },
        { key: 'value', title: 'Value' },
      ]}
      defaultOptions={defaultColumns}
      value={selectedColumns}
      onChange={setSelectedColumns}
    />
  );

  return { columns, GTColumnSelectAnchorExported };
};
