import React from 'react';
import colors from 'src/theme/colors';

interface Props {
  isActive?: boolean;
}

export const IconInvite: React.FC<Props> = ({ isActive }) => {
  return (
    <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall" focusable="false" viewBox="0 0 24 24">
      <path
        fill={isActive ? colors.red : colors.cflowerBlue}
        d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4m-9-2V7H4v3H1v2h3v3h2v-3h3v-2zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4"
      ></path>
    </svg>
  );
};
