import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'src/components/form/Button';
import MaterialInput from 'src/components/form/MaterialInput';
import { useFormik } from 'formik';
import { queryCache, useMutation, useQuery } from 'react-query';
import { getAssessment, updateAssessment } from 'src/api/assessment';
import * as Yup from 'yup';
import { Poppins, Spacer } from 'src/common';
import { Guard } from './Guard';
import { Modal } from './comps';
import { QKeys } from 'src/api/types';
import colors from 'src/theme/colors';
import { getDiff } from 'src/utils/getDiff';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';

const StyledModal = styled(Modal)`
  width: 566px;
  color: ${colors.cflowerBlue};

  .error {
    color: ${colors.error};
    text-align: center;
  }
`;

const assessmentSchema = Yup.object({
  executive_summary: Yup.string(),
});

interface ExecSummaryModalProps {
  assessmentId: string;
  onClose: () => void;
}

export const ExecSummaryModal: React.FC<ExecSummaryModalProps> = ({ assessmentId, onClose }) => {
  const [isCloseRequested, setIsCloseRequested] = useState(false);
  const [err, setErr] = useState('');

  const onSuccess = () => {
    queryCache.invalidateQueries();
    onClose();
  };

  const onError = (err: any) => {
    setErr('Something went wrong');
  };

  const [update] = useMutation(updateAssessment, { onSuccess, onError });

  const { data } = useQuery([QKeys.Assessment, { assessmentId }], getAssessment);

  const { values, dirty, handleChange, handleBlur, touched, errors, isValid, handleSubmit, isSubmitting } = useFormik({
    initialValues: {
      executive_summary: data?.executive_summary || '',
    },
    validationSchema: assessmentSchema,
    onSubmit: async (values) => {
      setErr('');
      const diff = getDiff({ executive_summary: data?.executive_summary }, values);
      return update({ ...diff, id: assessmentId });
    },
    enableReinitialize: true,
  });

  return (
    <Guard
      onCancel={() => setIsCloseRequested(false)}
      onClose={() => onClose()}
      isOpen={isCloseRequested}
      isDirty={dirty}
      modalComponent={
        <StyledModal onRequestClose={() => setIsCloseRequested(true)} isOpen>
          <Poppins className="m-title" px={28}>
            Executive Summary
          </Poppins>
          <Spacer $px={30} />
          <MaterialInput
            name="executive_summary"
            value={values.executive_summary}
            onChange={handleChange}
            onBlur={handleBlur}
            multiline
            fullWidth
            error={touched.executive_summary && Boolean(errors.executive_summary)}
            helperText={touched.executive_summary && errors.executive_summary}
            placeholder="Executive summary"
            rows={10}
            data-cy="exec-summary-input"
          />
          <Spacer $px={30} />
          <Button
            disabled={!isValid || isSubmitting || !dirty}
            onClick={() => {
              handleSubmit();
              mpEvent(MPEvents.ButtonClick, {
                button: 'Add',
                modal: 'Executive summary modal',
              });
            }}
            primary
            css="width: 100%;"
            data-cy="submit"
          >
            ADD
          </Button>
          {err && (
            <>
              <Spacer $px={20} />
              <Poppins px={14} className="error">
                {err}
              </Poppins>
            </>
          )}
        </StyledModal>
      }
    />
  );
};
