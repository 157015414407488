import React, { useEffect, useRef, memo } from 'react';
import styled from 'styled-components';
import { toJpeg } from 'html-to-image';
import { isEqual } from 'lodash';
import Chart from 'src/components/charts/Chart';
import { ChartConf } from 'src/components/charts/Chart/Chart';

const Div = styled.div`
  z-index: -100;
  width: 900px;
  position: absolute;
`;

interface SnapChartImageProps {
  isLogarithmic: boolean;
  onSnap: (data: string) => void;
  disabled?: boolean;
  chartConf: ChartConf[];
}

export const SnapChartImage: React.FC<SnapChartImageProps> = memo(
  ({ isLogarithmic, onSnap, disabled, chartConf }) => {
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (disabled) return;
      const timer = setTimeout(() => {
        toJpeg(elementRef.current as HTMLElement, { cacheBust: false, quality: 0.5, backgroundColor: 'white' })
          .then((dataUrl) => {
            onSnap(dataUrl);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            clearTimeout(timer);
          });
      }, 10);
    }, [disabled]);

    if (disabled) {
      return null;
    }

    return (
      <Div ref={elementRef}>
        <Chart
          isLogarithmic={isLogarithmic}
          isPrintable
          input={chartConf.map((el) => {
            return { ...el, name: 'chart' };
          })}
        />
      </Div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
